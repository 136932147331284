import React from 'react'
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons'

// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: "MaterialCommunityIcons";
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject stylesheet
document.head.appendChild(style)

export const MCIcon = props => {
  return (
    <Icon
      name={props.name}
      size={props.size}
      color={props.color}
      style={props.style}
    />
  )
}
