import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { BaseText, HoverableTextTouchHighlight, MIcon } from '../../components'
import { colors, scale, hexToRgba } from '../../functions'

/*
 * To Do:
 *
 * - listen for a click/touch outside so we can close this.
 *
 */

const s = StyleSheet.create({
  wrapper: {
    display: 'flex'
  },
  wrapperActive: {
    zIndex: 1000,
  },
  dropdownContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    paddingRight: 20,
  },
  dropdownItems: {
    position: 'absolute',
    top: '100%',
    width: '100%',
  },
  dropdownItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: colors.primary3,
  },
  dropdownItemHover: {
    backgroundColor: colors.primary2,
  },
  dropdownItemActive: {
    backgroundColor: colors.primary3,
  },
})

const arrowIconSize = scale(28)

class Dropdown extends React.Component {
  state = {
    opened: false,
    selected: null,
  }
  componentWillMount() {
    if (this.props.selected) {
      this.setState({ selected: this.props.selected })
    }
  }
  handleToggle = () => {
    this.setState({ opened: !this.state.opened })
  }
  handleItemPress = label => {
    if (this.state.selected === label) {
      return
    }
    this.setState({ selected: label, opened: false })
    this.props.handleItemPress(label)
  }
  render() {
    const { opened } = this.state
    const {
      containerStyle,
      containerActiveStyle,
      itemContainerStyle,
      itemContainerActiveStyle,
      wrapperStyle,
      disabled,
    } = this.props
    return (
      <View
        style={[
          s.wrapper,
          wrapperStyle && wrapperStyle,
          opened && s.wrapperActive,
        ]}
      >
        <TouchableOpacity
          activeOpacity={0.8}
          style={[
            s.dropdownContainer,
            containerStyle && containerStyle,
            containerActiveStyle && opened && containerActiveStyle,
          ]}
          onPress={disabled ? () => {} : this.handleToggle}
        >
          <BaseText
            fs={16}
            lh={24}
            pv={11}
            mr={30}
            ta={'center'}
            color={disabled ? 'disabled' : 'secondary3'}
            children={this.state.selected && this.state.selected}
          />
          <MIcon
            name={`arrow-drop-${opened ? 'up' : 'down'}`}
            color={colors.teal}
            size={arrowIconSize}
            style={s.arrowIcon}
          />
        </TouchableOpacity>

        {opened && (
          <View style={s.dropdownItems}>
            {this.props.items &&
              this.props.items.map((x, i) => {
                const active = this.state.selected === x.label ? true : false
                return (
                  <HoverableTextTouchHighlight
                    key={i}
                    activeOpacity={1}
                    underlayColor={colors.primary2}
                    text={x.label}
                    style={[
                      s.dropdownItem,
                      itemContainerStyle && itemContainerStyle,
                      itemContainerActiveStyle &&
                        active &&
                        itemContainerActiveStyle,
                    ]}
                    hoverStyle={s.dropdownItemHover}
                    textStyle={{
                      color: active ? colors.teal : colors.secondary2,
                    }}
                    // textStyle={
                    // 	active
                    // 		? { color: colors.teal, fontFamily: fonts.semibold }
                    // 		: { color: colors.secondary2 }
                    // }
                    textHoverStyle={{ color: colors.teal }}
                    onPress={() => this.handleItemPress(x.label, x.action)}
                  />
                )
              })}
          </View>
        )}
      </View>
    )
  }
}

Dropdown.propTypes = {
  /** a list of items for the dropdowm. each item in the array needs a `label` at minimum */
  items: t.array.isRequired,
  /** the label of the selected value */
  selected: t.string.isRequired,
  /** the function that gets called when an item is selected */
  handleItemPress: t.func.isRequired,
  /** style for View that wraps the component */
  wrapperStyle: t.object,
  /** element disabled */
  disabled: t.bool,
}

export default Dropdown
