import React from 'react'
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
} from 'react-native'

// const ratio = 354 / 230

const factor = 1.1
const image_width = Math.round(354 * factor)
const image_height = Math.round(230 * factor)

const s = StyleSheet.create({
  touchable: {
    display: 'flex',
    // marginHorizontal: '9.5px',
    marginRight: '19px',
  },
  imageBackground: {
    width: image_width,
    height: image_height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  image: {
    width: image_width,
    height: image_height,
    borderRadius: 10,
    resizeMode: 'cover',
  },
  name: {
    fontFamily: 'OpenSans',
    fontSize: '20px',
    lineHeight: '27px',
    fontWeight: '300',
    color: '#fff',
  },
  date: {
    fontFamily: 'OpenSans',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '300',
    color: '#C4C4C4',
  },
  backgroundFilter: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(35,35,35, 0.5)',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: '17px',
    paddingTop: '22px',
    paddingBottom: '6px',
    zIndex: 2,
  },
})

export const EventCard = ({
  event,
  event: { image, title, date },
  handlePress,
}) => (
  <TouchableOpacity
    activeOpacity={0.8}
    style={s.touchable}
    onPress={() => handlePress(event)}
  >
    <ImageBackground
      source={image}
      style={s.imageBackground}
      imageStyle={s.image}
      resizeMode="cover"
    >
      <View style={s.backgroundFilter}>
        <Text style={s.name}>{title}</Text>
        <Text style={s.date}>{date}</Text>
      </View>
    </ImageBackground>
  </TouchableOpacity>
)
