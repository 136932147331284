import gql from 'graphql-tag'

export const CREATE_VENUE = gql`
  mutation(
    $placeId: String!
    $name: String!
    $address: String!
    $phoneNumber: String
    $intlPhoneNumber: String
    $website: String
    $geoloc: Json!
    $hoursText: Json
    $hours: [VenuehoursHoursPeriod!]
  ) {
    createVenue(
      placeId: $placeId
      name: $name
      address: $address
      phoneNumber: $phoneNumber
      intlPhoneNumber: $intlPhoneNumber
      website: $website
      geoloc: $geoloc
      hoursText: $hoursText
      hours: $hours
    ) {
      id
      placeId
      name
      address
      phoneNumber
      intlPhoneNumber
      website
      geoloc
      hoursText
    }
  }
`
