import React from 'react'
import { StyleSheet } from 'react-native'
import { getNumberOfFormLines } from '../../../functions'
import {
  ResponsiveContainer,
  NumericInput,
  LabelInput,
  BaseText,
} from '../../../components'
import { DimensionsConsumer } from '../../../lib'

const s = StyleSheet.create({
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  firstRowMobile: {
    flexDirection: 'column',
  },
  labelInputWrapperStyle: {
    marginBottom: 0,
    paddingBottom: 0,
    borderWidth: 0,
    marginRight: 8,
    flex: 1,
  },
  textInputStyle: {
    padding: 10,
    paddingRight: 10,
    backgroundColor: '#2a2a34',
    width: '100%',
  },
  textInputCenterAlign: {
    textAlign: 'center',
  },
})

export const Details = ({ additionalProps }) => (
  <DimensionsConsumer>
    {({ width }) => {
      var descLength = getNumberOfFormLines(additionalProps.values.description)
      descLength = descLength === 1 ? 2 : descLength

      return (
        <React.Fragment>
          <ResponsiveContainer
            style={s.firstRow}
            breakpoint={769}
            breakpointStyle={s.firstRowMobile}
          >
            <LabelInput
              collapseLabel={true}
              labelwidth={'100%'}
              label={'Ticket name'}
              name={'name'}
              onChangeText={additionalProps.setFieldValue}
              onChangeTextOnlyOnBlur={true}
              value={additionalProps.values.name}
              wrapperStyle={[
                s.labelInputWrapperStyle,
                width >= 769
                  ? { width: '100%', maxWidth: 350, flex: -1 }
                  : null,
              ]}
              autoComplete={'off'}
              textInputStyle={s.textInputStyle}
              placeholder={'Ticket name here'}
            />
            <LabelInput
              collapseLabel={true}
              label={'Quantity'}
              name={'maxQuantity'}
              onChangeText={additionalProps.setFieldValue}
              onChangeTextOnlyOnBlur={true}
              value={
                additionalProps.values.maxQuantity
                  ? additionalProps.values.maxQuantity.toString()
                  : ''
              }
              wrapperStyle={s.labelInputWrapperStyle}
              textInputStyle={[s.textInputStyle, s.textInputCenterAlign]}
              textInputKeyboardType="numeric"
              autoComplete={'off'}
            />
            <NumericInput
              collapseLabel={true}
              label={'Price'}
              noFill={true}
              labelWidth={75} // this is being weird, needed this or Price From is on 2 lines
              name={'price'}
              onChangeText={additionalProps.setFieldValue}
              onChangeTextOnlyOnBlur={true}
              value={
                additionalProps.values.price
                  ? parseFloat(additionalProps.values.price)
                  : ''
              }
              wrapperStyle={s.labelInputWrapperStyle}
              textInputStyle={s.textInputStyle}
              autoComplete={'off'}
            />
          </ResponsiveContainer>
          <LabelInput
            collapseLabel={true}
            label={'Description'}
            name={'description'}
            multiline={true}
            numberOfLines={descLength}
            onChangeText={additionalProps.setFieldValue}
            onChangeTextOnlyOnBlur={true}
            value={additionalProps.values.description || ''}
            placeholder={'Type your description here.'}
            wrapperStyle={s.labelInputWrapperStyle}
            textInputStyle={s.textInputStyle}
            autoComplete={'off'}
          />
          {additionalProps.errors.name && (
            <BaseText
              fs={12}
              color={'pink'}
              children={additionalProps.errors.name}
            />
          )}
          {additionalProps.errors.maxQuantity && (
            <BaseText
              fs={12}
              color={'pink'}
              children={additionalProps.errors.maxQuantity}
            />
          )}
          {additionalProps.errors.price && (
            <BaseText
              fs={12}
              color={'pink'}
              children={additionalProps.errors.price}
            />
          )}
        </React.Fragment>
      )
    }}
  </DimensionsConsumer>
)
