/*
 * To do:
 *
 * - clean up this file (in relation to routesNew.js). not sure what purpose it serves anymore,
 * perhaps just styles for the SideNav? or QuickActions ?
 *
 */

import * as Screens from '../screens'
import * as Layouts from '../layouts'

export const routes = [
  {
    layout: Layouts.PublicLayout,
    exact: true,
    path: '/',
    component: Screens.Landing,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.PublicLayout,
    path: '/solutions',
    component: Screens.Solutions,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.PublicLayout,
    path: '/resources',
    component: Screens.Resources,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.PublicLayout,
    path: '/contact',
    component: Screens.Landing,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.PublicLayout,
    path: '/login',
    component: Screens.Login,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.PublicLayout,
    path: '/employee-login',
    component: Screens.Login,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/dashboard',
    label: 'Dashboard',
    iconName: 'pulse',
    iconType: 'custom',
    iconColor: '#7E5EFF',
    component: Screens.Dashboard,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/manage-venue',
    label: 'Manage Venue',
    quickActionLabel: 'Venue',
    iconName: 'places-home-4',
    iconType: 'custom',
    iconColor: '#5E5EFF',
    component: Screens.ManageVenue,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/specials',
    label: 'Specials',
    quickActionLabel: 'Specials',
    iconName: 'specials',
    iconType: 'custom',
    iconColor: '#98FF5E',
    component: Screens.Specials,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/tools',
    label: 'Tools',
    quickActionLabel: 'Tools',
    iconName: 'build',
    iconType: 'material',
    iconColor: '#16CCD9',
    component: Screens.Tools,
    stackProps: {
      animationType: 'none',
    },
    allowedRoles: ['ADMIN', 'SUPERADMIN'],
  },
  {
    layout: Layouts.AuthLayoutStack,
    path: '/special',
    component: Screens.Special,
    stackProps: {
      animationType: 'slide-vertical',
      replaceTransitionType: 'POP',
    },
  },
  {
    layout: Layouts.AuthLayoutStack,
    path: '/event',
    component: Screens.Event,
    stackProps: {
      animationType: 'slide-vertical',
      replaceTransitionType: 'POP',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/reports',
    label: 'Reports',
    quickActionLabel: 'Reports',
    iconName: 'trending-up',
    iconType: 'material',
    iconColor: '#7E5EFF',
    component: Screens.Reports,
    stackProps: {
      animationType: 'none',
    },
    allowedRoles: ['ADMIN', 'SUPERADMIN'],
  },
  {
    layout: Layouts.AuthLayout,
    path: '/events',
    label: 'Event Manager',
    quickActionLabel: 'Events',
    iconName: 'flash',
    iconType: 'custom',
    iconColor: '#FFD45E',
    component: Screens.Events,
    stackProps: {
      animationType: 'none',
      replaceTransitionType: 'POP',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/account',
    label: 'Business Account',
    quickActionLabel: 'Organization',
    iconName: 'emoticonexcited',
    iconType: 'custom',
    iconColor: '#FF895E',
    component: Screens.Account,
    stackProps: {
      animationType: 'none',
    },
  },
  {
    layout: Layouts.AuthLayout,
    path: '/banking',
    label: 'Banking',
    quickActionLabel: 'Banking',
    iconName: 'piggy-bank',
    iconType: 'custom',
    iconColor: '#FF5E9E',
    component: Screens.Banking,
    stackProps: {
      animationType: 'none',
    },
  },
]

export const routeHeaders = {
  account: {
    label: 'Business Account',
    color: '#FF895E',
    colors: ['#E65151', '#FF895E'],
  },
  banking: {
    label: 'Banking',
    color: '#FF5E9E',
    colors: ['#E651D3', '#FF5E9E'],
  },
  organization: {
    label: 'Organization',
    color: '#7E5EFF',
    colors: ['#16A996', '#1DEACC'],
  },
  dashboard: {
    label: 'Dashboard',
    color: '#7E5EFF',
    colors: ['#5E79FF', '#5ECFFF'],
  },
  events: {
    label: 'Event Manager',
    color: '#FFD45E',
    colors: ['#FF935E', '#FFD45E'],
  },
  'events-manager-admin': {
    label: 'Events Manager Admin',
    color: '#FFD45E',
    colors: ['#FF935E', '#FFD45E'],
  },
  'manage-venue': {
    label: 'Manage Venue',
    color: '#5E5EFF',
    colors: ['#7E5EFF', '#A45EFF'],
  },
  specials: {
    label: 'Weekly Specials',
    color: '#98FF5E',
    colors: ['#25B747', '#98FF5E'],
  },

  tools: {
    label: 'Tools',
    color: '#7E5EFF',
    colors: ['#16A996', '#1DEACC'],
  },
  'create-venue': {
    label: 'Create Venue',
    color: '#7E5EFF',
    colors: ['#16A996', '#1DEACC'],
  },
  reports: {
    label: 'Reports',
    color: '#5E5EFF',
    colors: ['#7E5EFF', '#A45EFF'],
  },
  'bulk-create-venue': {
    label: 'Bulk Create Venue',
    color: '#7E5EFF',
    colors: ['#16A996', '#1DEACC'],
  },
}
