import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { DimensionsConsumer } from '../../lib'
import { Button, BaseText } from '../../components'
import { colors, scale, hexToRgba } from '../../functions'

const flexRatio = 0.85

// using size of viewbox when the 410x64 text box is converted to a svg
const logoWidthBase = 402.176
const logoHeightBase = 48.385
const aspectRatio = logoHeightBase / logoWidthBase

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  top: {
    display: 'flex',
    flex: flexRatio,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: scale(-50),
  },
  bottom: {
    position: 'absolute',
    width: '100%',
    bottom: scale(50),
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    paddingVertical: '40px',
  },
  buttonText: {
    color: colors.primary1,
  },
  buttonStyle: {
    width: '217px',
  },
  textShadow: {
    textShadowColor: hexToRgba(colors.black0, 0.16),
    textShadowOffset: { width: 0, height: 3 }, // x & y in XD
    textShadowRadius: 6, // b in XD
  },
})

// define view width variables for mobile styles
const logoVw = 0.58 // ~239/414
const textVw = 0.06 // ~26/414
const marginVw = 0.04 // ~17/414

export const Landing = ({ history }) => (
  <DimensionsConsumer>
    {({ width }) => {
      // define breakpoints for the logo and 'Venue Manager' text
      const logoBreakpoint = width <= 670 // (402px / 60vw)
      const textBreakpoint = width <= 550 // (33px / 6vw)

      // set width & height values for image
      const logoWidth = logoBreakpoint ? width * logoVw : logoWidthBase
      const logoHeight = logoBreakpoint
        ? logoWidth * aspectRatio
        : logoHeightBase

      return (
        <View style={s.wrapper}>
          <View style={s.top}>
            <Image
              source={require('../../assets/barcard-text-logo.svg')}
              /* eslint-disable react-native/no-inline-styles */
              style={{
                width: logoWidth,
                height: logoHeight,
                marginBottom: scale(textBreakpoint ? width * marginVw : 24),
              }}
              resizeMode={'contain'}
            />
            <BaseText
              fs={textBreakpoint ? textVw * width : 33}
              weight={'regular'}
              style={s.textShadow}
              children={'Venue Manager'}
            />
          </View>
          <View style={s.bottom}>
            <Button
              disabled={false}
              text={'Log In'}
              color={colors.white}
              textStyle={s.buttonText}
              containerStyle={s.buttonContainer}
              buttonStyle={s.buttonStyle}
              onPress={() => history.push('/login')}
            />
          </View>
        </View>
      )
    }}
  </DimensionsConsumer>
)
