import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native'
import { graphql, compose } from 'react-apollo'
import {
  ORGANIZATIONS_QUERY,
  DELETE_ORGANIZATION_VENUE,
  CREATE_ORGANIZATION_VENUE,
  VENUES_QUERY,
} from '../../gql'
import { Section, DeleteModal } from './components'
import { sections } from './sections'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'

import {
  ContentContainer,
  ErrorsContainer,
  FormButtons,
} from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

class AssignVenueForm extends React.Component {
  state = {}

  render() {
    const { organizations, venues, values, history } = this.props
    const ready = !(
      organizations.loading ||
      venues.loading ||
      organizations.error ||
      venues.error
    )
    const { allOrganizations } = organizations
    const { allVenues } = venues

    const initialState = {
      permissions: [],
    }

    if (!ready) {
      return <ActivityIndicator />
    }

    return (
      <ScrollView
        ref={c => (this.scrollView = c)}
        contentContainerStyle={s.wrapper}
        scrollsToTop={true}
      >
        <ContentContainer
          style={s.contentContainer}
          smallStyle={s.contentContainerMobile}
        >
          {!ready ? (
            <ActivityIndicator />
          ) : (
            <Mutation
              mutation={DELETE_ORGANIZATION_VENUE}
              refetchQueries={mutationResult => [
                {
                  query: ORGANIZATIONS_QUERY,
                },
              ]}
            >
              {(deleteOrganizationVenue, { data }) => (
                <Mutation
                  mutation={CREATE_ORGANIZATION_VENUE}
                  refetchQueries={mutationResult => [
                    {
                      query: ORGANIZATIONS_QUERY,
                    },
                  ]}
                >
                  {(createOrganizationVenue, { data }) => (
                    <Formik
                      ref={this.props.formRef}
                      enableReinitialize={true}
                      initialValues={initialState}
                      validate={values => {
                        let errors = {}

                        if (!values.id) {
                          errors.noOrganization = 'No Organization selected'
                        }

                        if (!values.assignedVenues.length) {
                          errors.noVenues = 'No Venues selected'
                        } else {
                          let venues = values.assignedVenues.filter(
                            item => !item.isDeleted,
                          )

                          if (!venues.length) {
                            errors.noVenues = 'No Venues selected'
                          }
                        }

                        return errors
                      }}
                      onSubmit={async (values, actions) => {
                        const currentOrg = organizations.allOrganizations.find(
                          org => org.id === values.id,
                        )
                        try {
                          actions.setSubmitting(true)

                          const deletedVenues = values.assignedVenues.filter(
                            item => {
                              return item.isDeleted
                            },
                          )
                          let permissionIdsToBeDeleted = []
                          currentOrg.permissions.map(permission => {
                            if (
                              deletedVenues.find(
                                item => item.id === permission.targetVenue.id,
                              )
                            ) {
                              permissionIdsToBeDeleted.push(permission.id)
                              return
                            }
                          })
                          console.log(
                            'permissionIdsToBeDeleted',
                            permissionIdsToBeDeleted,
                          )
                          let resDeleteOrgVenue = await Promise.all(
                            permissionIdsToBeDeleted.map(id => {
                              return deleteOrganizationVenue({
                                variables: { id },
                              })
                            }),
                          )
                          console.log(`res.data.deleteEvent`, resDeleteOrgVenue)

                          const addedVenues = values.assignedVenues.filter(
                            item => {
                              return item.isAdded
                            },
                          )
                          console.log('addedVenues', addedVenues)
                          let resCreateOrgVenue = await Promise.all(
                            addedVenues.map(({ id }) => {
                              return createOrganizationVenue({
                                variables: {
                                  id: values.id,
                                  targetVenueId: id,
                                },
                              })
                            }),
                          )
                          console.log(`res.data.createEvent`, resCreateOrgVenue)

                          actions.setSubmitting(false)
                          history.goBack()
                        } catch (error) {
                          console.log('error', error)
                          actions.setSubmitting(false)
                        }
                      }}
                      render={props => {
                        const visibleSections = props.values.id
                          ? sections
                          : sections.slice(0, 1)

                        return (
                          <React.Fragment>
                            {visibleSections.map((x, i) => {
                              return (
                                <Section
                                  key={i}
                                  additionalProps={props}
                                  organizations={allOrganizations}
                                  venues={allVenues}
                                  {...x}
                                />
                              )
                            })}
                            <FormButtons
                              disabled={
                                !props.dirty ||
                                !props.isValid ||
                                props.isSubmitting
                              }
                              isSubmitting={props.isSubmitting}
                              handleSubmit={props.handleSubmit}
                              handleCancel={() => {
                                props.resetForm({})
                                this.props.handleBack()
                              }}
                            />
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </ContentContainer>
      </ScrollView>
    )
  }
}

export default compose(
  graphql(ORGANIZATIONS_QUERY, {
    options: props => ({
      fetchPolicy: 'cache-and-network',
    }),
    name: 'organizations',
  }),
  graphql(VENUES_QUERY, {
    options: props => ({
      fetchPolicy: 'cache-and-network',
    }),
    name: 'venues',
  }),
)(AssignVenueForm)
