import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { colors, scale } from '../../../functions'
import { BaseText, Checkbox, MIcon } from '../../../components'
import Stars from 'react-native-stars-rating'
import { TouchableBaseText } from './TouchableBaseText'

const s = StyleSheet.create({
  suggestion: {
    flexDirection: 'column',
    marginVertical: scale(5),
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
    backgroundColor: colors.primary3,
    borderRadius: scale(20),
  },
  suggestionColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
})

// Class used to display a card with information on a venue suggestion with a unique placeId.
// props: suggestion
export class SuggestionCard extends React.Component {
  // Navigate to the given URL in a new tab
  _navigate = url => {
    return window.open(url, '_blank')
  }

  // Navigate to a Google Place given its ID
  _navigateToPlace = placeId => {
    const placeUrl = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`
    return this._navigate(placeUrl)
  }

  render() {
    // Gather suggestion from props
    const { suggestion, onChecked } = this.props

    return (
      <View style={s.suggestion}>
        <View style={s.suggestionColumns}>
          <View style={s.leftColumn}>
            <BaseText
              fs={scale(20)}
              lh={scale(26)}
              weight={'semibold'}
              children={suggestion.formattedSuggestion.mainText}
            />
            <BaseText
              fs={scale(14)}
              lh={scale(18)}
              weight={'regular'}
              children={suggestion.formattedSuggestion.secondaryText}
            />
            {suggestion.formattedSuggestion.rating && (
              <Stars
                isActive={true}
                rateMax={5}
                isHalfStarEnabled={true}
                onStarPress={() => {}}
                rate={suggestion.formattedSuggestion.rating}
                size={15}
              />
            )}
          </View>
          <View style={s.rightColumn}>
            {suggestion.venueId ? (
              <React.Fragment />
            ) : (
              // <TouchableOpacity onPress={() => {}} activeOpacity={0.8}>
              //   <MIcon name="close" size={24} color={colors.secondary3} />
              // </TouchableOpacity>
              <Checkbox
                value={suggestion.checked}
                onChange={value => {
                  if (!this.props.disabled) {
                    onChecked(value)
                    this.forceUpdate()
                  }
                }}
              />
            )}
            <TouchableBaseText
              onPress={() => this._navigateToPlace(suggestion.placeId)}
              color={'teal'}
              fs={scale(14)}
              lh={scale(14)}
              weight={'semibold'}
              children={'View in Maps'}
            />
          </View>
        </View>
      </View>
    )
  }
}
SuggestionCard.propTypes = {
  suggestion: t.shape({
    placeId: t.string.isRequired,
    formattedSuggestion: t.shape({
      mainText: t.string.isRequired,
      secondaryText: t.string.isRequired,
    }).isRequired,
  }).isRequired,
}
