import React from 'react'
import {
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  View,
} from 'react-native'
import { Query, Mutation } from 'react-apollo'
import Modal from 'modal-enhanced-react-native-web'
import { colors, fonts, hexToRgba } from '../../../functions'
import { DimensionsConsumer } from '../../../lib'
import { DELETE_TICKET_OPTION } from '../../../gql'
import {
  BaseText,
  FormButton,
  HoverableText,
  PhotoCardSquare,
  PhotoUploader,
  PhotoPlaceholder,
  ScrollColumn,
  MCIcon,
} from '../../../components'

const WIDTH = 400
const PHOTOS_HEIGHT = 450

const s = StyleSheet.create({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: WIDTH,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 23,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingLeft: 30,
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginTop: 7,
    marginBottom: 14,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.secondary2,
    zIndex: 6,
  },
})

const SectionTitle = props => (
  <BaseText
    fs={20}
    lh={27}
    mb={7}
    weight={'semibold'}
    color={'secondary3'}
    {...props}
  />
)

export class DeleteModal extends React.Component {
  state = {
    modalIsVisible: false,
  }

  render() {
    const { modalIsVisible } = this.state
    const data = {
      variables: {
        id: this.props.id,
        isDeleted: true,
      },
    }

    return (
      <DimensionsConsumer>
        {({ width, height }) => {
          const isSmall = width < 673 // 653 + 10 px on each side
          const modalWidth = width - 20

          return (
            <Mutation mutation={DELETE_TICKET_OPTION}>
              {(deleteTicketOption, { error, loading }) => (
                <React.Fragment>
                  {this.props.render(() =>
                    this.setState({ modalIsVisible: true }),
                  )}

                  <Modal
                    isVisible={modalIsVisible}
                    onBackdropPress={() =>
                      this.setState({ modalIsVisible: false })
                    }
                  >
                    <View
                      style={[
                        s.modal,
                        isSmall && { width: modalWidth, paddingRight: 0 },
                      ]}
                    >
                      <View style={s.modalMessageContainer}>
                        <View style={s.sectionHeader}>
                          <BaseText
                            fs={20}
                            lh={27}
                            weight={'light'}
                            children={`Are you sure you want to delete?`}
                          />
                        </View>
                      </View>
                      <View style={s.modalControls}>
                        <View style={s.buttonsContainer}>
                          <FormButton
                            text={'Cancel'}
                            textColor={colors.pink}
                            handlePress={() =>
                              this.setState({ modalIsVisible: false })
                            }
                          />
                          <FormButton
                            text={'Delete'}
                            outerStyle={{ marginRight: 0 }}
                            textColor={colors.teal}
                            handlePress={async () => {
                              try {
                                const res = await deleteTicketOption(data)

                                console.log('res', res)
                                console.log(
                                  'res.data.updateDeleteTicketOptionStatus',
                                  res.data,
                                )

                                this.setState({ modalIsVisible: false })

                                if (this.props.onDelete) {
                                  this.props.onDelete(res)
                                }
                              } catch (error) {
                                console.log(error)
                              }
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </Modal>
                </React.Fragment>
              )}
            </Mutation>
          )
        }}
      </DimensionsConsumer>
    )
  }
}
