import gql from 'graphql-tag'
import { VenueFragments } from './fragments'

/*
 * Get Basic Venue Info
 * - for all forms & sub forms
 */

export const VENUES_QUERY = gql`
  query {
    allVenues(orderBy: name_ASC) {
      ...VenueBasics
    }
  }
  ${VenueFragments.basic}
`

export const VENUE_INFO_QUERY = gql`
  query($id: ID!) {
    Venue(id: $id) {
      ...VenueDetails
      # maybe we should do something like this? so we don't need to filter on the component?
      # photos (filter: {published: true}) # or whatever logic we have, profile true, etc.
      photos(orderBy: filename_ASC) {
        ...VenuePhotoDetails
      }
      # should do photo sections like this:
      # menuPhotos: photos(filter: { menu: true }) {
      #   ...VenuePhotoDetails
      # }
      social {
        ...VenueSocialDetails
      }
      # shouldn't we also include happyHours and hours here? And social?
    }
    allVenueCategories(orderBy: name_ASC) {
      id
      name
    }
  }
  ${VenueFragments.details}
  ${VenueFragments.social}
  ${VenueFragments.photoDetails}
`

export const UPDATE_VENUE_INFO = gql`
  mutation(
    $id: ID!
    $name: String
    $address: String
    $phoneNumber: String
    $intlPhoneNumber: String
    $website: String
  ) {
    updateVenueInfo: updateVenue(
      id: $id
      name: $name
      address: $address
      phoneNumber: $phoneNumber
      intlPhoneNumber: $intlPhoneNumber
      website: $website
    ) {
      ...VenueDetails
    }
  }
  ${VenueFragments.details}
`

/*
 * Update the venue's description
 *
 */

export const UPDATE_VENUE_DESCRIPTION = gql`
  mutation($id: ID!, $description: String) {
    updateVenueDescription: updateVenue(id: $id, description: $description) {
      ...VenueDetails
    }
  }
  ${VenueFragments.details}
`

/*
 * Update the venue's liveMusicDetails and happyHourDetails
 *
 */

export const UPDATE_VENUE_DETAILS = gql`
  mutation($id: ID!, $happyHourDetails: String, $liveMusicDetails: String) {
    updateVenueDetails: updateVenue(
      id: $id
      happyHourDetails: $happyHourDetails
      liveMusicDetails: $liveMusicDetails
    ) {
      ...VenueDetails
    }
  }
  ${VenueFragments.details}
`

export const UPDATE_VENUE_CATEGORIES = gql`
  mutation($id: ID!, $categoriesIds: [ID!]!) {
    updateVenueCategories: updateVenue(id: $id, categoriesIds: $categoriesIds) {
      ...VenueDetails
    }
  }
  ${VenueFragments.details}
`
