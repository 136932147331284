import React from 'react'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import { getNumberOfFormLines } from '../../../functions'
import {
  CREATE_SPECIAL,
  UPDATE_SPECIAL,
  VENUE_SPECIALS_QUERY_NEW,
} from '../../../gql'
import {
  DaysInput,
  ErrorsContainer,
  FormButtons,
  FormCheckbox,
  ResponsiveInput,
  TimesInput,
} from '../../../components'
import { WithSelectedVenue } from '../../../layouts/components'

function sanitizeValues({ description, ...rest }) {
  return {
    ...rest,
    description:
      description && description.length > 0 && description !== ' '
        ? description
        : null,
  }
}

class SpecialForm extends React.Component {
  state = {
    initialValues: null,
  }

  _setInitialValues = special => {
    this.setState({
      initialValues: {
        ...special,
        published: special.published ? true : false,
      },
    })
  }

  componentWillMount() {
    // we need to do this so we can reset / clear the form (_handleCancel)
    if (this.props.special) {
      this._setInitialValues(this.props.special)
    }
  }

  componentWillReceiveProps(nextProps) {
    //prolly should do a comparison here
    if (nextProps.special) {
      this._setInitialValues(nextProps.special)
    }
  }

  render() {
    const { initialValues } = this.state
    // const { special } = this.props
    return (
      <WithSelectedVenue>
        {({ selectedVenue }) => (
          <Mutation mutation={UPDATE_SPECIAL}>
            {(updateSpecial, { error, loading }) => (
              <Mutation
                mutation={CREATE_SPECIAL}
                refetchQueries={() => [
                  {
                    query: VENUE_SPECIALS_QUERY_NEW,
                    variables: { id: selectedVenue.id },
                  },
                ]}
              >
                {(createSpecial, { error, loading }) => (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      ...initialValues,
                      ...{ venueId: selectedVenue.id },
                    }}
                    // validate on blur when editing a special, but not creating
                    // not sure it's working ...
                    validateOnBlur={initialValues.id ? true : false}
                    validate={values => {
                      console.log('values', values)
                      let errors = {}
                      if (
                        values.days === null ||
                        values.days === undefined ||
                        values.days.length === 0
                      ) {
                        errors.days = 'At least one day is required!'
                      }
                      if (
                        values.name === null ||
                        values.name === undefined ||
                        values.name === ''
                      ) {
                        errors.name = 'Name required!'
                      }
                      if (
                        values.startTime === null ||
                        values.startTime === undefined
                      ) {
                        errors.startTime = 'Start time required!'
                      }
                      if (
                        values.endTime === null ||
                        values.endTime === undefined
                      ) {
                        errors.endTime = 'End time required!'
                      }
                      if (values.startTime > values.endTime) {
                        errors.endTime = 'End time must be after start time!'
                      }
                      if (values.startTime === '') {
                        errors.startTime = 'Not a valid start time!'
                      }
                      if (values.endTime === '') {
                        errors.endTime = 'Not a valid end time!'
                      }
                      console.log('errors', errors)
                      return errors
                    }}
                    onSubmit={async (values, actions) => {
                      const sanitizedValues = sanitizeValues(values)
                      try {
                        const res = values.id
                          ? await updateSpecial({ variables: sanitizedValues })
                          : await createSpecial({ variables: sanitizedValues })
                        console.log('res.data', res.data)
                        if (res && res.data.createSpecial) {
                          this.props.handleNewSpecial(res.data.createSpecial.id)
                        }
                        actions.setSubmitting(false)
                      } catch (error) {
                        console.log('error', error)
                        actions.setSubmitting(false)
                      }
                    }}
                    render={props => {
                      console.log(
                        'props.values.startTime',
                        props.values.startTime,
                      )
                      const numLines = getNumberOfFormLines(
                        props.values.description,
                      )
                      return (
                        <React.Fragment>
                          <ResponsiveInput
                            label={'Name'}
                            multiline={false}
                            numberOfLines={1}
                            name={'name'}
                            labelWidth={50}
                            onChangeText={props.setFieldValue}
                            value={props.values.name}
                            autoComplete={'off'}
                          />
                          <ResponsiveInput
                            label={'Description'}
                            multiline={true}
                            numberOfLines={numLines}
                            name={'description'}
                            labelWidth={120}
                            onChangeText={props.setFieldValue}
                            value={
                              props.values.description
                                ? props.values.description
                                : ''
                            }
                            autoComplete={'off'}
                          />
                          <DaysInput
                            value={props.values.days}
                            onChange={value =>
                              props.setFieldValue('days', value)
                            }
                          />
                          <TimesInput
                            startTime={props.values.startTime}
                            endTime={props.values.endTime}
                            onChange={(field, value) =>
                              props.setFieldValue(field, value)
                            }
                            showLabels={true}
                          />
                          <FormCheckbox
                            value={props.values.published}
                            onChange={value =>
                              props.setFieldValue('published', value)
                            }
                          />
                          <ErrorsContainer errors={props.errors} />
                          <FormButtons
                            containerStyle={{ zIndex: -1 }}
                            disabled={!props.dirty || props.isSubmitting}
                            isSubmitting={props.isSubmitting}
                            handleSubmit={props.handleSubmit}
                            handleCancel={props.handleReset}
                          />
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </WithSelectedVenue>
    )
  }
}

export default SpecialForm
