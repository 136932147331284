import React from 'react'
import { FIcon, MIcon } from '../../components'
import { scale } from '../../functions'

export const NavIcon = ({ iconName, iconType, size, color, style }) => (
  <React.Fragment>
    {iconType === 'custom' ? (
      <FIcon name={iconName} color={color} size={scale(size)} style={style} />
    ) : iconType === 'material' ? (
      <MIcon name={iconName} color={color} size={scale(size)} style={style} />
    ) : null}
  </React.Fragment>
)
