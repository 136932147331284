export const keys = {
  azure: {
    clientId: '3f71aeae-603a-49ec-a577-0ee0b36e8bcc',
    authority: 'https://login.microsoftonline.com/barcard.onmicrosoft.com',
    graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
  },
  downtown: {
    terms: 'https://www.barcard.co/terms',
    privacy: 'https://www.barcard.co/privacy',
    email: 'support@getbarcard.com',
  },
  facebook: {
    appId: '392838457784641',
    pixel: '2299230546972679',
  },
  filestack: {
    apiKey: 'AJ6BJu3OfRJiEgC3Eienpz',
  },
  google: {
    bcvmTrackingId: 'UA-98194226-5',
    places: 'AIzaSyAKr3y_Exww6_dl-N0et036RHIkmL26k6c',
  },
  gql: {
    prod: 'https://api.graph.cool/simple/v1/cjvnunrg51ngw0173wcgivgpv',
    dev: 'https://api.graph.cool/simple/v1/cjvnunrg51ngw0173wcgivgpv',
  },
  hotjar: {
    siteId: '1164788',
    serviceVersion: '6',
  },
  segment: {
    bcvm: 'tN4QP1i6QB6poXVcK6Oz53jhdFqPgmqH',
  },
  sentry: {
    dsn: 'https://85eb98ee4d7d49a3b9f852fe66e11b4e@sentry.io/1390641',
  },
  stripe: {
    prod: 'pk_live_REST',
    dev: 'pk_test_REST',
  },
}
