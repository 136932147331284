export const minutesTimeHash = {
  0: '12:00 AM',
  30: '12:30 AM',
  60: '1:00 AM',
  90: '1:30 AM',
  120: '2:00 AM',
  150: '2:30 AM',
  180: '3:00 AM',
  210: '3:30 AM',
  240: '4:00 AM',
  270: '4:30 AM',
  300: '5:00 AM',
  330: '5:30 AM',
  360: '6:00 AM',
  390: '6:30 AM',
  420: '7:00 AM',
  450: '7:30 AM',
  480: '8:00 AM',
  510: '8:30 AM',
  540: '9:00 AM',
  570: '9:30 AM',
  600: '10:00 AM',
  630: '10:30 AM',
  660: '11:00 AM',
  690: '11:30 AM',
  720: '12:00 PM',
  750: '12:30 PM',
  780: '1:00 PM',
  810: '1:30 PM',
  840: '2:00 PM',
  870: '2:30 PM',
  900: '3:00 PM',
  930: '3:30 PM',
  960: '4:00 PM',
  990: '4:30 PM',
  1020: '5:00 PM',
  1050: '5:30 PM',
  1080: '6:00 PM',
  1110: '6:30 PM',
  1140: '7:00 PM',
  1170: '7:30 PM',
  1200: '8:00 PM',
  1230: '8:30 PM',
  1260: '9:00 PM',
  1290: '9:30 PM',
  1320: '10:00 PM',
  1350: '10:30 PM',
  1380: '11:00 PM',
  1410: '11:30 PM',
  1440: '12:00 AM',
  1470: '12:30 AM',
  1500: '1:00 AM',
  1530: '1:30 AM',
  1560: '2:00 AM',
  1590: '2:30 AM',
  1620: '3:00 AM',
  1650: '3:30 AM',
  1680: '4:00 AM',
}
