import { AppRegistry } from 'react-native'
import App from './App'

// register the app
AppRegistry.registerComponent('App', () => App)

// run the app
AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('root'),
})
