import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { BaseText, PopoverMenu } from '../../components'
import { getName } from '../../functions'

const s = StyleSheet.create({
  // outer wrapper that can fil the whole width of the sidenav
  wrapper: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
  },
  triggerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  // user info before menu options
  userContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: 18,
    marginTop: 2,
    marginBottom: 7,
  },
  // profile image used in menu trigger
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 64 / 2,
    marginTop: 17,
    marginRight: 22,
    // marginLeft: 190, // used to make the container wider. let's change this...
  },
})

export const UserProfilePopover = ({ user, handleSelect }) => (
  <View style={s.wrapper}>
    <PopoverMenu
      renderTrigger={() => (
        <View style={s.triggerContainer}>
          <Image
            source={{ uri: 'https://placekitten.com/g/100/100' }}
            style={s.profileImage}
          />
        </View>
      )}
      renderPreOptions={() => (
        <View style={s.userContainer}>
          <BaseText fs={16} lh={22} children={user && getName(user)} />
          <BaseText
            fs={14}
            lh={19}
            color={'primary4'}
            children={user && user.email}
          />
        </View>
      )}
      options={[
        {
          label: 'Profile',
          onSelect: () => handleSelect('Profile'),
        },
        {
          label: 'Sign Out',
          onSelect: () => handleSelect('Sign Out'),
        },
      ]}
    />
  </View>
)
