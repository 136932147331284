import React from 'react'
import { LabelInput } from '../../../components'

export class ResultFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
  }

  _onChangeText = (name, value) => {
    this.setState({ text: value })

    if (this.props.onChangeText) {
      this.props.onChangeText(value)
    }
  }

  render() {
    return (
      <LabelInput
        collapseLabel={true}
        labelWidth={'100%'}
        label={''}
        onChangeText={this._onChangeText}
        autoComplete={'off'}
        placeholder={'Filter results...'}
        value={this.state.text}
        disabled={this.props.disabled}
      />
    )
  }
}
