/* converts hex string to rgb object */
export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

/* converts hex string to rgba string */
export function hexToRgba(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? // prettier-ignore
      `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`
    : null
}

/* creates a hashmap from an array */
export function hashMap(arr, key) {
  const res = arr.reduce((newObj, obj) => {
    if (obj[key] !== undefined) {
      newObj[obj[key]] = obj
      return newObj
    } else {
      return newObj
    }
  }, {})
  return res
}

// removes a key and its values from an object using the ugly delete operator
export function removeByKey(obj, prop) {
  let res = Object.assign({}, obj)
  delete res[prop]
  return res
}

// use to check if error object is empty in formik. `!isEmpty(error)`
export function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

// showZeroes: true => 15.00 / 14.99
// showZeroes: false => 15 / 14.99
export function toCurrency(float) {
  return float.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

// checks if a string is ' ' or ''
// need to expand this to handle numbers (i.e. priceFrom === 0)
function sanitizeField(field) {
  return field && field.length > 0 && field !== ' ' ? field : null
}

// object, [keys]
export function sanitizeValues(object, keys) {
  const sanitized = keys.reduce((tot, curr) => {
    return {
      ...tot,
      [curr]: sanitizeField(object[curr]),
    }
  }, {})
  return {
    ...object,
    ...sanitized,
  }
}
