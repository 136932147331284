import React from 'react'
import * as t from 'prop-types'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Query } from 'react-apollo'
import { colors, scale, formatPeriods } from '../../../functions'
import { BaseText } from '../../../components/Text/BaseText'
import { GET_INFO_BY_PLACE_ID } from '../../../gql/manage-venue'
import { TouchableBaseText } from './TouchableBaseText'
import { DetailRow } from './DetailRow'
import { CreateVenue } from './CreateVenue'
import { ViewVenue } from './ViewVenue'

const s = StyleSheet.create({
  suggestion: {
    flexDirection: 'column',
    marginVertical: scale(5),
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
    backgroundColor: colors.primary3,
    borderRadius: scale(20),
  },
  suggestionColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  details: {
    alignContent: 'stretch',
    marginTop: scale(15),
    marginBottom: scale(10),
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
    backgroundColor: colors.secondary1,
    borderRadius: scale(15),
  },
  createVenueContainer: {
    flex: 1,
    flexDirection: 'row',
  },
})

// Class used to display a card with information on a venue suggestion with a unique placeId.
// props: suggestion
export class SuggestionCard extends React.Component {
  state = {
    showInfo: false,
    venue: null,
  }

  // Navigate to the given URL in a new tab
  _navigate = url => {
    return window.open(url, '_blank')
  }

  // Navigate to a Google Place given its ID
  _navigateToPlace = placeId => {
    const placeUrl = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`
    return this._navigate(placeUrl)
  }

  _getInfo = () => {
    this.setState({ showInfo: true })
  }

  _handleDidCreate = data => {
    this.setState({ venue: data })
  }

  render() {
    // Gather suggestion from props
    const { suggestion } = this.props

    return (
      <View style={s.suggestion}>
        <View style={s.suggestionColumns}>
          <View style={s.leftColumn}>
            <BaseText
              fs={scale(20)}
              lh={scale(26)}
              weight={'semibold'}
              children={suggestion.formattedSuggestion.mainText}
            />
            <BaseText
              fs={scale(14)}
              lh={scale(18)}
              weight={'regular'}
              children={suggestion.formattedSuggestion.secondaryText}
            />
            <TouchableBaseText
              onPress={() => this._getInfo()}
              color={'teal'}
              fs={scale(12)}
              lh={scale(24)}
              weight={'semibold'}
              children={'View Place Details'}
            />
          </View>
          <View style={s.rightColumn}>
            <TouchableBaseText
              onPress={() => this._navigateToPlace(suggestion.placeId)}
              color={'teal'}
              fs={scale(14)}
              lh={scale(14)}
              weight={'semibold'}
              children={'View in Maps'}
            />
          </View>
        </View>

        {this.state.showInfo && (
          <Query
            query={GET_INFO_BY_PLACE_ID}
            fetchPolicy={'cache-and-network'}
            variables={{
              placeId: suggestion.placeId,
            }}
          >
            {({ data, networkStatus, error }) => {
              // Store the getInfoByPlaceId property into this variable
              const info =
                networkStatus !== 1 && data != null ? data.getInfoByPlaceId : {}

              // Determines wether the Add Venue button is active
              const canCreateVenue =
                networkStatus !== 1 &&
                ((data != null && data.Venue == null) || data == null) &&
                !this.state.venue

              return networkStatus === 1 ? (
                <ActivityIndicator />
              ) : data != null && data.getInfoByPlaceId != null ? (
                <View style={s.details}>
                  {canCreateVenue && info.googleInfo ? (
                    <View style={s.createVenueContainer}>
                      <CreateVenue
                        toast={this.props.toast}
                        didCreate={this._handleDidCreate}
                        {...{
                          placeId: info.placeId,
                          name: info.name,
                          address: info.address,
                          phoneNumber: info.phoneNumber,
                          intlPhoneNumber: info.intlPhoneNumber,
                          website: info.website,
                          geoloc: info.geoloc,
                          hoursText: info.hoursText,
                          hours: info.googleInfo.periods
                            ? formatPeriods(info.googleInfo.periods)
                            : [],
                        }}
                      />
                    </View>
                  ) : (
                    <View style={s.createVenueContainer}>
                      <ViewVenue
                        venue={
                          data && data.Venue ? data.Venue : this.state.venue
                        }
                        history={this.props.history}
                      />
                    </View>
                  )}
                  {info.address && (
                    <DetailRow name={'Address'} value={info.address} />
                  )}
                  {info.googleInfo.website && (
                    <DetailRow
                      name={'Website'}
                      value={info.website}
                      onPress={() => this._navigate(info.googleInfo.website)}
                    />
                  )}
                  {info.googleInfo.phoneNumber && (
                    <DetailRow
                      name={'Phone Number'}
                      value={info.googleInfo.phoneNumber}
                    />
                  )}
                  {info.hoursText && info.hoursText.hoursOpenText && (
                    <DetailRow
                      name={'Hours'}
                      // concatenate array of strings into a single string
                      value={
                        Array.isArray(info.hoursText.hoursOpenText)
                          ? info.hoursText.hoursOpenText.join('\n')
                          : info.hoursText.hoursOpenText
                      }
                    />
                  )}
                </View>
              ) : null
            }}
          </Query>
        )}
      </View>
    )
  }
}
SuggestionCard.propTypes = {
  suggestion: t.shape({
    placeId: t.string.isRequired,
    formattedSuggestion: t.shape({
      mainText: t.string.isRequired,
      secondaryText: t.string.isRequired,
    }).isRequired,
  }).isRequired,
}
