import React from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { INPUT_MARGIN_BOTTOM } from '../../config'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: `${INPUT_MARGIN_BOTTOM}px`,
  },
  inner: {
    display: 'flex',
    flex: 1,
    paddingVertical: '10px',
    width: '188px', // needs to be changed eventually
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '1px',
    borderColor: '#707070',
  },
  active: {
    backgroundColor: '#16CCD9',
    borderColor: '#16CCD9', // maybe remove this? and keep a gray border?
  },
  text: {
    fontFamily: 'OpenSans',
    fontWeight: '400',
    color: '#D4D4D4',
    fontSize: '16px',
    lineHeight: '24px',
  },
  activeText: {
    color: '#000000',
  },
})

export const Toggle = ({ selected, values, onChange }) => (
  <View style={s.wrapper}>
    {values.map((x, i) => (
      <TouchableOpacity
        key={i}
        style={[s.inner, selected === x && s.active]}
        activeOpacity={1}
        onPress={() => onChange(x)}
      >
        <Text style={[s.text, selected === x && s.activeText]}>{x}</Text>
      </TouchableOpacity>
    ))}
  </View>
)
