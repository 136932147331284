import gql from 'graphql-tag'

export const GET_INFO_BY_PLACE_ID = gql`
  query($placeId: String!) {
    getInfoByPlaceId(placeId: $placeId) {
      placeId
      name
      address
      phoneNumber
      intlPhoneNumber
      website
      geoloc
      hoursText
      googleInfo
    }
    Venue(placeId: $placeId) {
      id
      name
    }
  }
`

export const GET_VENUES_BY_PLACE_IDS = gql`
  query($placeIds: [String!]!) {
    allVenues(filter: { placeId_in: $placeIds }) {
      id
      name
      placeId
    }
  }
`
