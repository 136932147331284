import React from 'react'
import { StyleSheet } from 'react-native'
import LinearGradient from 'react-native-web-linear-gradient'
import { FIcon, MIcon } from '../../components'
import { scale } from '../../functions'

const s = StyleSheet.create({
  iconButton: {
    width: scale(54),
    height: scale(54),
    borderRadius: scale(54) / 2,
    alignItems: 'center',
    justifyContent: 'center',
    margin: scale(20),
  },
})

export const GradientIconButton = ({
  iconColors,
  iconName,
  iconType,
  size,
  color,
}) => (
  <LinearGradient colors={iconColors} style={s.iconButton}>
    {iconType === 'custom' ? (
      <FIcon name={iconName} color={color} size={scale(size)} />
    ) : iconType === 'material' ? (
      <MIcon name={iconName} color={color} size={scale(size)} />
    ) : null}
  </LinearGradient>
)
