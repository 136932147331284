import React from 'react'
import { BaseText } from '../../components'

export const FormLabel = props => (
  <BaseText
    fs={14 / 0.95}
    lh={24 / 0.95}
    color={'secondary3'}
    weight={'semibold'}
    children={props.children}
    {...props}
  />
)
