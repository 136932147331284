import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { DimensionsConsumer } from '../../../lib'
import { UPDATE_EVENT_TICKETING_INFO, EVENT_QUERY } from '../../../gql'
import {
  BaseText,
  FormButtons,
  FormCheckbox,
  NumericInput,
  ResponsiveInput,
} from '../../../components'

// this is used so we don't upload fields with blank space to the db
// should really make this a reusable function that accepts an object
// & array of keys to sanitize
function sanitizeValues({ ticketUrl, priceFrom, ...rest }) {
  return {
    ...rest,
    priceFrom:
      priceFrom && parseInt(priceFrom) && priceFrom !== 0 ? priceFrom : null,
    ticketUrl:
      ticketUrl && ticketUrl.length > 0 && ticketUrl !== ' ' ? ticketUrl : null,
  }
}

const s = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  rowMobileOverride: {
    flexDirection: 'column',
  },
  checkboxWrapperMobile: {
    marginTop: 15,
    marginBottom: 5,
  },
})

export const TicketingInfo = ({ initialValues }) => {
  return (
    <DimensionsConsumer>
      {({ width }) => {
        const isSmall = width <= 414
        return (
          <Mutation
            mutation={UPDATE_EVENT_TICKETING_INFO}
            // when this is uncommented, the cursor stays where i was editing
            // not sure if this is needed to properly update the cache :/ will investigate further.
            // also remember reading something about a 'Pure' component type implementation with Formik
            // should also investigate this tomorrow.
            refetchQueries={() => [
              { query: EVENT_QUERY, variables: { id: initialValues.id } },
            ]}
          >
            {(updateEventTicketingInfo, { error, loading }) => (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                  let errors = {}
                  if (!values.priceFrom && !values.free) {
                    errors.priceFrom = 'Price from or free required!'
                  }
                  return errors
                }}
                onSubmit={async (values, actions) => {
                  console.log('info form submit values', values)
                  const sanitizedValues = sanitizeValues(values)
                  try {
                    const res = await updateEventTicketingInfo({
                      variables: sanitizedValues,
                    })
                    console.log('res', res)
                    console.log(
                      'res.data.updateEventTicketingInfo',
                      res.data.updateEventTicketingInfo,
                    )
                    actions.setSubmitting(false)
                  } catch (error) {
                    console.log('error', error)
                    // actions.setErrors(parseAuthErrors(error.toString()))
                    actions.setSubmitting(false)
                  }
                }}
                render={props => {
                  return (
                    <React.Fragment>
                      <View style={[s.row, isSmall && s.rowMobileOverride]}>
                        <NumericInput
                          label={'Price From'}
                          noFill={true}
                          labelWidth={75} // this is being weird, needed this or Price From is on 2 lines
                          name={'priceFrom'}
                          onChangeText={props.setFieldValue}
                          value={
                            props.values.priceFrom
                              ? parseFloat(props.values.priceFrom)
                              : null
                          }
                        />
                        <FormCheckbox
                          label={'Free'}
                          value={props.values.free || false}
                          onChange={value => props.setFieldValue('free', value)}
                          wrapperStyle={isSmall && s.checkboxWrapperMobile}
                        />
                      </View>
                      <ResponsiveInput
                        label={'Ticket Url'}
                        name={'ticketUrl'}
                        labelWidth={122} // trying to line it up w/ priceFrom
                        onChangeText={props.setFieldValue}
                        value={props.values.ticketUrl || ''}
                        autoComplete={'off'}
                      />

                      {props.errors.priceFrom && (
                        <BaseText
                          fs={12}
                          color={'pink'}
                          children={props.errors.priceFrom}
                        />
                      )}
                      <FormButtons
                        disabled={!props.dirty || props.isSubmitting}
                        isSubmitting={props.isSubmitting}
                        handleSubmit={props.handleSubmit}
                        handleCancel={() => props.resetForm(initialValues)}
                      />
                    </React.Fragment>
                  )
                }}
              />
            )}
          </Mutation>
        )
      }}
    </DimensionsConsumer>
  )
}
