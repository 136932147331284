import * as Sentry from '@sentry/browser'
import { keys } from '../config'

const __DEV__ = process.env.NODE_ENV === 'development'

if (!__DEV__) {
  // Disables Sentry in dev environment

  console.log(`Sentry initialized`)

  // Sentry initialization
  Sentry.init({
    dsn: keys.sentry.dsn,
  })

  // additional set up notes:
  // https://github.com/getsentry/sentry-javascript/tree/master/packages/browser
} else {
  // Describe that Sentry was not initialized in dev
  console.log(`Sentry NOT initialized (in dev mode)`)
}

// not working in browser sdk
// Sentry.setTagsContext({
//   environment: __DEV__ ? 'development' : 'production',
//   react: true,
// })

export async function TrackError(error) {
  try {
    if (__DEV__) {
      return console.log('Error Captured:', error)
    }
    return Sentry.captureException(error)
  } catch (e) {
    console.log('error sending error to sentry ...')
  }
}

// does this need to be set up in App.js, or can we just import the whole file ?
