import React from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { BaseText, MCIcon, MIcon } from '../../../components'
import { Query, Mutation } from 'react-apollo'
import { Formik } from 'formik'
import {
  TICKET_OPTION_STATUS_QUERY,
  UPDATE_TICKET_OPTION_STATUS,
  TICKET_OPTION_QUERY,
} from '../../../gql'
import { colors } from '../../../functions'
import { TicketPopover, TicketStatusPopover } from '../components'
import { DeleteModal } from '../../Ticket/components'
import { ActivityIndicator } from 'react-native-web'

const s = StyleSheet.create({
  row: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    flexBasis: 'auto',
    paddingTop: 5,
    borderRadius: 5,
    justifyContent: 'center',
  },
  col3: {
    flex: 3,
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    marginLeft: 10,
    marginRight: 10,
  },
  col2: {
    flex: 2,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  col2Data: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col1: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  rowData: {
    backgroundColor: colors.primary3,
    marginBottom: 5,
  },
  iconButton: {
    width: 24,
    height: 24,
    alignItems: 'flex-end',
  },
  dotIcon: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    flex: 1,
    textAlign: 'right',
  },
  statusText: {
    flex: 4,
    alignItems: 'flex-start',
  },
  rowDataContainer: {
    maxHeight: 200,
  },
})

const publishStatus = 'Published'

export const EventTicketingList = ({ initialTicketValues, permissions }) => {
  console.log('Event', initialTicketValues)
  let ticketToBeDeleted = null
  let allTicketOptionStatuses = []

  return (
    <Query query={TICKET_OPTION_STATUS_QUERY}>
      {({ loading, error, data, refetch }) => {
        if (loading || error) {
          return <ActivityIndicator />
        }

        allTicketOptionStatuses = data.allTicketOptionStatuses

        return (
          <React.Fragment>
            <View style={s.row}>
              <View style={s.col3}>
                <BaseText
                  weight={'bold'}
                  fs={19}
                  lh={24}
                  mb={10}
                  children={'Ticket Name'}
                />
              </View>
              <View style={s.col1}>
                <BaseText
                  weight={'bold'}
                  fs={19}
                  lh={24}
                  mb={10}
                  children={'Price'}
                />
              </View>
              <View style={s.col2}>
                <BaseText
                  weight={'bold'}
                  fs={19}
                  lh={24}
                  mb={10}
                  children={'Display'}
                />
              </View>
              <View style={s.col1}>
                <BaseText
                  weight={'bold'}
                  fs={19}
                  lh={24}
                  mb={10}
                  children={'Action'}
                />
              </View>
            </View>
            <View style={s.rowDataContainer}>
              <ScrollView>
                {initialTicketValues.ticketOptions.map((item, index) => {
                  if (!item.status) {
                    item.status = allTicketOptionStatuses[0]
                  }

                  const dotColor =
                    item.status.value === publishStatus
                      ? colors.green
                      : colors.red

                  return (
                    <Mutation
                      key={index}
                      mutation={UPDATE_TICKET_OPTION_STATUS}
                      refetchQueries={mutationResult => [
                        {
                          query: TICKET_OPTION_QUERY,
                          variables: { id: item.id },
                        },
                      ]}
                    >
                      {(updateTicketOptionStatus, { error, loading }) => (
                        <DeleteModal
                          id={item.id}
                          render={openDeleteModal => (
                            <Formik
                              initialValues={item}
                              enableReinitialize={true}
                              onSubmit={async (values, actions) => {
                                try {
                                  const res = await updateTicketOptionStatus({
                                    variables: values,
                                  })

                                  console.log('res', res)
                                  console.log(
                                    'res.data.updateDeleteTicketOptionStatus',
                                    res.data,
                                  )
                                  actions.setSubmitting(false)
                                } catch (error) {
                                  console.log('error', error)
                                  actions.setSubmitting(false)
                                }
                              }}
                              render={props =>
                                item.isDeleted ? (
                                  <React.Fragment />
                                ) : (
                                  <View key={index} style={[s.row, s.rowData]}>
                                    <View style={s.col3}>
                                      <BaseText
                                        weight={'regular'}
                                        fs={14}
                                        lh={24}
                                        children={item.name}
                                      />
                                    </View>
                                    <View style={s.col1}>
                                      <BaseText
                                        weight={'regular'}
                                        fs={14}
                                        lh={24}
                                        children={'$' + item.price}
                                      />
                                    </View>
                                    <View style={[s.col2, s.col2Data]}>
                                      <MCIcon
                                        name={'circle'}
                                        color={dotColor}
                                        size={14}
                                        style={s.dotIcon}
                                      />

                                      {permissions.find(
                                        x =>
                                          x.permission ===
                                          'MANAGE_VENUE_EVENTS_TICKETS',
                                      ) && (
                                        <TicketStatusPopover
                                          containerStyle={s.statusText}
                                          ticketOptionId={item.id}
                                          selectedTicketOptionStatus={
                                            item.status
                                          }
                                          ticketOptionStatus={
                                            allTicketOptionStatuses
                                          }
                                          onSelect={status => {
                                            if (item.status === status) {
                                              return
                                            }
                                            item.status = status
                                            item.statusId = status.id
                                            props.submitForm()
                                          }}
                                        />
                                      )}
                                      {!permissions.find(
                                        x =>
                                          x.permission ===
                                          'MANAGE_VENUE_EVENTS_TICKETS',
                                      ) && (
                                        <View
                                          style={[
                                            s.statusText,
                                            { marginTop: -10 },
                                          ]}
                                        >
                                          <BaseText
                                            weight={'regular'}
                                            fs={14}
                                            lh={24}
                                            children={item.status.displayName}
                                          />
                                        </View>
                                      )}
                                    </View>
                                    <View style={s.col1}>
                                      {permissions.find(
                                        x =>
                                          x.permission ===
                                          'MANAGE_VENUE_EVENTS_TICKETS',
                                      ) && (
                                        <TicketPopover
                                          id={item.id}
                                          eventId={item.eventId}
                                          deleteTicketOption={() => {
                                            openDeleteModal()
                                          }}
                                        />
                                      )}
                                      {!permissions.find(
                                        x =>
                                          x.permission ===
                                          'MANAGE_VENUE_EVENTS_TICKETS',
                                      ) && (
                                        <View
                                          style={{ padding: 10, paddingTop: 0 }}
                                        >
                                          <MIcon
                                            name={'settings'}
                                            color={colors.white}
                                            size={24}
                                          />
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                )
                              }
                            />
                          )}
                        />
                      )}
                    </Mutation>
                  )
                })}
              </ScrollView>
            </View>
          </React.Fragment>
        )
      }}
    </Query>
  )
}
