import React from 'react'
import { StyleSheet, TouchableHighlight, View } from 'react-native'
import { Route } from 'react-router-native'
import { BaseText, NavIcon } from '../../components'
import { colors } from '../../functions'

const s = StyleSheet.create({
  navItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 48,
  },
  navItemActive: {
    backgroundColor: colors.primary3,
  },
  icon: {
    marginLeft: 20,
    marginRight: 36,
  },
})

export const NavLink = ({
  item: { path, label, iconName, iconColor, iconType, customIconSize },
  handlePress,
}) => (
  <Route
    path={path}
    children={({ match }) => (
      <TouchableHighlight
        style={[s.navItem, match && s.navItemActive]}
        underlayColor={colors.primary3}
        activeOpacity={1}
        onPress={() => handlePress(path)}
      >
        <View style={s.navItem}>
          <NavIcon
            iconName={iconName}
            iconType={iconType}
            size={customIconSize ? customIconSize : 24}
            color={iconColor ? iconColor : '#fff'}
            style={s.icon}
          />
          <BaseText
            fs={14}
            lh={24}
            weight={'semibold'}
            color={'white'}
            children={label}
          />
        </View>
      </TouchableHighlight>
    )}
  />
)
