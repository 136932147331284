import React from 'react'
import Stack from 'react-router-native-stack'
import { MenuProvider } from 'react-native-popup-menu'
import Routing, { Router } from './utils/routing'
import { routesNew } from './config'
import { DimensionsProvider, withApollo, WithAnalytics } from './lib'
import './utils/styles/fonts'
import './utils/styles/scrollbars'
import './utils/styles/autofill'

const Route = Routing.Route

class App extends React.Component {
  render() {
    return (
      <DimensionsProvider>
        <MenuProvider>
          <WithAnalytics>
            <Router>
              <Stack animationType={'none'}>
                {routesNew.map(x => {
                  return (
                    <Route
                      key={x.routeKey}
                      exact={x.exact}
                      path={x.path}
                      component={x.component}
                      {...x.stackProps}
                      childRoutes={x.childRoutes}
                    />
                  )
                })}
              </Stack>
            </Router>
          </WithAnalytics>
        </MenuProvider>
      </DimensionsProvider>
    )
  }
}

export default withApollo(App)
