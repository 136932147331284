import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { colors, hexToRgba, sanitizeValues } from '../../functions'
import { Formik } from 'formik'
import { Section } from './components'
import { sections } from './sections'
import {
  ContentContainer,
  FormButton,
  BaseText,
  Toast,
  MCIcon,
} from '../../components'
import { ApolloConsumer, Mutation } from 'react-apollo'
import {
  SEARCH_USER_EMAIL_QUERY,
  CREATE_VENUE_GROUP_ORGANIZATION_WITH_USER,
} from '../../gql'
import { TouchableOpacity } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  navbarContainer: {
    marginTop: -44,
    height: 44,
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
  },
  warningTextWrapper: {
    paddingVertical: 20,
  },
  toastWrapper: {
    width: '100%',
    bottom: 30,
    marginTop: 30,
  },
  icon: {
    marginHorizontal: 15,
    marginTop: -36,
    marginBottom: 10,
  },
})

class Organization extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
    }
  }

  organizationValidation = values => {
    let errors = {}

    if (!values.organizationName || values.organizationName === '') {
      errors.organizationName = 'A name for your organization is required!'
    }
    if (!values.userFirstName || values.userFirstName === '') {
      errors.userFirstName = 'First name is required!'
    }
    if (!values.userLastName || values.userLastName === '') {
      errors.userLastName = 'Last name is required!'
    }
    if (!values.email || values.email === '') {
      errors.email = 'Email address is required!'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Not a valid email address!'
    }
    if (!values.password || values.password === '') {
      errors.password = 'Password is required'
    }
    if (!values.confirmPassword || values.confirmPassword === '') {
      errors.confirmPassword = 'Please re-enter password'
    } else if (values.confirmPassword != values.password) {
      errors.confirmPassword = 'Passwords do not match'
    }

    return errors
  }

  _handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    const initialValues = {
      organizationName: '',
      userFirstName: '',
      userLastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    }

    return (
      <ApolloConsumer>
        {client => (
          <React.Fragment>
            <View style={s.navbarContainer} />
            <View style={s.wrapper}>
              <TouchableOpacity
                style={s.icon}
                activeOpacity={0.7}
                onPress={this._handleBack}
              >
                <MCIcon name="arrow-left" size={24} color={colors.white} />
              </TouchableOpacity>
              <Mutation mutation={CREATE_VENUE_GROUP_ORGANIZATION_WITH_USER}>
                {(createVenueGroupOrganizationWithUser, { data, loading }) => (
                  <Formik
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={this.organizationValidation}
                    onSubmit={async (values, actions) => {
                      const { data } = await client.query({
                        query: SEARCH_USER_EMAIL_QUERY,
                        variables: { email: values.email },
                        fetchPolicy: 'network-only',
                      })

                      if (data.searchUserEmail.length > 0) {
                        actions.setSubmitting(false)
                        this.toast.addMessage({
                          type: 'error',
                          text: 'Error! Email address is already in use',
                        })
                      } else {
                        try {
                          // lets not insert names or descriptions that look like ' ' or ''
                          const variables = sanitizeValues(values, [
                            'organizationName',
                            'userFirstName',
                            'userLastName',
                            'email',
                          ])

                          await createVenueGroupOrganizationWithUser({
                            variables,
                          })

                          actions.setSubmitting(false)

                          this.setState({ formSubmitted: true })

                          this.toast.addMessage({
                            type: 'success',
                            text: 'Success! User and Organization created',
                          })
                        } catch (error) {
                          console.log('error', error)
                          actions.setSubmitting(false)
                          this.toast.addMessage({
                            type: 'error',
                            text: `Error! ${error.message}`,
                          })
                        }
                      }
                    }}
                    render={props => {
                      props.formSubmitted = this.state.formSubmitted

                      return (
                        <ScrollView
                          ref={c => (this.scrollView = c)}
                          contentContainerStyle={s.formWrapper}
                          scrollsToTop={true}
                        >
                          <ContentContainer
                            style={s.contentContainer}
                            smallStyle={s.contentContainerMobile}
                          >
                            <ScrollView style={{ width: '100%' }}>
                              {sections.map((x, i) => {
                                if (
                                  x.id === '#basic-account-info' &&
                                  this.state.formSubmitted
                                ) {
                                  x.title =
                                    'User Account & Organization Setup Summary'
                                }
                                return (
                                  <Section
                                    key={i}
                                    additionalProps={props}
                                    {...x}
                                  />
                                )
                              })}
                              {!this.state.formSubmitted && props.dirty && (
                                <View style={s.warningTextWrapper}>
                                  <BaseText
                                    fs={18}
                                    ta={'center'}
                                    color={'pink'}
                                    children={
                                      'WARNING! Once you submit this form it cannot be edited.'
                                    }
                                  />
                                </View>
                              )}
                              {this.state.formSubmitted && (
                                <View style={s.warningTextWrapper}>
                                  <BaseText
                                    fs={18}
                                    ta={'center'}
                                    color={'pink'}
                                    children={
                                      "WARNING! If you don't write down this information you can't access it again."
                                    }
                                  />
                                </View>
                              )}
                            </ScrollView>
                            <Toast
                              ref={x => (this.toast = x)}
                              wrapperStyles={s.toastWrapper}
                            />
                            {!this.state.formSubmitted && (
                              <FormButton
                                text={'Submit'}
                                textColor={colors.teal}
                                disabled={!props.isValid}
                                handlePress={async () => {
                                  await props.submitForm()
                                }}
                              />
                            )}
                            {this.state.formSubmitted && (
                              <FormButton
                                text={'Complete'}
                                textColor={colors.teal}
                                handlePress={() => {
                                  this.props.history.push('/tools')
                                }}
                              />
                            )}
                          </ContentContainer>
                        </ScrollView>
                      )
                    }}
                  />
                )}
              </Mutation>
            </View>
          </React.Fragment>
        )}
      </ApolloConsumer>
    )
  }
}

export default Organization
