/* send in location object from props (not history)
 * and get back the params formatted as an Object
 */

export function decodeUri(location) {
  const search = location.search.substring(1)
  return JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => {
      return key === '' ? value : decodeURIComponent(value)
    },
  )
}
