import React from 'react'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { withClientState } from 'apollo-link-state'
import { LoadingPage } from '../components'
import { createHttpLink } from 'apollo-link-http'
import { keys } from '../config'

const httpLinkBase = createHttpLink({
  uri: keys.gql.prod,
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')

  operation.setContext({
    headers: {
      authorization: `Bearer ${token}` || null,
    },
  })
  return forward(operation)
})

export const httpLink = middlewareLink.concat(httpLinkBase)

export const defaults = {
  selectedVenue: {
    id: null,
    name: null,
    __typename: 'SelectedVenue',
  },
}

export const resolvers = {
  Mutation: {
    setSelectedVenue: (_, { id, name }, { cache }) => {
      const selectedVenue = { id: id, name: name, __typename: 'SelectedVenue' }
      const data = { selectedVenue }
      cache.writeData({ data })
      return selectedVenue
    },
  },
}

export function withApollo(WrappedComponent) {
  return class extends React.Component {
    state = {
      client: null,
      loaded: false,
    }

    async componentDidMount() {
      const cache = new InMemoryCache()

      const stateLink = withClientState({
        cache,
        defaults,
        resolvers,
      })

      const client = new ApolloClient({
        link: ApolloLink.from([stateLink, httpLink]),
        cache: cache,
      })

      try {
        await persistCache({
          cache,
          storage: window.localStorage,
        })
      } catch (error) {
        console.error('Error restoring Apollo cache', error)
      }

      this.setState({
        client,
        loaded: true,
      })
    }

    render() {
      const { client, loaded } = this.state

      if (!loaded) {
        return <LoadingPage />
      }
      return (
        <ApolloProvider client={client}>
          <WrappedComponent />
        </ApolloProvider>
      )
    }
  }
}
