import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors, hashMap } from '../../functions'
import { AutoCompleteWeb, TagPill } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    marginBottom: 20,
    backgroundColor: colors.primary3,
    // justifyContent: 'space-between',
    // alignItems: 'center',
  },
  tagsRow: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 15,
  },
})

function mapOptions(options) {
  return options.map(x => {
    return {
      ...x,
      label: x.name,
    }
  })
}

export class TagsInput extends React.Component {
  state = {
    opts: mapOptions(this.props.options),
    optsIdMap: hashMap(this.props.options, 'id'),
    optsNameMap: hashMap(this.props.options, 'name'),
    selectedItems: this.props.values || [],
    selectedIds: this.props.valueIds || [],
    value: '',
  }
  handleRemove = item => {
    this.setState(
      {
        selectedItems: this.state.selectedItems.filter(x => x.id !== item.id),
        selectedIds: this.state.selectedIds.filter(x => x !== item.id),
      },
      () => this.props.onChange('categoriesIds', this.state.selectedIds),
    )
  }
  handleSelect = (field, item) => {
    /*
     * should check to see that endTime > startTime
     * also need to add logic to pull endValue instead of value
     */
    if (item.value === '') {
      return
    }
    this.setState(
      {
        value: '',
        selectedItems: [...this.state.selectedItems, item],
        selectedIds: [...this.state.selectedIds, item.id],
      },
      () => this.props.onChange('categoriesIds', this.state.selectedIds),
    )
    // this.props.onChange(field, item.value)
  }

  handleInput = (field, text) => {
    this.setState({
      value: text,
    })
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 7 && this.state.optsNameMap[text]) {
      this.handleSelect(field, this.state.optsNameMap[text])
    }
  }

  render() {
    const { opts, selectedItems, selectedIds, value } = this.state
    const { name } = this.props

    // lets only show options that aren't yet selected
    const filteredOpts = opts.filter(o => selectedIds.indexOf(o.id) < 0)

    return (
      <View style={s.wrapper}>
        <View style={s.tagsRow}>
          {selectedItems &&
            selectedItems.length > 0 &&
            selectedItems.map((x, i) => (
              <TagPill key={i} item={x} handleRemove={this.handleRemove} />
            ))}
        </View>
        <AutoCompleteWeb
          clearOnSelect={true}
          maxNumberOfItems={6}
          items={filteredOpts}
          handleSelect={text => this.handleSelect(name, text)}
          handleInput={text => this.handleInput(name, text)}
          value={value}
          wrapperStyle={{ marginRight: 15 }}
          width={'100%'}
        />
      </View>
    )
  }
}
