import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Switch } from 'react-router-dom'
import Routing from '../../utils/routing'
import { isEqual } from 'lodash'
import DrawerLayout from 'react-native-drawer-layout'
import { childRoutes, routes, routeHeaders } from '../../config'
import { DimensionsConsumer } from '../../lib'
import { colors } from '../../functions'
import { AuthHeader, SideNav, WithUser, PageNotFound } from '../components'

const Route = Routing.Route

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.primary1,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  f1: {
    flex: 1,
  },
  fg1: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
})

function getBasePath(pathname) {
  return pathname.replace(/^\/([^/]*).*$/, '$1')
}

/*
 * Don't really need to do all this business w/ getBasePath to determine the Header Props.
 * Refactor in the near future, this may cause unnecessary re-renders
 *
 * * Look for notes in App.js about how to improve this. It currently re-renders on every route change,
 * and probably needs to use a <Switch /> component to prevent this.
 *
 */

class AuthLayout extends React.Component {
  state = {
    sideNavRoutes: routes.filter(x => x.label),
    headerProps: null,
    selectedVenue: null,
  }

  componentWillMount() {
    // console.log('authlayout mounting...')
    const base = getBasePath(this.props.location.pathname)
    this._mapRoutesToState(base)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location) {
      const currBase = getBasePath(this.props.location.pathname)
      const newBase = getBasePath(nextProps.location.pathname)
      if (currBase !== newBase) {
        this._mapRoutesToState(newBase)
      }
    }
    // initial the view with the first venue on mount
    // if (this.state.selectedVenue === null && nextProps.data && nextProps.data.allVenues) {
    // 	this.setState({ currentVenue: nextProps.data.allVenues[0].id })
    // }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // prevent change in children prop from re-rendering entire tree
    if (!isEqual(this.state.sideNavRoutes, nextState.sideNavRoutes)) {
      return true
    } else if (!isEqual(this.state.headerProps, nextState.headerProps)) {
      return true
    } else {
      return false
    }
  }

  _mapRoutesToState = base => {
    this.setState({
      headerProps: routeHeaders[base],
    })
  }

  _handlePress = () => {
    return this.drawer && this.drawer.openDrawer()
  }

  _filterSideNavRoutes = user => {
    let newRoutes = routes.filter(x => {
      return (
        !x.allowedRoles ||
        x.allowedRoles.length == 0 ||
        x.allowedRoles.indexOf(user.role) !== -1
      )
    })

    this.setState({ sideNavRoutes: newRoutes.filter(x => x.label) })
  }

  _isAuthorized = user => {
    let result = true

    childRoutes.auth.map(x => {
      if (x.path !== this.props.match.url) {
        return x
      }
      if (!x.allowedRoles || x.allowedRoles.length == 0) {
        return x
      }
      if (x.allowedRoles.indexOf(user.role) === -1) {
        result = false
      }
      return x
    })

    return result
  }

  render() {
    return (
      <View style={s.wrapper}>
        <DimensionsConsumer>
          {({ width }) => (
            <WithUser>
              {({ user }) => {
                this._filterSideNavRoutes(user)
                let isAuthorized = this._isAuthorized(user)
                if (!isAuthorized) {
                  return <PageNotFound />
                }

                return (
                  <DrawerLayout
                    drawerWidth={width <= 414 ? 240 : 305}
                    ref={drawer => {
                      return (this.drawer = drawer)
                    }}
                    keyboardDismissMode="on-drag"
                    renderNavigationView={() => (
                      <SideNav
                        routes={this.state.sideNavRoutes}
                        user={user && user}
                        drawerRef={this.drawer && this.drawer}
                      />
                    )}
                  >
                    <View style={[s.column, s.f1]}>
                      <View style={s.row}>
                        <AuthHeader
                          handlePress={this._handlePress}
                          headerProps={this.state.headerProps}
                        />
                      </View>
                      <Switch>
                        {childRoutes.auth.map(
                          (
                            {
                              routeKey,
                              exact,
                              path,
                              component,
                              stackProps,
                              ...rest
                            },
                            i,
                          ) => (
                            <Route
                              key={i}
                              exact={exact}
                              path={path}
                              component={component}
                              {...stackProps}
                              {...rest}
                            />
                          ),
                        )}
                      </Switch>
                    </View>
                  </DrawerLayout>
                )
              }}
            </WithUser>
          )}
        </DimensionsConsumer>
      </View>
    )
  }
}

export default AuthLayout
