import React from 'react'
import { View, StyleSheet } from 'react-native'
import { RadioButton, FormLabel } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 20,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})

const publishingOptions = [
  {
    label: 'Publish Now',
    value: true,
  },
  {
    label: 'Save as Unpublished',
    value: false,
  },
]

export const Publishing = ({ props }) => (
  <React.Fragment>
    <View style={[s.wrapper]}>
      {publishingOptions.map((item, index) => {
        const val = props.values.published === item.value
        return (
          <View key={index} style={s.radioContainer}>
            <RadioButton
              value={val}
              radioValue={item.value}
              onPress={async e => {
                await props.setFieldValue('published', item.value)
                await props.setFieldValue('hidden', !item.value)
              }}
            />
            <FormLabel mr={20} ml={10} mt={4} children={item.label} />
          </View>
        )
      })}
    </View>
  </React.Fragment>
)
