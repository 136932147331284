import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { SubMenuTile } from './components'
import { ContentContainer, FormButtons, BaseText } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
    alignItems: 'center',
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
  },
  subMenuTileContainer: {
    width: '100%',
  },
})

const subMenu = [
  {
    title: 'Create Venue (Search by Name)',
    subTitle: 'Search for venues by name.  Location not required.',
    url: '/create-venue',
  },
  {
    title: 'Bulk Create Venue',
    subTitle: 'Search for venues by neighborhood, category, and name.',
    url: '/bulk-create-venue',
  },
  {
    title: 'Create User & Organization',
    subTitle: `This tool allows you to create a new user and organization. Users
created here will automatically be added to organization.`,
    url: '/organization/new',
  },
  {
    title: `Add / Edit Venues to your Organization`,
    subTitle:
      'This tool allows you to add venues in our system to your organization.',
    url: '/organization/assign-venue',
  },
]

const eventManagerSubMenu = [
  {
    title: 'Edit Events',
    subTitle: 'This tool allows you to search for & edit events.',
    url: '/events-manager-admin',
  },
]

class ToolsForm extends React.Component {
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <ScrollView
        ref={c => (this.scrollView = c)}
        contentContainerStyle={s.wrapper}
        scrollsToTop={true}
      >
        <ContentContainer
          style={s.contentContainer}
          smallStyle={s.contentContainerMobile}
        >
          <View style={s.subMenuTileContainer}>
            {subMenu.map((item, index) => {
              return (
                <SubMenuTile
                  key={index}
                  title={item.title}
                  subTitle={item.subTitle}
                  handleClick={() => {
                    this.props.handleNavigate(item.url)
                  }}
                />
              )
            })}
            <BaseText
              fs={19}
              lh={19}
              mt={30}
              mb={15}
              ml={20}
              mr={20}
              weight={'600'}
              children={'Event Manager'}
            />
            {eventManagerSubMenu.map((item, index) => {
              return (
                <SubMenuTile
                  key={index}
                  title={item.title}
                  subTitle={item.subTitle}
                  handleClick={() => {
                    this.props.handleNavigate(item.url)
                  }}
                />
              )
            })}
          </View>
        </ContentContainer>
      </ScrollView>
    )
  }
}

export default ToolsForm
