import React from 'react'
import { TagsInputNew, BaseText } from '../../../components'

export const Categories = ({ props, allEventCategories }) => (
  <React.Fragment>
    <TagsInputNew
      label={'Categories'}
      name={'categoriesIds'}
      options={allEventCategories}
      values={props.values.categories}
      valueIds={props.values.categoriesIds}
      onChange={props.setFieldValue}
    />
    {props.errors.categoriesIds && (
      <BaseText fs={12} color={'pink'} children={props.errors.categoriesIds} />
    )}
  </React.Fragment>
)
