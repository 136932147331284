import React from 'react'
import { Row, InfoText } from '../index'
import { INPUT_MARGIN_BOTTOM, INPUT_BORDER } from '../../config'

const marginBottom = INPUT_MARGIN_BOTTOM + INPUT_BORDER

export const LogItem = ({
  isHeader,
  header,
  headerColor,
  user,
  activity,
  date,
}) => (
  <Row
    jc={'space-between'}
    pt={15}
    pb={11}
    ph={23}
    mb={marginBottom}
    bgColor={'rgba(35,35,35,0.5)'}
  >
    {isHeader ? (
      <InfoText
        color={headerColor ? headerColor : '#ff6666'}
        children={header}
      />
    ) : (
      <React.Fragment>
        <InfoText
          color={'#fff'}
          children={user ? user : ''}
          style={{ width: '100px' }}
        />
        <InfoText
          pl={'3'}
          color={'#C4C4C4'}
          children={activity}
          style={{ width: '60%' }}
        />
        <InfoText color={'#C4C4C4'} children={date} />
      </React.Fragment>
    )}
  </Row>
)
