import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from '../../functions'
import { BaseText, MCIcon } from '../../components'
import { Mutation } from 'react-apollo'
import TicketForm from './TicketForm'
import { ExitModal } from './components'
import { CREATE_TICKET_OPTION } from '../../gql'
import {
  WithSelectedVenue,
  WithSelectedVenuePermission,
  Unauthorized,
} from '../../layouts/components'
import { ActivityIndicator } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: colors.primary2,
    height: 68,
    paddingBottom: 12,
    paddingHorizontal: 20,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
})

class Ticket extends React.Component {
  state = {
    ticketId: null,
    eventId: null, // only used for creating a new ticket,
    action: null,
    label: null,
    form: null,
  }

  _setInitialState = ({ pathname, search }) => {
    try {
      // split the the pathname
      const paths = pathname.split('/')
      // parse a path that looks like this: /ticket/cjqpjpv8l002j0zz4cl3tdmxs/edit
      if (paths[2].length > 20 && paths[2].charAt(0) === 'c') {
        this.setState({
          ticketId: paths[2],
          action: paths[3],
          label: paths[3]
            ? paths[3].charAt(0).toUpperCase() + paths[3].slice(1)
            : '',
        })
        // parse a path that looks like this: /ticket/create?cjqpjpv8l002j0zz4cl3tdmxs
      } else if (paths[2] === 'create' || paths[2] === 'add') {
        if (
          search.slice(1) &&
          search.slice(1).length > 20 &&
          search.slice(1).charAt(0) === 'c'
        ) {
          this.setState({
            ticketId: null,
            eventId: search.slice(1),
            action: 'create',
            label: 'Create',
          })
        } else {
          // to do: handle this better
          return console.log('ERROR: something is wrong with the URL')
        }
      } else if (paths[2] === 'duplicate') {
        if (
          search.slice(1) &&
          search.slice(1).length > 20 &&
          search.slice(1).charAt(0) === 'c'
        ) {
          this.setState({
            ticketId: search.slice(1),
            action: 'duplicate',
            label: 'Duplicate',
          })
        } else {
          // to do: handle this better
          return console.log('ERROR: something is wrong with the URL')
        }
      } else {
        // to do: handle this better
        // show an error message to the user or redirect them
        return console.log('ERROR: something is wrong with the URL')
      }
    } catch (e) {
      console.log('e', e)
    }
  }

  componentWillMount() {
    this._setInitialState(this.props.location)
  }

  _handleBack = () => {
    this.props.history.goBack()
    this._setInitialState(this.props.location)
  }

  _bindSubmit = submitForm => {
    this.submitForm = submitForm
  }

  _bindValidate = validateForm => {
    this.validateForm = validateForm
  }

  _handleTicketAdded = ticketId => {
    this.props.history.replace(`/ticket/${ticketId}/edit`)
    this._setInitialState(this.props.location)
  }

  render() {
    const { ticketId, eventId, label } = this.state

    return (
      <WithSelectedVenue>
        {({ selectedVenue }) => (
          <WithSelectedVenuePermission
            permission={'MANAGE_VENUE_EVENTS_TICKETS'}
          >
            {({ permission }) => {
              if (!permission) {
                return <Unauthorized />
              }

              return (
                <View style={s.wrapper}>
                  <View style={s.header}>
                    <View style={s.headerLeft}>
                      <ExitModal
                        history={this.props.history}
                        form={this.state.form}
                      />
                      <BaseText
                        fs={20}
                        lh={27}
                        weight={'light'}
                        children={selectedVenue.name}
                      />
                    </View>
                    <BaseText
                      fs={20}
                      lh={27}
                      weight={'light'}
                      children={`${label} Ticket`}
                    />
                  </View>
                  <Mutation mutation={CREATE_TICKET_OPTION}>
                    {(createTicketOption, { error, loading }) => {
                      if (loading) return <ActivityIndicator />
                      return (
                        <TicketForm
                          ticketId={ticketId}
                          eventId={eventId}
                          duplicate={label === 'Duplicate'}
                          handleTicketAdded={this._handleTicketAdded}
                          formRef={form =>
                            this.state.form || this.setState({ form })
                          }
                          history={this.props.history}
                          handleBack={this._handleBack}
                          handleDuplicate={async variables => {
                            try {
                              const res = await createTicketOption({
                                variables,
                              })
                              console.log(
                                `res.data.createTicketOption`,
                                res.data.createTicketOption,
                              )
                              if (res) {
                                this._handleTicketAdded(
                                  res.data.createTicketOption.id,
                                )
                              }
                            } catch (error) {
                              console.log('error', error)
                            }
                          }}
                        />
                      )
                    }}
                  </Mutation>
                </View>
              )
            }}
          </WithSelectedVenuePermission>
        )}
      </WithSelectedVenue>
    )
  }
}

export default Ticket
