import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { getNumberOfFormLines } from '../../../functions'
import { UPDATE_VENUE_DESCRIPTION } from '../../../gql'
import {
  FormButtons,
  ResponsiveInput,
  ErrorsContainer,
} from '../../../components'

function sanitizeValues({ description, ...rest }) {
  return {
    ...rest,
    description:
      description && description.length > 0 && description !== ' '
        ? description
        : null,
  }
}

export const Description = ({ initialValues }) => {
  return (
    <Mutation mutation={UPDATE_VENUE_DESCRIPTION}>
      {(updateVenueDescription, { error, loading }) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={values => {
            let errors = {}
            if (!values.description || values.description.length <= 1) {
              errors.description = 'A description is required!'
            }
            return errors
          }}
          onSubmit={async (values, actions) => {
            const sanitizedValues = sanitizeValues(values)
            try {
              const res = await updateVenueDescription({
                variables: sanitizedValues,
              })
              console.log('res', res)
              console.log(
                'res.data.updateVenueDescription',
                res.data.updateVenueDescription,
              )
              actions.setSubmitting(false)
            } catch (error) {
              TrackError(error)
              // actions.setErrors(parseAuthErrors(error.toString()))
              actions.setSubmitting(false)
            }
          }}
          render={props => {
            const descNumLines = getNumberOfFormLines(props.values.description)
            return (
              <React.Fragment>
                <ResponsiveInput
                  label={'Description'}
                  name={'description'}
                  multiline={true}
                  numberOfLines={descNumLines}
                  onChangeText={props.setFieldValue}
                  value={props.values.description || ''}
                  editable={!props.isSubmitting}
                  autoComplete={'off'}
                />
                <ErrorsContainer errors={props.errors} />
                <FormButtons
                  disabled={!props.dirty || props.isSubmitting}
                  isSubmitting={props.isSubmitting}
                  handleSubmit={props.handleSubmit}
                  handleCancel={() => props.resetForm(initialValues)}
                />
              </React.Fragment>
            )
          }}
        />
      )}
    </Mutation>
  )
}
