import gql from 'graphql-tag'
import { TicketFragments } from './fragments'

/*
 * Get info for the Manage Ticket page / edit ticket
 *
 */

export const TICKET_OPTION_QUERY = gql`
  query($id: ID!) {
    TicketOption(id: $id) {
      ...TicketOptionDetails
      ...TicketsAllowedPerOrder
      ...TicketOptionShowQuantity
      ...TicketOptionFee
      ...TicketOptionOnSale

      status {
        ...TicketOptionStatusVars
      }

      event {
        ...TicketOptionEvent
      }
    }
  }
  ${TicketFragments.details}
  ${TicketFragments.ticketsAllowedPerOrder}
  ${TicketFragments.showQuantity}
  ${TicketFragments.fee}
  ${TicketFragments.status}
  ${TicketFragments.event}
  ${TicketFragments.onSale}
`

export const TICKET_OPTION_STATUS_QUERY = gql`
  query {
    allTicketOptionStatuses {
      ...TicketOptionStatusVars
    }
  }
  ${TicketFragments.status}
`

export const CREATE_TICKET_OPTION = gql`
  mutation(
    $name: String!
    $maxQuantity: Int!
    $price: Float!
    $description: String
    $minimumAllowed: Int
    $maximumAllowed: Int
    $showQuantity: Boolean
    $fee: Float
    $statusId: ID
    $eventId: ID
    $sellAfterTicket: Boolean
    $soldAfterTicketId: ID
    $salesStart: DateTime
    $salesEnd: DateTime
  ) {
    createTicketOption(
      name: $name
      maxQuantity: $maxQuantity
      price: $price
      description: $description
      minimumAllowed: $minimumAllowed
      maximumAllowed: $maximumAllowed
      showQuantity: $showQuantity
      fee: $fee
      statusId: $statusId
      eventId: $eventId
      requiresAccessCode: false
      sellAfterTicket: $sellAfterTicket
      soldAfterTicketId: $soldAfterTicketId
      salesStart: $salesStart
      salesEnd: $salesEnd
    ) {
      ...TicketOptionDetails
      ...TicketsAllowedPerOrder
      ...TicketOptionShowQuantity
      ...TicketOptionFee
      ...TicketOptionOnSale

      status {
        ...TicketOptionStatusVars
      }

      event {
        ...TicketOptionEvent
      }
    }
  }
  ${TicketFragments.details}
  ${TicketFragments.ticketsAllowedPerOrder}
  ${TicketFragments.showQuantity}
  ${TicketFragments.fee}
  ${TicketFragments.status}
  ${TicketFragments.event}
  ${TicketFragments.onSale}
`

export const UPDATE_TICKET_OPTION = gql`
  mutation(
    $id: ID!
    $name: String!
    $maxQuantity: Int!
    $price: Float!
    $description: String
    $minimumAllowed: Int
    $maximumAllowed: Int
    $showQuantity: Boolean
    $fee: Float
    $statusId: ID
    $eventId: ID
    $sellAfterTicket: Boolean
    $soldAfterTicketId: ID
    $salesStart: DateTime
    $salesEnd: DateTime
  ) {
    updateTicketOption(
      id: $id
      name: $name
      maxQuantity: $maxQuantity
      price: $price
      description: $description
      minimumAllowed: $minimumAllowed
      maximumAllowed: $maximumAllowed
      showQuantity: $showQuantity
      fee: $fee
      statusId: $statusId
      eventId: $eventId
      requiresAccessCode: false
      sellAfterTicket: $sellAfterTicket
      soldAfterTicketId: $soldAfterTicketId
      salesStart: $salesStart
      salesEnd: $salesEnd
    ) {
      ...TicketOptionDetails
      ...TicketsAllowedPerOrder
      ...TicketOptionShowQuantity
      ...TicketOptionFee
      ...TicketOptionOnSale

      status {
        ...TicketOptionStatusVars
      }

      event {
        ...TicketOptionEvent
      }
    }
  }
  ${TicketFragments.details}
  ${TicketFragments.ticketsAllowedPerOrder}
  ${TicketFragments.showQuantity}
  ${TicketFragments.fee}
  ${TicketFragments.status}
  ${TicketFragments.event}
  ${TicketFragments.onSale}
`

export const UPDATE_TICKET_OPTION_STATUS = gql`
  mutation($id: ID!, $statusId: ID, $eventId: ID) {
    updateTicketOption(id: $id, statusId: $statusId, eventId: $eventId) {
      ...TicketOptionDetails
      ...TicketsAllowedPerOrder
      ...TicketOptionShowQuantity
      ...TicketOptionFee
      ...TicketOptionOnSale

      status {
        ...TicketOptionStatusVars
      }

      event {
        ...TicketOptionEvent
      }
    }
  }
  ${TicketFragments.details}
  ${TicketFragments.ticketsAllowedPerOrder}
  ${TicketFragments.showQuantity}
  ${TicketFragments.fee}
  ${TicketFragments.status}
  ${TicketFragments.onSale}
  ${TicketFragments.event}
`

export const DELETE_TICKET_OPTION = gql`
  mutation($id: ID!, $isDeleted: Boolean) {
    updateTicketOption(id: $id, isDeleted: $isDeleted) {
      ...TicketOptionDetails
      ...TicketsAllowedPerOrder
      ...TicketOptionShowQuantity
      ...TicketOptionOnSale

      status {
        ...TicketOptionStatusVars
      }

      event {
        ...TicketOptionEvent
      }
    }
  }
  ${TicketFragments.details}
  ${TicketFragments.ticketsAllowedPerOrder}
  ${TicketFragments.showQuantity}
  ${TicketFragments.status}
  ${TicketFragments.fee}
  ${TicketFragments.event}
  ${TicketFragments.onSale}
`
