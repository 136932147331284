import React from 'react'
import { StyleSheet, View } from 'react-native'
import { BaseText, Button } from '../../components'
import { colors, fonts } from '../../functions'
import { DimensionsConsumer } from '../../lib'

const s = StyleSheet.create({
  containerStyle: {
    marginTop: 10,
    zIndex: -1,
  },
  buttonStyle: {
    width: 376,
    backgroundColor: colors.secondary1,
    borderRadius: 10,
  },
  textStyle: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.teal,
  },
})

export const AddButton = ({ containerStyle, disabledText, ...rest }) => (
  <DimensionsConsumer>
    {({ width }) => {
      const tooSmall = width < 608
      if (tooSmall) {
        return (
          <View style={[s.containerStyle, containerStyle && containerStyle]}>
            <BaseText
              fs={14}
              lh={19}
              mb={9}
              mt={9}
              weight={'lightitalic'}
              color={'secondary2'}
              opacity={0.7}
              children={disabledText}
            />
          </View>
        )
      } else {
        return (
          <Button
            containerStyle={[
              s.containerStyle,
              containerStyle && containerStyle,
            ]}
            buttonStyle={s.buttonStyle}
            textStyle={s.textStyle}
            noShadow={true}
            {...rest}
          />
        )
      }
    }}
  </DimensionsConsumer>
)
