import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Mutation } from 'react-apollo'
import { DUPLICATE_EVENT } from '../../gql'
import { colors } from '../../functions'
import { BaseText, MCIcon } from '../../components'
import { WithSelectedVenue } from '../../layouts/components'
import EventForm from './EventForm'
import { ActivityIndicator } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: colors.primary2,
    height: 68,
    paddingBottom: 12,
    paddingHorizontal: 20,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    paddingRight: 15,
  },
})

class Event extends React.Component {
  state = {
    eventId: null,
    venueId: null, // only used for creating a new event
    action: null,
    label: null,
  }
  _setInitialState = ({ pathname, search }) => {
    try {
      // split the the pathname
      const paths = pathname.split('/')
      // parse a path that looks like this: /event/cjqpjpv8l002j0zz4cl3tdmxs/edit
      if (paths[2].length > 20 && paths[2].charAt(0) === 'c') {
        this.setState({
          eventId: paths[2],
          action: paths[3],
          label: paths[3]
            ? paths[3].charAt(0).toUpperCase() + paths[3].slice(1)
            : '',
        })
        // parse a path that looks like this: /event/new?cjqpjpv8l002j0zz4cl3tdmxs
      } else if (paths[2] === 'new' || paths[2] === 'add') {
        if (
          search.slice(1) &&
          search.slice(1).length > 20 &&
          search.slice(1).charAt(0) === 'c'
        ) {
          this.setState({
            eventId: null,
            venueId: search.slice(1),
            action: 'new',
            label: 'New',
          })
        } else {
          // to do: handle this better
          return console.log('ERROR: something is wrong with the URL')
        }
      } else if (paths[2] === 'duplicate') {
        if (
          search.slice(1) &&
          search.slice(1).length > 20 &&
          search.slice(1).charAt(0) === 'c'
        ) {
          this.setState({
            eventId: search.slice(1),
            action: 'duplicate',
            label: 'Duplicate',
          })
        } else {
          // to do: handle this better
          return console.log('ERROR: something is wrong with the URL')
        }
      } else {
        // to do: handle this better
        // show an error message to the user or redirect them
        return console.log('ERROR: something is wrong with the URL')
      }
    } catch (e) {
      console.log('e', e)
    }
  }
  componentWillMount() {
    this._setInitialState(this.props.location)
  }

  componentWillReceiveProps(nextProps) {
    // we need this for when a new special was created, and we redirect to the edit page
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this._setInitialState(nextProps.location)
    }
  }

  _handleEventAdded = id => {
    this.props.history.replace(`${id}/edit`)
  }

  _handleNewTicketOption = id => {
    this.props.history.push(`/event/${id}/edit`)
    this.props.history.replace(`/ticket/create?${id}`)
  }

  _handleCurrentTabChanged = currentTab => {
    this.props.history.replace(`#${currentTab}`)
  }

  _handleBack = () => {
    // this.props.history.replace('/events')
    // this.props.history.push('/events')
    this.props.history.goBack()
  }

  render() {
    const { eventId, venueId, label } = this.state
    // to do: catch error if no eventId or venueId is defined
    return (
      <WithSelectedVenue>
        {({ selectedVenue }) => (
          <View style={s.wrapper}>
            <View style={s.header}>
              <View style={s.headerLeft}>
                <TouchableOpacity
                  style={s.icon}
                  activeOpacity={0.7}
                  onPress={this._handleBack}
                >
                  <MCIcon name="arrow-left" size={24} color={colors.white} />
                </TouchableOpacity>
                <BaseText
                  fs={20}
                  lh={27}
                  weight={'light'}
                  children={selectedVenue.name}
                />
              </View>
              <BaseText
                fs={20}
                lh={27}
                weight={'light'}
                children={`${label} Event`}
              />
            </View>
            <Mutation mutation={DUPLICATE_EVENT}>
              {(duplicateEvent, { error, loading }) => {
                if (loading) return <ActivityIndicator />

                return (
                  <EventForm
                    eventId={eventId}
                    venueId={venueId}
                    currentTab={this.props.location.hash.replace('#', '')}
                    duplicate={label === 'Duplicate'}
                    handleBack={this._handleBack}
                    handleCurrentTabChanged={this._handleCurrentTabChanged}
                    handleEventAdded={this._handleEventAdded}
                    handleNewTicketOption={this._handleNewTicketOption}
                    handleDuplicate={async variables => {
                      try {
                        const res = await duplicateEvent({ variables })
                        console.log(
                          `res.data.duplcateEvent`,
                          res.data.duplicateEvent,
                        )
                        if (res) {
                          // send this event up so we can update the URL
                          this._handleEventAdded(res.data.duplicateEvent.id)
                        }
                      } catch (error) {
                        console.log('error', error)
                      }
                    }}
                  />
                )
              }}
            </Mutation>
          </View>
        )}
      </WithSelectedVenue>
    )
  }
}

export default Event
