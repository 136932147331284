import 'react-primitives'
import styled from 'styled-components/primitives'
import { scale, fonts, colors } from '../../functions'

export const BaseText = styled.Text(props => ({
  // font
  fontFamily: fonts[props.weight] || fonts['regular'],
  color: colors[props.color] || colors['white'],
  fontSize: props.fs ? scale(props.fs) : scale(21),
  lineHeight: props.lh ? scale(props.lh) : scale(28),
  // padding
  paddingLeft: props.pl ? props.pl : null,
  paddingRight: props.pr ? props.pr : null,
  paddingBottom: props.pb ? props.pb : null,
  paddingTop: props.pt ? props.pt : null,
  paddingHorizontal: props.ph ? props.ph : null,
  paddingVertical: props.pv ? props.pv : null,
  padding: props.p ? props.p : null,
  // margin
  marginLeft: props.ml ? props.ml : null,
  marginRight: props.mr ? props.mr : null,
  marginBottom: props.mb ? props.mb : null,
  marginTop: props.mt ? props.mt : null,
  marginHorizontal: props.mh ? props.mh : null,
  marginVertical: props.mv ? props.mv : null,
  margin: props.m ? props.m : null,
  textAlign: props.ta ? props.ta : null,
  alignSelf: props.as ? props.as : null,
  opacity: props.opacity ? props.opacity : null,
}))

// export const BaseTextTest = styled.Text`
// 	font-family: 'OpenSans';
// 	font-weight: ${props => (props.fw ? props.fw : 300)};
// 	font-size: 30px;
// 	line-height: 40px;
// 	color: ${props => (props.color ? props.color : '#707070')};
// 	${props => props.mt && `margin-top: ${props.mt}px`};
// 	${props => props.mb && `margin-bottom: ${props.mb}px`};
// 	${props => props.ml && `margin-left: ${props.ml}px`};
// 	${props => props.mr && `margin-right: ${props.mr}px`};
// 	${props => props.mv && `margin-top: ${props.mv}px`};
// 	${props => props.mv && `margin-bottom: ${props.mv}px`};
// 	${props => props.mh && `margin-left: ${props.mh}px`};
// 	${props => props.mh && `margin-right: ${props.mh}px`};
// 	${props => props.pt && `padding-top: ${props.pt}px`};
// 	${props => props.pb && `padding-bottom: ${props.pb}px`};
// 	${props => props.pl && `padding-left: ${props.pl}px`};
// 	${props => props.pr && `padding-right: ${props.pr}px`};
// 	${props => props.pv && `padding-top: ${props.pv}px`};
// 	${props => props.pv && `padding-bottom: ${props.pv}px`};
// 	${props => props.ph && `padding-left: ${props.ph}px`};
// 	${props => props.ph && `padding-right: ${props.ph}px`};
// `
