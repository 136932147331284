import React from 'react'
import { Row, Column, InfoText, SubText } from '../index'

export const Chat = ({ message: { type, user, date, message } }) => (
  <Column
    pv={16}
    ph={21}
    mv={3}
    bgColor={'#000'}
    style={[
      {
        flexShrink: 1,
        borderRadius: 10,
        width: '50%',
      },
      type === 'to' && {
        alignSelf: 'flex-end',
      },
    ]}
  >
    <Row>
      <InfoText
        color={type === 'to' ? '#66FF94' : '#16CCD9'}
        style={{ width: '60px' }}
        children={type === 'to' ? 'Me' : user}
      />
      <InfoText color={'#C4C4C4'} children={date} />
    </Row>
    <SubText color={'#fff'} mt={9} children={message} />
  </Column>
)
