import OpenSans from '../../assets/fonts/OpenSans.woff2'
import OpenSansLight from '../../assets/fonts/OpenSans-Light.woff2'
import OpenSansLightItalic from '../../assets/fonts/OpenSans-LightItalic.woff2'
import OpenSansItalic from '../../assets/fonts/OpenSans-Italic.woff2'
import OpenSansSemibold from '../../assets/fonts/OpenSans-Semibold.woff2'
import OpenSansBold from '../../assets/fonts/OpenSans-Bold.woff2'

const fontStyles = `
  @font-face {
    src: url(${OpenSans}) format('woff2');
    font-family: OpenSans;
  }
  @font-face {
    src: url(${OpenSansLight}) format('woff2');
    font-family: OpenSans-Light;
  }
  @font-face {
    src: url(${OpenSansLightItalic}) format('woff2');
    font-family: OpenSans-LightItalic;
  }
  @font-face {
    src: url(${OpenSansItalic}) format('woff2');
    font-family: OpenSans-Italic;
  }
  @font-face {
    src: url(${OpenSansSemibold}) format('woff2');
    font-family: OpenSans-Semibold;
  }
  @font-face {
    src: url(${OpenSansBold}) format('woff2');
    font-family: OpenSans-Bold;
  }
`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = fontStyles
} else {
  style.appendChild(document.createTextNode(fontStyles))
}

// Inject stylesheet
document.head.appendChild(style)

/*
 * Eventually use preload-webpack-plugin to preload fonts
 * https://www.npmjs.com/package/preload-webpack-plugin
 *
 */
