export * from './dates'
export * from './decodeUri'
export * from './getName'
export * from './formHelpers'
export * from './helpers'
export * from './hoursHelpers'
export * from './minutesHelpers'
export * from './styles'
export * from './times'

/* clean this up. lol'ed when i went to open this file */
export function scale(num) {
  let factor = 0.95
  return num * factor
}
