import styled from 'styled-components/primitives'

export const Row = styled.View`
  display: flex;
  flex-direction: row;
  ${props => props.zi && `z-index: ${props.zi}`};
  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.ai && `align-items: ${props.ai}`};
  ${props => props.as && `align-self: ${props.as}`};
  ${props => props.jc && `justify-content: ${props.jc}`};
  ${props => props.fr && `flex-wrap: ${props.fr}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
`
export const Column = styled.View`
  display: flex;
  flex-direction: column;
  ${props => props.zi && `z-index: ${props.zi}`};
  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.width && `width: ${props.width}`};
  ${props => props.ai && `align-items: ${props.ai}`};
  ${props => props.as && `align-self: ${props.as}`};
  ${props => props.jc && `justify-content: ${props.jc}`};
  ${props => props.fr && `flex-wrap: ${props.fr}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
`
export const Wrapper = styled.View`
  display: flex;
  flex: 1;
`
