export function parseAuthErrors(error) {
  // Track('event', 'Auth Error', { action: 'Error', error: error.toString() }, 'Error')
  let errors = {}
  if (error.includes("We couldn't find an account for")) {
    errors.email = 'Invalid username and/or password.'
  }
  if (
    error.includes(
      'Password is incorrect. Please try again or click Forgot Password to reset it.',
    )
  ) {
    errors.password = 'Invalid username and/or password.'
  }
  return errors
}
