import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import { scale, colors, hexToRgba } from '../../functions'
import { keys } from '../../config/keys'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { Input, Toast, MCIcon } from '../../components'
import { SuggestionCard } from './components'
import { TouchableOpacity } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    margin: scale(15),
  },
  navbarContainer: {
    marginTop: -44,
    height: 44,
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  toastWrapper: {
    width: '100%',
    bottom: 30,
    marginTop: 30,
  },
  icon: {
    marginHorizontal: 15,
    marginTop: -36,
    marginBottom: 10,
  },
})

// Minimum length of a venu search before a Places search is triggered
const SEARCH_MIN_LENGTH = 3
const SEARCH_DEBOUNCE_TIME = 1000 // in millis

class CreateVenueTool extends React.Component {
  // Used for debouncing
  _addressChangeTimeout = null

  state = {
    gmapsLoaded: false,
    address: '',
  }

  componentDidMount() {
    // we are asynchronously injected the google maps script so as it's only needed in this component
    // https://github.com/hibiken/react-places-autocomplete/issues/195#issuecomment-382596430
    // add a function to the window that google maps can use as a callback once loaded
    window.initMap = this._initMap
    const key = keys.google.places
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl)
  }

  componentWillUnmount() {
    // remove all 'maps.googleapis.com' scripts injected into the document
    // hat tip for removing injected scripts: https://stackoverflow.com/a/9469983/5045662
    const tags = document.getElementsByTagName('script')
    for (let i = tags.length; i >= 0; i--) {
      //search backwards within nodelist for matching elements to remove
      if (
        tags[i] &&
        tags[i].getAttribute('src') != null &&
        tags[i].getAttribute('src').includes('maps.googleapis.com')
      )
        tags[i].parentNode.removeChild(tags[i]) //remove element by calling parentNode.removeChild()
    }
    // set the google.maps instance on the window to null
    window.google.maps = null
  }

  _initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }

  _handleAddressChange = address => {
    this.setState({ address })
  }

  _handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error))
  }

  _handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <React.Fragment>
        <View style={s.navbarContainer} />
        <TouchableOpacity
          style={s.icon}
          activeOpacity={0.7}
          onPress={this._handleBack}
        >
          <MCIcon name="arrow-left" size={24} color={colors.white} />
        </TouchableOpacity>
        {this.state.gmapsLoaded && (
          <PlacesAutocomplete
            /*
              The following criteria are used to limit oversearching:
              - Enough time has passed since the last text change (done within PlacesAutocomplete)
              - The text achieves a minimum length
              The values for each criterion are in SEARCH_DEBOUNCE_TIME and SEARCH_MIN_LENGTH.
            */
            value={this.state.address}
            onChange={this._handleAddressChange}
            onSelect={this._handleSelect}
            debounce={SEARCH_DEBOUNCE_TIME}
            shouldFetchSuggestions={
              this.state.address.length >= SEARCH_MIN_LENGTH
            }
          >
            {({ getInputProps, suggestions, loading }) => {
              // getInputProps: Function that returns extra props given placeholder, className. We need onChange.
              // suggestions: List of suggestion objects. Check SuggestionCard for shape.
              // loading: Is the places data loading?

              // Input props are required by PlacesAutocomplete. Kinda janky so a few manual fixes were needed.
              const inputProps = getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })

              return (
                <View style={s.wrapper}>
                  {/* Places text input */}
                  <Input
                    onChangeText={(name, value) =>
                      inputProps.onChange({ target: { value } })
                    }
                    onBlur={() => {}}
                    placeholder={inputProps.placeholder}
                  />
                  <Toast
                    ref={x => (this.toast = x)}
                    wrapperStyles={s.toastWrapper}
                  />
                  <ScrollView style={s.suggestions}>
                    {loading ? (
                      <ActivityIndicator />
                    ) : (
                      // Map suggestions to their own cards
                      suggestions.map((suggestion, i) => (
                        <SuggestionCard
                          key={i}
                          suggestion={suggestion}
                          toast={this.toast}
                          history={this.props.history}
                        />
                      ))
                    )}
                  </ScrollView>
                </View>
              )
            }}
          </PlacesAutocomplete>
        )}
      </React.Fragment>
    )
  }
}

export default CreateVenueTool
