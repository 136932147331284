import React from 'react'
import { Query } from 'react-apollo'
import { LoadingPage } from '../../components'
import { GET_USER_VENUE_QUERY } from '../../gql'
import { WithUser, WithSelectedVenue } from '.'

const WithSelectedVenuePermission = props => {
  return (
    <WithUser>
      {({ user }) => (
        <WithSelectedVenue>
          {({ selectedVenue }) => {
            return (
              <Query
                query={GET_USER_VENUE_QUERY}
                variables={{
                  userId: user.id,
                  venueId: selectedVenue.id,
                }}
                fetchPolicy={'cache-and-network'}
              >
                {userInfo => {
                  if (userInfo.loading) return <LoadingPage />

                  const user =
                    userInfo.data.allUsers && userInfo.data.allUsers.length
                      ? userInfo.data.allUsers[0]
                      : {}
                  const orgs = user.organizations
                  const permissions =
                    orgs && orgs.length > 0 ? orgs[0].permissions : []
                  const permission = permissions.find(item => {
                    return (
                      item &&
                      item.targetVenue &&
                      item.targetVenue.id === selectedVenue.id &&
                      item.permission === props.permission
                    )
                  })
                  return props.children({ permission: permission })
                }}
              </Query>
            )
          }}
        </WithSelectedVenue>
      )}
    </WithUser>
  )
}

export default WithSelectedVenuePermission
