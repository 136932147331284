import React from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Formik } from 'formik'
import { SubText, InfoText, Checkbox, MIcon, Column, Row } from '../index'

const arrowIconSize = 28
const imageSize = 100

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(35,35,35,0.5)',
    maxWidth: 550,
    borderRadius: 20,
    marginBottom: 15,
  },
  touchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileImage: {
    width: imageSize,
    height: imageSize,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 10,
  },
  arrowIcon: {
    marginHorizontal: 15,
  },
})

class UserDropdown extends React.Component {
  state = {
    opened: false,
  }
  handleToggle = () => {
    this.setState({ opened: !this.state.opened })
  }
  render() {
    const { opened } = this.state
    const { name, email, initialValues, permissionFields } = this.props
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1500)
        }}
        render={props => (
          <View style={s.container}>
            <TouchableOpacity
              activeOpacity={1}
              style={s.touchWrapper}
              onPress={this.handleToggle}
            >
              <Image
                source={
                  'https://res.cloudinary.com/bce/image/upload/v1523413077/bbq-glasses_sr76vg.png'
                }
                resizeMode={'cover'}
                style={s.profileImage}
              />
              <Column pl={20} pr={100}>
                <SubText>{name}</SubText>
                <SubText>{email}</SubText>
              </Column>
              <MIcon
                name={`arrow-drop-${opened ? 'up' : 'down'}`}
                color={'rgba(255,255,255,0.54)'}
                size={arrowIconSize}
                style={s.arrowIcon}
              />
            </TouchableOpacity>
            {opened && (
              <Column mb={15} ml={imageSize + 15 + 20}>
                <Row ml={240 + 5} mb={5}>
                  <InfoText pr={40} color={'#C4C4C4'} children={'can read'} />
                  <InfoText color={'#C4C4C4'} children={'can edit'} />
                </Row>
                {permissionFields &&
                  permissionFields.map(x => (
                    <Row key={x.field}>
                      <Column width={240} jc={'center'} ml={15}>
                        <InfoText color={'#C4C4C4'}>{x.label}</InfoText>
                      </Column>
                      <Column mr={75}>
                        <Checkbox
                          name={`${x.field}_read`}
                          onChange={val =>
                            props.setFieldValue(`${x.field}_read`, val)
                          }
                          value={props.values[`${x.field}_read`]}
                        />
                      </Column>
                      <Column mr={10}>
                        <Checkbox
                          name={`${x.field}_write`}
                          onChange={val =>
                            props.setFieldValue(`${x.field}_write`, val)
                          }
                          value={props.values[`${x.field}_write`]}
                        />
                      </Column>
                    </Row>
                  ))}
              </Column>
            )}
          </View>
        )}
      />
    )
  }
}

export default UserDropdown
