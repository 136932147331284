import { AgeGroup } from './AgeGroup'
import { Categories } from './Categories'
import { DateAndTime } from './DateAndTime'
import { Details } from './Details'
import { DetailsEdit } from './DetailsEdit'
import { EventCardPhoto } from './EventCardPhoto'
import { EventPhoto } from './EventPhoto'
import { TicketingInfo } from './TicketingInfo'

export const infoSections = [
  {
    id: '#details',
    title: 'Event Details',
    component: Details,
  },
  {
    id: '#details-edit',
    title: 'Basic Info',
    component: DetailsEdit,
  },
  {
    id: '#date-and-time',
    title: 'Date & Time',
    component: DateAndTime,
  },
  {
    id: '#age-group',
    title: 'Age Group',
    subtext: 'Please specify if there is an age requirement for your event.',
    component: AgeGroup,
  },
  {
    id: '#categories',
    title: 'Event Category Type',
    subtext: 'What category best describe your event?',
    component: Categories,
  },
  {
    id: '#ticketing-info',
    title: 'Ticketing Info',
    component: TicketingInfo,
  },
  {
    id: '#photo',
    title: 'Event Photo',
    subtext: 'Add a compelling photo for your event.',
    component: EventPhoto,
  },
  {
    id: '#photo',
    title: 'Event Card Photo',
    // subtext: 'Add a compelling photo for your event.',
    component: EventCardPhoto,
  },
]
