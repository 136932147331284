import gql from 'graphql-tag'
import { SpecialFragments } from './fragments'

/*
 * Get & Update Weekly Specials
 *
 */

export const VENUE_SPECIALS_QUERY_NEW = gql`
  query($id: ID!) {
    Venue(id: $id) {
      id
      specials(orderBy: name_ASC) {
        ...SpecialFull
      }
    }
  }
  ${SpecialFragments.full}
`

/*
 * Actions for the SpecialPopover Menu (Publish / Unpublish & Delete)
 */

export const UPDATE_SPECIAL_PUBLISHED = gql`
  mutation($id: ID!, $published: Boolean!) {
    updateSpecialPublished: updateSpecial(id: $id, published: $published) {
      ...SpecialFull
    }
  }
  ${SpecialFragments.full}
`

export const DELETE_SPECIAL = gql`
  mutation($id: ID!) {
    deleteSpecial(id: $id) {
      id
    }
  }
`

/*
 * Get info for the Edit Special Page
 *
 */

export const SPECIAL_QUERY = gql`
  query($id: ID!) {
    Special(id: $id) {
      ...SpecialFull
    }
  }
  ${SpecialFragments.full}
`

/*
 * Create & update Specials
 *
 */

export const CREATE_SPECIAL = gql`
  mutation(
    $venueId: ID!
    $name: String!
    $description: String
    $days: [Day!]!
    $startTime: Int!
    $endTime: Int!
    $published: Boolean
  ) {
    createSpecial(
      venueId: $venueId
      name: $name
      description: $description
      days: $days
      startTime: $startTime
      endTime: $endTime
      published: $published
    ) {
      ...SpecialFull
    }
  }
  ${SpecialFragments.full}
`

export const UPDATE_SPECIAL = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String
    $days: [Day!]!
    $startTime: Int!
    $endTime: Int!
    $published: Boolean
  ) {
    updateSpecial(
      id: $id
      name: $name
      description: $description
      days: $days
      startTime: $startTime
      endTime: $endTime
      published: $published
    ) {
      ...SpecialFull
    }
  }
  ${SpecialFragments.full}
`

/*
 * Create, update & delete Special Items
 *
 */

// to do:
// schema may need to be updated here, special items --> special is currently many to many,
// but we should consider making it many to one (a special item can only be in one special)
export const CREATE_SPECIAL_ITEM = gql`
  mutation(
    $specialsIds: [ID!]!
    $name: String!
    $description: String
    $price: Float
    $type: SpecialItemType!
  ) {
    createSpecialItem(
      specialsIds: $specialsIds
      name: $name
      description: $description
      price: $price
      type: $type
    ) {
      ...SpecialItemDetails
    }
  }
  ${SpecialFragments.specialItemDetails}
`

export const UPDATE_SPECIAL_ITEM = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String
    $price: Float
    $type: SpecialItemType!
  ) {
    updateSpecialItem(
      id: $id
      name: $name
      description: $description
      price: $price
      type: $type
    ) {
      ...SpecialItemDetails
    }
  }
  ${SpecialFragments.specialItemDetails}
`

export const DELETE_SPECIAL_ITEM = gql`
  mutation($id: ID!) {
    deleteSpecialItem(id: $id) {
      id
    }
  }
`
