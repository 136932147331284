import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native'
import { graphql, compose } from 'react-apollo'
import {
  TICKET_OPTION_QUERY,
  TICKET_OPTION_STATUS_QUERY,
  CREATE_TICKET_OPTION,
  UPDATE_TICKET_OPTION,
} from '../../gql'
import { Section, DeleteModal } from './components'
import { sections } from './sections'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'

import {
  ContentContainer,
  ErrorsContainer,
  FormButtons,
} from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

function getNewTicketOptionTemplate(eventId) {
  return {
    name: '',
    maxQuantity: '',
    price: 0,
    description: '',
    showQuantity: false,
    eventId: eventId,
    minimumAllowed: '',
    maximumAllowed: '',
    fee: null,
    salesStart: new Date(),
    salesEnd: new Date(),
    sellAfterTicket: false,
  }
}

function formatDuplicateTicketOption(TicketOption, statuses) {
  const status = statuses.find(
    ticketStatus => ticketStatus.value === 'Unpublished',
  )

  return {
    ...TicketOption,
    eventId: TicketOption.eventId || TicketOption.event.id,
    name: `Copy of - ${TicketOption.name}`,
    status: status,
    statusId: status.id,
  }
}

class TicketOptionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sentDuplicate: false,
    }
  }

  componentDidMount() {
    const ready = !(
      this.props.ticket.loading ||
      this.props.statuses.loading ||
      this.props.ticket.error ||
      this.props.statuses.error
    )

    if (!ready) {
      return
    }

    if (
      this.props.duplicate &&
      this.props.ticket.TicketOption &&
      this.state.sentDuplicate === false
    ) {
      const formattedTicketOption = formatDuplicateTicketOption(
        this.props.ticket.TicketOption,
        this.props.statuses.allTicketOptionStatuses,
      )
      console.log('componentDidMount duplicate')
      this.setState({ sentDuplicate: true }, () => {
        this.props.handleDuplicate(formattedTicketOption)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.duplicate &&
      nextProps.ticket.TicketOption &&
      this.state.sentDuplicate === false
    ) {
      const formattedTicketOption = formatDuplicateTicketOption(
        nextProps.ticket.TicketOption,
        nextProps.statuses.allTicketOptionStatuses,
      )
      console.log('componentDidMount duplicate')
      this.setState({ sentDuplicate: true }, () => {
        nextProps.handleDuplicate(formattedTicketOption)
      })
    }
  }

  ticketOptionValidation = values => {
    let errors = {}
    if (!values.name || values.name === '') {
      errors.name = 'A name for your ticket is required!'
    }

    if (!values.maxQuantity || values.name === '') {
      errors.maxQuantity = 'Quantity is required!'
    }

    if (!values.price || values.name === '') {
      errors.price = 'Price is required!'
    }

    if (
      values.minimumAllowed &&
      values.maximumAllowed &&
      parseInt(values.minimumAllowed) > parseInt(values.maximumAllowed)
    ) {
      errors.minimumAllowed =
        'Minimum ticket should not be greater than maximum ticket!'
    }

    if (!values.status || !values.statusId) {
      errors.status = 'TicketOption status is required!'
    }

    if (values.sellAfterTicket) {
      if (!values.soldAfterTicketId) {
        errors.soldAfterTicket = 'Other ticket to sell is required!'
      }
    } else {
      if (!values.salesStart || !values.salesEnd) {
        errors.salesStart = 'Sale start and end date is required!'
      }
    }

    return errors
  }

  render() {
    const { ticketId, duplicate, ticket, statuses, history } = this.props
    const ready = !(
      ticket.loading ||
      statuses.loading ||
      ticket.error ||
      statuses.error
    )
    const { TicketOption } = ticket
    const { allTicketOptionStatuses } = statuses
    const eventId = TicketOption ? TicketOption.eventId : this.props.eventId

    if (!ready) {
      return <ActivityIndicator />
    }

    // if we're createing an ticket, we need to create an empty object with
    // certain to keys to pre-populate the form
    const newTicketOptionObj = getNewTicketOptionTemplate(eventId)
    const initialValues = TicketOption ? TicketOption : newTicketOptionObj
    if (!initialValues.status) {
      initialValues.status = allTicketOptionStatuses[0]
      initialValues.statusId = allTicketOptionStatuses[0].id
    } else {
      initialValues.statusId = initialValues.status.id
    }

    if (initialValues.event) {
      initialValues.eventId = initialValues.event.id
      delete initialValues.event
    }

    if (initialValues.soldAfterTicket) {
      initialValues.soldAfterTicketId = initialValues.soldAfterTicket.id
    }

    initialValues.salesStart = initialValues.salesStart || new Date()
    initialValues.salesEnd = initialValues.salesEnd || new Date()

    return (
      <ScrollView
        ref={c => (this.scrollView = c)}
        contentContainerStyle={s.wrapper}
        scrollsToTop={true}
      >
        <ContentContainer
          style={s.contentContainer}
          smallStyle={s.contentContainerMobile}
        >
          {!ready ? (
            <ActivityIndicator />
          ) : (
            <Mutation
              mutation={CREATE_TICKET_OPTION}
              refetchQueries={mutationResult => [
                {
                  query: TICKET_OPTION_QUERY,
                  variables: { id: mutationResult.data.createTicketOption.id },
                },
              ]}
            >
              {(
                createTicketOption,
                { data, loading: loadingCreateTicketOption },
              ) => {
                if (loadingCreateTicketOption) return <ActivityIndicator />

                return (
                  <Mutation
                    mutation={UPDATE_TICKET_OPTION}
                    refetchQueries={mutationResult => [
                      {
                        query: TICKET_OPTION_QUERY,
                        variables: {
                          id: mutationResult.data.updateTicketOption.id,
                        },
                      },
                    ]}
                  >
                    {(
                      updateTicketOption,
                      { data, loading: loadingUpdateTicketOption },
                    ) => {
                      if (loadingUpdateTicketOption)
                        return <ActivityIndicator />
                      return (
                        <DeleteModal
                          id={ticketId}
                          onDelete={() => this.props.handleBack()}
                          render={openDeleteModal => (
                            <Formik
                              ref={this.props.formRef}
                              enableReinitialize={true}
                              initialValues={{
                                ...initialValues,
                              }}
                              validate={this.ticketOptionValidation}
                              onSubmit={async (values, actions) => {
                                console.log('info form submit values', values)
                                try {
                                  actions.setSubmitting(false)
                                  values.maxQuantity = parseInt(
                                    values.maxQuantity,
                                  )
                                  values.minimumAllowed = parseInt(
                                    values.minimumAllowed,
                                  )
                                  values.maximumAllowed = parseInt(
                                    values.maximumAllowed,
                                  )
                                  values.eventId = eventId
                                  values.fee = values.fee ? values.fee : 0
                                  values.sellAfterTicket = values.sellAfterTicket
                                    ? values.sellAfterTicket
                                    : false
                                  const variables = values

                                  const res = ticketId
                                    ? await updateTicketOption({ variables })
                                    : await createTicketOption({ variables })

                                  console.log(`res.data.createEvent`, res.data)
                                  actions.setSubmitting(false)
                                  history.goBack()
                                } catch (error) {
                                  console.log('error', error)
                                  actions.setSubmitting(false)
                                }
                              }}
                              render={props => {
                                return (
                                  <React.Fragment>
                                    {sections.map((x, i) => {
                                      return (
                                        <Section
                                          key={i}
                                          additionalProps={props}
                                          {...x}
                                        />
                                      )
                                    })}
                                    <FormButtons
                                      disabled={props.isSubmitting}
                                      isSubmitting={props.isSubmitting}
                                      handleSubmit={props.handleSubmit}
                                      handleCancel={() => {
                                        props.resetForm(initialValues)
                                        this.props.handleBack()
                                      }}
                                    />
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      )
                    }}
                  </Mutation>
                )
              }}
            </Mutation>
          )}
        </ContentContainer>
      </ScrollView>
    )
  }
}

export default compose(
  graphql(TICKET_OPTION_QUERY, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: { id: props.ticketId ? props.ticketId : 'null' },
    }),
    name: 'ticket',
  }),
  graphql(TICKET_OPTION_STATUS_QUERY, {
    options: props => ({
      fetchPolicy: 'network-only',
    }),
    name: 'statuses',
  }),
)(TicketOptionForm)
