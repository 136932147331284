import React from 'react'
import { Svg, Path, G, Rect, Circle } from 'react-native-svg'
import { scale } from '../../functions'

const height = 58
const width = 58
const viewBox = `0 0 ${width} ${height}`

export const ImagePlaceholder = () => (
  <Svg height={scale(height)} width={scale(width)} viewBox={viewBox}>
    <G transform="translate(-2 -2)">
      <Rect
        width="56"
        height="56"
        rx="2"
        transform="translate(3 3)"
        strokeWidth="2"
        stroke="#bebed1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Circle
        cx="7.167"
        cy="7.167"
        r="7.167"
        transform="translate(14.111 14.111)"
        strokeWidth="2"
        stroke="#bebed1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M51.444,33.889,36.931,10,5,62.556"
        transform="translate(7.556 -3.556)"
        fill="none"
        stroke="#bebed1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </G>
  </Svg>
)
