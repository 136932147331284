import React from 'react'
import { View, StyleSheet } from 'react-native'
import { ScrollColumn } from '../../../components'
import { EventListItem, NoEventsText } from '../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  fixedHeight: {
    maxHeight: 255,
  },
})

export const EventsList = (events, status, venueId, queryDate, scrollable) => {
  const eventCount = events.length // doing this since we use the value twice
  const scrollPadding = eventCount > 5 ? 30 : 0 // only add if we have to scroll
  return (
    <View style={[s.wrapper, scrollable && s.fixedHeight]}>
      {eventCount && eventCount > 0 ? (
        <React.Fragment>
          {scrollable ? (
            // to do: show vertical scroll indicator (requires some work app-wide, esp for windows devices)
            // also, bottom padding is jacked up so the popover menu doesn't go off screen
            <ScrollColumn pb={scrollPadding}>
              {events.map(x => (
                <EventListItem
                  key={x.id}
                  status={status}
                  {...x}
                  venueId={venueId}
                  queryDate={queryDate}
                />
              ))}
            </ScrollColumn>
          ) : (
            <React.Fragment>
              {events.map(x => (
                <EventListItem
                  key={x.id}
                  status={status}
                  {...x}
                  venueId={venueId}
                  queryDate={queryDate}
                />
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <NoEventsText status={status} />
      )}
    </View>
  )
}
