import React from 'react'
import { Row, Column, InfoText } from '../index'

export const Message = ({ user, date, message }) => (
  <Column
    pv={17}
    ph={21}
    bgColor={'#111111'}
    style={{ borderBottomWidth: 1, borderBottomColor: '#363636' }}
  >
    <Row jc={'space-between'}>
      <InfoText color={'#fff'} children={user} />
      <InfoText color={'#fff'} children={date} />
    </Row>
    <InfoText color={'#16CCD9'} mt={14} children={message} />
  </Column>
)
