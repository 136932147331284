import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'

const s = StyleSheet.create({
  scrollRow: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  scrollColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  scrollContent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    paddingTop: `${5}px`,
    paddingBottom: `${20}px`,
  },
})

export const ScrollRow = ({ children, pb, contentContainerStlye, ...rest }) => (
  <ScrollView
    showsHorizontalScrollIndicator={false}
    showsVerticalScrollIndicator={false}
    directionalLockEnabled={true}
    horizontal={true}
    scrollEventThrottle={16}
    contentContainerStyle={[
      s.scrollRow,
      pb && { paddingBottom: pb },
      contentContainerStlye && contentContainerStlye,
    ]}
    {...rest}
    // pointerEvents={'box-none'}
  >
    {children}
  </ScrollView>
)

export const ScrollColumn = ({
  children,
  pb,
  contentContainerStlye,
  ...rest
}) => (
  <ScrollView
    showsHorizontalScrollIndicator={false}
    showsVerticalScrollIndicator={false}
    directionalLockEnabled={true}
    scrollEventThrottle={16}
    contentContainerStyle={[
      s.scrollColumn,
      pb && { paddingBottom: pb },
      contentContainerStlye && contentContainerStlye,
    ]}
    {...rest}
    // pointerEvents={'box-none'}
  >
    {children}
  </ScrollView>
)

export const EventScroller = props => (
  <ScrollView
    showsHorizontalScrollIndicator={true}
    showsVerticalScrollIndicator={false}
    directionalLockEnabled={true}
    scrollEventThrottle={16}
    horizontal={true}
    contentContainerStyle={s.scrollContent}
    // pointerEvents={'box-none'}
  >
    {props.children}
  </ScrollView>
)
