const sDay = new Array()
sDay[0] = 'Sun'
sDay[1] = 'Mon'
sDay[2] = 'Tues'
sDay[3] = 'Wed'
sDay[4] = 'Thur'
sDay[5] = 'Fri'
sDay[6] = 'Sat'

const sMonth = new Array()
sMonth[0] = 'Jan'
sMonth[1] = 'Feb'
sMonth[2] = 'Mar'
sMonth[3] = 'Apr'
sMonth[4] = 'May'
sMonth[5] = 'Jun'
sMonth[6] = 'Jul'
sMonth[7] = 'Aug'
sMonth[8] = 'Sep'
sMonth[9] = 'Oct'
sMonth[10] = 'Nov'
sMonth[11] = 'Dec'

// Jan 28, 2019
export function getShortDate(date) {
  const d = new Date(date)
  return `${sMonth[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}

// Mon, Jan 28, 2019 12:47 PM
export function getShortDateWithDayTime(date) {
  const d = new Date(date)
  return `${sDay[d.getDay()]}, ${
    sMonth[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}, ${getTime(d)}`
}

export const getTime = unformatted => {
  const date = new Date(unformatted)
  const hours = date.getHours()
  const fHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
  const minutes = date.getMinutes().toString()
  const fMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  const ampm = hours === 0 ? 'am' : hours >= 12 ? 'pm' : 'am'
  return `${fHours}:${fMinutes}${ampm}`
}

export const formatDate = unformatted => {
  const date = new Date(unformatted)

  const day1 = date.getDate().toString()
  const day = day1.length === 1 ? `0${day1}` : day1
  const month1 = (date.getMonth() + 1).toString()
  const month = month1.length === 1 ? `0${month1}` : month1
  const year = date
    .getFullYear()
    .toString()
    .slice(2, 4)
  const hours = date.getHours()
  const fHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
  const minutes = date.getMinutes().toString()
  const fMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  const ampm = hours === 0 ? 'am' : hours >= 12 ? 'pm' : 'am'

  return `${month}/${day}/${year} ${fHours}:${fMinutes} ${ampm}`
}

export const formatDateString = unformatted => {
  const date = new Date(unformatted)
  const day1 = date.getDate().toString()
  const day = day1.length === 1 ? `0${day1}` : day1
  const month1 = (date.getMonth() + 1).toString()
  const month = month1.length === 1 ? `0${month1}` : month1
  const year = date.getFullYear()
  const hours = date.getHours().toString()
  const fHours = hours.length === 1 ? `0${hours}` : hours
  const minutes = date.getMinutes().toString()
  const fMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  return `${year}-${month}-${day}T${fHours}:${fMinutes}`
}

export const formatShortDate = unformatted => {
  const date = new Date(unformatted)

  const day = date.getDate()
  const month1 = (date.getMonth() + 1).toString()
  const month = month1.length === 1 ? `0${month1}` : month1
  const year = date.getFullYear().toString()
  // .slice(2, 4)

  return `${month}/${day}/${year}`
}

export const formatTime = unformatted => {
  const date = new Date(unformatted)
  const hours = date.getHours()
  const fHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
  const minutes = date.getMinutes().toString()
  const fMinutes = minutes.length === 1 ? `0${minutes}` : minutes
  const ampm = hours === 0 ? 'AM' : hours >= 12 ? 'PM' : 'AM'
  return `${fHours}:${fMinutes} ${ampm}`
}

export const formatDateOld = unformatted => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(unformatted)

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date
    .getFullYear()
    .toString()
    .slice(2, 4)

  return `${monthNames[monthIndex]}-${day}-${year} ${formatTime(unformatted)}`
}

export const formatLongDate = unformatted => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const weekdayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const date = new Date(unformatted)

  const weekdayIndex = date.getDay()
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear().toString()

  return `${weekdayNames[weekdayIndex]} ${
    monthNames[monthIndex]
  } ${day}, ${year}`
}

export const formatLongDateUpper = unformatted => {
  const monthNames = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ]

  const weekdayNames = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ]

  const date = new Date(unformatted)

  const weekdayIndex = date.getDay()
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear().toString()

  return `${weekdayNames[weekdayIndex]} ${
    monthNames[monthIndex]
  } ${day}, ${year}`
}

export function nearestInterval(interval, iso = true) {
  const date = new Date()
  const minutes = date.getMinutes()
  // Math.round rounds, Math.floor rounds down, Math.ceil rounds uo
  const rounded = Math.round(minutes / interval) * interval
  // 0, 0 sets seconds & milliseconds
  const newDate = date.setMinutes(rounded, 0, 0)
  return iso ? new Date(newDate).toISOString() : new Date(newDate)
}

// using this to map utc dates from graphcool to local times
// haven't ever had to do this, so let's make sure we review this function
// and it's logic thoroughly
export function adjustForTimezone(d, plus = true) {
  const date = new Date(d)
  var timeOffsetInMS = date.getTimezoneOffset() * 60000
  if (plus) {
    date.setTime(date.getTime() + timeOffsetInMS)
  } else {
    date.setTime(date.getTime() - timeOffsetInMS)
  }
  return date
}
