import React from 'react'
import { View } from 'react-native'
import { withRouter } from 'react-router-native'
import Modal from 'modal-enhanced-react-native-web'
import { PopoverMenu, BaseText } from '../../../components'

export class TicketStatusPopover extends React.Component {
  state = {
    modalIsVisible: false,
  }

  _handleRemove = () => {
    this.setState({ modalIsVisible: true })
  }

  _handleSelect = status => {
    console.log('### SELECTED STATUS', this.props.ticketOptionId, status)
    this.props.onSelect(status)
  }

  _renderModalContent = () => (
    // TODO Modal with mutation component to delete the ticket
    <React.Fragment />
  )

  render() {
    const {
      selectedTicketOptionStatus,
      ticketOptionStatus,
      containerStyle,
    } = this.props
    const { modalIsVisible } = this.state
    const options = ticketOptionStatus.map(status => {
      return {
        label: status.displayName,
        onSelect: () => this._handleSelect(status),
      }
    })

    return (
      <React.Fragment>
        <View style={containerStyle}>
          <PopoverMenu
            centerAnchor={true}
            menuProviderWrapperStyle={{ paddingTop: 0 }}
            renderTrigger={() => (
              <BaseText
                weight={'regular'}
                fs={14}
                lh={24}
                children={selectedTicketOptionStatus.displayName}
              />
            )}
            options={options}
          />
        </View>

        <Modal
          isVisible={modalIsVisible}
          onBackdropPress={() => this.setState({ modalIsVisible: false })}
        >
          {this._renderModalContent()}
        </Modal>
      </React.Fragment>
    )
  }
}
