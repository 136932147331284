import React from 'react'
import { View, StyleSheet } from 'react-native'
import { hashMap } from '../../../functions'
import { DimensionsConsumer } from '../../../lib'
import { AutoCompleteWeb, BaseText, FormLabel } from '../../../components'
import { timeOpts } from './timeOpts'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    //marginBottom: 50,
  },
  wrapperMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 0,
  },
  timeOptsRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputWrapperMobile: {
    flexGrow: 1,
    marginTop: 15,
  },
})

export class TimesInput extends React.Component {
  state = {
    timeOpts: timeOpts,
    timeOptsHash: hashMap(timeOpts, 'value'),
    endTimeOptsHash: hashMap(timeOpts, 'endValue'),
    timeOptsNameHash: hashMap(timeOpts, 'name'),
  }
  handleSelect = (field, item) => {
    /*
     * should check to see that endTime > startTime
     * also need to add logic to pull endValue instead of value
     */
    const value =
      field === 'endTime' && item.endValue ? item.endValue : item.value
    this.props.onChange(field, value)
  }

  handleInput = (field, text) => {
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 7 && this.state.timeOptsNameHash[text]) {
      this.handleSelect(field, this.state.timeOptsNameHash[text])
    }
  }

  render() {
    const { timeOpts, timeOptsHash, endTimeOptsHash } = this.state
    const { startTime, endTime, showLabels } = this.props

    const startTimeValue = timeOptsHash[startTime]
      ? timeOptsHash[startTime].name
      : null
    const endTimeValue = timeOptsHash[endTime]
      ? timeOptsHash[endTime].name
      : endTimeOptsHash[endTime]
      ? endTimeOptsHash[endTime].name
      : null

    const duration = endTime - startTime
    // minutes into hours, and only show the decimal when necessary. And only show when startTime/endTime are not null
    const durationText = duration
      ? `${Math.round((duration / 60) * 100) / 100} hours`
      : null
    return (
      <DimensionsConsumer>
        {({ width }) => {
          const breakpointOne = width < 555
          const breakpointTwo = width < 470
          return (
            <View style={[s.wrapper, breakpointOne && s.wrapperMobile]}>
              <View style={[s.timeOptsRow, breakpointTwo && s.wrapperMobile]}>
                <View
                  style={[
                    s.inputWrapper,
                    breakpointTwo && s.inputWrapperMobile,
                  ]}
                >
                  {showLabels && <FormLabel children={'Start'} mr={15} />}
                  <AutoCompleteWeb
                    items={timeOpts}
                    handleSelect={text => this.handleSelect('startTime', text)}
                    handleInput={text => this.handleInput('startTime', text)}
                    value={startTimeValue ? startTimeValue : ''}
                    wrapperStyle={{ marginRight: 15 }}
                    maxWidth={120}
                  />
                </View>
                <View
                  style={[
                    s.inputWrapper,
                    breakpointTwo && s.inputWrapperMobile,
                  ]}
                >
                  {showLabels && (
                    <FormLabel
                      children={'End'}
                      mr={breakpointTwo ? 22 : 15} // to account for 'Start' being longer than 'End'
                      ml={breakpointTwo ? 0 : 15} // to account for column layout
                    />
                  )}
                  <AutoCompleteWeb
                    items={timeOpts}
                    handleSelect={text => this.handleSelect('endTime', text)}
                    handleInput={text => this.handleInput('endTime', text)}
                    value={endTimeValue ? endTimeValue : ''}
                    wrapperStyle={{ marginRight: 15 }}
                    maxWidth={120}
                  />
                </View>
              </View>
              {durationText && (
                <BaseText
                  fs={16}
                  lh={24}
                  mt={breakpointOne ? 25 : 0}
                  color={'teal'}
                  children={durationText}
                />
              )}
            </View>
          )
        }}
      </DimensionsConsumer>
    )
  }
}
