import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors, hexToRgba } from '../../functions'
import ToolsForm from './ToolsForm'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  navbarContainer: {
    marginTop: -44,
    height: 44,
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class Tools extends React.Component {
  componentWillMount() {}

  handleNavigate = url => {
    this.props.history.push(url)
  }

  render() {
    return (
      <View style={s.wrapper}>
        <View style={s.navbarContainer} />
        <ToolsForm handleNavigate={this.handleNavigate} />
      </View>
    )
  }
}

export default Tools
