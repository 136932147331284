import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, View } from 'react-native'
import { scale } from '../../../functions'
import { BaseText } from '../../../components/Text/BaseText'
import { TouchableBaseText } from './TouchableBaseText'

const s = StyleSheet.create({
  leftColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightColumn: {
    flex: 1,
    alignContent: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  detail: {
    flexDirection: 'row',
    marginVertical: scale(5),
  },
})

// Row component for displaying different kinds of details.
// props: name, value, onPress
// NOTE: onPress called when pressing the value text.
export class DetailRow extends React.Component {
  render() {
    const { name, value, onPress } = this.props

    return (
      <View style={s.detail}>
        <View style={s.leftColumn}>
          <BaseText
            color={'white'}
            fs={scale(14)}
            lh={scale(16)}
            weight={'bold'}
            children={name}
          />
        </View>
        <View style={s.rightColumn}>
          {onPress ? (
            <TouchableBaseText
              color={'teal'}
              onPress={onPress}
              fs={scale(12)}
              lh={scale(16)}
              ta={'right'}
              weight={'semibold'}
              children={value}
            />
          ) : (
            <BaseText
              color={'white'}
              fs={scale(12)}
              lh={scale(16)}
              ta={'right'}
              weight={'regular'}
              children={value}
            />
          )}
        </View>
      </View>
    )
  }
}
DetailRow.propTypes = {
  /** Key/name value in left hand column. */
  name: t.string.isRequired,
  /** Value in respect to the key. */
  value: t.string.isRequired,
  /** Optional onPress function called when clicking value. */
  onPress: t.func,
}
