import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { colors } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    alignSelf: 'stretch',
    // backgroundColor: colors.primary1,
  },
})

export const LoadingPage = () => (
  <View style={s.wrapper}>
    <ActivityIndicator />
  </View>
)
