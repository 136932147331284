import gql from 'graphql-tag'
import { VenueFragments } from './fragments'

/*
 * Update, Create, & Delete Hours
 *
 */

export const UPDATE_HOURS_PERIOD = gql`
  mutation($id: ID!, $day: Int!, $startTime: Int!, $endTime: Int!) {
    updateHoursPeriod(
      id: $id
      day: $day
      startTime: $startTime
      endTime: $endTime
    ) {
      ...HoursDetails
    }
  }
  ${VenueFragments.hoursDetails}
`

export const CREATE_HOURS_PERIOD = gql`
  mutation($venueId: ID!, $day: Int!, $startTime: Int!, $endTime: Int!) {
    createHoursPeriod(
      venueId: $venueId
      day: $day
      startTime: $startTime
      endTime: $endTime
    ) {
      ...HoursDetails
    }
  }
  ${VenueFragments.hoursDetails}
`

export const DELETE_HOURS_PERIOD = gql`
  mutation($id: ID!) {
    deleteHoursPeriod(id: $id) {
      id
    }
  }
`

/*
 * Update, Create, & Delete Happy Hours
 *
 */

export const UPDATE_HAPPY_HOURS_PERIOD = gql`
  mutation($id: ID!, $day: Int!, $startTime: Int!, $endTime: Int!) {
    updateHappyHoursPeriod(
      id: $id
      day: $day
      startTime: $startTime
      endTime: $endTime
    ) {
      ...HappyHoursDetails
    }
  }
  ${VenueFragments.happyHoursDetails}
`

export const CREATE_HAPPY_HOURS_PERIOD = gql`
  mutation($venueId: ID!, $day: Int!, $startTime: Int!, $endTime: Int!) {
    createHappyHoursPeriod(
      venueId: $venueId
      day: $day
      startTime: $startTime
      endTime: $endTime
    ) {
      ...HappyHoursDetails
    }
  }
  ${VenueFragments.happyHoursDetails}
`

export const DELETE_HAPPY_HOURS_PERIOD = gql`
  mutation($id: ID!) {
    deleteHappyHoursPeriod(id: $id) {
      id
    }
  }
`
