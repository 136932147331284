import React from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { colors, hexToRgba } from '../../functions'
import { ContentContainer } from '../../components'
import { Section } from './components'
import {
  VENUE_META_QUERY,
  USER_META_QUERY,
  VENUE_REPORT_QUERY,
  USER_REPORT_QUERY,
} from '../../gql'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const formatUsersData = users => {
  let result = []
  users.map(user => {
    let row = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      organizationId: '',
      organizationName: '',
      organizationOwner: '',
      MANAGE_EVENT_CAMPAIGN_EVENTS: '',
      MANAGE_VENUE_INFO: '',
      MANAGE_VENUE_EVENTS: '',
      MANAGE_VENUE_SPECIALS: '',
      MANAGE_VENUE_EVENTS_TICKETS: '',
      MANAGE_VENUE_EVENTS_TICKETS_FEES: '',
    }

    if (user.organizations.length === 0) {
      result.push(row)
      return
    }

    user.organizations.map(org => {
      row.organizationId = org.id
      row.organizationName = org.name
      row.organizationOwner = org.owner.id
    })

    let assignTargetVenuesToPermission = permission => {
      row[permission] = user.organizations
        .filter(
          org =>
            org.permissions.filter(p => p.permission === permission).length > 0,
        )
        .map(org =>
          org.permissions
            .filter(p => p.permission === permission && p.targetVenue)
            .map(p => p.targetVenue.name)
            .join(', '),
        )
        .join(', ')
    }

    assignTargetVenuesToPermission('MANAGE_EVENT_CAMPAIGN_EVENTS')
    assignTargetVenuesToPermission('MANAGE_VENUE_INFO')
    assignTargetVenuesToPermission('MANAGE_VENUE_EVENTS')
    assignTargetVenuesToPermission('MANAGE_VENUE_SPECIALS')
    assignTargetVenuesToPermission('MANAGE_VENUE_EVENTS_TICKETS')
    assignTargetVenuesToPermission('MANAGE_VENUE_EVENTS_TICKETS_FEES')

    result.push(row)
    return
  })

  return result
}

const formatVenuesData = venues => {
  let result = []
  const days = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ]

  venues.forEach(venue => {
    let row = {
      name: venue.name,
      address: venue.address,
      phoneNumber: venue.phoneNumber,
      intlPhoneNumber: venue.intlPhoneNumber,
      website: venue.website,
      description: venue.description
        ? `"${venue.description.replace(/,/g, '').replace(/"/g, "'")}"`
        : '',
      numProfilePhotos: venue.photos
        ? venue.photos.filter(({ profile }) => profile).length
        : '',
      numUnpublishedPhotos: venue.photos
        ? venue.photos.filter(({ published }) => !published).length
        : '',
      numFeaturePhotos: venue.photos
        ? venue.photos.filter(({ feature }) => feature).length
        : '',
      hours: venue.hours
        ? venue.hours
            .map(date => {
              let hours = `${days[date.day]} ${
                new Date(date.startTime).getHours() % 12
                  ? new Date(date.startTime).getHours() % 12
                  : 12
              }:${new Date(date.startTime).getMinutes()} ${
                new Date(date.startTime).getHours() < 12 ? 'AM' : 'PM'
              } to ${
                new Date(date.endTime).getHours() % 12
                  ? new Date(date.endTime).getHours() % 12
                  : 12
              }:${new Date(date.endTime).getMinutes()} ${
                new Date(date.endTime).getHours() < 12 ? 'AM' : 'PM'
              }`

              return hours
            })
            .join(', ')
        : '',
      numHappyHourDays: venue.happyHours ? venue.happyHours.length : '',
      numHappyHourHours: venue.happyHours
        ? venue.happyHours
            .map(date => {
              return (
                (new Date(date.endTime) - new Date(date.startTime)) /
                (1000 * 60 * 60)
              )
            })
            .reduce((sum, hours) => sum + hours, 0)
        : '',
      numMenus: venue.menus ? venue.menus.length : '',
      uniqueFeatures:
        venue.menus && venue.specials
          ? `• ${venue.happyHourDetails}\n` +
            venue.specials.map(({ name }) => `• ${name}`).join('\n')
          : '',
      categories: venue.categories
        ? venue.categories.map(({ name }) => name).join(', ')
        : '',
      instagramHandle: venue.social ? venue.social.instagramHandle : '',
      facebookUrl: venue.social ? venue.social.facebookUrl : '',
      yelpId: venue.social ? venue.social.yelpId : '',
      numEvents: venue.events ? venue.events.length : '',
      numSpecials: venue.specials ? venue.specials.length : '',
      createdAt: venue.createdAt,
      updatedAt: venue.updatedAt,
      status: venue.published ? 'Published' : 'Unpublished',
    }

    result.push(row)
  })

  return result
}

export const sections = [
  {
    id: '#users',
    title: 'User',
    subtext:
      'Click once.  Report may take up to 2 minutes to generate and download.',
    reportQuery: USER_REPORT_QUERY,
    metaQuery: USER_META_QUERY,
    formatData: formatUsersData,
  },
  {
    id: '#venues',
    title: 'Venue',
    subtext:
      'Click once.  Report may take up to 2 minutes to generate and download.',
    reportQuery: VENUE_REPORT_QUERY,
    metaQuery: VENUE_META_QUERY,
    formatData: formatVenuesData,
  },
]

class Reports extends React.Component {
  render() {
    return (
      <ScrollView
        ref={c => (this.scrollView = c)}
        contentContainerStyle={s.wrapper}
        scrollsToTop={true}
      >
        <ContentContainer
          style={s.contentContainer}
          smallStyle={s.contentContainerMobile}
        >
          {sections.map((props, i) => (
            <Section key={i} {...props} />
          ))}
        </ContentContainer>
      </ScrollView>
    )
  }
}

export default Reports
