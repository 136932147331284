import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, View } from 'react-native'
import { DimensionsConsumer } from '../../lib'

// desktop: 1440,
// laptop: 1024,
// tablet: 768,
// mobilePlus: 414,
// mobileStandard: 375,
// mobileSmall: 320,

// maxWidth: 728, leave 10px margin on each side for mobile

const s = StyleSheet.create({
  default: {
    display: 'flex',
    flexGrow: 1, // not sure if this is needed, but if we refactor, make sure to check consumers of this component
    flexDirection: 'column',
    width: '100%',
    maxWidth: 728,
  },
})

export const ContentContainer = ({ style, smallStyle, children }) => (
  <DimensionsConsumer>
    {({ width }) => {
      const isSmall = width < 748
      const smallStyles = [{ maxWidth: width - 20 }, smallStyle && smallStyle]
      return (
        <View style={[style, s.default, isSmall && smallStyles]}>
          {children}
        </View>
      )
    }}
  </DimensionsConsumer>
)

ContentContainer.propTypes = {
  style: t.oneOfType([t.object, t.array, t.number]).isRequired,
  children: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
}
