import React from 'react'
import * as t from 'prop-types'
import { View } from 'react-native'
import { DimensionsConsumer } from '../../lib'

export const FixedWidthContainer = ({
  style,
  width,
  padding,
  children,
  setHeight,
  breakpointStyle,
}) => (
  <DimensionsConsumer>
    {({ width: deviceWidth }) => {
      // determine if we need to render a smaller sized container
      const isBreakpoint = deviceWidth < width + padding
      // calculate size of the container
      const targetSize = isBreakpoint ? deviceWidth - padding : width
      // define styles, with & without height
      const calculatedStyles = setHeight
        ? { width: targetSize, height: targetSize }
        : { width: targetSize }

      return (
        <View
          style={[style, calculatedStyles, isBreakpoint && breakpointStyle]}
        >
          {children}
        </View>
      )
    }}
  </DimensionsConsumer>
)

FixedWidthContainer.propTypes = {
  style: t.oneOfType([t.object, t.array, t.number]).isRequired,
  width: t.number.isRequired,
  padding: t.number.isRequired,
  children: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
  breakpointStyle: t.oneOfType([t.object, t.array, t.number]),
  setHeight: t.bool,
}
