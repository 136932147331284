import gql from 'graphql-tag'
import { UserFragments } from './fragments'

export const USER_QUERY = gql`
  query {
    user {
      ...UserInfo
    }
  }
  ${UserFragments.info}
`

export const SELECTED_VENUE_QUERY = gql`
  query($userId: ID!) {
    selectedVenue @client {
      id
      name
    }
  }
`

export const VENUE_OPTIONS_QUERY = gql`
  query($userId: ID!) {
    allVenues1: allVenues(
      filter: {
        # currently says any user who's in an organization that has
        # been granted the MANAGE_VENUE_INFO permission for venues
        acl_some: {
          AND: [
            { organization: { members_some: { id: $userId } } }
            { permission: MANAGE_VENUE_INFO }
          ]
        }
      }
      orderBy: name_ASC
      first: 1000
      skip: 0
    ) {
      id
      name
      label: name
    }
    allVenues2: allVenues(
      filter: {
        # currently says any user who's in an organization that has
        # been granted the MANAGE_VENUE_INFO permission for venues
        acl_some: {
          AND: [
            { organization: { members_some: { id: $userId } } }
            { permission: MANAGE_VENUE_INFO }
          ]
        }
      }
      orderBy: name_ASC
      first: 1000
      skip: 1000
    ) {
      id
      name
      label: name
    }
  }
`

export const GET_USER_VENUE_QUERY = gql`
  query($userId: ID!, $venueId: ID!) {
    allUsers(filter: { id: $userId }) {
      id
      email
      organizations {
        id
        name
        permissions(filter: { targetVenue: { id: $venueId } }) {
          id
          permission
          targetVenue {
            id
            name
          }
        }
      }
    }
  }
`

export const SET_SELECTED_VENUE = gql`
  mutation($id: ID!, $isSelected: String!) {
    setSelectedVenue(id: $id, name: $name) @client {
      id
      name
    }
  }
`
