import React from 'react'
import * as t from 'prop-types'
import { Dimensions } from 'react-native'

// destructure Context.Consumer, Context.Provider
const { Provider, Consumer } = React.createContext()

// future inspiration
// https://github.com/styled-system/styled-system/blob/master/docs/responsive-styles.md

// also see breakpoints.js for more details around breakpoints (deviceWidths, sizeWidths)

const deviceWidths = {
  // desktopXl: 1920, // may not use, i believe 1440 will be our largest
  // xl: upperbound
  desktop: 1440,
  laptop: 1024,
  tablet: 768,
  mobile: 414,
}

const sizeWidths = {
  // xl: upperbound
  lg: 1280,
  md: 768,
  sm: 414,
}

function getDeviceBreakpoint(width) {
  if (width > deviceWidths.desktop) {
    return 'xl'
  } else if (width > deviceWidths.laptop) {
    return 'desktop'
  } else if (width > deviceWidths.tablet) {
    return 'laptop'
  } else if (width > deviceWidths.mobile) {
    return 'tablet'
  } else {
    return 'mobile'
  }
}

function getSizeBreakpoint(width) {
  if (width > sizeWidths.lg) {
    return 'xl'
  } else if (width > sizeWidths.md) {
    return 'lg'
  } else if (width > sizeWidths.sm) {
    return 'md'
  } else {
    return 'sm'
  }
}

class DimensionsProvider extends React.Component {
  state = {
    width: null, // width of the window
    height: null, // height of the window
    scale: null, // zoom (i.e. 90% returns ~0.89999)
    fontScale: null, // need to investigate more, may be a browser setting the user defines. currently only observing a value of 1
    deviceBreakpoint: null, // enum(xl, desktop, laptop, tablet, mobile)
    sizeBreakpoint: null, // enum(xl, lg, md, sm)

    // to do: consider adding variables here (i.e. isSmall)
    // so we don't need to recalculate isSmall everywhere (width changing = re-render)

    // alternatively, a helper that takes an array of values [ 350, 400, 500, 500]
    // that maps to a set amount of breakpoints. the method would return the correct value from the array
  }

  componentWillMount() {
    this._initState()
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this._dimensionsHandler)
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this._dimensionsHandler)
  }

  _dimensionsHandler = ({ window }) => {
    const { width, height, scale, fontScale } = window

    // get the device & size breakpoints
    // const deviceBreakpoint = getDeviceBreakpoint(width)
    // const sizeBreakpoint = getSizeBreakpoint(width)

    this.setState({
      width,
      height,
      scale,
      fontScale,
    })
  }

  _initState = () => {
    // note: Dimensions.get('screen') seems to return the devices entire resolution, i.e. 1920x1080,
    // where we need window (the size of the browser window)
    const { width, height, scale, fontScale } = Dimensions.get('window')

    // get the device & size breakpoints
    // const deviceBreakpoint = getDeviceBreakpoint(width)
    // const sizeBreakpoint = getSizeBreakpoint(width)

    this.setState({
      width,
      height,
      scale,
      fontScale,
    })
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

DimensionsProvider.propTypes = {
  children: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
}

export { DimensionsProvider, Consumer as DimensionsConsumer }
