import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { colors, fonts } from '../../functions'

const s = StyleSheet.create({
  touchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary3,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    fontFamily: fonts.regular,
    fontSize: 16,
    lineHeight: 24,
    paddingVertical: 11,
    textAlign: 'center',
    color: colors.secondary2,
  },
})

class HoverableTextTouchHighlight extends React.Component {
  setStyles = (containerStyle, textStyle) => {
    this.root.setNativeProps({
      style: containerStyle,
    })
    this.text.setNativeProps({
      style: textStyle,
    })
  }

  render() {
    const {
      style,
      innerContainerStyle,
      hoverStyle,
      underlayColor,
      textStyle,
      textHoverStyle,
      text,
      onPress,
    } = this.props
    const touchStyles = [s.touchContainer, style]
    const textStyles = [s.textStyle, textStyle]
    return (
      <TouchableHighlight
        underlayColor={underlayColor}
        ref={ref => (this.root = ref)}
        onMouseEnter={() => this.setStyles(hoverStyle, textHoverStyle)}
        onMouseLeave={() => this.setStyles(touchStyles, textStyles)}
        style={touchStyles}
        onPress={onPress}
      >
        <View
          style={[s.innerContainer, innerContainerStyle && innerContainerStyle]}
        >
          <Text
            ref={ref => (this.text = ref)}
            style={textStyles}
            children={text}
          />
        </View>
      </TouchableHighlight>
    )
  }
}

HoverableTextTouchHighlight.propTypes = {
  /** the string of text to display inside the button */
  text: t.node.isRequired,
  /** the event handler */
  onPress: t.func.isRequired,
  /** backgroundColor and style overrides for TouchableHighlight */
  style: t.oneOfType([t.object, t.array, t.number]), // needs number for RN stylesheet reference
  // style: t.object.isRequired,
  /** backgroundColor and style overrides for hover state for TouchableHighlight */
  hoverStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  /** text color and style overrides for Text */
  textStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  /** style for hover state for Text */
  textHoverStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  /** style overrides for View wrapping the Text */
  innerContainerStyle: t.oneOfType([t.object, t.array, t.number]),
  /** TouchableHighlight's underlayColor */
  underlayColor: t.string,
}

HoverableTextTouchHighlight.defaultProps = {
  underlayColor: colors.primary3,
}

export default HoverableTextTouchHighlight
