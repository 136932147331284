/*
 * For some reason the Checkbox component appears twice.
 * This is the depreciated one. ♻️ soon...
 *
 */

import React from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { scale, fonts, colors } from '../../functions'

const s = StyleSheet.create({
  checkBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: scale(4),
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
  },
  labelText: {
    fontFamily: fonts.regular,
    color: colors.darkgray,
    fontSize: scale(13),
    lineHeight: scale(18),
  },
  checkBoxCont: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: 0,
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: scale(10),
    display: 'flex',
    flexDirection: 'column',
    marginTop: scale(3),
    justifyContent: 'flex-end',
  },
})

export const Checkbox = ({ label, value, onChange }) => (
  <View style={s.checkBoxRow}>
    <View style={s.labelContainer}>
      <Text style={s.labelText}>{label}</Text>
    </View>
    <TouchableOpacity
      activeOpacity={1}
      style={s.checkBoxCont}
      onPressIn={() => onChange(!value)}
    >
      <Icon
        name={value ? 'checkbox-marked' : 'checkbox-blank-outline'}
        color={value ? '#16CCD9' : '#bfbfbf'}
        size={scale(24)}
      />
    </TouchableOpacity>
  </View>
)

/*
 *  example with a link: 
 * 
  <View style={s.checkBoxRow}>
    <Text style={s.checkboxText}>{`I have read the `}</Text>
    <TouchableOpacity style={s.linkCont} activeOpacity={0.7} onPress={this._handleTerms}>
      <Text style={[s.checkboxText, s.linkText]}>terms</Text>
    </TouchableOpacity>
    <Text style={s.checkboxText}>{` and `}</Text>
    <TouchableOpacity style={s.linkCont} activeOpacity={0.7} onPress={this._handlePrivacy}>
      <Text style={[s.checkboxText, s.linkText]}>privacy policy</Text>
    </TouchableOpacity>
    <TealCheckBox onPress={() => this.setState({ termsChecked: !this.state.termsChecked })} checked={this.state.termsChecked} />
  </View>
 * 
 */
