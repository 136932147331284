import React from 'react'
import { View, StyleSheet } from 'react-native'
import { PhotoCardSquare, PhotoPlaceholder } from '../../../components'
import { EventPhotoOptionsModal } from '../components'

const s = StyleSheet.create({
  photosContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
})

export const EventPhoto = ({ initialValues }) => {
  const venueId = initialValues.venue.id
  const image = initialValues.photos.filter(x => x.cover)
  return (
    <React.Fragment>
      <View style={s.photosContainer}>
        {image.length > 0 ? (
          <PhotoCardSquare
            image={image[0]}
            actions={[]}
            size={'xlarge'}
            disabled={true}
            handleAction={() => null}
          />
        ) : (
          <PhotoPlaceholder
            wrapperStyle={{ marginBottom: 23 }}
            size={'cover'}
          />
        )}
      </View>
      <EventPhotoOptionsModal venueId={venueId} eventId={initialValues.id} />
    </React.Fragment>
  )
}
