import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { UPDATE_VENUE_INFO, VENUE_INFO_QUERY } from '../../../gql'
import { FormButtons, ResponsiveInput } from '../../../components'

export const Info = ({ initialValues }) => {
  return (
    <Mutation
      mutation={UPDATE_VENUE_INFO}
      refetchQueries={() => [
        { query: VENUE_INFO_QUERY, variables: { id: initialValues.id } },
      ]}
    >
      {(updateVenueInfo, { error, loading }) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            console.log('info form submit values', values)
            try {
              const res = await updateVenueInfo({ variables: values })
              console.log('res', res)
              console.log('res.data.updateVenueInfo', res.data.updateVenueInfo)
              actions.setSubmitting(false)
            } catch (error) {
              TrackError(error)
              // actions.setErrors(parseAuthErrors(error.toString()))
              actions.setSubmitting(false)
            }
          }}
          render={props => (
            <React.Fragment>
              <ResponsiveInput
                label={'Venue Name'}
                name={'name'}
                disabled={true}
                onChangeText={props.setFieldValue}
                value={props.values.name}
                editable={!props.isSubmitting}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={'Address'}
                name={'address'}
                disabled={true}
                dataDetectorTypes={'address'}
                onChangeText={props.setFieldValue}
                value={props.values.address}
                editable={!props.isSubmitting}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={'Phone Number'}
                name={'phoneNumber'}
                disabled={true}
                dataDetectorTypes={'phoneNumber'}
                onChangeText={props.setFieldValue}
                value={props.values.phoneNumber}
                editable={!props.isSubmitting}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={"Int'l Phone Number"}
                name={'intlPhoneNumber'}
                disabled={true}
                // dataDetectorTypes={'phoneNumber'}
                onChangeText={props.setFieldValue}
                value={props.values.intlPhoneNumber}
                editable={!props.isSubmitting}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={'Website'}
                name={'website'}
                dataDetectorTypes={'link'}
                onChangeText={props.setFieldValue}
                value={props.values.website}
                editable={!props.isSubmitting}
                autoComplete={'off'}
              />
              <FormButtons
                disabled={!props.dirty || props.isSubmitting}
                isSubmitting={props.isSubmitting}
                // handleSubmit={props.handleSubmit}
                handleSubmit={props.submitForm}
                handleCancel={() => props.resetForm(initialValues)}
              />
            </React.Fragment>
          )}
        />
      )}
    </Mutation>
  )
}
