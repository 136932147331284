import React from 'react'
import { DateTimeInput, BaseText } from '../../../components'
import { DurationRow } from '../components'
import { StyleSheet } from 'react-native'

export const DateAndTime = ({ props }) => {
  const s = StyleSheet.create({
    dateTimeInputLabelWrapperStyle: {
      flex: 1,
    },
    dateTimeInputLabelStyle: {
      textAlign: 'center',
    },
    dateTimeInputDateWrapperStyle: {
      flex: 1,
    },
    durationRowWrapperStyle: {
      marginBottom: 15,
    },
    durationRowTextWrapperStyle: {
      textAlign: 'center',
      width: '100%',
    },
  })

  return (
    <React.Fragment>
      <DateTimeInput
        label={'Start'}
        name={'date'}
        value={props.values.date}
        onChange={props.setFieldValue}
        wrapperStyle={[s.dateTimeInputWrapperStyle, { zIndex: 1 }]}
        labelWrapperStyle={s.dateTimeInputLabelWrapperStyle}
        labelStyle={s.dateTimeInputLabelStyle}
        dateWrapperStyle={s.dateTimeInputDateWrapperStyle}
        timeInnerMaxWidth={'100%'}
        timeTextAlign={'center'}
      />
      <DateTimeInput
        label={'End'}
        name={'endDate'}
        value={props.values.endDate}
        onChange={props.setFieldValue}
        wrapperStyle={s.dateTimeInputWrapperStyle}
        labelWrapperStyle={s.dateTimeInputLabelWrapperStyle}
        labelStyle={s.dateTimeInputLabelStyle}
        dateWrapperStyle={s.dateTimeInputDateWrapperStyle}
        timeInnerMaxWidth={'100%'}
        timeTextAlign={'center'}
      />
      <DurationRow
        startDate={props.values.date}
        endDate={props.values.endDate}
        wrapperStyle={s.durationRowWrapperStyle}
        textWrapperStyle={s.durationRowTextWrapperStyle}
      />
      {props.errors.date && (
        <BaseText fs={12} color={'pink'} children={props.errors.date} />
      )}
      {props.errors.endDate && (
        <BaseText fs={12} color={'pink'} children={props.errors.endDate} />
      )}
    </React.Fragment>
  )
}
