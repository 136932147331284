import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { CREATE_VENUE_SOCIAL, UPDATE_VENUE_SOCIAL } from '../../../gql'
import {
  FormButtons,
  ResponsiveInput,
  ErrorsContainer,
} from '../../../components'

function sanitizeField(field) {
  return field && field.length > 0 && field !== ' ' ? field : null
}

function sanitizeValues({ instagramHandle, facebookUrl, yelpId, ...rest }) {
  return {
    ...rest,
    instagramHandle: sanitizeField(instagramHandle),
    facebookUrl: sanitizeField(facebookUrl),
    yelpId: sanitizeField(yelpId),
  }
}

export const Social = ({ initialValues: { id, social } }) => {
  const initialValues = {
    venueId: id,
    ...social,
  }
  return (
    <Mutation mutation={CREATE_VENUE_SOCIAL}>
      {(createVenueSocial, { error, loading }) => (
        <Mutation mutation={UPDATE_VENUE_SOCIAL}>
          {(updateVenueSocial, { error, loading }) => (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={values => {
                let errors = {}
                // if (!values.description || values.description.length <= 1) {
                //   errors.description = 'A description is required!'
                // }
                return errors
              }}
              onSubmit={async (values, actions) => {
                const sanitizedValues = sanitizeValues(values)
                try {
                  if (sanitizedValues.id) {
                    const res = await updateVenueSocial({
                      variables: sanitizedValues,
                    })
                    console.log('res', res)
                    console.log(
                      'res.data.updateVenueSocial',
                      res.data.updateVenueSocial,
                    )
                  } else {
                    const res = await createVenueSocial({
                      variables: sanitizedValues,
                    })
                    console.log('res', res)
                    console.log(
                      'res.data.createVenueSocial',
                      res.data.createVenueSocial,
                    )
                  }

                  actions.setSubmitting(false)
                } catch (error) {
                  TrackError(error)
                  // actions.setErrors(parseAuthErrors(error.toString()))
                  actions.setSubmitting(false)
                }
              }}
              render={props => {
                return (
                  <React.Fragment>
                    <ResponsiveInput
                      label={'Instagram'}
                      name={'instagramHandle'}
                      onChangeText={props.setFieldValue}
                      // maybe should mask this with an @ sign?
                      // should probably make it clickable?
                      // Or doing something w/ a url so they know it works?
                      value={props.values.instagramHandle || ''}
                      editable={!props.isSubmitting}
                      autoComplete={'off'}
                    />
                    <ResponsiveInput
                      label={'Facebook Url'}
                      name={'facebookUrl'}
                      onChangeText={props.setFieldValue}
                      // should probably make it clickable?
                      // Or doing something w/ a url so they know it works?
                      value={props.values.facebookUrl || ''}
                      editable={!props.isSubmitting}
                      autoComplete={'off'}
                    />
                    <ResponsiveInput
                      label={'Yelp Id'}
                      name={'yelpId'}
                      onChangeText={props.setFieldValue}
                      // should probably make it clickable?
                      // Or doing something w/ a url so they know it works?
                      value={props.values.yelpId || ''}
                      editable={!props.isSubmitting}
                      autoComplete={'off'}
                    />
                    <ErrorsContainer errors={props.errors} />
                    <FormButtons
                      disabled={!props.dirty || props.isSubmitting}
                      isSubmitting={props.isSubmitting}
                      handleSubmit={props.handleSubmit}
                      handleCancel={() => props.resetForm(initialValues)}
                    />
                  </React.Fragment>
                )
              }}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  )
}
