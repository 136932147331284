import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import { AutoCompleteWeb, MIcon } from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import { colors, hexToRgba } from '../../../functions'

const s = StyleSheet.create({
  wrapper: {
    // display: 'flex',
    // flexDirection: 'row',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 100,
  },
  selectionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selection: {
    display: 'block',
    color: colors.white,
    fontSize: 18,
    lineHeight: 24,
    paddingVertical: 7,
    marginBottom: 10,
    flex: 1,
  },
  unselect: {
    color: colors.pink,
    fontSize: 16,
  },
  autoCompleteWrapperStyle: {
    width: '100%',
    backgroundColor: colors.primary3,
    marginRight: 20,
    marginBottom: 10,
    paddingLeft: 10,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  autoCompleteStyle: {
    flex: 1,
    display: 'flex',
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginVertical: 7,
    zIndex: -2,
  },
})

export const Organization = ({ additionalProps, organizations }) => {
  return (
    <DimensionsConsumer>
      {({ width }) => {
        return (
          <React.Fragment>
            <View style={[s.wrapper]}>
              {additionalProps.values.id ? (
                <View style={[s.selectionWrapper]}>
                  <Text style={[s.selection]}>
                    {additionalProps.values.name}
                  </Text>
                  <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={() => {
                      additionalProps.setFieldValue('name', null)
                      additionalProps.setFieldValue('id', null)
                    }}
                  >
                    <Text style={[s.unselect]}>Change Organization</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={[s.autoCompleteWrapperStyle]}>
                  <MIcon name={'search'} size={26} color={colors.white} />
                  <AutoCompleteWeb
                    wrapperStyle={{ flex: 1, display: 'flex' }}
                    items={organizations.map(org => ({
                      name: org.name,
                      label: org.name,
                      value: org,
                    }))}
                    handleSelect={({ value }) => {
                      additionalProps.setFieldValue('id', value.id)
                      additionalProps.setFieldValue('name', value.name)

                      const permissionTargetVenues = value.permissions
                        .filter(permission => permission.targetVenue)
                        .map(permission => permission.targetVenue)

                      let distinctAssignedVenues = [
                        ...new Set(permissionTargetVenues),
                      ].sort((a, b) => (a.name > b.name ? 1 : -1))

                      // Reset isDeleted and isAdded Venues
                      distinctAssignedVenues = distinctAssignedVenues
                        .filter(venue => {
                          return !venue.isAdded
                        })
                        .map(venue => {
                          if (venue.isDeleted) {
                            delete venue.isDeleted
                          }
                          return venue
                        })

                      additionalProps.setFieldValue(
                        'assignedVenues',
                        distinctAssignedVenues,
                      )
                    }}
                    value={additionalProps.values.name || ''}
                  />
                </View>
              )}
              <View style={[s.lineBreak]} />
            </View>
          </React.Fragment>
        )
      }}
    </DimensionsConsumer>
  )
}
