import React from 'react'
import * as t from 'prop-types'
import { StyleSheet } from 'react-native'
import { Mutation } from 'react-apollo'
import { colors, scale } from '../../../functions'
import { CREATE_VENUE } from '../../../gql/manage-venue'
import { TouchableBaseText } from './TouchableBaseText'

const s = StyleSheet.create({
  createVenue: {
    padding: scale(5),
    borderRadius: scale(5),
    marginBottom: scale(10),
  },
})

// Create venue mutation button
// props: googleInfo
export class CreateVenue extends React.Component {
  state = {
    /** Did the user just create the venue? */
    didCreateVenue: false,
  }

  render() {
    return (
      <Mutation mutation={CREATE_VENUE}>
        {(createVenue, { data }) => (
          <TouchableBaseText
            style={[
              s.createVenue,
              {
                backgroundColor: this.state.didCreateVenue
                  ? colors.secondary1
                  : colors.teal,
              },
            ]}
            disabled={this.state.didCreateVenue}
            onPress={async () => {
              try {
                const vars = {
                  placeId: this.props.placeId,
                  name: this.props.name,
                  address: this.props.address,
                  phoneNumber: this.props.phoneNumber,
                  intlPhoneNumber: this.props.intlPhoneNumber,
                  website: this.props.website,
                  geoloc: this.props.geoloc,
                  hoursText: this.props.hoursText,
                  hours: this.props.hours,
                }

                let res = await createVenue({ variables: vars })
                console.log('res', res)
                if (this.props.onPress) {
                  this.props.onPress(data)
                  this.setState({ didCreateVenue: true })
                }
                this.props.didCreate(res.data.createVenue)
              } catch (error) {
                this.props.toast.addMessage({
                  type: 'error',
                  text: `Error adding new venue.`,
                })
                console.log('Error adding new venue.', error)
              }
            }}
            color={this.state.didCreateVenue ? 'secondary3' : 'white'}
            fs={scale(14)}
            lh={scale(14)}
            weight={'semibold'}
            children={this.state.didCreateVenue ? 'Venue Added' : 'Add Venue'}
          />
        )}
      </Mutation>
    )
  }
}
CreateVenue.propTypes = {
  placeId: t.string.isRequired,
  name: t.string.isRequired,
  address: t.string,
  phoneNumber: t.string,
  intlPhoneNumber: t.string,
  website: t.string,
  geoloc: t.shape({
    lat: t.number.isRequired,
    lng: t.number.isRequired,
  }),
  hoursText: t.shape({
    hoursOpenText: t.arrayOf(t.string).isRequired,
  }),
  hours: t.arrayOf(
    t.shape({
      day: t.string.isRequired,
      startTime: t.number.isRequired,
      endTime: t.number.isRequired,
    }),
  ),
}
