import React from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import LinearGradient from 'react-native-web-linear-gradient'
import { scale } from '../../functions'

const logoHeight = 49
const logoTextDimensions = 215 / 59

const logoRectWidth = scale(335)
const logoRectHeight = scale(96.25)

const s = StyleSheet.create({
  triangleOverlay: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: 0,
    height: 0,
    zIndex: 3,
    borderTopColor: 'rgba(255,255,255,0.12)',
    borderRightColor: 'rgba(0,0,0,0)',
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.44,
        shadowRadius: 6,
      },
      android: {
        backgroundColor: 'rgba(0,0,0,0)',
        elevation: 3,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.44,
        shadowRadius: 6,
      },
    }),
  },
  logoRect: {
    width: logoRectWidth,
    height: logoRectHeight,
    zIndex: 2,
  },
  redLogoContainer: {
    zIndex: 2,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(35,35,35,0.65)',
  },
  logo: {
    width: `${logoHeight * logoTextDimensions}px`,
    height: `${logoHeight}px`,
    zIndex: 4,
  },
})

const TriangleOverlay = ({ width, height }) => (
  <View
    style={[
      s.triangleOverlay,
      width && { borderRightWidth: width },
      height && { borderTopWidth: height },
    ]}
  />
)

export const PinkLogo = ({ handlePress }) => (
  <TouchableOpacity
    activeOpacity={1}
    style={[s.logoRect, s.shadow]}
    onPress={handlePress}
  >
    <TriangleOverlay width={logoRectWidth} height={logoRectHeight} />
    <LinearGradient
      start={{ x: 0, y: 1.8 }}
      end={{ x: 0.765, y: 0 }}
      colors={['#FF6666', '#FF5EAF']}
      style={[s.redLogoContainer]}
    >
      <Image
        source={require('../../assets/BarCard_Virtual_Manager.png')}
        style={s.logo}
      />
    </LinearGradient>
  </TouchableOpacity>
)
