import React from 'react'
import { StyleSheet } from 'react-native'
import { SectionHeader, ResponsiveContainer } from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  sectionContainer: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingHorizontal: 20,
    zIndex: -2,
  },
  sectionContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: -2,
  },
})

export const Section = ({
  title,
  subtext,
  // this is the component that was passed in (...info-sections, ticket-sections)
  component,
  initialValues,
  allEventCategories,
  allAgeGroups,
  handleEventAdded,
  initialTicketValues,
  props,
  permissions,
}) => (
  <ResponsiveContainer
    style={s.sectionContainer}
    breakpoint={434} // 414 + 10 px padding on each side
    breakpointStyle={s.sectionContainerMobile}
  >
    <SectionHeader title={title} subtext={subtext} />
    {component({
      initialValues,
      allEventCategories,
      allAgeGroups,
      handleEventAdded,
      initialTicketValues,
      props,
      permissions,
    })}
  </ResponsiveContainer>
)
