import gql from 'graphql-tag'
import { OrganizationFragments } from './fragments'

/*
 * Get info for the Manage Ticket page / edit ticket
 *
 */

export const ORGANIZATIONS_QUERY = gql`
  query {
    allOrganizations(orderBy: name_ASC) {
      ...OrganizationInfo
    }
  }
  ${OrganizationFragments.info}
`

export const DELETE_ORGANIZATION_VENUE = gql`
  mutation($id: ID!) {
    deleteAccessControlEntry(id: $id) {
      ...PermissionsInfo
    }
  }
  ${OrganizationFragments.permissions}
`

export const CREATE_ORGANIZATION_VENUE = gql`
  mutation($id: ID!, $targetVenueId: ID!) {
    createVenueInfo: createAccessControlEntry(
      organizationId: $id
      targetVenueId: $targetVenueId
      grantingOrganizationId: $id
      permission: MANAGE_VENUE_INFO
    ) {
      ...PermissionsInfo
    }

    createVenueEvents: createAccessControlEntry(
      organizationId: $id
      targetVenueId: $targetVenueId
      grantingOrganizationId: $id
      permission: MANAGE_VENUE_EVENTS
    ) {
      ...PermissionsInfo
    }

    createVenueSpecials: createAccessControlEntry(
      organizationId: $id
      targetVenueId: $targetVenueId
      grantingOrganizationId: $id
      permission: MANAGE_VENUE_SPECIALS
    ) {
      ...PermissionsInfo
    }
  }
  ${OrganizationFragments.permissions}
`
