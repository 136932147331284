import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const SpecialFragments = {
  specialDetails: gql`
    fragment SpecialDetails on Special {
      id
      name
      description
      days
      startTime
      endTime
      published
      referenceUrl
    }
  `,
  specialItemDetails: gql`
    fragment SpecialItemDetails on SpecialItem {
      id
      name
      description
      price
      type
      # discount
      # buyOneGetOne
    }
  `,
  full: gql`
    fragment SpecialFull on Special {
      id
      name
      description
      days
      startTime
      endTime
      published
      referenceUrl
      items {
        id
        name
        description
        price
        type
      }
    }
  `,
}

export const WeeklySpecialFragments = {
  full: gql`
    fragment WeeklySpecialFull on WeeklySpecial {
      id
      mondayTitle
      mondayBody
      tuesdayTitle
      tuesdayBody
      wednesdayTitle
      wednesdayBody
      thursdayTitle
      thursdayBody
      fridayTitle
      fridayBody
      saturdayTitle
      saturdayBody
      sundayTitle
      sundayBody
    }
  `,
}
