import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { colors, fonts } from '../../../functions'
import {
  SET_VENUE_FEATURE_PHOTO,
  UPDATE_VENUE_FEATURE_PHOTO,
  UPDATE_VENUE_PHOTO,
  VENUE_INFO_QUERY,
} from '../../../gql'
import {
  PhotoCardSquare,
  PhotoPlaceholder,
  PhotoUploader,
  HoverableText,
  BaseText,
} from '../../../components'
import { PhotoControls } from '../components'

const s = StyleSheet.create({
  photosContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  uploadButtonContainer: {
    marginBottom: 23,
  },
  dim: {
    opacity: 0.7,
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingLeft: 30,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.secondary2,
    zIndex: 6,
  },
})

const photoActions = [
  {
    label: 'Set as Feature',
    action: 'setFeature',
  },
  {
    label: 'Add to Profile',
    action: 'addToProfile',
  },
  {
    label: 'Remove from Profile',
    action: 'removeFromProfile',
  },
]

class PhotosSection extends React.Component {
  state = {
    aspectRatio: 1,
    featurePhotoId: null,
    showPublished: true,
    showLarge: false,
  }

  componentWillMount() {
    const { photos } = this.props.initialValues
    return this.setState({
      featurePhotoId:
        (photos.filter(x => x.feature)[0] &&
          photos.filter(x => x.feature)[0].id) ||
        null,
      publishedPhotos: photos.filter(x => x.profile).filter(x => x.published),
      unpublishedPhotos: photos
        .filter(x => x.profile)
        .filter(x => !x.published),
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues) {
      const { photos } = nextProps.initialValues
      return this.setState({
        featurePhotoId:
          (photos.filter(x => x.feature)[0] &&
            photos.filter(x => x.feature)[0].id) ||
          null,
        publishedPhotos: photos.filter(x => x.profile).filter(x => x.published),
        unpublishedPhotos: photos
          .filter(x => x.profile)
          .filter(x => !x.published),
      })
    }
  }
  render() {
    const { initialValues, title } = this.props
    const {
      aspectRatio,
      showPublished,
      showLarge,
      publishedPhotos,
      unpublishedPhotos,
    } = this.state
    const photos = showPublished ? publishedPhotos : unpublishedPhotos

    return (
      <React.Fragment>
        <PhotoControls
          title={title}
          showPublished={showPublished}
          showLarge={showLarge}
          handleSetState={newState => this.setState(newState)}
        />
        <Mutation
          mutation={SET_VENUE_FEATURE_PHOTO}
          refetchQueries={() => [
            { query: VENUE_INFO_QUERY, variables: { id: initialValues.id } },
          ]}
        >
          {(setFeature, { error, loading }) => (
            <Mutation mutation={UPDATE_VENUE_FEATURE_PHOTO}>
              {(updateFeature, { error, loading }) => (
                <Mutation mutation={UPDATE_VENUE_PHOTO}>
                  {(updatePhoto, { error, loading }) => (
                    <React.Fragment>
                      <View style={s.photosContainer}>
                        {photos && photos.length > 0 ? (
                          photos.map((p, index) => (
                            <PhotoCardSquare
                              key={`${p.id}-${index}`}
                              image={p}
                              actions={photoActions}
                              size={showLarge ? 'medium' : 'small'}
                              featured={p.feature}
                              handleAction={async (action, image) => {
                                if (
                                  action === 'addToProfile' ||
                                  action === 'removeFromProfile'
                                ) {
                                  try {
                                    const res = await updatePhoto({
                                      variables: {
                                        id: image.id,
                                        profile:
                                          action === 'addToProfile'
                                            ? true
                                            : false,
                                      },
                                    })
                                    console.log('res', res)
                                    console.log(
                                      'res.data.updatePhoto',
                                      res.data.updatePhoto,
                                    )
                                  } catch (error) {
                                    TrackError(error)
                                  }
                                }
                                if (action === 'setFeature') {
                                  if (this.state.featurePhotoId) {
                                    try {
                                      const res = await updateFeature({
                                        variables: {
                                          prevFeaturePhotoId: this.state
                                            .featurePhotoId,
                                          featurePhotoId: image.id,
                                        },
                                      })
                                      console.log('res', res)
                                      console.log(
                                        'res.data.updateFeature',
                                        res.data.updateFeature,
                                      )
                                    } catch (error) {
                                      TrackError(error)
                                    }
                                  } else {
                                    try {
                                      const res = await setFeature({
                                        variables: {
                                          featurePhotoId: image.id,
                                        },
                                      })
                                      console.log('res', res)
                                      console.log(
                                        'res.data.setFeature',
                                        res.data.setFeature,
                                      )
                                    } catch (error) {
                                      TrackError(error)
                                    }
                                  }
                                }
                              }}
                            />
                          ))
                        ) : (
                          <PhotoPlaceholder
                            size={showLarge ? 'cover' : 'square'}
                            wrapperStyle={{ marginBottom: 23 }}
                          />
                        )}
                      </View>
                      <View style={[s.row, { paddingLeft: 0 }]}>
                        <View style={[s.rowCenter, { marginRight: 20 }]}>
                          <HoverableText
                            outerContainerStyle={s.touchWrapper}
                            textStyle={[s.text, aspectRatio !== 1 && s.dim]}
                            textHoverStyle={s.textHoverStyle}
                            children={'1:1 Ratio'}
                            onPress={() => this.setState({ aspectRatio: 1 })}
                          />
                          <BaseText fs={13} lh={18} children={' | '} />
                          <HoverableText
                            outerContainerStyle={s.touchWrapper}
                            textStyle={[s.text, aspectRatio !== 1.9 && s.dim]}
                            textHoverStyle={s.textHoverStyle}
                            children={'16:9 Ratio'}
                            onPress={() => this.setState({ aspectRatio: 1.9 })}
                          />
                          <BaseText fs={13} lh={18} children={' | '} />
                          <HoverableText
                            outerContainerStyle={s.touchWrapper}
                            textStyle={[s.text, aspectRatio !== 0 && s.dim]}
                            textHoverStyle={s.textHoverStyle}
                            children={'Free Ratio'}
                            onPress={() => this.setState({ aspectRatio: 0 })}
                          />
                        </View>
                      </View>
                      <View style={s.uploadButtonContainer}>
                        <PhotoUploader
                          aspectRatio={aspectRatio}
                          maxFiles={20} // the max number of files the user can upload at a time
                          photoType={'VenuePhoto'} // enum('EventPhoto', 'VenuePhoto')
                          // additional props we want to send along with the graphql mutation
                          photoProps={{
                            venueId: initialValues.id,
                            profile: true,
                            menu: false,
                            feature: false,
                            gallery: false,
                            foodDrink: null,
                            experience: null,
                            published: null, // chanage to true soon
                          }}
                          // do we want to set an optimisticResponse in apollo?
                          optimisticUpdates={true}
                          // query we want to write cache w/ and for apollo to refetch
                          refetchQuery={{
                            query: VENUE_INFO_QUERY,
                            variables: { id: initialValues.id },
                          }}
                        />
                      </View>
                    </React.Fragment>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      </React.Fragment>
    )
  }
}

export const Photos = props => <PhotosSection {...props} />
