import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { withRouter } from 'react-router-native'
import { GradientIconButton, MIcon, SubText } from '../../components'
import { scale } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    borderBottomWidth: 2,
    borderBottomColor: '#363535',
    borderLeftWidth: 2,
    borderLeftColor: '#363535',
  },
  dropdownContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    width: scale(54),
    height: scale(54),
    borderRadius: scale(54) / 2,
    alignItems: 'center',
    justifyContent: 'center',
    margin: scale(20),
  },
  arrowIcon: {
    marginHorizontal: scale(15),
  },
  dropdownItems: {
    marginTop: '2px',
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 100,
    // bottom: 0,
    // overflowY: 'auto',
    // need to make sure this doesn't overlap a ScrolLView
    // or no touch events will get passed through
  },
  dropdownItem: {
    zIndex: 100,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#232323',
  },
})

const accountIconSize = scale(28)
const arrowIconSize = scale(28)

class Account extends React.Component {
  state = {
    opened: false,
  }
  handleToggle = () => {
    this.setState({ opened: !this.state.opened })
  }
  handleAccount = () => {
    this.setState({ opened: false })
    this.props.history.push('/account/users')
  }
  handleLogout = () => {
    this.setState({ opened: false })
    this.props.history.push('/')
  }
  render() {
    const { opened } = this.state
    return (
      <View style={s.wrapper}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={s.dropdownContainer}
          onPress={this.handleToggle}
        >
          <GradientIconButton
            iconColors={['#16CCD9', '#F462A8']}
            iconName={'ic_face'}
            iconType={'custom'}
            size={accountIconSize}
            color={'#fff'}
          />
          <SubText mr={20} fw={400} color={'#fff'} children={"Maverick's"} />
          <MIcon
            name={`arrow-drop-${opened ? 'up' : 'down'}`}
            color={'rgba(255,255,255,0.54)'}
            size={arrowIconSize}
            style={s.arrowIcon}
          />
        </TouchableOpacity>
        {opened && (
          <View style={s.dropdownItems}>
            <TouchableOpacity
              activeOpacity={0.8}
              style={s.dropdownItem}
              onPress={this.handleAccount}
            >
              <SubText
                pv={14}
                pl={75}
                color={'#fff'}
                children={'User account'}
              />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.8}
              style={s.dropdownItem}
              onPress={this.handleLogout}
            >
              <SubText pv={14} pl={75} color={'#fff'} children={'Logout'} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    )
  }
}

export default withRouter(Account)
