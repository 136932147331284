import React from 'react'
import { View, StyleSheet } from 'react-native'
import { BaseText, EmptyBoxOpen, FormButton } from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 142, // should do a flex-box layout here
  },
})

export const NoSpecials = ({ handlePress }) => (
  <View style={s.wrapper}>
    <EmptyBoxOpen />
    <BaseText
      mt={34} // should do a flex-box layout here
      mb={160} // should do a flex-box layout here
      color={'secondary2'}
      fs={16}
      mh={20} // make sure the text doesnt touch the edges of the screen on smaller devices
      lh={22}
      ta={'center'} // ensure the text wraps cleanly on smaller devices
      children={"There doesn't seem to be anything here."}
    />
    <FormButton
      text={'+ New Special'}
      textColor={colors.teal}
      handlePress={handlePress}
    />
  </View>
)
