import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, Text, View } from 'react-native'
import Autocomplete from 'react-autocomplete'
import { colors, fonts } from '../../functions'

const s = StyleSheet.create({
  outer: {
    display: 'flex',
    zIndex: -1,
  },
  menuWrapper: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 1000,
    backgroundColor: colors.primary3,
    // borderBottomWidth: 1,
    // borderRightWidth: 1,
    // borderLeftWidth: 1,
    // borderColor: colors.teal,
  },
  item: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    zIndex: 1000,
    height: 44,
    paddingLeft: 14,
    backgroundColor: colors.primary3,
  },
  itemHighlighted: {
    backgroundColor: colors.primary2,
  },
  itemLabel: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.white,
  },
})

class AutoCompleteWeb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      items: [],
      value: null,
      textAlign: props.textAlign || 'left',
    }
  }

  componentWillMount() {
    this.setState({
      items: this.props.items,
      value: this.props.value,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
    if (nextProps.items && nextProps.items.length !== this.props.items.length) {
      this.setState({ items: nextProps.items })
    }
  }

  matchStateToTerm = (state, value) => {
    const resp =
      state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
      state.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    return resp
  }

  comp = (a, b) => {
    return a.toLowerCase().trim() === b.toLowerCase().trim()
  }

  findItem = (query = '') => {
    const { maxNumberOfItems } = this.props
    const { items } = this.state
    return items
      .filter(({ name }) => name.startsWith(query))
      .sort(this._sortItems)
      .slice(0, maxNumberOfItems)
      .reverse()
  }

  findItem = query => {
    const { maxNumberOfItems } = this.props
    const { items } = this.state
    if (query === '' || query === null) {
      return items.slice(0, maxNumberOfItems)
    }
    const regex = new RegExp(`${query.trim()}`, 'i')
    return items
      .filter(x =>
        this.props.onSearch
          ? this.props.onSearch(query, x)
          : x.name.search(regex) >= 0,
      )
      .sort(this.props.onSort)
      .slice(0, maxNumberOfItems)
      .reverse()
  }

  _handleSelect = (value, item) => {
    if (this.props.clearOnSelect) {
      this.setState({ value: '' })
      this.props.handleSelect(item)
    } else {
      this.setState({ value })
      this.props.handleSelect(item)
    }
  }

  _handleChange = (event, value) => {
    this.setState({ value })
    this.props.handleInput && this.props.handleInput(value)
  }

  _renderItem = (item, isHighlighted) => {
    return (
      <View
        key={item.label}
        style={[s.item, isHighlighted && s.itemHighlighted]}
      >
        <Text style={[s.itemLabel, { textAlign: this.state.textAlign }]}>
          {item.label}
        </Text>
      </View>
    )
  }

  _renderMenu = x => {
    return <View style={s.menuWrapper}>{x}</View>
  }

  _sortItems = (a, b, value) => {
    return a.label > b.label ? -1 : 1
  }

  render() {
    const { wrapperStyle, width, maxWidth, disabled } = this.props
    const textAlign = this.state.textAlign
    const items = this.findItem(this.state.value)

    return (
      <View
        style={[
          s.outer,
          wrapperStyle && wrapperStyle,
          width && { width: width },
          maxWidth && { maxWidth: maxWidth },
        ]}
        pointerEvents={disabled ? 'none' : 'auto'}
      >
        <Autocomplete
          value={this.state.value}
          onMenuVisibilityChange={open => this.setState({ open })}
          items={
            items.length === 1 && this.comp(this.state.value, items[0].name)
              ? []
              : items
          }
          open={this.state.open}
          getItemValue={item => item.name}
          onChange={this._handleChange}
          onSelect={this._handleSelect}
          selectOnBlur={!!items.length}
          renderMenu={this._renderMenu}
          renderItem={this._renderItem}
          wrapperStyle={{ display: 'flex', flex: 1 }}
          // shouldItemRender={this.matchStateToTerm} // favor our custom implementation so we can limit the # of results
          // sortItems={sortStates} // lets just use alphabetical for now. i don't see a reason to implement this functionality
          inputProps={{
            //id: 'states-autocomplete',
            style: {
              boxSizing: 'border-box',
              borderWidth: 0,
              backgroundColor: colors.primary3,
              fontFamily: fonts.light,
              fontSize: '14px',
              lineHeight: '24px',
              color: disabled ? colors.secondary2 : colors.white,
              paddingLeft: '14px',
              paddingTop: '7px',
              paddingBottom: '7px',
              textAlign: textAlign,
              width: '100%',
              // borderBottomWidth: 0,
              // borderTopWidth: 1,
              // borderRightWidth: 1,
              // borderLeftWidth: 1,
              // borderRightColor: colors.teal,
              // borderTopColor: colors.teal,
              // borderLeftColor: colors.teal,
            },
            autoComplete: this.props.innerAutoCompleteType,
          }}
        />
      </View>
    )
  }
}

AutoCompleteWeb.propTypes = {
  /** a list of items for the autocomplete dropdown. each item in the array needs a `label` at minimum */
  items: t.array.isRequired,
  /** the label of the selected value */
  value: t.string.isRequired,
  /** the max number of items to display as suggestions. default value is 4 */
  maxNumberOfItems: t.number,
  /** the function that gets called when an item is selected */
  handleSelect: t.func.isRequired,
  /** style for View that wraps the component */
  wrapperStyle: t.object,

  disabled: t.bool,

  innerAutoCompleteType: t.string,
}

AutoCompleteWeb.defaultProps = {
  maxNumberOfItems: 4,
  innerAutoCompleteType: 'off',
}

export default AutoCompleteWeb
