import gql from 'graphql-tag'

export const CREATE_VENUE_GROUP_ORGANIZATION_WITH_USER = gql`
  mutation(
    $organizationName: String!
    $userFirstName: String!
    $userLastName: String!
    $email: String!
    $password: String!
  ) {
    createVenueGroupOrganizationWithUser(
      organizationName: $organizationName
      userFirstName: $userFirstName
      userLastName: $userLastName
      email: $email
      password: $password
    ) {
      organizationId
      ownerId
      memberIds
    }
  }
`
