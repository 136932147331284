import React from 'react'
import { StyleSheet, View } from 'react-native'
import { FormButton } from '../../components'
import { colors } from '../../functions'

const s = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20,
    marginBottom: 17,
  },
})

export const FormButtons = ({
  containerStyle,
  disabled,
  handleCancel,
  handleSubmit,
  isSubmitting,
}) => (
  <View style={[s.container, containerStyle && containerStyle]}>
    <FormButton
      text={'Cancel'}
      textColor={colors.pink}
      disabled={disabled}
      handlePress={handleCancel}
    />
    <FormButton
      text={'Save'}
      textColor={colors.teal}
      disabled={disabled}
      isSubmitting={isSubmitting}
      handlePress={handleSubmit}
    />
  </View>
)
