import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-native'
import Modal from 'modal-enhanced-react-native-web'
import { colors } from '../../../functions'
import {
  BaseText,
  FormButton,
  MIcon,
  PopoverMenu,
  FixedWidthContainer,
} from '../../../components'
import {
  EVENTS_QUERY,
  UPDATE_EVENT_PUBLISHED,
  DELETE_EVENT,
} from '../../../gql'

const s = StyleSheet.create({
  wrapper: {
    // padding: 10,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
})

class EventPopover extends React.Component {
  state = {
    modalIsVisible: false,
  }

  _handleRemove = () => {
    this.setState({ modalIsVisible: true })
  }

  _handleEdit = id => {
    this.props.history.push(`event/${id}/edit`)
  }

  _handleDuplicate = id => {
    this.props.history.push(`event/duplicate?${id}`)
  }

  _handleAdd = () => {
    this.props.history.push(`event/new`)
  }

  _renderModalContent = () => (
    <Mutation
      mutation={DELETE_EVENT}
      refetchQueries={() => [
        {
          query: EVENTS_QUERY,
          variables: { id: this.props.venueId, date: this.props.queryDate },
        },
      ]}
    >
      {(deleteEvent, { error, loading }) => (
        <FixedWidthContainer style={s.modal} width={400} padding={22}>
          <View style={s.modalMessageContainer}>
            <BaseText
              fs={16}
              lh={24}
              ta={'center'}
              color={'secondary3'}
              children={'Permanently delete event?'}
            />
          </View>
          <View style={s.modalControls}>
            <FormButton
              text={'Cancel'}
              outerStyle={{ marginRight: 0 }}
              textColor={colors.teal}
              handlePress={() => this.setState({ modalIsVisible: false })}
            />
            <FormButton
              text={'Delete'}
              outerStyle={{ marginRight: 0 }}
              textColor={colors.pink}
              handlePress={async () => {
                // to do:
                // need to make this cascade & delete all children !
                try {
                  console.log('event.id', this.props.id)
                  const res = await deleteEvent({
                    variables: { id: this.props.id },
                  })
                  console.log(
                    'successfully removed Event',
                    res.data.deleteEvent,
                  )
                } catch (e) {
                  console.log('error removing Event', e)
                }
              }}
            />
          </View>
        </FixedWidthContainer>
      )}
    </Mutation>
  )

  render() {
    const { id, handleEdit } = this.props
    const { modalIsVisible } = this.state

    return (
      <React.Fragment>
        <Mutation
          mutation={UPDATE_EVENT_PUBLISHED}
          refetchQueries={() => [
            {
              query: EVENTS_QUERY,
              variables: { id: this.props.venueId, date: this.props.queryDate },
            },
          ]}
        >
          {(updateEventPublished, { error, loading }) => (
            <View style={s.wrapper}>
              <PopoverMenu
                menuProviderWrapperStyle={{ padding: 0 }}
                renderTrigger={() => (
                  <MIcon name={'more-horiz'} color={colors.teal} size={24} />
                )}
                options={[
                  {
                    label: 'Publish',
                    onSelect: async () => {
                      try {
                        console.log('event.id', id)
                        const res = await updateEventPublished({
                          variables: { id: id, hidden: false, published: true },
                        })
                        console.log(
                          'successfully published Event',
                          res.data.updateEventPublished,
                        )
                      } catch (e) {
                        console.log('error publishing Event', e)
                      }
                    },
                  },
                  {
                    label: 'Unpublish',
                    onSelect: async () => {
                      try {
                        console.log('event.id', id)
                        const res = await updateEventPublished({
                          // to do: change to published
                          variables: { id: id, hidden: true, published: false },
                        })
                        console.log(
                          'successfully unpublished Event',
                          res.data.updateEventPublished,
                        )
                      } catch (e) {
                        console.log('error unpublishing Event', e)
                      }
                    },
                  },
                  {
                    label: 'Edit',
                    onSelect: () => this._handleEdit(id),
                  },
                  // logic still a WIP. so hold off for now.
                  {
                    label: 'Duplicate',
                    onSelect: () => this._handleDuplicate(id),
                  },
                  {
                    label: 'Delete',
                    onSelect: () => this._handleRemove(),
                  },
                ]}
              />
            </View>
          )}
        </Mutation>

        <Modal
          isVisible={modalIsVisible}
          onBackdropPress={() => this.setState({ modalIsVisible: false })}
        >
          {this._renderModalContent()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(EventPopover)
