import React from 'react'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import Autocomplete from 'react-native-autocomplete-input'
import { scale, colors, fonts } from '../../functions'
import { HoverableTextTouchHighlight } from '../../components'

const Autocompletee = Autocomplete

const searchBarHeight = scale(40)

const s = StyleSheet.create({
  absoluteWrapper: {
    display: 'flex',
    // flex: 1,
  },
  // autocompleteContainer: {
  // 	flex: 1,
  // 	backgroundColor: colors.blackBg,
  // 	alignSelf: 'stretch',
  // 	position: 'absolute',
  // 	top: 0,
  // 	right: 0,
  // 	left: 0,
  // 	zIndex: 50,
  // },
  textInputStyle: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.white,
    // zIndex: 50,
    paddingVertical: 11, // same padding exists in HoverableTextTouchHighlight. makes rows same height
    // backgroundColor: colors.primary3,
  },
  inputContainerStyle: {
    backgroundColor: colors.primary3,
    borderWidth: 0,
    height: searchBarHeight,
    paddingLeft: 14,
    // paddingLeft: scale(23),
    justifyContent: 'center',
    // width: '100%',
    width: 120,
    position: 'absolute',
    // zIndex: 50,
  },
  listContainerStyle: {
    display: 'flex',
    flex: 1,
    elevation: 5, //maybe not necessary
    // width: '100%',
    width: 120,
    marginTop: searchBarHeight,
    backgroundColor: colors.primary2,
    zIndex: 50,
  },
  listStyle: {
    // borderWidth: StyleSheet.hairlineWidth,
    // borderColor: 'green',
    width: '100%',
    position: 'absolute',
    zIndex: 100,
    elevation: 5, //maybe not necessary
    backgroundColor: colors.primary2,
  },
  resultTouchWrapper: {
    zIndex: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    backgroundColor: colors.primary3,
    paddingLeft: 14,
  },
  resultText: {
    fontFamily: fonts.regular,
    alignSelf: 'flex-start',
    fontSize: 14,
    lineHeight: 24,
    color: colors.secondary2,
    zIndex: 50,
  },
})

class AutoComplete extends React.Component {
  state = {
    query: '',
    items: [],
    backspacePressed: false,
  }

  componentWillMount() {
    if (this.props.items && this.props.items.length > 0) {
      return this.setState({ items: this.props.items })
    } else {
      return
    }
  }

  setTextInputAria = ref => {
    // console.log('setTextInputAria fired')
    // if (ref && Platform.OS === 'web') {
    // 	console.log('setTextInputAria inner', ref)
    // 	ref.setNativeProps({
    // 		role: 'listbox',
    // 	})
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.items.length === 0 && nextProps.items) {
      return this.setState({ items: nextProps.items })
    }
    if (nextProps.items && this.state.items.length !== nextProps.items.length) {
      return this.setState({ items: nextProps.items })
    }
  }

  findItem = query => {
    if (query === '') {
      return []
    }
    const { items } = this.state
    const regex = new RegExp(`${query.trim()}`, 'i')
    return items.filter(x => x.name.search(regex) >= 0).slice(0, 5)
  }

  handleSelect = item => {
    if (this.props.clearOnSelect) {
      this.setState({ query: '' })
      this.props.handleSelect(item)
    } else {
      this.setState({ query: item.name })
      this.props.handleSelect(item)
    }
  }

  handleInput = text => {
    this.setState({ query: text })
    this.props.handleInput && this.props.handleInput(text)
  }
  // _onFocus = () => {
  // 	if (this.state.hasFocused) {
  // 		return
  // 	} else {
  // 		return this.setState({ hasFocused: true })
  // 	}
  // }
  _onKeyPress = ({ nativeEvent }) => {
    if (
      this.state.backspacePressed === false &&
      nativeEvent.key === 'Backspace'
    ) {
      this.setState({ backspacePressed: true })
    } else {
      return
    }
    // this.setState({keys: this.state.keys + nativeEvent.key + ', '})
  }

  _onBlur = () => {
    if (this.state.query.length === 0) {
      return this.setState({
        query: this.props.value ? this.props.value : this.props.placeholder,
      })
    }
  }

  _renderTextInput = props => {
    // remove props that TextInput does not need
    const {
      onShowResult,
      data,
      ref,
      inputContainerStyle,
      keyboardShouldPersistTaps,
      listContainerStyle,
      listStyle,
      onStartShouldSetResponderCapture,
      renderItem,
      renderSeparator,
      renderTextInput,
      rowHasChanged,
      defaultValue,
      ...rest
    } = props
    // pass along value instead of defaultValue. defaultValue wasn't updating the screen on handleSelect
    return (
      <TextInput
        ref={this.setTextInputAria}
        value={defaultValue}
        {...rest}
        onKeyPress={this._onKeyPress}
        onBlur={this._onBlur}
      />
    )
  }

  comp = (a, b) => {
    return a.toLowerCase().trim() === b.toLowerCase().trim()
  }

  render() {
    const { query, backspacePressed } = this.state
    const { wrapperStyle } = this.props
    const items = this.findItem(query)

    const defaultValue =
      query.length > 0 || (backspacePressed === true && query.length === 0)
        ? query
        : this.props.value
        ? this.props.value
        : this.props.placeholder

    const extraStyles = this.props.width ? { width: this.props.width } : null
    return (
      <View style={[s.absoluteWrapper, wrapperStyle && wrapperStyle]}>
        <Autocompletee
          ref={c => (this.autocomplete = c)}
          // data & values
          data={
            items.length === 1 && this.comp(query, items[0].name) ? [] : items
          }
          defaultValue={defaultValue}
          placeholder={this.props.placeholder}
          // TextInput props
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={this.props.autoFocus}
          keyboardAppearance={'dark'}
          keyboardShouldPersistTaps={'false'}
          // styles
          style={s.textInputStyle}
          //containerStyle={s.autocompleteContainer}
          inputContainerStyle={[
            s.inputContainerStyle,
            extraStyles && extraStyles,
          ]}
          listContainerStyle={[
            s.listContainerStyle,
            extraStyles && extraStyles,
          ]}
          listStyle={s.listStyle}
          // colors
          placeholderTextColor={colors.secondary4}
          selectionColor={colors.teal}
          // functions
          onShowResult={t => console.log('onShowResult', t)}
          onChangeText={this.handleInput}
          renderTextInput={this._renderTextInput}
          renderItem={(item, someRandomProp, index) => (
            <HoverableTextTouchHighlight
              key={index}
              activeOpacity={1}
              underlayColor={colors.primary2}
              text={item.label}
              style={s.resultTouchWrapper}
              hoverStyle={{ backgroundColor: colors.primary2 }}
              textStyle={s.resultText}
              textHoverStyle={s.resultText}
              onPress={() => this.handleSelect(item)}
              role={'option'}
            />
          )}
        />
      </View>
    )
  }
}

export default AutoComplete
