import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { getNumberOfFormLines } from '../../../functions'
import { UPDATE_VENUE_DETAILS } from '../../../gql'
import {
  FormButtons,
  ResponsiveInput,
  ErrorsContainer,
} from '../../../components'

function sanitizeValues({ liveMusicDetails, happyHourDetails, ...rest }) {
  return {
    ...rest,
    liveMusicDetails:
      liveMusicDetails &&
      liveMusicDetails.length > 0 &&
      liveMusicDetails !== ' '
        ? liveMusicDetails
        : null,
    happyHourDetails:
      happyHourDetails &&
      happyHourDetails.length > 0 &&
      happyHourDetails !== ' '
        ? happyHourDetails
        : null,
  }
}

export const Details = ({ initialValues }) => {
  return (
    <Mutation mutation={UPDATE_VENUE_DETAILS}>
      {(updateVenueDetails, { error, loading }) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={values => {
            let errors = {}
            // if (!values.description || values.description.length <= 1) {
            //   errors.description = 'A description is required!'
            // }
            return errors
          }}
          onSubmit={async (values, actions) => {
            const sanitizedValues = sanitizeValues(values)
            try {
              const res = await updateVenueDetails({
                variables: sanitizedValues,
              })
              console.log('res', res)
              console.log(
                'res.data.updateVenueDetails',
                res.data.updateVenueDetails,
              )
              actions.setSubmitting(false)
            } catch (error) {
              TrackError(error)
              // actions.setErrors(parseAuthErrors(error.toString()))
              actions.setSubmitting(false)
            }
          }}
          render={props => {
            // prettier-ignore
            const hhNumLines = getNumberOfFormLines(props.values.happyHourDetails)
            // prettier-ignore
            const lmNumLines = getNumberOfFormLines(props.values.liveMusicDetails)
            return (
              <React.Fragment>
                <ResponsiveInput
                  label={'Happy Hour Details'}
                  name={'happyHourDetails'}
                  multiline={true}
                  numberOfLines={hhNumLines}
                  onChangeText={props.setFieldValue}
                  value={props.values.happyHourDetails || ''}
                  editable={!props.isSubmitting}
                  autoComplete={'off'}
                />
                <ResponsiveInput
                  label={'Live Music Details'}
                  name={'liveMusicDetails'}
                  multiline={true}
                  numberOfLines={lmNumLines}
                  onChangeText={props.setFieldValue}
                  value={props.values.liveMusicDetails || ''}
                  editable={!props.isSubmitting}
                  autoComplete={'off'}
                />
                <ErrorsContainer errors={props.errors} />
                <FormButtons
                  disabled={!props.dirty || props.isSubmitting}
                  isSubmitting={props.isSubmitting}
                  handleSubmit={props.handleSubmit}
                  handleCancel={() => props.resetForm(initialValues)}
                />
              </React.Fragment>
            )
          }}
        />
      )}
    </Mutation>
  )
}
