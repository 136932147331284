import { dayEnumMap } from './hoursHelpers'

export const ONE_DAY = 1440 // 60 * 24

export function timeStringToMinutes(time) {
  var hours = parseInt(time.slice(0, 2)) * 60
  var minutes = parseInt(time.slice(2, 4))
  return hours + minutes
}

// Formats periods into BarCard's hours format
export function formatPeriods(periods) {
  console.log('periods', periods)

  if (periods == null) {
    return []
  }

  // Map the periods into hours
  return periods
    .filter(period => period.open != null && period.close != null)
    .map(period => {
      // Is the venue open and closed on the same day?
      const sameDay = period.open.day === period.close.day

      return period.open != null
        ? {
            day: period.open.day,
            startTime: timeStringToMinutes(period.open.time),
            endTime: sameDay
              ? timeStringToMinutes(period.close.time)
              : timeStringToMinutes(period.close.time) + ONE_DAY,
          }
        : {}
    })
}
