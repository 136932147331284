import React from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-native'
import { LoadingPage } from '../../components'
import { USER_QUERY } from '../../gql'

const WithUser = props => (
  <Query query={USER_QUERY} fetchPolicy={'network-only'}>
    {/* <Query {...props} query={USER_QUERY} fetchPolicy={'cache-and-network'}> */}
    {({ data, networkStatus }) => {
      if (networkStatus === 1) return <LoadingPage />
      // consider catching a network/timeout error, as a failed network req should not force the users into signing in again
      if (!data || !data.user) return <Redirect to={'/login'} />
      return props.children(data)
    }}
  </Query>
)

export default WithUser
