import React from 'react'
import * as t from 'prop-types'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { colors, minutesTimeHash } from '../../../functions'
import { BaseText, MIcon } from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import { SpecialItem, SpecialPopover } from './index'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 374,
    // internal spacing
    paddingVertical: 11,
    paddingHorizontal: 15,
    // external spacing
    marginBottom: 18,
    // borders
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.secondary2,
    backgroundColor: colors.primary2,
    // shadow
    shadowOpacity: 0.5,
    shadowColor: colors.black0,
    shadowRadius: 6, // shadowBlur
    shadowOffset: { width: 0, height: 3 },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  expandedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 19,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10, // to make it slightly off center like the design
  },
  descriptionContainer: {
    display: 'flex',
    marginRight: 8, // 23 - 15
  },
})

const dayMap = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thurs',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
}

const SpecialNameText = props => (
  <BaseText
    fs={16}
    lh={22}
    weight={'bold'}
    color={'white'}
    numberOfLines={1}
    ellipsizeMode={'tail'}
    {...props}
  />
)

const SpecialTimeDayText = props => (
  <BaseText
    fs={14}
    lh={19}
    color={'secondary2'}
    numberOfLines={1}
    ellipsizeMode={'tail'}
    {...props}
  />
)

const PublishedText = ({ published }) => (
  <BaseText
    fs={12}
    lh={17}
    pt={2}
    color={published ? 'teal' : 'pink'}
    children={published ? 'Published' : 'Not Published'}
  />
)

const DescriptionSection = ({ description }) => (
  <View style={s.descriptionContainer}>
    <BaseText
      fs={14}
      lh={24}
      pb={10}
      weight={'semibold'}
      color={'secondary3'}
      children={'Description'}
    />
    <BaseText
      fs={14}
      lh={19}
      weight={'light'}
      color={'secondary2'}
      children={description}
    />
  </View>
)

export class SpecialCard extends React.Component {
  state = {
    opened: false,
  }

  _handleOpen = () => {
    this.setState({ opened: !this.state.opened })
  }

  render() {
    const { opened } = this.state
    const { special, handleEdit } = this.props
    const {
      id,
      name,
      description,
      days,
      startTime,
      endTime,
      published,
      items,
    } = special
    const dayText = days.map(x => dayMap[x]).join(', ')
    const timeText = `${minutesTimeHash[startTime]} - ${
      minutesTimeHash[endTime]
    }`

    const showMore =
      (items && items.length > 0) || (description && description.length > 0)
    const lastIndex = items ? items.length - 1 : 0

    return (
      <DimensionsConsumer>
        {({ width }) => {
          const isSmall = width < 394
          return (
            <View style={[s.wrapper, isSmall && { width: width - 20 }]}>
              <View style={s.topContainer}>
                <View style={s.column}>
                  <SpecialNameText children={name} />
                  <SpecialTimeDayText children={dayText} />
                  <SpecialTimeDayText children={timeText} />
                  <PublishedText published={published} />
                </View>
                <View style={s.controlsRow}>
                  {showMore && (
                    <TouchableOpacity onPress={this._handleOpen}>
                      <MIcon
                        name={`arrow-drop-${opened ? 'up' : 'down'}`}
                        color={colors.teal}
                        size={24}
                        style={{ marginHorizontal: 10 }}
                      />
                    </TouchableOpacity>
                  )}
                  <SpecialPopover id={id} handleEdit={handleEdit} />
                </View>
              </View>
              {opened && (
                <View style={s.expandedContainer}>
                  {description && (
                    <DescriptionSection description={description} />
                  )}
                  {items &&
                    items.length > 0 &&
                    items.map((x, i) => (
                      <SpecialItem
                        key={x.id}
                        first={i === 0}
                        last={i === lastIndex}
                        {...x}
                      />
                    ))}
                </View>
              )}
            </View>
          )
        }}
      </DimensionsConsumer>
    )
  }
}

SpecialCard.propTypes = {
  name: t.string.isRequired,
  description: t.string,
  days: t.array.isRequired,
  startTime: t.number.isRequired,
  endTime: t.number.isRequired,
  // published: t.bool.isRequired, // currently null for some specials in the DB
  published: t.bool,
}

SpecialCard.defaultProps = {
  name: 'Happy Hour',
  days: ['SUNDAY', 'MONDAY', 'TUESDAY'],
  startTime: 660,
  endTime: 990,
  published: true,
}
