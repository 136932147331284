import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, View } from 'react-native'
import { FormLabel, Checkbox } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: 120,
    zIndex: -1,
  },
  displayNone: {
    display: 'none',
  },
})

export const FormCheckbox = ({
  wrapperStyle,
  label,
  value,
  onChange,
  labelAfterCheckbox,
}) => (
  <View style={[s.wrapper, wrapperStyle && wrapperStyle]}>
    <FormLabel
      mr={15}
      mb={4}
      children={label ? label : 'Published'}
      style={labelAfterCheckbox ? s.displayNone : {}}
    />
    <Checkbox value={value} onChange={onChange} />
    <FormLabel
      ml={15}
      mb={4}
      children={label ? label : 'Published'}
      style={labelAfterCheckbox ? {} : s.displayNone}
    />
  </View>
)

FormCheckbox.propTypes = {
  /** the label for the checkbox. default value is Published */
  label: t.string,
  value: t.bool.isRequired,
  onChange: t.func.isRequired,
}
