import gql from 'graphql-tag'
import { WeeklySpecialFragments } from './fragments'

/*
 * Get & Update Weekly Specials
 *
 */

export const VENUE_SPECIALS_QUERY = gql`
  query($id: ID!) {
    Venue(id: $id) {
      id
      weeklySpecial {
        ...WeeklySpecialFull
      }
    }
  }
  ${WeeklySpecialFragments.full}
`

export const UPDATE_WEEKLY_SPECIAL = gql`
  mutation(
    $id: ID!
    $mondayTitle: String
    $mondayBody: String
    $tuesdayTitle: String
    $tuesdayBody: String
    $wednesdayTitle: String
    $wednesdayBody: String
    $thursdayTitle: String
    $thursdayBody: String
    $fridayTitle: String
    $fridayBody: String
    $saturdayTitle: String
    $saturdayBody: String
    $sundayTitle: String
    $sundayBody: String
  ) {
    updateWeeklySpecial(
      id: $id
      mondayTitle: $mondayTitle
      mondayBody: $mondayBody
      tuesdayTitle: $tuesdayTitle
      tuesdayBody: $tuesdayBody
      wednesdayTitle: $wednesdayTitle
      wednesdayBody: $wednesdayBody
      thursdayTitle: $thursdayTitle
      thursdayBody: $thursdayBody
      fridayTitle: $fridayTitle
      fridayBody: $fridayBody
      saturdayTitle: $saturdayTitle
      saturdayBody: $saturdayBody
      sundayTitle: $sundayTitle
      sundayBody: $sundayBody
    ) {
      ...WeeklySpecialFull
    }
  }
  ${WeeklySpecialFragments.full}
`
export const CREATE_WEEKLY_SPECIAL = gql`
  mutation(
    $venueId: ID!
    $mondayTitle: String
    $mondayBody: String
    $tuesdayTitle: String
    $tuesdayBody: String
    $wednesdayTitle: String
    $wednesdayBody: String
    $thursdayTitle: String
    $thursdayBody: String
    $fridayTitle: String
    $fridayBody: String
    $saturdayTitle: String
    $saturdayBody: String
    $sundayTitle: String
    $sundayBody: String
  ) {
    createWeeklySpecial(
      venueId: $venueId
      mondayTitle: $mondayTitle
      mondayBody: $mondayBody
      tuesdayTitle: $tuesdayTitle
      tuesdayBody: $tuesdayBody
      wednesdayTitle: $wednesdayTitle
      wednesdayBody: $wednesdayBody
      thursdayTitle: $thursdayTitle
      thursdayBody: $thursdayBody
      fridayTitle: $fridayTitle
      fridayBody: $fridayBody
      saturdayTitle: $saturdayTitle
      saturdayBody: $saturdayBody
      sundayTitle: $sundayTitle
      sundayBody: $sundayBody
    ) {
      ...WeeklySpecialFull
    }
  }
  ${WeeklySpecialFragments.full}
`
