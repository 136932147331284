import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Circular } from '../../components'
import { DimensionsConsumer } from '../../lib'
import { colors, fonts, hexToRgba } from '../../functions'

const s = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 182,
    height: 55,
    borderRadius: 20,
    backgroundColor: colors.secondary1,
    marginRight: 10,
  },
  disabled: {
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
  },
  buttonText: {
    fontFamily: fonts.regular,
    fontSize: 13,
    lineHeight: 18,
    color: colors.white,
  },
})

function scale(size, currentWidth, targetWidth) {
  return (currentWidth / targetWidth) * size
}

export const FormButton = ({
  text,
  textColor,
  disabled,
  isSubmitting,
  handlePress,
  outerStyle,
}) => {
  let touchableOpacity = null

  return (
    <DimensionsConsumer>
      {({ width }) => {
        // should really be 414, but we're somehow 5 pixels off. 419 works perfectly
        const isSmall = width < 419
        const responsiveStyle = {
          width: scale(182, width, 419),
          // not sure if we should also decrease the height
          // it doesn't look good scaled linearly with width
          height: scale(55, width, 419),
        }
        return (
          <TouchableOpacity
            ref={x => (touchableOpacity = x)}
            style={[
              s.container,
              isSmall && responsiveStyle,
              disabled && s.disabled,
              outerStyle && outerStyle,
            ]}
            onPressIn={async e => {
              await touchableOpacity.focus()
              await handlePress(e)
            }}
            disabled={disabled}
            activeOpacity={0.8}
          >
            <ButtonInner
              text={text}
              textColor={disabled ? hexToRgba(textColor, 0.5) : textColor}
              isSubmitting={isSubmitting}
            />
          </TouchableOpacity>
        )
      }}
    </DimensionsConsumer>
  )
}

const ButtonInner = ({ text, textColor, isSubmitting }) => (
  <React.Fragment>
    {isSubmitting ? (
      <Circular color={textColor} />
    ) : (
      <Text
        style={[s.buttonText, textColor && { color: textColor }]}
        children={text}
      />
    )}
  </React.Fragment>
)
