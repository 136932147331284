import React from 'react'
import * as t from 'prop-types'
import { keys } from '../../config'

class WithAnalytics extends React.Component {
  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV !== 'development'
    ) {
      // set up segment
      const Segment = require('./segment.js').default
      Segment.init(keys.segment.bcvm)

      // set up hotjar
      const Hotjar = require('./hotjar').default
      Hotjar.init(keys.hotjar.siteId, keys.hotjar.serviceVersion)

      // set up google analytics
      const GA = require('react-ga')
      GA.initialize(keys.google.bcvmTrackingId)

      // set up fb pixel
      const FB = require('react-facebook-pixel').default
      FB.init(keys.facebook.pixel)
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

WithAnalytics.propTypes = {
  children: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
}

export default WithAnalytics
