import React from 'react'
import * as t from 'prop-types'
import ReactFilestack from 'filestack-react'
import { compose, graphql } from 'react-apollo'
import { TrackError } from '../../lib'
import { CREATE_VENUE_PHOTO, CREATE_EVENT_PHOTO } from '../../gql'
import { FormButton } from '../../components'
import { colors } from '../../functions'
import { keys } from '../../config'

const typeMap = {
  VenuePhoto: {
    type: 'Venue',
    mutation: 'createVenuePhoto',
  },
  EventPhoto: {
    type: 'Event',
    mutation: 'createEventPhoto',
  },
}

class PhotoUploader extends React.Component {
  handleSuccess = async ({ filesFailed, filesUploaded }) => {
    const {
      photoType,
      photoProps,
      refetchQuery,
      optimisticUpdates,
    } = this.props

    if (filesFailed && filesFailed.length > 0) {
      TrackError(filesFailed)
    }

    if (filesUploaded) {
      // set a last index so we only call refetchQueries once
      const lastIndex = filesUploaded.length - 1

      // map over the uploaded photos and create them in our database
      const photoIds = await Promise.all(
        filesUploaded.map(async (x, i) => {
          // set the inner values of the optimistic response
          const optimisticResponse = {
            __typename: photoType, // enum('VenuePhoto', 'EventPhoto')
            ...x,
            ...photoProps,
            id: x.uploadId, // set a temporary id
            // createdAt: new Date().toISOString(),
          }

          // set the arguments for the mutation
          const mutationArgs = {
            // the props from filestack, and the additional props / metadata for the photo
            variables: { ...x, ...photoProps },
            // set an optimistic response of its set up
            ...(optimisticUpdates && {
              optimisticResponse: {
                __typename: 'Mutation',
                ...(photoType === 'VenuePhoto'
                  ? { createVenuePhoto: { ...optimisticResponse } }
                  : { createEventPhoto: { ...optimisticResponse } }),
              },
            }),
            // call the update function that is set on the component
            // ...(optimisticUpdates && { update: update }),
            ...(optimisticUpdates && {
              update: (proxy, { data }) => {
                // Read the data from our cache for this query.
                const cache = proxy.readQuery(refetchQuery)

                // use these typeMap lookups so we can isolate the apollo
                // logic in this component
                const typeName = typeMap[photoType].type
                const mutationName = typeMap[photoType].mutation

                // Add our photo from the mutation to the end.
                cache[typeName].photos.push(data[mutationName])

                // Write our data back to the cache.
                proxy.writeQuery({
                  ...refetchQuery,
                  data: cache,
                })
              },
            }),
            // conditionally add the refetchQueries argument
            ...(refetchQuery &&
              lastIndex === i && { refetchQueries: () => [refetchQuery] }),
          }

          // check to see if it's a venue photo or event photo
          if (photoType === 'VenuePhoto') {
            const res = await this.props.createVenuePhoto(mutationArgs)
            console.log('res', res)
            return res
          }
          if (photoType === 'EventPhoto') {
            const res = await this.props.createEventPhoto(mutationArgs)
            console.log('res', res)
            return res
          }
        }),
      ).catch(error => TrackError(error))
      /*
       * maybe add a toast notification here ?
       */
      console.log(`Success! Uploaded ${photoIds.length} photos.`)
    }
  }

  render() {
    const { maxFiles, photoType, buttonText, buttonColor } = this.props
    const aspectRatio = this.props.aspectRatio || 0

    if (photoType === null) {
      throw new Error(
        "photoType prop must be provided. enum('EventPhoto', VenuePhoto')",
      )
    }
    // console.log('refetchQueries', this.props.refetchQueries)
    // console.log('refetchQueries', this.props.refetchQueries[0])
    return (
      <ReactFilestack
        apikey={keys.filestack.apiKey}
        mode="pick"
        fromSources={[
          'local_file_system',
          'url',
          // 'facebook',
          // 'instagram',
          // 'dropbox',
          // 'googledrive',
        ]}
        options={{
          maxFiles: maxFiles ? maxFiles : 15,
          accept: ['image/png', 'image/jpeg'],
          transformations: {
            crop: {
              aspectRatio: aspectRatio,
              force: true,
            },
          },
        }}
        onSuccess={res => this.handleSuccess(res)}
        onError={err => TrackError(err)}
        render={({ onPick }) => (
          <FormButton
            text={buttonText}
            textColor={buttonColor ? buttonColor : colors.teal}
            handlePress={onPick}
          />
        )}
      />
    )
  }
}

PhotoUploader.propTypes = {
  buttonText: t.string,
}

PhotoUploader.defaultProps = {
  buttonText: 'Choose File',
}

export default compose(
  graphql(CREATE_VENUE_PHOTO, { name: 'createVenuePhoto' }),
  graphql(CREATE_EVENT_PHOTO, { name: 'createEventPhoto' }),
)(PhotoUploader)

/*
 * sample response from filestack onSuccess
 *
	const sampleRes = {
		filesFailed: [],
		filesUploaded: [
			{
				filename: 'NYE.jpg',
				handle: 'MjM9XA5ZQkaLhJkl2wkP',
				mimetype: 'image/jpeg',
				size: 103238,
				source: 'local_file_system',
				url: 'https://cdn.filestackcontent.com/MjM9XA5ZQkaLhJkl2wkP',
			},
		],
	}
*/
