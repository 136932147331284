import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import LinearGradient from 'react-native-web-linear-gradient'
import { MCIcon } from '../../components'
import { WithSelectedVenue } from '../../layouts/components'
import { fonts, colors } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingTop: 30,
    paddingBottom: 10,
  },
  f1: {
    flex: 1,
  },
  glowContainer: {
    position: 'absolute',
    top: 46,
    right: 0,
    left: 0,
    height: 53,
    // only will work on web
    // shadowRadius: 30,
    filter: 'blur(30px)',
    // zIndex: 49,
  },
  contentBg: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 50,
    minHeight: 112,
    // backgroundColor: hexToRgba('#0F131B', 0.3),
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'row',
    // maxWidth: 50,
  },
  labelText: {
    fontFamily: fonts.regular,
    fontSize: 20,
    lineHeight: 27,
    color: colors.white,
    marginLeft: 20,
  },
})

export const AuthHeader = ({
  handlePress,
  children,
  headerProps: { label, colors },
}) => {
  return (
    <WithSelectedVenue>
      {({ selectedVenue }) => (
        <View style={s.wrapper}>
          <LinearGradient
            style={[s.column, s.f1]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0.44 }}
            colors={colors}
          >
            <View style={s.contentBg}>
              <View style={s.row}>
                <View style={s.iconButton}>
                  <TouchableOpacity onPress={handlePress} activeOpacity={0.8}>
                    <MCIcon name={'menu'} size={26} color={'#fff'} />
                  </TouchableOpacity>
                  <Text style={s.labelText} children={selectedVenue.name} />
                </View>
                <Text style={s.labelText} children={label} />
              </View>
            </View>
            <LinearGradient
              style={s.glowContainer}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 0.44 }}
              colors={colors}
            />
          </LinearGradient>
        </View>
      )}
    </WithSelectedVenue>
  )
}
