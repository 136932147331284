import gql from 'graphql-tag'
import { EventFragments } from './fragments'

/*
 * Update the 'Details' for an event
 * name, desc, date, endDate
 *
 */

export const CREATE_EVENT = gql`
  mutation(
    $venueId: ID!
    $name: String!
    $description: String
    $date: DateTime!
    $endDate: DateTime
    $isTicketedEvent: Boolean
    $recurring: Boolean
  ) {
    createEvent(
      venueId: $venueId
      name: $name
      description: $description
      date: $date
      endDate: $endDate
      hidden: true # to do: swap this for published
      published: false # not implemented yet
      isTicketedEvent: $isTicketedEvent
      recurring: $recurring
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * Get info for the Manage Event page / edit event
 *
 */

export const EVENT_QUERY = gql`
  query($id: ID!) {
    Event(id: $id) {
      ...EventFullDetails
      # eventSeries {
      #   id
      #   name
      # }
    }
    allEventCategories(orderBy: name_ASC) {
      ...EventCategoryDetails
    }
    allAgeGroups(orderBy: age_ASC) {
      id
      age
      ageText
    }
  }
  ${EventFragments.full}
  ${EventFragments.category}
`

export const UPDATE_EVENT_INFO = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String
    $date: DateTime!
    $endDate: DateTime
    $ageGroupId: ID
    $categoriesIds: [ID!]!
    $ticketUrl: String
    $priceFrom: Float
    $free: Boolean
    $recurring: Boolean
    $published: Boolean
    $hidden: Boolean
  ) {
    updateEvent(
      id: $id
      name: $name
      description: $description
      date: $date
      endDate: $endDate
      ageGroupId: $ageGroupId
      categoriesIds: $categoriesIds
      ticketUrl: $ticketUrl
      priceFrom: $priceFrom
      free: $free
      recurring: $recurring
      hidden: $hidden
      published: $published
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * Update the 'Details' for an event
 * name, desc, date, endDate
 *
 */

export const UPDATE_EVENT_DETAILS = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String
    $date: DateTime!
    $endDate: DateTime
    $hidden: Boolean # to do: swap this for published
    $published: Boolean # not implemented yet
    $isTicketedEvent: Boolean
  ) {
    updateEventDetails: updateEvent(
      id: $id
      name: $name
      description: $description
      date: $date
      endDate: $endDate
      hidden: $hidden # to do: swap this for published
      published: $published # not implemented yet
      isTicketedEvent: $isTicketedEvent
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * a much simpler way to update an Event's categories...
 *
 */

export const UPDATE_EVENT_CATEGORIES = gql`
  mutation($eventId: ID!, $categoriesIds: [ID!]!) {
    updateEventCategories: updateEvent(
      id: $eventId
      categoriesIds: $categoriesIds
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * update ticketUrl, priceFrom & free for an event
 *
 */

export const UPDATE_EVENT_TICKETING_INFO = gql`
  mutation($id: ID!, $ticketUrl: String, $priceFrom: Float, $free: Boolean) {
    updateEventTicketingInfo: updateEvent(
      id: $id
      ticketUrl: $ticketUrl
      priceFrom: $priceFrom
      free: $free
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`
export const UPDATE_EVENT_IS_TICKETED = gql`
  mutation($id: ID!, $isTicketedEvent: Boolean) {
    updateEventDetails: updateEvent(
      id: $id
      isTicketedEvent: $isTicketedEvent
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

export const UPDATE_EVENT_IS_PUBLISHED = gql`
  mutation(
    $id: ID!
    $recurring: Boolean
    $published: Boolean
    $hidden: Boolean
  ) {
    updateEvent(
      id: $id
      recurring: $recurring
      published: $published
      hidden: $hidden
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * copy an event to make a new one. will need to add
 * additonal fields like ticket info + promo codes
 *
 */
//This op does not exist in prod schema?
//Using different function, createEvent, as a hotfix for now
export const DUPLICATE_EVENT = gql`
  mutation(
    $venueId: ID!
    $name: String!
    $description: String
    $date: DateTime!
    $endDate: DateTime
    $ageGroupId: ID
    $categoriesIds: [ID!]
    $photosIds: [ID!]
    $isTicketedEvent: Boolean
  ) {
    duplicateEvent: createEvent(
      venueId: $venueId
      name: $name
      description: $description
      date: $date
      endDate: $endDate
      ageGroupId: $ageGroupId
      categoriesIds: $categoriesIds
      photosIds: $photosIds
      isTicketedEvent: $isTicketedEvent #createEvent(
    ) #  venueId: $venueId
    # duplicateEvent(
    #  id: $id
    #  venueId: $venueId
    #  duplicateForRecurringEvent: false
    {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`
//TODO: Correctly implement recurring event logic
//given prod schema doesn't contain duplicate op anywhere?
export const DUPLICATE_RECURRING_EVENT = gql`
  mutation(
    $id: ID!
    $venueId: ID!
    $newStartDate: DateTime!
    $newEndDate: DateTime!
  ) {
    duplicateEvent: duplicateEvent(
      id: $id
      venueId: $venueId
      duplicateForRecurringEvent: true
      newStartDate: $newStartDate
      newEndDate: $newEndDate
    ) {
      id
    }
  }
`
