import React from 'react'
import { StyleSheet, TouchableHighlight, View } from 'react-native'
import { BaseText } from '..'
import { colors } from '../../functions'

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10,
  },
  touchable: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
    marginRight: 8,
  },
  breadCrumbItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadCrumbItemActive: {
    color: colors.pink,
  },
  labelUnderlined: {
    textDecorationLine: 'underline',
  },
})

export const BreadCrumb = props => (
  <View style={s.container}>
    {props.items.map((item, index) => {
      const isCurrentState = item.state === props.currentState

      return (
        <TouchableHighlight
          style={s.touchable}
          underlayColor={colors.primary3}
          activeOpacity={1}
          onPress={() => props.onPress(item)}
          key={index}
        >
          <View style={s.breadCrumbItem}>
            <BaseText
              fs={14}
              lh={24}
              weight={'semibold'}
              color={'white'}
              children={item.label}
              style={[isCurrentState && s.breadCrumbItemActive]}
            />
            <BaseText
              fs={14}
              lh={24}
              weight={'semibold'}
              color={'white'}
              children={item.underlinedLabel}
              style={[
                s.labelUnderlined,
                isCurrentState && s.breadCrumbItemActive,
              ]}
            />
          </View>
        </TouchableHighlight>
      )
    })}
  </View>
)
