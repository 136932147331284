import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Person } from '../../components'
import { colors, hexToRgba } from '../../functions'

const LARGE = 122

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: LARGE,
    height: LARGE,
  },
  circularContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.secondary1,
    borderRadius: LARGE / 2, // default value
    width: LARGE, // default value
    height: LARGE, // default value
    zIndex: 15,
  },
  large: {
    width: LARGE,
    height: LARGE,
    borderRadius: LARGE / 2,
  },
  glow: {
    position: 'absolute',
    bottom: -11,
    height: 92,
    width: 92,
    backgroundColor: hexToRgba(colors.primary1, 0.62),
    // only will work on web
    filter: 'blur(14px)',
    zIndex: 5,
  },
})

export const ProfilePlaceholder = ({ size }) => (
  <View style={s.wrapper}>
    <View style={[s.circularContainer, size && s[size]]}>
      <Person />
    </View>
    {/*
     * a really weird way to do a shadow...
     * should be updated to use a shadow, but conforming to design spec for now
     */}
    <View style={s.glow} />
  </View>
)
