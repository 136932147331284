import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { WhiteTouchText } from '../../components'
import { scale, decodeUri } from '../../functions'
import ConfirmInviteForm from './ConfirmInviteForm'

const logoHeight = 73
const logoTextDimensions = 650 / 200
const flexRatio = 0.85

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  top: {
    display: 'flex',
    flex: flexRatio,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    flex: 1 - flexRatio,
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    justifyContent: 'space-around',
  },
  logo: {
    width: `${logoHeight * logoTextDimensions}px`,
    height: `${logoHeight}px`,
    zIndex: 4,
    marginHorizontal: scale(30),
  },
  text: {
    fontFamily: 'OpenSans',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#fff',
    textAlign: 'center',
    marginTop: '25px',
  },
})

class ConfirmInvite extends React.Component {
  state = {
    params: null,
  }

  componentWillMount() {
    const params = decodeUri(this.props.location)
    console.log('params', params)
    this.setState({ params })
  }

  _handleEmployeeLogin = () => {
    this.setState({ currentLogin: 'EMPLOYEE' })
    this.props.history.push('/employee-login')
  }

  _handlePartnerLogin = () => {
    this.setState({ currentLogin: 'PARTNER' })
    this.props.history.push('/login')
  }

  render() {
    console.log('props', this.props)
    console.log('state', this.state)
    return (
      <View style={s.wrapper}>
        <View style={s.top}>
          <ConfirmInviteForm
            history={this.props.history}
            initialValues={this.state.params}
          />
        </View>
        <View style={s.bottom}>
          <View style={s.bottomItems}>
            <WhiteTouchText
              weight="light"
              handlePress={this._handlePartnerLogin}
              children={'Partner Login'}
            />
            <Image
              source={require('../../assets/bc-virtual-manager-large-min.png')}
              style={s.logo}
            />
            <WhiteTouchText
              weight="light"
              handlePress={this._handleEmployeeLogin}
              children={'Employee Login'}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default ConfirmInvite
