import gql from 'graphql-tag'
import { EventFragments } from './fragments'

/*
 * grab the list of events by published, unpublished, & expired (in the past)
 * to display on the events page
 */
export const EVENTS_QUERY = gql`
  query($id: ID!, $date: DateTime!) {
    Venue(id: $id) {
      id
      name
      _eventsMeta {
        count
      }
      # to do: change to published: true
      published: events(
        filter: { endDate_gte: $date, hidden_not: true }
        orderBy: date_DESC
      ) {
        ...EventDetails
      }
      # to do: change to published: false
      unpublished: events(
        filter: { endDate_gte: $date, hidden: true }
        orderBy: date_DESC
      ) {
        ...EventDetails
      }
      expired: events(filter: { endDate_lt: $date }, orderBy: date_DESC) {
        ...EventDetails
      }
    }
  }
  ${EventFragments.short}
`

/*
 * grab the list of events by published, unpublished, & expired (in the past)
 * to display on the events page
 */
export const EVENTS_QUERY_ADMIN = gql`
  query(
    $date: DateTime!
    $limit: Int!
    $nameFilter: String
    $recurringDateFrom: DateTime
    $recurringDateTo: DateTime
  ) {
    # to do: change to published: true
    published: allEvents(
      filter: {
        endDate_gte: $date
        hidden_not: true
        name_contains: $nameFilter
      }
      orderBy: date_DESC
      first: $limit
    ) {
      ...EventFullDetails
    }
    # to do: change to published: false
    unpublished: allEvents(
      filter: { endDate_gte: $date, hidden: true, name_contains: $nameFilter }
      orderBy: date_DESC
      first: $limit
    ) {
      ...EventFullDetails
    }
    expired: allEvents(
      filter: { endDate_lt: $date, name_contains: $nameFilter }
      orderBy: date_DESC
      first: $limit
    ) {
      ...EventFullDetails
    }
    recurring: allEvents(
      filter: {
        recurring: true
        name_contains: $nameFilter
        date_gte: $recurringDateFrom
        date_lt: $recurringDateTo
      }
      orderBy: date_DESC
    ) {
      ...EventFullDetails
    }
  }
  ${EventFragments.full}
`

/*
 * Actions for the SpecialPopover Menu (Publish / Unpublish & Delete)
 */

export const UPDATE_EVENT_PUBLISHED = gql`
  mutation($id: ID!, $hidden: Boolean!, $published: Boolean!) {
    # to do: change to published
    updateEventPublished: updateEvent(
      id: $id
      hidden: $hidden
      published: $published
    ) {
      # to do: change to published
      ...EventDetails
    }
  }
  ${EventFragments.short}
`

export const DELETE_EVENT = gql`
  mutation($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`
