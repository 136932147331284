import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native'
import { Query, Mutation } from 'react-apollo'
import { EVENTS_QUERY_ADMIN, DUPLICATE_RECURRING_EVENT } from '../../gql'
import {
  Dropdown,
  FormButton,
  ContentContainer,
  MIcon,
  Input,
  Button,
  MCIcon,
} from '../../components'
import {
  colors,
  hashMap,
  nearestInterval,
  adjustForTimezone,
} from '../../functions'
import { EventsList, Section, EventFilter, ConfirmModal } from './components'
import { WithSelectedVenue } from '../../layouts/components'
import { View, TextInput } from 'react-native'
import debounce from 'lodash.debounce'
import { TouchableOpacity } from 'react-native-web'
import moment from 'moment'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    marginHorizontal: 15,
    marginTop: -36,
    marginBottom: 10,
  },
})

const sections = [
  {
    id: '#published',
    title: 'Published Events',
    label: 'Published Events',
    status: 'published',
    component: EventsList,
    path: '/replace-this-link',
  },
  {
    id: '#unpublished',
    title: 'Unpublished Events',
    label: 'Unpublished Events',
    status: 'unpublished',
    component: EventsList,
    path: '/replace-this-link',
  },
  {
    id: '#expired',
    title: 'Expired Events',
    label: 'Expired Events',
    status: 'expired',
    component: EventsList,
    path: '/replace-this-link',
  },
]

const dropdownItems = [{ id: '#all', label: 'All Events' }, ...sections]

class EventsManagerAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionsHash: hashMap(dropdownItems, 'label'),
      sectionsHashHash: hashMap(dropdownItems, 'id'),
      dropdownItems: dropdownItems,
      currentSection: 'All Events',
      date: null,
      search: '',
      isFormSubmitting: false,
    }

    this.debouncedSearch = null
  }

  componentWillMount() {
    const { hash } = this.props.history.location
    if (hash && hash.length > 0 && this.state.sectionsHashHash[hash]) {
      this.setState({
        currentSection: this.state.sectionsHashHash[hash].label,
        date: nearestInterval(15),
      })
    } else {
      this.setState({ date: nearestInterval(15) })
    }
  }

  _handleNewEvent = venueId => {
    this.props.history.push(`/event/new?${venueId}`)
  }

  _handleSectionSelected = label => {
    const hash = this.state.sectionsHash[label].id
    this.setState({ currentSection: label })
    this.props.history.push(hash)
  }

  _handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    const { currentSection, sectionsHash, date } = this.state
    let recurringDateFrom = moment(date.substr(0, 10))
      .subtract(7, 'days')
      .toISOString()
      .substr(0, 10)

    return (
      <React.Fragment>
        <Dropdown
          items={this.state.dropdownItems}
          selected={currentSection}
          wrapperStyle={{ marginTop: -44 }}
          handleItemPress={this._handleSectionSelected}
        />
        <TouchableOpacity
          style={s.icon}
          activeOpacity={0.7}
          onPress={this._handleBack}
        >
          <MCIcon name="arrow-left" size={24} color={colors.white} />
        </TouchableOpacity>
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.wrapper}
          scrollsToTop={true}
          // scrollEventThrottle={16}
        >
          <WithSelectedVenue>
            {({ selectedVenue }) => (
              <Query
                query={EVENTS_QUERY_ADMIN}
                fetchPolicy={'network-only'}
                variables={{
                  date: date,
                  limit: 100,
                  nameFilter: this.state.search || undefined,
                  recurringDateFrom: recurringDateFrom,
                  recurringDateTo: date.substr(0, 10),
                }}
              >
                {({ data, networkStatus, error, refetch }) => (
                  <Mutation mutation={DUPLICATE_RECURRING_EVENT}>
                    {createRecurringEvent => {
                      if (networkStatus === 1) return <ActivityIndicator />

                      // to do: add an error screen !
                      if (error) console.log('apollo error', error)

                      const currSectionProps =
                        currentSection !== 'All Events' &&
                        sectionsHash[currentSection]

                      return (
                        <ContentContainer
                          style={s.contentContainer}
                          smallStyle={s.contentContainerMobile}
                        >
                          <EventFilter
                            onChangeText={value => {
                              if (this.debouncedSearch) {
                                this.debouncedSearch.cancel()
                              }

                              this.debouncedSearch = debounce(() => {
                                this.setState({ search: value }, () =>
                                  refetch(),
                                )
                              }, 200)

                              this.debouncedSearch()
                            }}
                          />
                          {currentSection === 'All Events'
                            ? sections.map((x, i) => (
                                <Section
                                  key={i}
                                  venueId={selectedVenue.id}
                                  queryDate={date}
                                  events={
                                    data && data[x.status] && data[x.status]
                                  }
                                  eventCount={
                                    data &&
                                    data[`${x.status}EventsMeta`] &&
                                    data[`${x.status}EventsMeta`].count
                                  }
                                  //customLink={{ text: 'See All', handleClick: () => this.props.history.push(x.path)}}
                                  scrollable={true}
                                  status={x.status}
                                  // handleMeasureLayout={this.handleMeasureLayout}
                                  {...x}
                                />
                              ))
                            : currSectionProps && (
                                <Section
                                  events={
                                    data &&
                                    data[currSectionProps.status] &&
                                    data[currSectionProps.status]
                                  }
                                  eventCount={
                                    data &&
                                    data[
                                      `${currSectionProps.status}EventsMeta`
                                    ] &&
                                    data[`${currSectionProps.status}EventsMeta`]
                                      .count
                                  }
                                  //customLink={{ text: 'See All', handleClick: () => this.props.history.push(currSectionProps.path)}}
                                  scrollable={false}
                                  // handleMeasureLayout={this.handleMeasureLayout}
                                  {...currSectionProps}
                                />
                              )}
                          <View style={s.buttonWrapper}>
                            <FormButton
                              outerStyle={{
                                marginTop: 15,
                                alignSelf: 'center',
                              }}
                              text={'+ New Event'}
                              textColor={colors.teal}
                              handlePress={async () => {
                                this._handleNewEvent(selectedVenue.id)
                              }}
                            />
                            <FormButton
                              outerStyle={{
                                marginTop: 15,
                                alignSelf: 'center',
                              }}
                              text={'+ Recurring Events'}
                              textColor={colors.teal}
                              handlePress={() => this.confirmModal.showModal()}
                              disabled={!data.recurring.length}
                              isSubmitting={this.state.isFormSubmitting}
                            />
                            <ConfirmModal
                              ref={x => (this.confirmModal = x)}
                              message={`Are you sure you want to create ${
                                data.recurring.length
                              } recurring events?`}
                              data={data.recurring}
                              onConfirm={async formData => {
                                this.setState({ isFormSubmitting: true })
                                await Promise.all(
                                  formData.map(event => {
                                    const nextDate = new Date(
                                      +new Date(event.date) +
                                        1000 * 60 * 60 * 24 * 7,
                                    )
                                    const nextEndDate = new Date(
                                      +new Date(event.endDate) +
                                        1000 * 60 * 60 * 24 * 7,
                                    )
                                    return createRecurringEvent({
                                      variables: {
                                        id: event.id,
                                        venueId: event.venue.id,
                                        newStartDate: nextDate,
                                        newEndDate: nextEndDate,
                                      },
                                    })
                                  }),
                                )
                                await refetch()
                                this.setState({ isFormSubmitting: false })
                              }}
                            />
                          </View>
                        </ContentContainer>
                      )
                    }}
                  </Mutation>
                )}
              </Query>
            )}
          </WithSelectedVenue>
        </ScrollView>
      </React.Fragment>
    )
  }
}

export default EventsManagerAdmin
