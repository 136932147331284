import React from 'react'
import { StyleSheet } from 'react-native'
import { Mutation } from 'react-apollo'
import { colors, scale } from '../../../functions'
import { SET_SELECTED_VENUE } from '../../../gql'
import { TouchableBaseText } from './TouchableBaseText'

const s = StyleSheet.create({
  createVenue: {
    padding: scale(5),
    borderRadius: scale(5),
    marginBottom: scale(10),
  },
})

// Create venue mutation button
// props: googleInfo
export class ViewVenue extends React.Component {
  state = {
    /** Did the user just create the venue? */
    didCreateVenue: false,
  }

  render() {
    return (
      <Mutation mutation={SET_SELECTED_VENUE}>
        {(setSelectedVenue, { data }) => (
          <TouchableBaseText
            style={[
              s.createVenue,
              {
                backgroundColor: this.state.didCreateVenue
                  ? colors.secondary1
                  : colors.teal,
              },
            ]}
            disabled={this.state.didCreateVenue}
            onPress={async () => {
              try {
                let vars = {
                  id: this.props.venue.id,
                  name: this.props.venue.name,
                }

                let res = await setSelectedVenue({ variables: vars })
                this.props.history.push('/manage-venue')
              } catch (error) {
                console.log('Error View Venue', error)
              }
            }}
            color={this.state.didCreateVenue ? 'secondary3' : 'white'}
            fs={scale(14)}
            lh={scale(14)}
            weight={'semibold'}
            children={'View Venue'}
          />
        )}
      </Mutation>
    )
  }
}
