import React from 'react'
import { Image, TouchableOpacity, Text, View } from 'react-native'
import { MCIcon } from '../index'
import { styles as s } from './shared'

export const Button = ({
  disabled,
  text,
  color,
  disabledColor,
  disabledTextColor,
  containerStyle,
  buttonStyle,
  textStyle,
  icon,
  image,
  onPress,
  noShadow,
}) => (
  <View style={[s.wrapper, containerStyle && containerStyle]}>
    <TouchableOpacity
      style={[
        s.button,
        color && { backgroundColor: color },
        buttonStyle,
        disabled && { backgroundColor: disabledColor },
        !noShadow && s.shadow,
      ]}
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled}
    >
      <View style={s.row}>
        {icon ? (
          <MCIcon
            name={icon.name}
            size={icon.size}
            color={icon.color}
            style={[s.iconStyle, icon.style && icon.style]}
          />
        ) : image ? (
          <Image source={image.source} style={[s.iconStyle, image.style]} />
        ) : null}
        <Text
          style={[
            s.buttonText,
            textStyle,
            disabled && { color: disabledTextColor },
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  </View>
)
