import React from 'react'
import * as t from 'prop-types'
import {
  ImageBackground,
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { colors, fonts } from '../../functions'
import { getImgUrl, DimensionsConsumer } from '../../lib'
import { HoverableText } from '../../components'
// const ratio = 354 / 230

const factor = 1
const image_width = Math.round(182 * factor)
const image_height = Math.round(182 * factor)

const s = StyleSheet.create({
  touchable: {
    display: 'flex',
    marginRight: 21,
    marginBottom: 21,
  },
  border: {
    borderRadius: 10, // may need to change to 16 & 24 with s.medium, s.large, s.xlarge
    borderWidth: 2,
    borderColor: colors.teal,
  },
  imageBackground: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    borderRadius: 10,
    resizeMode: 'cover',
  },
  /* eslint-disable react-native/no-unused-styles */
  card: {
    width: 240 * 1.9,
    height: 240,
  },
  smallCard: {
    width: 125 * 1.9,
    height: 125,
  },
  small: {
    width: image_width,
    height: image_height,
  },
  medium: {
    width: image_width * 1.65,
    height: image_height * 1.65,
    borderRadius: 16,
  },
  large: {
    width: image_width * 2.077, // gets us to 374
    height: image_height * 2.077, // gets us to 374
    borderRadius: 24,
  },
  xlarge: {
    width: image_width * 2.5, // gets us to 374
    height: image_height * 2.5, // gets us to 374
    borderRadius: 24,
  },
  /* eslint-enable */
  pressedImage: {
    // https://www.w3schools.com/cssref/css3_pr_filter.asp
    // brightness = 1 - (12 / 50)
    filter:
      'blur(7px) brightness(0.76) drop-shadow(0px 3px 3px rgba(0,0,0,0.16))',
  },
  backgroundFilter: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingVertical: 15,
    paddingHorizontal: 5,
    alignItems: 'center',
    zIndex: 5,
  },
  touchWrapper: {
    display: 'flex',
    zIndex: 6,
    padding: 5,
  },
  text: {
    fontFamily: fonts.semibold,
    fontSize: 13,
    lineHeight: 18,
    color: colors.secondary2,
    zIndex: 6,
    marginBottom: 3,
  },
  fileName: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  textHoverStyle: {
    color: colors.white,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 90,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

const ALL_OPTIONS = [
  // {
  //   label: 'Publish',
  //   action: 'publish',
  // },
  {
    label: 'Set as Feature',
    action: 'setFeature',
  },
  {
    label: 'Add to Profile',
    action: 'addToProfile',
  },
  {
    label: 'Remove from Profile',
    action: 'removeFromProfile',
  },
  // {
  //   label: 'Remove from Food Drink',
  //   action: 'removeFromFoodDrink',
  // },
  // {
  //   label: 'Add to Food Drink',
  //   action: 'addToFoodDrink',
  // },
  // {
  //   label: 'Add to Experiences',
  //   action: 'addToExperiences',
  // },
  // {
  //   label: 'Remove from Experiences',
  //   action: 'removeFromExperiences',
  // },
  // {
  //   label: 'Unpublish',
  //   action: 'unPublish',
  // },
]

// maps the size prop to an image size for the filestack url
const imageScale = {
  smallCard: 1.5,
  card: 2,
  small: 1.2,
  medium: 1.71,
  large: 2.2,
  xlarge: 2.7,
}

function getDecimal(num) {
  return Math.round(num * 100) / 100
}

function bytesToMB(bytes) {
  const mb = bytes / 1000000
  if (mb < 1) {
    return `${getDecimal(mb * 1000)} KB`
  } else {
    return `${getDecimal(mb)} MB`
  }
}

function getMobileStyles(width, size) {
  if (size === 'small') {
    // 431 = 414 + 5, + 10 (margin right on card), + 2 (borderWidth)
    const cardSize = image_width * (width / 431)
    const mobileCardStyles = {
      width: cardSize,
      height: cardSize,
      borderRadius: 10,
    }
    const mobileTouchableStyle = {
      marginRight: 10 * (width / 414),
      marginBottom: 10,
    }
    return { mobileCardStyles, mobileTouchableStyle }
  } else if (size === 'medium') {
    // 431 = 414 + 5, + 10 (margin right on card), + 2 (borderWidth)
    const cardSize = width - 40
    const mobileCardStyles = {
      width: cardSize,
      height: cardSize,
      borderRadius: 16,
    }
    const mobileTouchableStyle = {
      marginRight: 0,
      marginBottom: 15,
    }
    return { mobileCardStyles, mobileTouchableStyle }
  } else if (size === 'xlarge') {
    const cardSize = width - 40
    const mobileCardStyles = {
      width: cardSize,
      height: cardSize,
      borderRadius: 16,
    }
    const mobileTouchableStyle = null
    return { mobileCardStyles, mobileTouchableStyle }
  } else if (size === 'card') {
    const cardSize = width - 40
    const mobileCardStyles = {
      width: cardSize,
      height: cardSize / 1.9,
      borderRadius: 16,
    }
    const mobileTouchableStyle = null
    return { mobileCardStyles, mobileTouchableStyle }
  } else {
    console.log('The following size does not have mobile styles:', size)
    return { mobileCardStyles: null, mobileTouchableStyle: null }
  }
}

export class PhotoCardSquare extends React.Component {
  state = {
    pressed: false,
  }
  handlePress = () => {
    return this.setState({ pressed: !this.state.pressed })
  }
  handleMenuPress = (image, action) => {
    // should just make a single handler that passes the option as a prop (with the image)
    this.props.handleAction(action, image)
    this.setState({ pressed: false })
  }
  render() {
    const { actions, image, size, featured, disabled } = this.props
    const { pressed } = this.state
    const imgUrl =
      typeof image === 'object'
        ? getImgUrl(image.handle, image_width * imageScale[size])
        : image

    return (
      <DimensionsConsumer>
        {({ width }) => {
          // TO DO: need to add some styles for 434px ~> 680/700px
          const isMobile = width < 434
          const { mobileCardStyles, mobileTouchableStyle } = getMobileStyles(
            width,
            size,
          )

          return (
            <TouchableOpacity
              activeOpacity={1}
              disabled={disabled}
              style={[
                s.touchable,
                mobileTouchableStyle && isMobile && mobileTouchableStyle,
                featured && s.border,
              ]}
              onPress={this.handlePress}
            >
              <ImageBackground
                source={{ uri: imgUrl }}
                style={[
                  s.imageBackground,
                  s[size],
                  mobileCardStyles && isMobile && mobileCardStyles,
                ]}
                imageStyle={[
                  s.image,
                  s[size],
                  mobileCardStyles && isMobile && mobileCardStyles,
                  pressed && s.pressedImage,
                ]}
                // getting warnings adding filter to image instead of view, but the menu
                // options are getting blurred w/ the following styles:
                // style={[s.imageBackground, pressed && s.pressedImage]}
                // imageStyle={s.image}
                resizeMode="cover"
              >
                {pressed && (
                  <View style={s.backgroundFilter}>
                    <View style={s.actionsContainer}>
                      {actions &&
                        actions.map(x => (
                          <HoverableText
                            key={x.action}
                            outerContainerStyle={s.touchWrapper}
                            textStyle={s.text}
                            textHoverStyle={s.textHoverStyle}
                            children={x.label}
                            onPress={() =>
                              this.handleMenuPress(image, x.action)
                            }
                          />
                        ))}
                    </View>

                    <View style={s.actionsContainer}>
                      {/* for some reason, can't seem to properly trim filenames without 
                spaces in them and they bleed well over the edge of the photo */}
                      <Text
                        style={[s.text, s.fileName]}
                        numberOfLines={2}
                        ellipsizeMode={'head'} // lets use head instead of the usual tail here
                      >
                        {image.filename && image.filename}
                      </Text>

                      <View style={s.infoContainer}>
                        <Text style={s.text}>
                          {image.mimetype && image.mimetype} |
                        </Text>
                        <Text style={s.text}>{bytesToMB(image.size)}</Text>
                      </View>
                    </View>
                  </View>
                )}
              </ImageBackground>
            </TouchableOpacity>
          )
        }}
      </DimensionsConsumer>
    )
  }
}

PhotoCardSquare.propTypes = {
  // wrapperStyle: t.object,
  /** the photo, should be an object with a `handle` */
  image: t.object.isRequired,
  // show the teal border
  featured: t.bool,
  /** the size of the photo */
  size: t.oneOf(['card', 'smallCard', 'small', 'medium', 'large', 'xlarge']),
  /** the different actions we can perform */
  actions: t.arrayOf(
    t.shape({
      action: t.string.isRequired,
      label: t.string.isRequired,
    }),
  ).isRequired,
  /** event handler. returns the action and image object */
  handleAction: t.func.isRequired,
}

PhotoCardSquare.defaultProps = {
  // wrapperStyle: t.object,
  size: 'small',
}

/*
 * can use filter css property in RNW
 *
 * BlurView component from RN seems like it might be compatible but it's not entirely clear
 * https://github.com/react-native-community/react-native-blur/pull/190
 *
 */
