import React from 'react'
import { Linking, StyleSheet, TouchableOpacity, View } from 'react-native'
import LinearGradient from 'react-native-web-linear-gradient'
import { colors, fonts, getName, specialColors } from '../../functions'
import {
  BaseText,
  HoverableTextTouchHighlight,
  MCIcon,
  ProfilePlaceholder,
} from '../../components'
import { WithUser } from '../../layouts/components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    height: 68,
    paddingBottom: 8,
    paddingHorizontal: 20,
  },
  gradientContainer: {
    display: 'flex',
    minHeight: 346,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  namePhotoContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25, // too account for slight offset. Not exactly 'fully centered', with 64 px on bottom and 39px on top
  },
  menuItems: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  menuItem: {
    display: 'flex',
    alignSelf: 'stretch',
    height: 58,
    // paddingLeft: 23,
    // alignItems: 'flex-start', // design spec is left, but keeping center for now
  },
  menuItemHover: {},
  menuItemText: {
    fontFamily: fonts.light,
    fontSize: 16,
    lineHeight: 24,
    color: colors.secondary3,
  },
  menuItemTextHover: {
    color: colors.white,
  },
})

const menuOpts = [
  { label: 'Edit Profile', action: 'edit' },
  { label: 'Support', action: 'support' },
  { label: 'Log Out', action: 'logout' },
]
class Profile extends React.Component {
  _handleBack = () => {
    this.props.history.goBack()
  }

  _handleMenuPress = action => {
    switch (action) {
      case 'edit':
        this.props.history.push('/edit-profile')
        break
      case 'support':
        Linking.openURL(
          'mailto:support@getbarcard.com?subject=BarCard BCVM Support',
        )
        break
      case 'logout':
        // clear entire localStorage (apollo cache) on logout
        localStorage.clear()
        setTimeout(() => this.props.history.push('/'), 300)
        break
      default:
        throw new Error(
          'This option is not defined. Check the User Profile Menu renderer.',
        )
    }
  }

  render() {
    return (
      <View style={s.wrapper}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0.4 }}
          locations={[0.05, 0.3, 0.65, 1.1]}
          colors={[
            colors.teal,
            specialColors.tealTwo,
            specialColors.tealThree,
            colors.pink,
          ]}
          style={s.gradientContainer}
        >
          <View style={s.header}>
            <TouchableOpacity activeOpacity={0.7} onPress={this._handleBack}>
              <MCIcon name="arrow-left" size={24} color={colors.white} />
            </TouchableOpacity>
            <BaseText
              fs={20}
              lh={27}
              weight={'light'}
              children={'My Profile'}
            />
          </View>
          <WithUser>
            {({ user }) => (
              <View style={s.namePhotoContainer}>
                <ProfilePlaceholder />
                <BaseText
                  fs={26}
                  lh={36}
                  mt={19}
                  weight={'bold'}
                  children={user && getName(user)}
                />
              </View>
            )}
          </WithUser>
        </LinearGradient>
        <View style={s.menuItems}>
          {menuOpts &&
            menuOpts.map(x => (
              <HoverableTextTouchHighlight
                key={x.action}
                activeOpacity={1}
                underlayColor={colors.primary3}
                // innerContainerStyle={s.innerContainerStyle}
                style={s.menuItem}
                text={x.label}
                hoverStyle={s.menuItemHover}
                textStyle={s.menuItemText}
                textHoverStyle={s.menuItemTextHover}
                onPress={() => this._handleMenuPress(x.action)}
              />
            ))}
        </View>
      </View>
    )
  }
}

export default Profile
