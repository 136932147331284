import React from 'react'
import { StyleSheet, View } from 'react-native'
import { NoAccount } from './NoAccount'
import { colors, hexToRgba } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  navbarContainer: {
    marginTop: -44,
    height: 44,
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class Account extends React.Component {
  state = {
    showTabs: true,
  }
  render() {
    return (
      <React.Fragment>
        <View style={s.navbarContainer} />
        <View style={s.wrapper}>
          <NoAccount />
        </View>
      </React.Fragment>
    )
  }
}

export default Account
