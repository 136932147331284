import React from 'react'
import { createIconSetFromFontello } from 'react-native-vector-icons'
import fontelloConfig2 from '../../../assets/fonts/fontelloConfig2.json'

import iconFont from '../../../assets/fonts/fontello2.ttf'
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: Downtown;
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject stylesheet
document.head.appendChild(style)

const CustomIcon = createIconSetFromFontello(fontelloConfig2, 'Downtown')

export const FIcon = props => {
  return (
    <CustomIcon
      name={props.name}
      size={props.size}
      color={props.color}
      style={props.style}
    />
  )
}
