import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Mutation } from 'react-apollo'
import Modal from 'modal-enhanced-react-native-web'
import { colors } from '../../../functions'
import {
  BaseText,
  FormButton,
  MIcon,
  PopoverMenu,
  FixedWidthContainer,
} from '../../../components'
import {
  VENUE_SPECIALS_QUERY_NEW,
  UPDATE_SPECIAL_PUBLISHED,
  DELETE_SPECIAL,
} from '../../../gql'
import { WithSelectedVenue } from '../../../layouts/components'

const s = StyleSheet.create({
  wrapper: {
    // padding: 10,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
})

export class SpecialPopover extends React.Component {
  state = {
    modalIsVisible: false,
  }

  _handleRemove = () => {
    this.setState({ modalIsVisible: true })
  }

  _renderModalContent = () => (
    <WithSelectedVenue>
      {({ selectedVenue }) => (
        <Mutation
          mutation={DELETE_SPECIAL}
          refetchQueries={() => [
            {
              query: VENUE_SPECIALS_QUERY_NEW,
              variables: { id: selectedVenue.id },
            },
          ]}
        >
          {(deleteSpecial, { error, loading }) => (
            <FixedWidthContainer style={s.modal} width={400} padding={22}>
              <View style={s.modalMessageContainer}>
                <BaseText
                  fs={16}
                  lh={24}
                  color={'secondary3'}
                  children={'Are you sure you want to delete?'}
                />
              </View>
              <View style={s.modalControls}>
                <FormButton
                  text={'Cancel'}
                  outerStyle={{ marginRight: 0 }}
                  textColor={colors.teal}
                  handlePress={() => this.setState({ modalIsVisible: false })}
                />
                <FormButton
                  text={'Delete'}
                  outerStyle={{ marginRight: 0 }}
                  textColor={colors.pink}
                  handlePress={async () => {
                    // to do:
                    // need to make this cascade & delete all children !
                    try {
                      console.log('special.id', this.props.id)
                      const res = await deleteSpecial({
                        variables: { id: this.props.id },
                      })
                      console.log('successfully removed Special', res)
                    } catch (e) {
                      console.log('error removing Special', e)
                    }
                  }}
                />
              </View>
            </FixedWidthContainer>
          )}
        </Mutation>
      )}
    </WithSelectedVenue>
  )

  render() {
    const { id, handleEdit } = this.props
    const { modalIsVisible } = this.state

    return (
      <React.Fragment>
        <Mutation mutation={UPDATE_SPECIAL_PUBLISHED}>
          {(updateSpecialPublished, { error, loading }) => (
            <View style={s.wrapper}>
              <PopoverMenu
                menuProviderWrapperStyle={{ padding: 0 }}
                renderTrigger={() => (
                  <MIcon name={'more-horiz'} color={colors.teal} size={24} />
                )}
                options={[
                  {
                    label: 'Publish',
                    onSelect: async () => {
                      try {
                        console.log('special.id', id)
                        const res = await updateSpecialPublished({
                          variables: { id: id, published: true },
                        })
                        console.log('successfully published Special', res)
                      } catch (e) {
                        console.log('error publishing Special', e)
                      }
                    },
                  },
                  {
                    label: 'Unpublish',
                    onSelect: async () => {
                      try {
                        console.log('special.id', id)
                        const res = await updateSpecialPublished({
                          variables: { id: id, published: false },
                        })
                        console.log('successfully unpublished Special', res)
                      } catch (e) {
                        console.log('error unpublishing Special', e)
                      }
                    },
                  },
                  {
                    label: 'Edit',
                    onSelect: () => handleEdit(id),
                  },
                  // logic still a WIP. so hold off for now.
                  // {
                  // 	label: 'Clone',
                  // 	onSelect: () => console.log('Clone pressed'),
                  // },
                  {
                    label: 'Delete',
                    onSelect: () => this._handleRemove(),
                  },
                ]}
              />
            </View>
          )}
        </Mutation>

        <Modal
          isVisible={modalIsVisible}
          onBackdropPress={() => this.setState({ modalIsVisible: false })}
        >
          {this._renderModalContent()}
        </Modal>
      </React.Fragment>
    )
  }
}
