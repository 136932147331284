import React from 'react'
import { StyleSheet, View } from 'react-native'
// import { Accordion } from '../../components'

const SOLUTIONS = [
  {
    title: 'So what’s the catch, do the venues have to pay?',
    content:
      'At BarCard, we don’t feel like it’s right to leave anyone out, so our basic venue listing and advertising service is completely free. We believe that your job as a venue manager does not come in a “one-size-fits-all” model, so we don’t think our products and services should have that model either. Instead, we have a free tier to join and premium features that could be added to your account if you feel like trying something new!',
  },
  {
    title: 'What types of venues are on the BarCard app?',
    content:
      'Every possible venue type we can find! From traditional bars, pubs and taverns to hip rooftop bars, clubs, and lounges; and all the way to live music and karaoke, the BarCard platform is meant to service all kinds of venues. We are in the business of selling experiences and advertising new, exclusive content to our users. Whether a wine bar, brunch spot, or comedy club, these are all different types of experiences that BarCard wants to help promote so we can show our users all the cool, unique places there are in NYC!',
  },
  {
    title:
      'How can bars, restaurants, and other venues apply to join the BarCard app?',
    content:
      'Easy! Click here to fill out a brief questionnaire to apply and one of our venue relationship managers will touch base and get you set-up right away! If you have any questions, please feel free to email sales@getbarcard.com for new customers and support@getbarcard.com for existing customers.',
  },
  {
    title: 'How do I update my venue profile on the BarCard app?',
    content:
      'Easy. If you have an account for the BC Virtual Manager application, click here to log into your account and go to the “Manage Venue” tab. If you do not have a login, please contact support@getbarcard.com with any requested changes to your profile. Please contact sales@getbarcard.com if you are interested in learning more about the BC Virtual Manager and the great features we have to offer!',
  },
  {
    title: 'How can I list events on the BarCard app?',
    content:
      'Easy. If you have an account for the BC Virtual Manager application, click here to log into your account and go to the “Event Manager” tab. If you do not have a login, please contact sales@getbarcard.com if you are interested in learning more about the BC Virtual Manager and the great features we have to offer!',
  },
  {
    title: 'Can I search for a specific venue on the BarCard app?',
    content:
      'Yes! Simply type the name of the venue that you are looking for in the search bar on the home screen OR use the search tab in the navigation bar to search by filter. We are constantly adding more venues to our platform and have more exciting search and discovery features coming soon!',
  },
  {
    title: 'What are some of the benefits of the BarCard app for users?',
    content:
      'As mentioned earlier, it’s free for users now and always – so nothing to lose by downloading the app taking a look at the cool features yourself – but if we had to say a few words: BarCard is an all-in-one going out app with features that let you live your best nightlife!',
    contentItems: [
      'Find all the best specials, happy hours, and events at your favorite venues or by the search criteria of your choice',
      'Set your preferences and customize your offers and notifications',
      'Pay through the app for events, tickets and party packages',
      'Earn loyalty rewards at your favorite venues',
      'Share venue details (specials, directions) with friends through the app',
      'Get exclusive offers, discounts, and invitations',
    ],
  },
  {
    title: 'How does BarCard help venues drive sales?',
    content:
      'Given our “Freemium” model, enjoy our core service, advertising specials, events, and last-minute marketing needs totally free. We’ll do the legwork.  We’ll check in to update your events and offerings, and suggest user driven specials that BarCard can co-sponsor parties at your venue. Add on any of our constantly expanding list of services as you need them, such as event ticketing: take payment ahead of time for group bookings, party packages, and public events.',
  },
]

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 950,
  },
})

export const Solutions = ({ history }) => (
  <View style={s.wrapper}>
    <View style={s.innerContent}>
      {/* <Accordion sections={SOLUTIONS} /> */}
    </View>
  </View>
)
