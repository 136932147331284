import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { colors, hexToRgba } from '../../../functions'
import { DimensionsConsumer } from '../../../lib'
import { BaseText, FormButton, MCIcon } from '../../../components'
import { EventsList } from './EventsList'

const WIDTH = 700

const s = StyleSheet.create({
  trigger: {
    paddingRight: 15,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: WIDTH,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalCloseButton: {
    position: 'absolute',
    zIndex: 1,
  },
  modalMessageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 23,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventsList: {
    borderTopColor: hexToRgba(colors.secondary1, 0.5),
    borderTopWidth: 2,
  },
})

export class ConfirmModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsVisible: false,
      data: props.data,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: props.data,
    })
  }

  _handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }

    this.closeModal()
  }

  _handleConfirm = () => {
    this.props.onConfirm(this.state.data.filter(({ recurring }) => recurring))
    this.closeModal()
  }

  showModal = () => {
    this.setState({ modalIsVisible: true })
  }

  closeModal = () => {
    this.setState({ modalIsVisible: false })
  }

  render() {
    const { modalIsVisible, data } = this.state

    return (
      <DimensionsConsumer>
        {({ width, height }) => {
          const isSmall = width < 673 // 653 + 10 px on each side
          const modalWidth = width - 20

          return (
            <React.Fragment>
              <Modal
                isVisible={modalIsVisible}
                onBackdropPress={() => this.setState({ modalIsVisible: false })}
              >
                <View
                  style={[
                    s.modal,
                    isSmall && { width: modalWidth, paddingRight: 0 },
                  ]}
                >
                  <View style={s.modalMessageContainer}>
                    <View style={s.sectionHeader}>
                      <BaseText
                        fs={20}
                        lh={27}
                        weight={'light'}
                        children={this.props.message}
                      />
                    </View>
                  </View>
                  <View style={s.modalControls}>
                    <View style={s.buttonsContainer}>
                      <FormButton
                        text={this.props.cancelButtonText || 'Cancel'}
                        textColor={colors.pink}
                        handlePress={this._handleCancel}
                      />
                      <FormButton
                        text={this.props.confirmButtonText || 'Confirm'}
                        outerStyle={{ marginRight: 0 }}
                        textColor={colors.teal}
                        handlePress={this._handleConfirm}
                      />
                    </View>
                  </View>
                  <View style={s.eventsList}>
                    {EventsList(
                      data,
                      'recurring',
                      null,
                      null,
                      true,
                      (index, value) => {
                        this.state.data[index].recurring = value
                        this.setState({
                          data: this.state.data,
                        })
                      },
                    )}
                  </View>
                </View>
              </Modal>
            </React.Fragment>
          )
        }}
      </DimensionsConsumer>
    )
  }
}

ConfirmModal.propTypes = {
  message: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}
