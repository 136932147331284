import React from 'react'
import { Query } from 'react-apollo'
import { LoadingPage } from '../../components'
import { SELECTED_VENUE_QUERY } from '../../gql'
import { WithUser } from '../components'

const WithSelectedVenue = props => {
  return (
    <WithUser>
      {({ user }) => (
        <Query
          query={SELECTED_VENUE_QUERY}
          variables={{ userId: user.id }}
          fetchPolicy={'cache-first'}
        >
          {({ data, loading }) => {
            if (loading) return <LoadingPage />
            return props.children(data)
          }}
        </Query>
      )}
    </WithUser>
  )
}

export default WithSelectedVenue
