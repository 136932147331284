import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { colors, fonts, hexToRgba, toCurrency } from '../../functions'
import { INPUT_MARGIN_BOTTOM, INPUT_BORDER } from '../../config'

const INPUT_LABEL_WIDTH = 50

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    // backgroundColor: 'rgba(35,35,35,0.5)',
    paddingTop: '15px',
    paddingBottom: '11px',
    marginBottom: `${INPUT_MARGIN_BOTTOM}px`,
    borderBottomWidth: `${INPUT_BORDER}px`,
    borderBottomColor: hexToRgba(colors.secondary1, 0.2),
  },
  inputField: {
    color: hexToRgba(colors.black, 0),
    caretColor: colors.teal,
    position: 'absolute',
  },
  inputActive: {
    borderBottomColor: colors.teal,
  },
  inputError: {
    borderBottomColor: colors.pink,
  },
  // overrides wrapper settings if we dont want a fall
  // think this can be deleted ?
  noFill: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: '1px',
    borderColor: '#232323',
  },
  input: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.secondary2,
    flex: 1,
    outline: 'none',
    paddingRight: '13px',
  },
  singleInput: {
    paddingRight: '0px',
    textAlign: 'center',
  },
  labelWrapper: {
    //paddingLeft: 13,
    width: INPUT_LABEL_WIDTH,
  },
  labelWrapperWide: {
    width: INPUT_LABEL_WIDTH * 1.3,
  },
  label: {
    fontFamily: fonts.semibold,
    fontSize: 14,
    lineHeight: 24,
    color: colors.secondary3,
  },
  labelActive: {
    color: colors.teal,
  },
  labelError: {
    color: colors.pink,
  },
  // collapseLabel input styles
  collapseLabelWrapper: {
    flexDirection: 'column',
  },
  collapseLabelInput: {
    marginTop: 7,
    paddingRight: 13,
  },
})

class NumericInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      value: props.value,
    }
  }

  componentWillMount() {
    if (this.props.value) {
      let suffix =
        this.props.suffix && !this.state.focused ? ` ${this.props.suffix}` : ''
      this.setState({ value: `$${toCurrency(this.props.value)}${suffix}` })
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps fired')
    if (nextProps.value && nextProps.value !== this.props.value) {
      console.log('componentWillReceiveProps inner fired')
      let suffix =
        this.props.suffix && !this.state.focused ? ` ${this.props.suffix}` : ''
      this.setState({ value: `$${toCurrency(nextProps.value)}${suffix}` })
    }
  }
  handleChange = value => {
    // we can't use numbers with TextInput for RN ?

    let suffix = this.props.suffix ? ` ${this.props.suffix}` : ''
    this.setState({
      value: `$${toCurrency(parseFloat(value.slice(1)))}${suffix}`,
    })

    if (!this.props.onChangeTextOnlyOnBlur) {
      this.props.onChangeText(this.props.name, parseFloat(value.slice(1)))
    }
  }
  handleOnBlur = () => {
    if (this.props.value) {
      let suffix = this.props.suffix ? ` ${this.props.suffix}` : ''
      this.setState({ value: `$${toCurrency(this.props.value)}${suffix}` })
    }

    if (this.props.onChangeTextOnlyOnBlur && this.props.onChangeText) {
      this.props.onChangeText(
        this.props.name,
        parseFloat(this.state.value.slice(1)),
      )
    }

    return this.setState({ focused: false })
  }
  handleOnFocus = () => {
    if (this.props.value && this.props.suffix && this.props.editable) {
      let suffix = this.props.suffix
      this.setState({
        value: `$${toCurrency(this.props.value).replace(suffix)}`,
      })
    }
    return this.setState({ focused: true })
  }
  render() {
    const {
      onChangeText,
      onChangeTextOnlyOnBlur,
      noFill,
      value,
      wide,
      labelWidth,
      label,
      labelColor,
      collapseLabel,
      wrapperStyle,
      textInputStyle,
      suffix,
      ...inputProps
    } = this.props
    const { focused } = this.state

    if (onChangeTextOnlyOnBlur) {
      inputProps.value = this.state.value
    }

    return (
      <View
        style={[
          s.wrapper,
          noFill && s.noFill,
          focused && s.inputActive,
          collapseLabel && s.collapseLabelWrapper,
          wrapperStyle && wrapperStyle,
        ]}
      >
        {label && (
          <View
            style={[
              s.labelWrapper,
              wide && s.labelWrapperWide,
              labelWidth && { width: labelWidth, maxWidth: labelWidth },
            ]}
          >
            <Text
              style={[
                s.text,
                s.label,
                labelColor && { color: labelColor },
                focused && s.labelActive,
              ]}
            >
              {label && label}
            </Text>
          </View>
        )}

        <TextInputMask
          ref={ref => (this._myMoneyField = ref)}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          style={[
            s.text,
            s.input,
            s.singleInput,
            collapseLabel && s.collapseLabelInput,
            textInputStyle && textInputStyle,
          ]}
          onChangeText={this.handleChange}
          {...inputProps}
          // keyboardType={'number-pad'}
          // 'react-native-masked-text props
          value={this.state.value}
          type={'money'}
          options={{
            // precision: 2,
            unit: '$', // prefix text
            separator: '.', // decimal seperator
            delimiter: ',', // thousand seperator
          }}
          keyboardType={'default'} // need this on web, since it's technically not a number
          // keyboardType={'numeric'}
          editable={this.props.editable}
        />
      </View>
    )
  }
}

export default NumericInput

/*
 *
 * need to make sure we're using keyboard type + max length:
 *   keyboardType={props.keyboardType ? props.keyboardType : 'default'}
 *   maxLength={props.maxLength ? props.maxLength : null}
 *
 * need to implement placeholders
 *   placeholder
 *   placeholderTextColor
 *
 */
