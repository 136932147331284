import React from 'react'
import { StyleSheet, View } from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { colors, fonts } from '../../../functions'
import { UPDATE_VENUE_PHOTO, VENUE_INFO_QUERY } from '../../../gql'
import {
  FormButton,
  ResponsiveInput,
  PhotoUploader,
  PhotoCardSquare,
  PhotoPlaceholder,
  HoverableText,
  BaseText,
} from '../../../components'
import { PhotoControls } from '../components'

const s = StyleSheet.create({
  dim: {
    opacity: 0.7,
  },
  photosContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  uploadButtonContainer: {
    marginBottom: 23,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingLeft: 30,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.secondary2,
    zIndex: 6,
  },
})

const photoActions = [
  {
    label: 'Publish',
    action: 'publish',
  },
  {
    label: 'Unpublish',
    action: 'unpublish',
  },
  {
    label: 'Edit Name',
    action: 'editName',
  },
]

class MenusBase extends React.Component {
  state = {
    aspectRatio: 1,
    modalIsVisible: false,
    showPublished: true,
    showLarge: false,
    publishedPhotos: [],
    unpublishedPhotos: [],
    image: null, // an image we want to edit the filename for
    newFilename: null,
  }

  componentWillMount() {
    const { photos } = this.props.initialValues
    return this.setState({
      publishedPhotos: photos.filter(x => x.menu).filter(x => x.published),
      unpublishedPhotos: photos.filter(x => x.menu).filter(x => !x.published),
    })
  }
  //Notes on these menu unpub photo fixes:
  //Schema may be edited to include explicit photo category types as props
  //instead of a single photos container that we filter here on component level
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues) {
      const { photos } = nextProps.initialValues
      return this.setState({
        publishedPhotos: photos.filter(x => x.menu).filter(x => x.published),
        unpublishedPhotos: photos.filter(x => x.menu).filter(x => !x.published),
      })
    }
  }

  render() {
    const { initialValues, title, subtext } = this.props

    const {
      aspectRatio,
      modalIsVisible,
      image, // currently selected image, to edit menu name
      newFilename,
      showPublished,
      showLarge,
      publishedPhotos,
      unpublishedPhotos,
    } = this.state

    const menus = showPublished ? publishedPhotos : unpublishedPhotos

    return (
      <React.Fragment>
        <PhotoControls
          title={title}
          subtext={subtext}
          showPublished={showPublished}
          showLarge={showLarge}
          handleSetState={newState => this.setState(newState)}
        />
        <Mutation mutation={UPDATE_VENUE_PHOTO}>
          {(updatePhoto, { error, loading }) => (
            <React.Fragment>
              <View style={s.photosContainer}>
                {menus && menus.length > 0 ? (
                  menus.map((menu, index) => (
                    <React.Fragment key={index}>
                      {/* <Field name={`menus.${index}`} /> */}
                      <PhotoCardSquare
                        key={`${menu.id}-${index}`}
                        image={menu}
                        actions={photoActions}
                        size={showLarge ? 'medium' : 'small'}
                        handleAction={async (action, image) => {
                          if (action === 'publish' || action === 'unpublish') {
                            try {
                              const res = await updatePhoto({
                                variables: {
                                  id: image.id,
                                  menu: action === 'publish' ? true : false,
                                },
                              })
                              console.log('res', res)
                              console.log(
                                'res.data.updatePhoto',
                                res.data.updatePhoto,
                              )
                            } catch (error) {
                              TrackError(error)
                            }
                          } else if (action === 'editName') {
                            this.setState({ modalIsVisible: true, image })
                          } else {
                            TrackError('action not mapped! (menus page)')
                            return console.log('action not mapped!')
                          }
                        }}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <PhotoPlaceholder
                    wrapperStyle={{ marginBottom: 22 }}
                    size={showLarge ? 'cover' : 'square'}
                  />
                )}
              </View>
              <View style={[s.row, { paddingLeft: 0 }]}>
                <View style={[s.rowCenter, { marginRight: 20 }]}>
                  <HoverableText
                    outerContainerStyle={s.touchWrapper}
                    textStyle={[s.text, aspectRatio !== 1 && s.dim]}
                    textHoverStyle={s.textHoverStyle}
                    children={'1:1 Ratio'}
                    onPress={() => this.setState({ aspectRatio: 1 })}
                  />
                  <BaseText fs={13} lh={18} children={' | '} />
                  <HoverableText
                    outerContainerStyle={s.touchWrapper}
                    textStyle={[s.text, aspectRatio !== 1.9 && s.dim]}
                    textHoverStyle={s.textHoverStyle}
                    children={'16:9 Ratio'}
                    onPress={() => this.setState({ aspectRatio: 1.9 })}
                  />
                  <BaseText fs={13} lh={18} children={' | '} />
                  <HoverableText
                    outerContainerStyle={s.touchWrapper}
                    textStyle={[s.text, aspectRatio !== 0 && s.dim]}
                    textHoverStyle={s.textHoverStyle}
                    children={'Free Ratio'}
                    onPress={() => this.setState({ aspectRatio: 0 })}
                  />
                </View>
              </View>
              <View style={s.uploadButtonContainer}>
                <PhotoUploader
                  aspectRatio={aspectRatio}
                  maxFiles={15} // the max number of files the user can upload at a time
                  photoType={'VenuePhoto'} // enum('EventPhoto', 'VenuePhoto')
                  // additional props we want to send along with the graphql mutation
                  photoProps={{
                    venueId: initialValues.id,
                    profile: false,
                    menu: true,
                    feature: false,
                    gallery: false,
                    foodDrink: null,
                    experience: null,
                    published: null, // chanage to true soon
                  }}
                  // do we want to set an optimisticResponse in apollo?
                  optimisticUpdates={true}
                  // query we want to write cache w/ and for apollo to refetch
                  refetchQuery={{
                    query: VENUE_INFO_QUERY,
                    variables: { id: initialValues.id },
                  }}
                />
              </View>
              <Modal
                isVisible={modalIsVisible}
                onBackdropPress={() => this.setState({ modalIsVisible: false })}
              >
                <View style={s.modal}>
                  <View style={s.modalMessageContainer}>
                    <ResponsiveInput
                      label={'Name'}
                      name={'filename'}
                      labelWidth={80}
                      onChangeText={(field, value) =>
                        this.setState({ newFilename: value })
                      }
                      value={
                        newFilename
                          ? newFilename
                          : image
                          ? image.filename.split('.')[0]
                          : ''
                      }
                      autoComplete={'off'}
                    />
                  </View>
                  <View style={s.modalControls}>
                    <FormButton
                      text={'Cancel'}
                      outerStyle={{ marginRight: 0 }}
                      textColor={colors.pink}
                      handlePress={() =>
                        this.setState({ modalIsVisible: false })
                      }
                    />
                    <FormButton
                      text={'Save'}
                      outerStyle={{ marginRight: 0 }}
                      textColor={colors.teal}
                      handlePress={async () => {
                        try {
                          const ext = image.filename.split('.')[1]
                          const res = await updatePhoto({
                            variables: {
                              id: image.id,
                              filename: `${newFilename}.${ext}`,
                            },
                          })
                          if (res) {
                            this.setState({
                              modalIsVisible: false,
                              newFilename: null,
                              image: null,
                            })
                          }
                          console.log('res', res)
                          console.log(
                            'res.data.updatePhoto',
                            res.data.updatePhoto,
                          )
                        } catch (error) {
                          TrackError(error)
                        }
                      }}
                    />
                  </View>
                </View>
              </Modal>
            </React.Fragment>
          )}
        </Mutation>
      </React.Fragment>
    )
  }
}

export const Menus = props => <MenusBase {...props} />
