import gql from 'graphql-tag'
import { UserFragments } from './fragments'

export const UPDATE_USER_INFO = gql`
  mutation($id: ID!, $firstName: String, $lastName: String, $email: String) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      ...UserInfo
    }
  }
  ${UserFragments.info}
`
