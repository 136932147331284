import React from 'react'
import { StyleSheet, View } from 'react-native'
import { isEqual } from 'lodash'
import {
  BaseText,
  HoverableTouchHighlight,
  LineBreak,
  NavIcon,
  SectionTitle,
} from '../../components'
import { colors } from '../../functions'
import { routes } from '../../config'
import { WithUser } from '../../layouts/components'

const s = StyleSheet.create({
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginBottom: 40,
    justifyContent: 'space-around',
  },
  quickActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 87,
    height: 87,
    borderRadius: 20,
    backgroundColor: colors.opaque,
    //flexGrow: 0.3,
  },
  quickActionContainerHover: {
    backgroundColor: colors.primary3,
  },
  quickActionInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class QuickActions extends React.Component {
  state = {
    actions: routes && routes.filter(x => x.quickActionLabel),
  }

  handlePress = path => {
    return this.props.handleNavigation(path)
  }

  renderAction = x => (
    <HoverableTouchHighlight
      key={x.path}
      underlayColor={colors.primary3}
      style={s.quickActionContainer}
      hoverStyle={s.quickActionContainerHover}
      onPress={() => this.handlePress(x.path)}
    >
      <NavIcon
        iconName={x.iconName}
        iconType={x.iconType}
        size={x.customIconSize ? x.customIconSize : 24}
        color={x.iconColor ? x.iconColor : '#fff'}
        style={s.icon}
      />
      <BaseText
        fs={14}
        lh={19}
        mt={15}
        ta={'center'}
        children={x.quickActionLabel}
      />
    </HoverableTouchHighlight>
  )

  _filterSideNavRoutes = user => {
    let newRoutes = routes.filter(x => {
      return (
        !x.allowedRoles ||
        x.allowedRoles.length == 0 ||
        x.allowedRoles.indexOf(user.role) !== -1
      )
    })

    this.setState({ actions: newRoutes.filter(x => x.quickActionLabel) })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // prevent change in children prop from re-rendering entire tree
    if (!isEqual(this.state.actions, nextState.actions)) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { actions } = this.state
    return (
      <WithUser>
        {({ user }) => {
          this._filterSideNavRoutes(user)

          return (
            <React.Fragment>
              <SectionTitle children={'Quick Actions'} />
              <LineBreak style={{ marginVertical: 14 }} />
              <View style={s.actionRow}>
                {actions && actions.slice(0, 3).map(x => this.renderAction(x))}
              </View>
              <View style={s.actionRow}>
                {actions && actions.slice(3, 6).map(x => this.renderAction(x))}
              </View>
            </React.Fragment>
          )
        }}
      </WithUser>
    )
  }
}

export default QuickActions
