import * as Screens from '../screens'
import * as Layouts from '../layouts'
import { PageNotFound } from '../layouts/components'

export const routesNew = [
  {
    path: [
      '/',
      '/solutions',
      '/resources',
      '/contact',
      '/login',
      '/employee-login',
      '/confirm-invite',
    ],
    exact: true,
    component: Layouts.PublicLayout,
    routeKey: 'public',
  },
  {
    path: [
      '/account',
      '/banking',
      '/organization/new',
      '/dashboard',
      '/events',
      '/events-manager-admin',
      '/manage-venue',
      '/menus',
      '/organization/assign-venue',
      '/specials',
      '/tools',
      '/create-venue',
      '/reports',
      '/bulk-create-venue',
    ],
    exact: true,
    component: Layouts.AuthLayout,
    routeKey: 'auth',
  },
  {
    path: ['/edit-profile', '/event', '/profile', '/special', '/ticket'],
    component: Layouts.AuthLayoutStack,
    routeKey: 'authStack',
    stackProps: {
      animationType: 'slide-vertical',
      replaceTransitionType: 'POP',
    },
  },
  {
    path: ['*'],
    exact: false,
    component: PageNotFound,
    routeKey: 'auth',
  },
]

export const childRoutes = {
  public: [
    {
      exact: true,
      path: '/',
      component: Screens.Landing,
    },
    {
      path: '/solutions',
      component: Screens.Solutions,
    },
    {
      path: '/resources',
      component: Screens.Resources,
    },
    {
      path: '/contact',
      component: Screens.Landing,
    },
    {
      path: '/login',
      component: Screens.Login,
    },
    {
      path: '/employee-login',
      component: Screens.Login,
    },
    {
      path: '/confirm-invite',
      component: Screens.ConfirmInvite,
    },
  ],
  auth: [
    {
      path: '/dashboard',
      label: 'Dashboard',
      iconName: 'pulse',
      iconType: 'custom',
      iconColor: '#7E5EFF',
      component: Screens.Dashboard,
    },
    {
      path: '/manage-venue',
      label: 'Manage Venue',
      quickActionLabel: 'Venue',
      iconName: 'places-home-4',
      iconType: 'custom',
      iconColor: '#5E5EFF',
      component: Screens.ManageVenue,
    },
    {
      path: '/specials',
      label: 'Specials',
      quickActionLabel: 'Specials',
      iconName: 'specials',
      iconType: 'custom',
      iconColor: '#98FF5E',
      component: Screens.Specials,
    },
    {
      path: '/tools',
      label: 'Tools',
      quickActionLabel: 'Tools',
      iconName: 'build',
      iconType: 'material',
      iconColor: '#16CCD9',
      component: Screens.Tools,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
    {
      path: '/events',
      label: 'Event Manager',
      quickActionLabel: 'Events',
      iconName: 'flash',
      iconType: 'custom',
      iconColor: '#FFD45E',
      component: Screens.Events,
      stackProps: {
        animationType: 'none',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/events-manager-admin',
      label: 'Events Manager Admin',
      component: Screens.EventsManagerAdmin,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
      stackProps: {
        animationType: 'none',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/account',
      label: 'Business Account',
      quickActionLabel: 'Organization',
      iconName: 'emoticonexcited',
      iconType: 'custom',
      iconColor: '#FF895E',
      component: Screens.Account,
    },
    {
      path: '/banking',
      label: 'Banking',
      quickActionLabel: 'Banking',
      iconName: 'piggy-bank',
      iconType: 'custom',
      iconColor: '#FF5E5E',
      component: Screens.Banking,
    },

    {
      path: '/reports',
      label: 'Reports',
      quickActionLabel: 'Reports',
      iconName: 'trending_up',
      iconType: 'material',
      iconColor: '#7E5EFF',
      component: Screens.Reports,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
    {
      path: '/create-venue',
      label: 'Create Venue',
      component: Screens.CreateVenueTool,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
    {
      path: '/organization/new',
      label: 'Create Organization',
      component: Screens.Organization,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
    {
      path: '/organization/assign-venue',
      label: 'Add Venue to Organization',
      component: Screens.OrganizationAssignVenue,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
    {
      path: '/bulk-create-venue',
      label: 'Bulk Create Venue',
      component: Screens.BulkCreateVenue,
      allowedRoles: ['ADMIN', 'SUPERADMIN'],
    },
  ],
  authStack: [
    {
      path: '/edit-profile',
      component: Screens.EditProfile,
      stackProps: {
        animationType: 'slide-horizontal',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/event',
      component: Screens.Event,
      stackProps: {
        animationType: 'slide-vertical',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/profile',
      component: Screens.Profile,
      stackProps: {
        animationType: 'slide-horizontal',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/special',
      component: Screens.Special,
      stackProps: {
        animationType: 'slide-horizontal',
        replaceTransitionType: 'POP',
      },
    },
    {
      path: '/ticket',
      component: Screens.Ticket,
      stackProps: {
        animationType: 'slide-vertical',
        replaceTransitionType: 'POP',
      },
    },
  ],
}
