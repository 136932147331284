import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { RadioButton, FormLabel } from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 20,
    justifyContent: 'center',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  infoText: {
    color: colors.pink,
    fontSize: 12,
    lineHeight: 26,
    marginTop: 10,
    textAlign: 'center',
  },
})

const recurringOptions = [
  {
    label: 'Recurring',
    value: true,
  },
  {
    label: 'One-Time',
    value: false,
  },
]

export const Recurring = ({ props }) => (
  <View style={[s.wrapper]}>
    <View style={[s.options]}>
      {recurringOptions.map((item, index) => {
        const val = props.values.recurring === item.value
        return (
          <View key={index} style={s.radioContainer}>
            <RadioButton
              value={val}
              radioValue={item.value}
              onPress={async e => {
                await props.setFieldValue('recurring', item.value)
              }}
            />
            <FormLabel mr={20} ml={10} mt={4} children={item.label} />
          </View>
        )
      })}
    </View>
    {props.values.recurring && (
      <Text style={s.infoText}>
        Warning: Repetitions of this event will <u>NOT</u> be created until an
        Admin clicks the{' '}
        <b style={{ whiteSpace: 'nowrap' }}>+ Recurring Events</b> button in the{' '}
        <b style={{ whiteSpace: 'nowrap' }}>Admin Events Manager</b> page
      </Text>
    )}
  </View>
)
