import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const UserFragments = {
  info: gql`
    fragment UserInfo on User {
      id
      email
      firstName
      lastName
      role

      permissions {
          permission
      }
    }
  `,
}
