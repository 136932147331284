import React from 'react'
import { DotIndicator, SkypeIndicator } from 'react-native-indicators'

export const Dots = ({ style, color }) => (
  <DotIndicator
    style={style && style}
    size={12}
    count={3}
    color={color || 'rgba(212,212,212,1)'}
  />
)

export const Circular = ({ style, color }) => (
  <SkypeIndicator
    style={style && style}
    size={20}
    color={color || 'rgba(255,255,255,1)'}
  />
)
