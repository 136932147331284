import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { FormButtons, Input, PinkErrorText } from '../../components'
import { parseAuthErrors } from '../../lib'
import { CONFIRM_INVITE } from '../../gql'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // alignSelf: 'stretch',
  },
  formView: {
    display: 'flex',
    alignSelf: 'stretch',
    minWidth: 400,
    maxWidth: 800,
    // marginHorizontal: scale(23),
    // marginBottom: scale(17),
  },
  errorsContainer: {
    minHeight: 60,
  },
})

class ConfirmInviteForm extends React.Component {
  state = {
    serverError: null,
  }

  render() {
    console.log('this.props.initialValues', this.props.initialValues)
    return (
      <Mutation
        mutation={CONFIRM_INVITE}
        // variables={this.state}
        // refetchQueries={() => [{ query: USER_QUERY }]}
      >
        {(confirmInvite, { error, loading }) => (
          <Formik
            ref={ref => (this.confirmInviteForm = ref)}
            initialValues={{
              ...this.props.initialValues,
              password: '',
              confirmPassword: '',
            }}
            validate={values => {
              console.log('values', values)
              let errors = {}
              if (!values.email) {
                errors.email = 'Email required!'
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Not a valid email address!'
              }
              if (!values.password) {
                errors.password = 'Password required!'
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = 'Please confirm your password!'
              }
              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match!'
              }
              return errors
            }}
            onSubmit={async (values, actions) => {
              try {
                const res = await confirmInvite({ variables: values })
                console.log('res', res)
                localStorage.setItem('token', res.data.confirmInvite.token)
                setTimeout(() => this.props.history.push('/dashboard'), 300)
                actions.setSubmitting(false)
              } catch (error) {
                console.log('error', error)
                // error Error: GraphQL error: function execution error: This invite link has expired or has already been used.
                actions.setErrors(parseAuthErrors(error.toString()))
                actions.setSubmitting(false)
              }
            }}
            render={({
              values,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              touched,
              errors,
              dirty,
              isSubmitting,
            }) => (
              <View style={s.wrapper}>
                <View style={s.formView}>
                  <Input
                    name="email"
                    placeholder={'Email'}
                    autoCapitalize={'none'}
                    keyboardType={'email-address'}
                    onChangeText={setFieldValue}
                    onBlur={setFieldTouched}
                    value={values.email}
                    error={touched.email && errors.email}
                    editable={false}
                    disabled={true}
                  />
                  <Input
                    name="password"
                    placeholder={'Password'}
                    autoCapitalize={'none'}
                    secureTextEntry={true}
                    onChangeText={setFieldValue}
                    onBlur={setFieldTouched}
                    value={values.password}
                    error={touched.password && errors.password}
                    editable={!isSubmitting}
                  />
                  <Input
                    name="confirmPassword"
                    placeholder={'Confirm Password'}
                    autoCapitalize={'none'}
                    secureTextEntry={true}
                    onChangeText={setFieldValue}
                    onBlur={setFieldTouched}
                    value={values.confirmPassword}
                    error={touched.confirmPassword && errors.confirmPassword}
                    editable={!isSubmitting}
                  />
                  <FormButtons
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                    handleCancel={this.props.handleCancel}
                  />
                  <View style={s.errorsContainer}>
                    {touched.email && errors.email ? (
                      <PinkErrorText children={errors.email} />
                    ) : null}
                    {touched.password && errors.password ? (
                      <PinkErrorText children={errors.password} />
                    ) : null}
                    {touched.confirmPassword && errors.confirmPassword ? (
                      <PinkErrorText children={errors.confirmPassword} />
                    ) : null}
                    {this.state.serverError ? (
                      <PinkErrorText children={this.state.serverError} />
                    ) : null}
                  </View>
                </View>
              </View>
            )}
          />
        )}
      </Mutation>
    )
  }
}

export default ConfirmInviteForm
