import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import { AutoCompleteWeb, MIcon } from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import { colors, hexToRgba } from '../../../functions'
import { ScrollView } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    // display: 'flex',
    // flexDirection: 'row',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 10,
  },
  selectionListWrapper: {
    maxHeight: 530,
  },
  selectionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selection: {
    display: 'block',
    color: colors.white,
    fontSize: 18,
    lineHeight: 24,
    paddingVertical: 7,
    marginBottom: 10,
    flex: 1,
  },
  unselect: {
    color: colors.pink,
    fontSize: 16,
  },
  autoCompleteWrapperStyle: {
    width: '100%',
    backgroundColor: colors.primary3,
    marginRight: 20,
    marginBottom: 10,
    paddingLeft: 10,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    zIndex: 2,
  },
  autoCompleteStyle: {
    flex: 1,
    display: 'flex',
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginVertical: 7,
    zIndex: -2,
  },
})

export const Venues = ({ additionalProps, venues }) => {
  return (
    <DimensionsConsumer>
      {({ width }) => {
        return (
          <React.Fragment>
            <View style={[s.wrapper]}>
              <View style={[s.autoCompleteWrapperStyle]}>
                <MIcon name={'search'} size={26} color={colors.white} />
                <AutoCompleteWeb
                  clearOnSelect={true}
                  wrapperStyle={{ flex: 1, display: 'flex' }}
                  items={venues
                    .filter(venue1 => {
                      return !additionalProps.values.assignedVenues.find(
                        venue2 => !venue2.isDeleted && venue1.id === venue2.id,
                      )
                    })
                    .map(venue => ({
                      id: venue.id,
                      name: venue.name,
                      label: venue.name,
                      value: venue,
                    }))}
                  handleSelect={value => {
                    let existingVenue = additionalProps.values.assignedVenues.find(
                      assignedVenue => value.id === assignedVenue.id,
                    )

                    if (existingVenue && !existingVenue.isDeleted) {
                      return
                    }

                    if (existingVenue && existingVenue.isAdded) {
                      return
                    }

                    let newVenues = []

                    if (existingVenue && existingVenue.isDeleted) {
                      newVenues = additionalProps.values.assignedVenues.map(
                        assignedVenue => {
                          if (value.id === assignedVenue.id) {
                            delete assignedVenue.isDeleted
                          }
                          return assignedVenue
                        },
                      )
                      additionalProps.setFieldValue('assignedVenues', newVenues)
                      return
                    }

                    newVenues = [
                      {
                        id: value.id,
                        name: value.name,
                        isAdded: true,
                      },
                    ].concat(additionalProps.values.assignedVenues)
                    additionalProps.setFieldValue('assignedVenues', newVenues)
                  }}
                  value={''}
                />
              </View>
              <View style={s.selectionListWrapper}>
                <ScrollView>
                  {additionalProps.values.assignedVenues.map(
                    (assignedVenue, index, array) => {
                      if (assignedVenue.isDeleted) {
                        return
                      }

                      return (
                        <View key={index}>
                          <View style={[s.selectionWrapper]}>
                            <Text style={[s.selection]}>
                              {assignedVenue.name}
                            </Text>
                            <TouchableOpacity
                              activeOpacity={0.7}
                              onPress={() => {
                                if (assignedVenue.isAdded) {
                                  additionalProps.setFieldValue(
                                    'assignedVenues',
                                    array
                                      .slice(0, index)
                                      .concat(array.slice(index + 1)),
                                  )
                                  return
                                }

                                assignedVenue.isDeleted = true
                                additionalProps.setFieldValue(
                                  'assignedVenues',
                                  array,
                                )
                              }}
                            >
                              <MIcon
                                name={'close'}
                                size={26}
                                color={colors.white}
                              />
                            </TouchableOpacity>
                          </View>
                          <View style={[s.lineBreak]} />
                        </View>
                      )
                    },
                  )}
                </ScrollView>
              </View>
            </View>
          </React.Fragment>
        )
      }}
    </DimensionsConsumer>
  )
}
