import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { compose, graphql } from 'react-apollo'
import LinearGradient from 'react-native-web-linear-gradient'
import { withRouter } from 'react-router-native'
import { TrackError } from '../../lib'
import { NavLink } from '../../components'
import { colors, specialColors } from '../../functions'
import { SET_SELECTED_VENUE } from '../../gql'
import {
  UserProfilePopover,
  VenueSelector,
  WithVenueOptions,
  WithSelectedVenue,
} from '../../layouts/components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  navContainer: {
    flex: 1,
    backgroundColor: colors.primary1,
  },
  gradientContainer: {
    display: 'flex',
    flex: 1,
    minHeight: 172,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
})

class SideNav extends React.Component {
  // handleLogoPress = () => {
  // 	this.props.history.push('/dashboard')
  // }

  componentWillMount() {
    if (this.props.selectedVenue.id === null) {
      this.handleVenueSelect(this.props.allVenues[0])
    }
  }
  handleLinkPress = path => {
    this.props.drawerRef && this.props.drawerRef.closeDrawer()
    return (this.timer1 = setTimeout(() => this.props.history.push(path), 400))
  }

  handlePopoverSelect = option => {
    switch (option) {
      case 'Profile':
        this.props.history.push('/profile')
        break
      case 'Sign Out':
        localStorage.clear()
        setTimeout(() => this.props.history.push('/'), 300)
        break
      default:
        throw new Error('Popover Menu option not mapped to a handler!')
    }
  }

  _closeDrawer = () => {
    this.props.drawerRef && this.props.drawerRef.closeDrawer()
  }

  handleVenueSelect = args => {
    this.props.drawerRef && this.props.drawerRef.closeDrawer()
    this.props
      .mutate({ variables: { ...args, __typename: 'SelectedVenue' } })
      .then(res => console.log('setSelectedVenue', res))
      .catch(e => TrackError(e))
  }

  render() {
    const { routes, user, allVenues, selectedVenue } = this.props
    return (
      <View style={s.wrapper}>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={true}
          directionalLockEnabled={true}
          scrollEventThrottle={16}
          stickyHeaderIndices={[0]}
          contentContainerStyle={s.navContainer}
        >
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0.4 }}
            locations={[0.05, 0.3, 0.65, 1.1]}
            colors={[
              colors.teal,
              specialColors.tealTwo,
              specialColors.tealThree,
              colors.pink,
            ]}
            style={s.gradientContainer}
          >
            <UserProfilePopover
              user={user}
              handleSelect={this.handlePopoverSelect}
            />
            <VenueSelector
              selectedVenue={selectedVenue}
              allVenues={allVenues}
              closeDrawer={this._closeDrawer}
              handleSelect={this.handleVenueSelect}
            />
          </LinearGradient>
          {routes &&
            routes.map((x, i) => {
              return (
                <NavLink
                  key={i}
                  item={x}
                  expanded={true}
                  handlePress={this.handleLinkPress}
                />
              )
            })}
        </ScrollView>
      </View>
    )
  }
}

const SideNavWithData = compose(
  withRouter,
  graphql(SET_SELECTED_VENUE),
)(SideNav)

export default props => (
  <WithSelectedVenue>
    {({ selectedVenue }) => (
      <WithVenueOptions>
        {({ allVenues1, allVenues2 }) => (
          <SideNavWithData
            allVenues={[...allVenues1, ...allVenues2]}
            selectedVenue={selectedVenue}
            {...props}
          />
        )}
      </WithVenueOptions>
    )}
  </WithSelectedVenue>
)
