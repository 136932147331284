import React from 'react'
import { keys } from '../../config/keys'
import {
  LocationAutoComplete,
  SearchResults,
  ResultFilter,
  ConfirmModal,
} from './components'
import { View, StyleSheet, ScrollView } from 'react-native-web'
import { withApollo } from 'react-apollo'
import {
  GET_VENUES_BY_PLACE_IDS,
  GET_INFO_BY_PLACE_ID,
  CREATE_VENUE,
} from '../../gql'
import { colors, formatPeriods } from '../../functions'
import { LabelInput, FormButton, BaseText } from '../../components'
import debounce from 'lodash.debounce'
import { scale } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  innerWrapper: {
    display: 'flex',
    flex: 1,
  },
  topFilterWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  topFilterIndex: {
    zIndex: 1,
  },
  topFilterContent: {
    flex: 1,
    marginHorizontal: 10,
  },
  resultFilterContainer: {
    flex: 2,
  },
  resultsContainer: {
    flex: 1,
  },
  searchClearButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchClearButton: {
    height: 30,
    width: 'calc(50% - 10px)',
    borderRadius: 10,
  },
  summaryContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addVenuesButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  addButtonStyle: {
    marginTop: 15,
  },
})

class BulkCreateVenueForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gmapsLoaded: false,
      address: '',
      category: '',
      name: '',
      bounds: null,
      resultFilter: '',
      disableButtons: false,
      errors: [],
      noResultFound: false,
    }

    this.debouncedFilter = null
    this.searchResultsComponent = null
  }

  componentDidMount() {
    // we are asynchronously injected the google maps script so as it's only needed in this component
    // https://github.com/hibiken/react-places-autocomplete/issues/195#issuecomment-382596430
    // add a function to the window that google maps can use as a callback once loaded
    window.initMap = this._initMap
    const key = keys.google.places
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl)
  }

  componentWillUnmount() {
    // remove all 'maps.googleapis.com' scripts injected into the document
    // hat tip for removing injected scripts: https://stackoverflow.com/a/9469983/5045662
    const tags = document.getElementsByTagName('script')
    for (let i = tags.length; i >= 0; i--) {
      //search backwards within nodelist for matching elements to remove
      if (
        tags[i] &&
        tags[i].getAttribute('src') != null &&
        tags[i].getAttribute('src').includes('maps.googleapis.com')
      )
        tags[i].parentNode.removeChild(tags[i]) //remove element by calling parentNode.removeChild()
    }
    // set the google.maps instance on the window to null
    window.google.maps = null
  }

  _initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }

  _handleAddressChange = address => {
    this.setState({ address })
  }

  _handleCategoryChange = (event, category) => {
    this.setState({ category })
  }

  _handleNameChange = (event, name) => {
    this.setState({ name })
  }

  _handleResultFilterChange = resultFilter => {
    if (this.debouncedFilter) {
      this.debouncedFilter.cancel()
    }

    this.debouncedFilter = debounce(() => {
      this.setState({ resultFilter })
    }, 200)

    this.debouncedFilter()
  }

  _handleSelectLocation = address => {
    var service = new window.google.maps.Geocoder()
    service.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (status !== 'OK' || (!results && !results[0])) {
          return
        }

        let sw = new window.google.maps.LatLng(
          results[0].geometry.viewport.getSouthWest().lat(),
          results[0].geometry.viewport.getSouthWest().lng(),
        )
        let ne = new window.google.maps.LatLng(
          results[0].geometry.viewport.getNorthEast().lat(),
          results[0].geometry.viewport.getNorthEast().lng(),
        )

        console.log('sw', sw.lat(), sw.lng())
        console.log('ne', ne.lat(), ne.lng())
        this.setState({ bounds: new window.google.maps.LatLngBounds(sw, ne) })
      },
    )
  }

  _validateForm = () => {
    const category = this.state.category
    const placeName = this.state.name || ''
    const bounds = this.state.bounds
    this.setState({ errors: [] })
    let errors = []

    if (!bounds) {
      errors.push('Neighborhood, City or Zip is required!')
    }
    if (!category && !placeName) {
      errors.push('Either Category or Place Name is required!')
    }
    this.setState({ errors: errors })
  }

  _handleClear = () => {
    this.setState({
      bounds: null,
      address: '',
      category: '',
      name: '',
      resultFilter: '',
      errors: [],
      noResultFound: false,
    })
    this.searchResultsComponent.setState({
      suggestions: [],
      newVenues: [],
      addedVenues: [],
    })
    this.locationAutoComplete.clear()
  }

  _handleSearch = () => {
    const category = this.state.category
    const placeName = this.state.name || ''
    const bounds = this.state.bounds
    this.searchResultsComponent.setState({
      suggestions: [],
      newVenues: [],
      addedVenues: [],
    })
    this.setState({ noResultFound: false })
    this._validateForm()

    if (this.state.errors.length) {
      return
    }

    this.setState({ disableButtons: true })
    var service = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    )
    service.nearbySearch(
      {
        bounds: bounds,
        keyword: category,
        name: placeName,
      },
      (results, status, pagination) => {
        if (!results.length) {
          this.setState({ noResultFound: true, disableButtons: false })
          return
        }

        let tempSuggestions = this.formatSuggestions(results)
        let tempNewVenues = []
        let tempAddedVenues = []

        this.props.client
          .query({
            query: GET_VENUES_BY_PLACE_IDS,
            variables: {
              placeIds: tempSuggestions.map(({ placeId }) => placeId),
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            if (data) {
              tempSuggestions.forEach(suggestion => {
                const foundVenue = data.allVenues.find(
                  venue => suggestion.placeId === venue.placeId,
                )
                if (foundVenue) {
                  suggestion.venueId = foundVenue.id
                  tempAddedVenues.push(suggestion)
                } else {
                  tempNewVenues.push(suggestion)
                }
              })
            }

            let suggestions = this.searchResultsComponent.state.suggestions.concat(
              tempSuggestions,
            )
            let addedVenues = this.searchResultsComponent.state.addedVenues.concat(
              tempAddedVenues,
            )
            let newVenues = this.searchResultsComponent.state.newVenues.concat(
              tempNewVenues,
            )

            this.searchResultsComponent.setState(
              {
                suggestions,
                addedVenues,
                newVenues,
              },
              () => {
                if (pagination.hasNextPage) {
                  pagination.nextPage()
                } else {
                  console.log('Total suggestions', suggestions.length)
                  this.setState({ disableButtons: false })
                }
              },
            )
          })
          .catch(() => {
            this.setState({ disableButtons: false })
          })
      },
    )
  }

  _handleSubmit = () => {
    this.setState({ disableButtons: true })

    const venues = this.searchResultsComponent.state.newVenues.filter(
      ({ checked }) => checked,
    )

    Promise.all(
      venues.map(({ placeId }) => {
        return this.props.client
          .query({
            query: GET_INFO_BY_PLACE_ID,
            variables: {
              placeId: placeId,
            },
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            const info = data.getInfoByPlaceId

            return this.props.client.mutate({
              mutation: CREATE_VENUE,
              variables: {
                placeId: info.placeId,
                name: info.name,
                address: info.address,
                phoneNumber: info.phoneNumber,
                intlPhoneNumber: info.intlPhoneNumber,
                website: info.website,
                geoloc: info.geoloc,
                hoursText: info.hoursText,
                hours: info.googleInfo.periods
                  ? formatPeriods(info.googleInfo.periods)
                  : [],
              },
              fetchPolicy: 'no-cache',
            })
          })
      }),
    )
      .then(() => {
        this.setState({ disableButtons: false })
      })
      .then(this._handleSearch)
      .catch(() => {
        this.setState({ disableButtons: false })
      })
  }

  formatSuggestions(suggestions) {
    suggestions.forEach((p, idx) => {
      p.placeId = p.place_id
      p.active = false
      p.index = idx
      p.formattedSuggestion = {
        mainText: p.name,
        secondaryText: p.vicinity,
        rating: p.rating,
        priceLevel: p.price_level,
      }
      p.matchedSubstrings = p.matched_substrings
      p.checked = false
    })

    return suggestions
  }

  render() {
    return (
      <View style={s.wrapper}>
        {this.state.gmapsLoaded && (
          <View style={s.innerWrapper}>
            <View style={[s.topFilterWrapper, s.topFilterIndex]}>
              <View style={[s.topFilterContent]}>
                <LocationAutoComplete
                  onChange={this._handleAddressChange}
                  onSelect={this._handleSelectLocation}
                  ref={x => (this.locationAutoComplete = x)}
                  disabled={this.state.disableButtons}
                />
              </View>
              <View style={s.topFilterContent}>
                <LabelInput
                  collapseLabel={true}
                  labelWidth={'100%'}
                  label={'Category'}
                  onChangeText={this._handleCategoryChange}
                  autoComplete={'off'}
                  placeholder={'Category'}
                  value={this.state.category}
                  disabled={this.state.disableButtons}
                />
              </View>
              <View style={s.topFilterContent}>
                <LabelInput
                  collapseLabel={true}
                  labelWidth={'100%'}
                  label={'Place Name'}
                  onChangeText={this._handleNameChange}
                  autoComplete={'off'}
                  placeholder={'Place Name'}
                  value={this.state.name}
                  disabled={this.state.disableButtons}
                />
              </View>
            </View>
            <View style={s.topFilterWrapper}>
              <View style={[s.topFilterContent, s.resultFilterContainer]}>
                <ResultFilter
                  onChangeText={this._handleResultFilterChange}
                  disabled={this.state.disableButtons}
                />
              </View>
              <View style={[s.topFilterContent, s.searchClearButtonContainer]}>
                <FormButton
                  text={'Clear'}
                  outerStyle={s.searchClearButton}
                  textColor={colors.pink}
                  disabled={this.state.disableButtons}
                  handlePress={this._handleClear}
                />
                <FormButton
                  text={'Search'}
                  outerStyle={s.searchClearButton}
                  textColor={colors.teal}
                  disabled={this.state.disableButtons}
                  handlePress={this._handleSearch}
                  isSubmitting={this.state.disableButtons}
                />
              </View>
            </View>
            {this.state.noResultFound && (
              <View style={s.summaryContainer}>
                <BaseText
                  fs={scale(14)}
                  lh={scale(16)}
                  weight={'normal'}
                  children={`No venues found...`}
                  pt={20}
                  pb={5}
                />
              </View>
            )}
            {this.searchResultsComponent &&
              this.searchResultsComponent.state.suggestions.length > 0 &&
              !this.state.disableButtons && (
                <View style={s.summaryContainer}>
                  <BaseText
                    fs={scale(14)}
                    lh={scale(16)}
                    weight={'normal'}
                    children={`Showing ${
                      this.searchResultsComponent.state.suggestions.length
                    } search results including ${
                      this.searchResultsComponent.state.newVenues.length
                    } New-To-Barcard and ${
                      this.searchResultsComponent.state.addedVenues.length
                    } venues already on BCVM`}
                    pt={20}
                    pb={5}
                  />
                </View>
              )}
            {this.state.errors.length > 0 &&
              this.state.errors.map((err, i) => (
                <View style={s.summaryContainer} key={i}>
                  <BaseText
                    fs={14}
                    ta={'center'}
                    color={'pink'}
                    children={err}
                    pt={5}
                  />
                </View>
              ))}
            <View style={s.resultsContainer}>
              <SearchResults
                ref={x => (this.searchResultsComponent = x)}
                filter={this.state.resultFilter}
                onCheckedItemChanged={() => {
                  this.forceUpdate()
                }}
                disabled={this.state.disableButtons}
              />
            </View>
            {this.searchResultsComponent &&
              this.searchResultsComponent.state.newVenues.length > 0 && (
                <React.Fragment>
                  <ConfirmModal
                    ref={x => (this.confirmModal = x)}
                    message={`Are you sure you want to create ${
                      this.searchResultsComponent.state.newVenues.filter(
                        x => x.checked,
                      ).length
                    } venues?`}
                    onConfirm={this._handleSubmit}
                  />
                  <View style={s.addVenuesButtonWrapper}>
                    <FormButton
                      text={'Add Selected Venues'}
                      outerStyle={s.addButtonStyle}
                      textColor={colors.teal}
                      disabled={
                        this.searchResultsComponent.state.newVenues.filter(
                          x => x.checked,
                        ).length === 0 || this.state.disableButtons
                      }
                      handlePress={() => {
                        this.confirmModal.showModal()
                      }}
                      isSubmitting={this.state.disableButtons}
                    />
                  </View>
                </React.Fragment>
              )}
          </View>
        )}
      </View>
    )
  }
}

export default withApollo(BulkCreateVenueForm)
