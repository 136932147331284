import React from 'react'
import { Svg, Path, G } from 'react-native-svg'
import { scale } from '../../functions'

const height = 135.525
const width = 148
const viewBox = `0 0 ${width} ${height}`

export const PiggyBank = () => (
  <Svg height={scale(height)} width={scale(width)} viewBox={viewBox}>
    <G transform="translate(3496.5 16357.027)">
      <Path
        d="M84,128.515a12,12,0,0,1-12-12v-2.425a75.216,75.216,0,0,1-20-.539v2.965a12,12,0,0,1-12,12H33a12,12,0,0,1-12-12v-13a12.01,12.01,0,0,1,.372-2.975C8.255,90.467,0,75.821,0,59.514c0-30.376,28.654-55,64-55A73.273,73.273,0,0,1,86.24,7.926C92.8,3.354,101.766-1.228,109.864.3c2.641.577,2.612,2.746,1.109,5.794-1.465,2.981-4.617,6.781-7.621,10.042a56.211,56.211,0,0,1,21.142,25.378H129a12,12,0,0,1,12,12v13a12,12,0,0,1-12,12h-4.921a56.565,56.565,0,0,1-21.085,24.617c0,.128.006.255.006.383v13a12,12,0,0,1-12,12Z"
        transform="translate(-3493 -16353.515)"
        fill="none"
        stroke="#21212a"
        strokeWidth="7"
      />
      <Path
        d="M193.6,171.044h-8.618a5.6,5.6,0,0,1,0-11.191h15.242a3.859,3.859,0,1,0,0-7.718h-7.042v-5.177a3.859,3.859,0,1,0-7.718,0v5.177h-.45a13.313,13.313,0,1,0,0,26.626h8.618a5.6,5.6,0,1,1,0,11.191h-15.5a3.859,3.859,0,0,0,0,7.718h7.332v5.274a3.859,3.859,0,1,0,7.718,0V197.67h.643a13.313,13.313,0,0,0-.225-26.626Z"
        transform="translate(-3618.187 -16468.59)"
        fill="#21212a"
      />
    </G>
  </Svg>
)
