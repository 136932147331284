import React from 'react'
import { StyleSheet, View, TouchableOpacity, ScrollView } from 'react-native'
import { colors } from '../../functions'
import Modal from 'modal-enhanced-react-native-web'
import { DimensionsConsumer } from '../../lib'
import {
  BaseText,
  FormButton,
  MCIcon,
  HoverableTextTouchHighlight,
} from '../../components'

const s = StyleSheet.create({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    flex: 1,
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    width: '100%',
    height: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 23,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectItem: {
    backgroundColor: colors.primary3,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch',
  },
  hoverItem: {
    backgroundColor: colors.primary1,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch',
  },
  selectedItem: {
    color: colors.teal,
  },
  scrollView: {
    width: '100%',
  },
  textHover: {
    color: colors.white,
  },
})

export class DropdownModal extends React.Component {
  state = {
    modalIsVisible: false,
    selectedItems: this.props.items,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedItems: this.props.items,
    })
  }

  _handleExitPrompt = async () => {
    this.setState({ modalIsVisible: false })
  }

  _handleSelect = async selected => {
    const item = this.state.selectedItems.find(
      ({ item }) => selected.id === item.id,
    )
    item.selected = !item.selected

    this.setState({ selectedItems: this.state.selectedItems })
  }

  _handleSubmit = async () => {
    this.props.handleSubmit(
      this.state.selectedItems
        .filter(({ selected }) => selected)
        .map(({ item }) => item),
    )
    this.setState({ modalIsVisible: false })
  }

  render() {
    const { modalIsVisible, selectedItems } = this.state
    const {
      headerContainerStyle,
      closeIconColor,
      title,
      selectItemStyle,
      selectedItemStyle,
      hoverItemStyle,
      textHoverStyle,
    } = this.props

    return (
      <DimensionsConsumer>
        {({ width, height }) => {
          return (
            <React.Fragment>
              {this.props.render(() => this.setState({ modalIsVisible: true }))}

              <Modal
                isVisible={modalIsVisible}
                onBackdropPress={() => this.setState({ modalIsVisible: false })}
              >
                <View style={[s.modal]}>
                  <View
                    style={[
                      s.headerContainer,
                      headerContainerStyle && headerContainerStyle,
                    ]}
                  >
                    <TouchableOpacity
                      activeOpacity={0.7}
                      onPress={this._handleExitPrompt}
                    >
                      <MCIcon
                        name="close"
                        size={24}
                        color={closeIconColor || colors.white}
                      />
                    </TouchableOpacity>
                    <BaseText
                      fs={20}
                      lh={27}
                      weight={'light'}
                      children={title || ''}
                    />
                  </View>

                  <View style={s.modalMessageContainer}>
                    <ScrollView style={s.scrollView}>
                      {selectedItems.map(({ item, selected }, index) => (
                        <HoverableTextTouchHighlight
                          key={index}
                          text={
                            <React.Fragment>
                              {item.name}
                              {selected && (
                                <MCIcon
                                  name={'check'}
                                  color={colors.teal}
                                  size={16}
                                  style={{ marginLeft: 5 }}
                                />
                              )}
                            </React.Fragment>
                          }
                          textStyle={[
                            selected && s.selectedItem,
                            selectedItemStyle && selectedItemStyle,
                          ]}
                          style={[
                            s.selectItem,
                            selectItemStyle && selectItemStyle,
                          ]}
                          hoverStyle={[
                            s.hoverItem,
                            hoverItemStyle && hoverItemStyle,
                          ]}
                          textHoverStyle={[
                            s.textHover,
                            textHoverStyle && textHoverStyle,
                          ]}
                          onPress={() => this._handleSelect(item)}
                        />
                      ))}
                    </ScrollView>
                  </View>

                  <View style={s.modalControls}>
                    <View style={s.buttonsContainer}>
                      <FormButton
                        text={'Submit'}
                        outerStyle={{ marginRight: 0 }}
                        textColor={colors.teal}
                        handlePress={this._handleSubmit}
                      />
                    </View>
                  </View>
                </View>
              </Modal>
            </React.Fragment>
          )
        }}
      </DimensionsConsumer>
    )
  }
}
