import React from 'react'
import { colors } from '../../../functions'
import { Input, BaseText } from '../../../components'

export const BasicAccountInfo = ({ additionalProps }) => (
  <React.Fragment>
    <Input
      name="organizationName"
      placeholder={'Organization Name'}
      autoCapitalize={'none'}
      onChangeText={additionalProps.setFieldValue}
      onBlur={additionalProps.setFieldTouched}
      value={additionalProps.values.organizationName}
      editable={!additionalProps.isSubmitting}
      disabled={additionalProps.formSubmitted}
      borderColor={additionalProps.formSubmitted ? colors.blackgray : undefined}
      borderHeight={2}
      autoComplete={'off'}
    />
    {additionalProps.errors.organizationName && (
      <BaseText
        fs={12}
        color={'pink'}
        children={additionalProps.errors.organizationName}
      />
    )}
    <Input
      name="userFirstName"
      placeholder={'First Name'}
      autoCapitalize={'none'}
      onChangeText={additionalProps.setFieldValue}
      onBlur={additionalProps.setFieldTouched}
      value={additionalProps.values.userFirstName}
      editable={!additionalProps.isSubmitting}
      disabled={additionalProps.formSubmitted}
      borderColor={additionalProps.formSubmitted ? colors.blackgray : undefined}
      borderHeight={2}
      autoComplete={'off'}
    />
    {additionalProps.errors.userFirstName && (
      <BaseText
        fs={12}
        color={'pink'}
        children={additionalProps.errors.userFirstName}
      />
    )}
    <Input
      name="userLastName"
      placeholder={'Last Name'}
      autoCapitalize={'none'}
      onChangeText={additionalProps.setFieldValue}
      onBlur={additionalProps.setFieldTouched}
      value={additionalProps.values.userLastName}
      editable={!additionalProps.isSubmitting}
      disabled={additionalProps.formSubmitted}
      borderColor={additionalProps.formSubmitted ? colors.blackgray : undefined}
      borderHeight={2}
      autoComplete={'off'}
    />
    {additionalProps.errors.userLastName && (
      <BaseText
        fs={12}
        color={'pink'}
        children={additionalProps.errors.userLastName}
      />
    )}
    <Input
      name="email"
      placeholder={'Email'}
      autoCapitalize={'none'}
      keyboardType={'email-address'}
      onChangeText={additionalProps.setFieldValue}
      onBlur={additionalProps.setFieldTouched}
      value={additionalProps.values.email}
      editable={!additionalProps.isSubmitting}
      disabled={additionalProps.formSubmitted}
      borderColor={additionalProps.formSubmitted ? colors.blackgray : undefined}
      borderHeight={2}
      autoComplete={'off'}
    />
    {additionalProps.errors.email && (
      <BaseText
        fs={12}
        color={'pink'}
        children={additionalProps.errors.email}
      />
    )}
    <Input
      name="password"
      placeholder={'Create Password'}
      autoCapitalize={'none'}
      onChangeText={additionalProps.setFieldValue}
      onBlur={additionalProps.setFieldTouched}
      value={additionalProps.values.password}
      editable={!additionalProps.isSubmitting}
      disabled={additionalProps.formSubmitted}
      borderColor={additionalProps.formSubmitted ? colors.blackgray : undefined}
      borderHeight={2}
      secureTextEntry={!additionalProps.formSubmitted}
      autoComplete={'new-password'}
    />
    {additionalProps.errors.password && (
      <BaseText
        fs={12}
        color={'pink'}
        children={additionalProps.errors.password}
      />
    )}
    {additionalProps.formSubmitted ? (
      <React.Fragment />
    ) : (
      <Input
        name="confirmPassword"
        placeholder={'Confirm Password'}
        autoCapitalize={'none'}
        onChangeText={additionalProps.setFieldValue}
        onBlur={additionalProps.setFieldTouched}
        value={additionalProps.values.confirmPassword}
        editable={!additionalProps.isSubmitting}
        borderColor={
          additionalProps.formSubmitted ? colors.blackgray : undefined
        }
        borderHeight={2}
        secureTextEntry={!additionalProps.formSubmitted}
        autoComplete={'new-password'}
      />
    )}
    {!additionalProps.formSubmitted &&
      additionalProps.errors.confirmPassword && (
        <BaseText
          fs={12}
          color={'pink'}
          children={additionalProps.errors.confirmPassword}
        />
      )}
  </React.Fragment>
)
