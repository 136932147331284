import React from 'react'
import * as t from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { ImagePlaceholder } from '../../components'
import { colors, hexToRgba } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    borderRadius: 10,
  },
  /* eslint-disable react-native/no-unused-styles */
  square: {
    width: 182,
    height: 182,
  },
  card: {
    width: 240 * 1.9,
    height: 240,
  },
  wide: {
    width: 374,
    height: 174,
  },
  cover: {
    width: 378,
    height: 378,
  },
  /* eslint-enable */
})

export const PhotoPlaceholder = ({ size, wrapperStyle }) => (
  <View style={[s.wrapper, size && s[size], wrapperStyle && wrapperStyle]}>
    <ImagePlaceholder />
  </View>
)

PhotoPlaceholder.propTypes = {
  wrapperStyle: t.object,
  size: t.oneOf(['card', 'square', 'wide', 'cover']),
}
