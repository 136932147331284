import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Query, Mutation } from 'react-apollo'
import Modal from 'modal-enhanced-react-native-web'
import { colors, fonts, hexToRgba } from '../../../functions'
import { DimensionsConsumer } from '../../../lib'
import {
  ALL_EVENT_PHOTOS,
  EVENT_QUERY,
  UPDATE_EVENT_PHOTO,
  UPDATE_EVENTS_PHOTOS,
} from '../../../gql'
import {
  BaseText,
  FormButton,
  HoverableText,
  PhotoCardSquare,
  PhotoUploader,
  PhotoPlaceholder,
  ScrollColumn,
} from '../../../components'

const WIDTH = 653
const PHOTOS_HEIGHT = 450

const s = StyleSheet.create({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: WIDTH,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  photosContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    // width: WIDTH,
    height: PHOTOS_HEIGHT,
    flex: 1,
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 23,
  },
  scrollRowContentContainerStlye: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: 22, // to account for marginRight: 22 on PhotoCardSquare
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    paddingTop: 10,
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingLeft: 30,
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginTop: 7,
    marginBottom: 14,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.secondary2,
    zIndex: 6,
  },
  dim: {
    opacity: 0.7,
  },
  textHoverStyle: {
    color: colors.white,
    opacity: 1,
  },
})

const SectionTitle = props => (
  <BaseText
    fs={20}
    lh={27}
    mb={7}
    weight={'semibold'}
    color={'secondary3'}
    {...props}
  />
)

const currPhotoActions = [
  {
    label: 'Set as Cover',
    action: 'setCover',
  },
  {
    label: 'Set as Card',
    action: 'setCard',
  },
  {
    label: 'Remove from Event',
    action: 'removeFromEvent',
  },
]

const allPhotoActions = [
  {
    label: 'Add to Event',
    action: 'addToEvent',
  },
]

export class EventPhotoOptionsModal extends React.Component {
  state = {
    modalIsVisible: false,
    showLarge: false,
    showAll: false,
    aspectRatio: 1,
  }

  _handleSetPhoto = () => {
    this.setState({ modalIsVisible: true })
  }

  render() {
    const { eventId, venueId } = this.props
    const { modalIsVisible, showLarge, showAll, aspectRatio } = this.state

    return (
      <DimensionsConsumer>
        {({ width, height }) => {
          const isSmall = width < 673 // 653 + 10 px on each side
          const modalWidth = width - 20
          const photosHeightCalc = height * 0.5
          const photosHeight =
            PHOTOS_HEIGHT > photosHeightCalc ? photosHeightCalc : PHOTOS_HEIGHT
          return (
            <Mutation
              mutation={UPDATE_EVENTS_PHOTOS}
              refetchQueries={() => [
                {
                  query: ALL_EVENT_PHOTOS,
                  variables: { eventId, venueId },
                },
              ]}
            >
              {(updateEventsPhotos, { error, loading }) => (
                <Mutation
                  mutation={UPDATE_EVENT_PHOTO}
                  refetchQueries={() => [
                    {
                      query: ALL_EVENT_PHOTOS,
                      variables: { eventId, venueId },
                    },
                    {
                      query: EVENT_QUERY,
                      variables: { id: eventId },
                    },
                  ]}
                >
                  {(updateEventPhoto, { error, loading }) => (
                    <React.Fragment>
                      <View style={s.buttonsContainer}>
                        <FormButton
                          text={'Set Photo'}
                          textColor={colors.teal}
                          handlePress={this._handleSetPhoto}
                        />
                      </View>
                      <Modal
                        isVisible={modalIsVisible}
                        onBackdropPress={() =>
                          this.setState({ modalIsVisible: false })
                        }
                      >
                        <View
                          style={[
                            s.modal,
                            isSmall && { width: modalWidth, paddingRight: 0 },
                          ]}
                        >
                          <View style={s.modalMessageContainer}>
                            <View style={s.sectionHeader}>
                              <View
                                style={[s.row, isSmall && { paddingLeft: 10 }]}
                              >
                                <SectionTitle children={'Event Photos'} />
                                <View style={s.rowCenter}>
                                  <View
                                    style={[s.rowCenter, { marginRight: 20 }]}
                                  >
                                    <HoverableText
                                      disabled={showAll}
                                      outerContainerStyle={s.touchWrapper}
                                      textStyle={[s.text, showAll && s.dim]}
                                      textHoverStyle={s.textHoverStyle}
                                      children={'Event Photos'}
                                      onPress={() =>
                                        this.setState({ showAll: false })
                                      }
                                    />
                                    <BaseText
                                      fs={13}
                                      lh={18}
                                      children={' | '}
                                    />
                                    <HoverableText
                                      disabled={!showAll}
                                      outerContainerStyle={s.touchWrapper}
                                      textStyle={[s.text, !showAll && s.dim]}
                                      textHoverStyle={s.textHoverStyle}
                                      children={'All Photos'}
                                      onPress={() =>
                                        this.setState({ showAll: true })
                                      }
                                    />
                                  </View>
                                </View>
                              </View>
                              <View style={s.lineBreak} />
                            </View>
                            <Query
                              query={ALL_EVENT_PHOTOS}
                              variables={{ venueId, eventId }}
                              fetchPolicy={'cache-and-network'}
                            >
                              {({ data, loading, error }) => {
                                if (loading) {
                                  return <ActivityIndicator />
                                }
                                if (error) {
                                  console.log('error', error)
                                  // TO DO: we should show an error screen here...
                                  return <ActivityIndicator />
                                }
                                const eventPhotoIds =
                                  data &&
                                  data.eventPhotos &&
                                  data.eventPhotos.map(x => x.id)

                                const photosToDisplay = showAll
                                  ? data.allEventPhotos
                                  : data.eventPhotos

                                const photoActions = showAll
                                  ? allPhotoActions
                                  : currPhotoActions
                                return (
                                  <View
                                    style={[
                                      s.photosContainer,
                                      { height: photosHeight },
                                    ]}
                                  >
                                    <ScrollColumn
                                      contentContainerStlye={[
                                        s.scrollRowContentContainerStlye,
                                        isSmall && { paddingLeft: 0 },
                                      ]}
                                    >
                                      {photosToDisplay &&
                                      photosToDisplay.length > 0 ? (
                                        photosToDisplay.map((p, index) => (
                                          <PhotoCardSquare
                                            key={`${p.id}-${index}`}
                                            image={p}
                                            actions={photoActions}
                                            size={
                                              showLarge ? 'medium' : 'small'
                                            }
                                            //featured={p.cover}
                                            handleAction={async (
                                              action,
                                              image,
                                            ) => {
                                              console.log('image', image)
                                              if (
                                                action === 'setCover' ||
                                                action === 'setCard'
                                              ) {
                                                console.log('setCover', image)
                                                try {
                                                  // to do:
                                                  // remove previous photo that was cover photo
                                                  // pass in current cover & card photo props (maybe just card)
                                                  const res = await updateEventPhoto(
                                                    {
                                                      variables: {
                                                        ...image,
                                                        ...(action ===
                                                          'setCover' && {
                                                          cover: true,
                                                        }),
                                                        ...(action ===
                                                          'setCard' && {
                                                          card: true,
                                                          cardWide: true,
                                                        }),
                                                        eventId: eventId,
                                                        // need to pass in ALL the photos for the event...
                                                        // need to remove the last photo that was a cover photo
                                                        photosIds: [
                                                          ...eventPhotoIds,
                                                          image.id,
                                                        ],
                                                      },
                                                    },
                                                  )
                                                  console.log(
                                                    'res.data',
                                                    res.data,
                                                  )
                                                } catch (error) {
                                                  console.log('error', error)
                                                }
                                                // updatePhoto
                                              } else if (
                                                action === 'addToEvent' ||
                                                action === 'removeFromEvent'
                                              ) {
                                                const photosIds =
                                                  action === 'addToEvent'
                                                    ? [
                                                        ...eventPhotoIds,
                                                        image.id,
                                                      ]
                                                    : eventPhotoIds.filter(
                                                        x => x !== image.id,
                                                      )
                                                try {
                                                  const res = await updateEventsPhotos(
                                                    {
                                                      variables: {
                                                        id: eventId,
                                                        photosIds,
                                                      },
                                                    },
                                                  )
                                                  console.log(
                                                    'res.data',
                                                    res.data,
                                                  )
                                                } catch (error) {
                                                  console.log('error', error)
                                                }
                                              } else {
                                                console.log('prop not mapped')
                                              }
                                            }}
                                          />
                                        ))
                                      ) : (
                                        <PhotoPlaceholder size={'square'} />
                                      )}
                                    </ScrollColumn>
                                  </View>
                                )
                              }}
                            </Query>
                          </View>
                          <View style={[s.row, { paddingLeft: 0 }]}>
                            <View style={[s.rowCenter, { marginRight: 20 }]}>
                              <HoverableText
                                disabled={showAll}
                                outerContainerStyle={s.touchWrapper}
                                textStyle={[s.text, aspectRatio !== 1 && s.dim]}
                                textHoverStyle={s.textHoverStyle}
                                children={'1:1 Ratio'}
                                onPress={() =>
                                  this.setState({ aspectRatio: 1 })
                                }
                              />
                              <BaseText fs={13} lh={18} children={' | '} />
                              <HoverableText
                                disabled={showAll}
                                outerContainerStyle={s.touchWrapper}
                                textStyle={[
                                  s.text,
                                  aspectRatio !== 1.9 && s.dim,
                                ]}
                                textHoverStyle={s.textHoverStyle}
                                children={'16:9 Ratio'}
                                onPress={() =>
                                  this.setState({ aspectRatio: 1.9 })
                                }
                              />
                              <BaseText fs={13} lh={18} children={' | '} />
                              <HoverableText
                                disabled={showAll}
                                outerContainerStyle={s.touchWrapper}
                                textStyle={[s.text, aspectRatio !== 0 && s.dim]}
                                textHoverStyle={s.textHoverStyle}
                                children={'Free Ratio'}
                                onPress={() =>
                                  this.setState({ aspectRatio: 0 })
                                }
                              />
                            </View>
                          </View>
                          <View style={s.modalControls}>
                            <View style={s.buttonsContainer}>
                              <PhotoUploader
                                aspectRatio={aspectRatio}
                                buttonText={'Upload File'}
                                buttonColor={colors.pink}
                                maxFiles={1} // the max number of files the user can upload at a time
                                photoType={'EventPhoto'} // enum('EventPhoto', 'VenuePhoto')
                                // additional props we want to send along with the graphql mutation
                                photoProps={{
                                  venueId: venueId,
                                  // normally we don't want to do this (since a photo can be used for multiple
                                  // events), but it's ok here since we're creating and not updating
                                  eventsIds: [eventId],
                                  cover: null,
                                  card: null,
                                  cardWide: null,
                                  published: true, // chanage to true soon
                                }}
                                // do we want to set an optimisticResponse in apollo?
                                optimisticUpdates={false} // disabling for now since it's intented to update the cache in a different way then we have the queries set up
                                // query we want to write cache w/ and for apollo to refetch
                                refetchQuery={{
                                  query: ALL_EVENT_PHOTOS,
                                  variables: { venueId, eventId },
                                }}
                              />
                            </View>
                            <FormButton
                              text={'Confirm'}
                              outerStyle={{ marginRight: 0 }}
                              textColor={colors.teal}
                              handlePress={() =>
                                this.setState({ modalIsVisible: false })
                              }
                            />
                          </View>
                        </View>
                      </Modal>
                    </React.Fragment>
                  )}
                </Mutation>
              )}
            </Mutation>
          )
        }}
      </DimensionsConsumer>
    )
  }
}
