import React from 'react'
import { MIcon, Button } from '../../../components'
import { View, TextInput } from 'react-native'
import { colors } from '../../../functions'
import { StyleSheet } from 'react-native'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    maxWidth: 550,
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    backgroundColor: colors.blackgray,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    marginRight: 10,
    paddingHorizontal: 10,
  },
  searchBox: {
    color: colors.white,
    flex: 1,
    display: 'flex',
    marginLeft: 5,
  },
  clearFilter: {
    backgroundColor: colors.white80,
    borderRadius: 12,
    width: 130,
    height: 40,
    marginBottom: 0,
  },
  clearFilterText: {
    color: colors.black,
    fontSize: 12,
  },
})

class EventFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
    }
  }

  _onChangeText = text => {
    this.setState({ text })

    if (this.props.onChangeText) {
      this.props.onChangeText(text)
    }
  }

  render() {
    return (
      <View style={s.wrapper}>
        <View style={s.innerWrapper}>
          <View style={s.searchWrapper}>
            <MIcon name={'search'} size={26} color={colors.white} />
            <TextInput
              name="search"
              placeholder={'Search for an event'}
              autoCapitalize={'none'}
              onChangeText={this._onChangeText}
              value={this.state.text}
              onBlur={() => {}}
              editable={true}
              disabled={false}
              style={s.searchBox}
              autoComplete={'off'}
            />
          </View>
          <Button
            text={'Clear filter'}
            buttonStyle={s.clearFilter}
            textStyle={s.clearFilterText}
            onPress={() => {
              this._onChangeText('')
            }}
          />
        </View>
      </View>
    )
  }
}

export default EventFilter
