import React from 'react'
import { View, StyleSheet } from 'react-native'
import { hashMap } from '../../functions'
import { AutoCompleteWeb, FormLabel } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelWrapper: {
    minWidth: 60,
  },
})

function mapOptions(options) {
  return options.map(x => {
    return {
      ...x,
      value: x.ageText,
      name: x.ageText,
      label: x.ageText,
    }
  })
}

/*
 * realistically, this can be configured to be a more resuable dropdown component,
 * similar to TagsInput and TimeInput (maybe create one for all 3), but for the sake of time,
 * this works for now
 */

export class AgeGroupInput extends React.Component {
  state = {
    opts: mapOptions(this.props.options),
    optsIdMap: hashMap(this.props.options, 'id'),
    optsNameMap: hashMap(this.props.options, 'ageText'),
  }
  handleSelect = (field, item) => {
    /*
     * should check to see that endTime > startTime
     * also need to add logic to pull endValue instead of value
     */
    this.props.onChange(field, item.id)
  }

  handleInput = (field, text) => {
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 7 && this.state.optsNameMap[text]) {
      this.handleSelect(field, this.state.optsNameMap[text])
    }
  }

  render() {
    const { opts, optsIdMap } = this.state
    const { name, label, value } = this.props
    const sValue = optsIdMap[value] ? optsIdMap[value].ageText : null

    return (
      <View style={s.wrapper}>
        <View style={s.labelWrapper}>
          <FormLabel children={label || 'Age Group'} mr={15} />
        </View>
        <AutoCompleteWeb
          items={opts}
          handleSelect={text => this.handleSelect(name, text)}
          handleInput={text => this.handleInput(name, text)}
          value={sValue ? sValue : ''}
          wrapperStyle={{ marginRight: 15 }}
          maxWidth={120}
        />
      </View>
    )
  }
}
