import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { getNumberOfFormLines, sanitizeValues } from '../../../functions'
import { CREATE_EVENT, UPDATE_EVENT_DETAILS, EVENT_QUERY } from '../../../gql'
import {
  ErrorsContainer,
  FormButtons,
  FormCheckbox,
  DateTimeInput,
  ResponsiveInput,
} from '../../../components'
import { DurationRow } from '../components'

export const Details = ({ initialValues, handleEventAdded }) => {
  return (
    <Mutation
      mutation={CREATE_EVENT}
      refetchQueries={mutationResult => [
        {
          query: EVENT_QUERY,
          variables: { id: mutationResult.data.createEvent.id },
        },
      ]}
    >
      {(createEvent, { error, loading }) => (
        <Mutation
          mutation={UPDATE_EVENT_DETAILS}
          // when this is uncommented, the cursor stays where i was editing
          // not sure if this is needed to properly update the cache :/ will investigate further.
          // also remember reading something about a 'Pure' component type implementation with Formik
          // should also investigate this tomorrow.
          refetchQueries={() => [
            { query: EVENT_QUERY, variables: { id: initialValues.id } },
          ]}
        >
          {(updateEventDetails, { error, loading }) => (
            <Formik
              enableReinitialize={true}
              initialValues={{
                ...initialValues,
                venueId: initialValues.venue.id, // gets defined for new events in EventForm
              }}
              validate={values => {
                let errors = {}
                if (!values.name || values.name === '') {
                  errors.name = 'A name for your event is required!'
                }
                if (values.date === values.endDate) {
                  errors.endDate = 'Start date cannot equal end date!'
                }
                if (!values.date) {
                  errors.date = 'A start date is required!'
                }
                if (!values.endDate) {
                  errors.endDate = 'End Date required!'
                } else if (values.endDate < values.date) {
                  errors.endDate =
                    "The end date can't before before the start date!"
                }
                // console.log('errors', errors)
                return errors
              }}
              onSubmit={async (values, actions) => {
                console.log('info form submit values', values)
                try {
                  // lets not insert names or descriptions that look like ' ' or ''
                  const variables = sanitizeValues(values, [
                    'name',
                    'description',
                  ])
                  // if we don't have an eventId, we need to create and event
                  const isNew = values.id === 'null'
                  const res = isNew
                    ? await createEvent({ variables })
                    : await updateEventDetails({ variables })

                  console.log(
                    `res.data.${isNew ? 'createEvent' : 'updateEventDetails'}`,
                    res.data,
                    res.data[isNew ? 'createEvent' : 'updateEventDetails'],
                  )
                  if (res && isNew) {
                    // send this event up so we can update the URL
                    handleEventAdded(res.data.createEvent.id)
                  }
                  actions.setSubmitting(false)
                } catch (error) {
                  console.log('error', error)
                  // actions.setErrors(parseAuthErrors(error.toString()))
                  actions.setSubmitting(false)
                }
              }}
              render={props => {
                const descLength = getNumberOfFormLines(
                  props.values.description,
                )
                return (
                  <React.Fragment>
                    <ResponsiveInput
                      label={'Event name'}
                      name={'name'}
                      onChangeText={props.setFieldValue}
                      onChangeTextOnlyOnBlur={true}
                      value={props.values.name}
                      autoComplete={'off'}
                    />
                    <DateTimeInput
                      label={'Start'}
                      name={'date'}
                      value={props.values.date}
                      onChange={props.setFieldValue}
                      wrapperStyle={{ marginTop: 15, zIndex: 1 }}
                    />
                    <DateTimeInput
                      label={'End'}
                      name={'endDate'}
                      value={props.values.endDate}
                      onChange={props.setFieldValue}
                    />
                    <DurationRow
                      startDate={props.values.date}
                      endDate={props.values.endDate}
                      wrapperStyle={{ marginBottom: 15 }}
                    />
                    <FormCheckbox
                      label={'Published'}
                      value={!props.values.hidden} // to do: change to {props.values.published}
                      onChange={value => props.setFieldValue('hidden', !value)} // to do: change to ('published', value)
                    />
                    <ResponsiveInput
                      label={'Description'}
                      name={'description'}
                      multiline={true}
                      numberOfLines={descLength}
                      onChangeText={props.setFieldValue}
                      onChangeTextOnlyOnBlur={true}
                      value={props.values.description || ''}
                      autoComplete={'off'}
                    />
                    <ErrorsContainer errors={props.errors} />

                    <FormButtons
                      disabled={!props.dirty || props.isSubmitting}
                      isSubmitting={props.isSubmitting}
                      handleSubmit={props.handleSubmit}
                      handleCancel={() => props.resetForm(initialValues)}
                    />
                  </React.Fragment>
                )
              }}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  )
}
