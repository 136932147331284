import React from 'react'
import * as t from 'prop-types'
import { StyleSheet, TouchableHighlight, View } from 'react-native'
import { colors } from '../../functions'

const s = StyleSheet.create({
  touchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary3,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class HoverableTouchHighlight extends React.Component {
  setStyles = styles => {
    this.root.setNativeProps({
      style: styles,
    })
  }

  render() {
    const {
      style,
      hoverStyle,
      underlayColor,
      children,
      onPress,
      ...rest
    } = this.props
    const touchStyles = [s.touchContainer, style]
    return (
      <TouchableHighlight
        underlayColor={underlayColor}
        ref={ref => (this.root = ref)}
        onMouseEnter={() => this.setStyles(hoverStyle)}
        onMouseLeave={() => this.setStyles(touchStyles)}
        style={touchStyles}
        onPress={onPress}
        {...rest}
      >
        <View style={s.innerContainer}>{children}</View>
      </TouchableHighlight>
    )
  }
}

HoverableTouchHighlight.propTypes = {
  /** the event handler */
  onPress: t.func.isRequired,
  /** the child or children to be wrapped inside the hoverable */
  children: t.node.isRequired,
  /** backgroundColor and style overrides for TouchableHighlight */
  style: t.oneOfType([t.object, t.array, t.number]).isRequired, // needs number for RN stylesheet reference
  /** backgroundColor and style overrides for hover state for TouchableHighlight */
  hoverStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  /** style overrides for the View that wraps children */
  innerContainerStyle: t.oneOfType([t.object, t.array, t.number]),
  /** TouchableHighlight's underlayColor */
  underlayColor: t.string,
}

HoverableTouchHighlight.defaultProps = {
  underlayColor: colors.primary3,
}

export default HoverableTouchHighlight
