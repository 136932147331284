// const width = '10px'
// const borderStyle = '1px solid #363636'
// const bgInactive = 'rgba(0,0,0,0)'
// const bgActive = 'rgba(0,0,0,1)'

// const kindOfWorkingStyles = `
// ::-webkit-scrollbar {
//   width: ${width};
//   height: ${width};
//   background-color: ${bgInactive};
//   -webkit-border-radius: 100px;
// }
// ::-webkit-scrollbar-thumb {
//   background: ${bgActive};
//   border: ${borderStyle};
//   -webkit-border-radius: 100px;
// }
// ::-webkit-scrollbar-thumb:active {
//   background: ${bgActive};
//   border: ${borderStyle};
//   -webkit-border-radius: 100px;
// }
// `
const scrollBarStyles = `
body {
  background-color: #17171C;
}
::-webkit-scrollbar {
  display: none;
}
input:focus {
  outline:none;
}
ul li{
  color: #fff;
}
#root {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}
`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = scrollBarStyles
} else {
  style.appendChild(document.createTextNode(scrollBarStyles))
}

// Inject stylesheet
document.head.appendChild(style)
