import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { ResponsiveContainer, BaseText, MIcon } from '../../../components'
import { colors } from '../../../functions'
import { scale } from '../../../functions'

const s = StyleSheet.create({
  sectionContainer: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingHorizontal: 20,
    paddingBottom: 17,
    zIndex: 1,
  },
  sectionContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1,
  },
  tile: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingTop: 15,
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 15,
  },
})

export const SubMenuTile = ({ title, subTitle, handleClick }) => (
  <TouchableOpacity onPressIn={handleClick}>
    <ResponsiveContainer
      style={s.sectionContainer}
      breakpoint={434} // 414 + 10 px padding on each side
      breakpointStyle={s.sectionContainerMobile}
    >
      <View style={s.tile}>
        <View style={s.textContainer}>
          <BaseText fs={24} lh={28} mb={5} weight={'600'} children={title} />
          <BaseText fs={12} lh={16} weight={'light'} children={subTitle} />
        </View>
        <View style={s.iconContainer}>
          <MIcon name={'chevron-right'} color={colors.white} size={scale(24)} />
        </View>
      </View>
    </ResponsiveContainer>
  </TouchableOpacity>
)
