import React from 'react'
import { StyleSheet, View } from 'react-native'
import { BaseText } from '../../components'
import { colors } from '../../functions'

const s = StyleSheet.create({
  textContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: colors.primary2,
  },
})

class Unauthorized extends React.Component {
  render() {
    return (
      <View style={s.textContainer}>
        <BaseText
          fs={24}
          lh={28}
          mb={5}
          weight={'600'}
          children={this.props.title || '401 Unauthorized'}
        />
      </View>
    )
  }
}

export default Unauthorized
