import gql from 'graphql-tag'
import { EventFragments } from './fragments'

export const ALL_EVENT_PHOTOS = gql`
  query($venueId: ID!, $eventId: ID!) {
    # gets the venue's uploaded event photos, that aren't currently assigned the current event
    allEventPhotos(
      filter: { venue: { id: $venueId }, events_none: { id: $eventId } }
      orderBy: createdAt_DESC
    ) {
      ...EventPhotoDetails
    }
    # gets the photos associated with the event
    eventPhotos: allEventPhotos(filter: { events_some: { id: $eventId } }) {
      ...EventPhotoDetails
    }
    # cover: allEventPhotos(
    #   filter: { events_some: { id: $eventId }, cover: true }
    # ) {
    #   ...EventPhotoDetails
    # }
    # card: allEventPhotos(
    #   filter: { events_some: { id: $eventId }, card: true }
    # ) {
    #   ...EventPhotoDetails
    # }
    # cardWide: allEventPhotos(
    #   filter: { events_some: { id: $eventId }, cardWide: true }
    # ) {
    #   ...EventPhotoDetails
    # }
  }
  ${EventFragments.photo}
`
export const CREATE_EVENT_PHOTO = gql`
  mutation(
    $filename: String!
    $handle: String!
    $mimetype: String!
    $size: Int!
    $url: String!
    $cover: Boolean
    $card: Boolean
    $cardWide: Boolean
    $venueId: ID!
    $eventsIds: [ID!]!
  ) {
    createEventPhoto(
      filename: $filename
      handle: $handle
      mimetype: $mimetype
      size: $size
      url: $url
      cover: $cover
      card: $card
      cardWide: $cardWide
      venueId: $venueId
      eventsIds: $eventsIds
    ) {
      ...EventPhotoDetails
    }
  }
  ${EventFragments.photo}
`

/*
 * update attributes on an event photo (card, cardWide, cover)
 * we can't set the eventsIds here bc we may override another event
 */

export const UPDATE_EVENTS_PHOTOS = gql`
  mutation($id: ID!, $photosIds: [ID!]!) {
    updateEventsPhotos: updateEvent(id: $id, photosIds: $photosIds) {
      id
      photos {
        ...EventPhotoDetails
      }
    }
  }
  ${EventFragments.photo}
`

export const UPDATE_EVENT_PHOTO = gql`
  mutation($id: ID!, $cover: Boolean, $card: Boolean, $cardWide: Boolean) {
    updateEventPhoto(id: $id, cover: $cover, card: $card, cardWide: $cardWide) {
      ...EventPhotoDetails
    }
  }
  ${EventFragments.photo}
`

// export const UPDATE_EVENT_PHOTO = gql`
//   mutation(
//     $id: ID!
//     $eventId: ID!
//     $photosIds: [ID!]!
//     $cover: Boolean
//     $card: Boolean
//     $cardWide: Boolean
//   ) {
//     photo: updateEventPhoto(
//       id: $id
//       cover: $cover
//       card: $card
//       cardWide: $cardWide
//     ) {
//       ...EventPhotoDetails
//     }
//     event: updateEvent(id: $id, photosIds: $photosIds) {
//       id
//       photos {
//         ...EventPhotoDetails
//       }
//     }
//   }
//   ${EventFragments.photo}
// `
