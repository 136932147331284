import React from 'react'
import { StyleSheet, View, ScrollView, ActivityIndicator } from 'react-native'
import { Query } from 'react-apollo'
import { EVENT_QUERY } from '../../../gql'
import {
  RadioButton,
  FormLabel,
  DateTimeInput,
  FormCheckbox,
  BaseText,
} from '../../../components'
import { DimensionsConsumer } from '../../../lib'

const s = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  dateStart: {
    zIndex: 3,
  },
  dateEnd: {
    zIndex: 2,
  },
  dateTimeWrapper: {
    flexDirection: 'row',
    flex: 4,
    zIndex: 1,
    maxWidth: 'unset',
  },
  dateTimeWrapperStyle: {
    flex: 1,
  },
  filler: {
    flex: 1,
  },
  ticketWrapper: {
    marginRight: 'auto',
    marginLeft: 30,
    flex: 1,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  displayNone: {
    display: 'none',
  },
  checkBoxWrapper: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  ticketOptionListContainer: {
    flexDirection: 'row',
    maxHeight: 200,
    flex: 1,
  },
})

export const OnSale = ({ additionalProps }) => (
  <Query query={EVENT_QUERY} variables={{ id: additionalProps.values.eventId }}>
    {({ loading, error, data }) => {
      if (loading || !data) return <ActivityIndicator />

      return (
        <DimensionsConsumer>
          {({ width }) => (
            <React.Fragment>
              <FormLabel children={'Ticket Sale Start'} />
              <View style={[s.wrapper, s.dateStart]}>
                <DateTimeInput
                  name={'salesStart'}
                  labelWrapperStyle={s.displayNone}
                  wrapperStyle={s.dateTimeWrapper}
                  dateWrapperStyle={s.dateTimeWrapperStyle}
                  timeInnerMaxWidth={1000}
                  timeTextAlign={'center'}
                  value={additionalProps.values.salesStart}
                  onChange={additionalProps.setFieldValue}
                  disabled={additionalProps.values.sellAfterTicket}
                />
                <View style={[s.filler]} />
              </View>

              <FormLabel children={'Ticket Sale End'} />
              <View style={[s.wrapper, s.dateEnd]}>
                <DateTimeInput
                  name={'salesEnd'}
                  labelWrapperStyle={s.displayNone}
                  wrapperStyle={s.dateTimeWrapper}
                  dateWrapperStyle={s.dateTimeWrapperStyle}
                  timeInnerMaxWidth={1000}
                  timeTextAlign={'center'}
                  value={additionalProps.values.salesEnd}
                  onChange={additionalProps.setFieldValue}
                  disabled={additionalProps.values.sellAfterTicket}
                />
                <View style={[s.filler]} />
              </View>
              {!!data.Event.ticketOptions.filter(
                ({ id }) => id !== additionalProps.values.id,
              ).length && (
                <View>
                  <FormCheckbox
                    label={'When other tickets sell out'}
                    name={'sellAfterTicket'}
                    value={
                      additionalProps.values.sellAfterTicket
                        ? additionalProps.values.sellAfterTicket
                        : false
                    }
                    onChange={value =>
                      additionalProps.setFieldValue('sellAfterTicket', value)
                    }
                    labelAfterCheckbox={true}
                    wrapperStyle={s.checkBoxWrapper}
                  />

                  {additionalProps.values.sellAfterTicket && (
                    <View style={s.ticketOptionListContainer}>
                      <ScrollView style={[s.ticketWrapper]}>
                        {data.Event.ticketOptions
                          .filter(({ id }) => id !== additionalProps.values.id)
                          .map((item, index) => (
                            <View style={[s.radioContainer]} key={index}>
                              <RadioButton
                                value={
                                  additionalProps.values.soldAfterTicketId ===
                                  item.id
                                }
                                radioValue={
                                  additionalProps.values.soldAfterTicketId
                                }
                                onPress={e => {
                                  additionalProps.setFieldValue(
                                    'soldAfterTicketId',
                                    item.id,
                                  )
                                }}
                              />
                              <FormLabel
                                mr={20}
                                ml={10}
                                mt={4}
                                children={item.name}
                              />
                            </View>
                          ))}
                      </ScrollView>
                    </View>
                  )}
                </View>
              )}
              {additionalProps.errors.soldAfterTicket && (
                <BaseText
                  fs={12}
                  color={'pink'}
                  children={additionalProps.errors.soldAfterTicket}
                />
              )}
              {additionalProps.errors.salesStart && (
                <BaseText
                  fs={12}
                  color={'pink'}
                  children={additionalProps.errors.salesStart}
                />
              )}
            </React.Fragment>
          )}
        </DimensionsConsumer>
      )
    }}
  </Query>
)
