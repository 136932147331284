/*
 * adapted from the example here:
 * https://github.com/devmynd/react-native-x-platform-letter-spacing
 *
 */

import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

const Letter = props => {
  const { children, spacing, style } = props

  const letterStyles = [style, { paddingRight: spacing }]

  return <Text style={letterStyles}>{children}</Text>
}

const spacingForLetterIndex = (letters, index, spacing) =>
  letters.length - 1 === index ? 0 : spacing

export const TextWithLetterSpacing = props => {
  const { children, spacing, viewStyle, style } = props
  const letters = children.split('')

  return (
    <View style={[styles.container, viewStyle && viewStyle]}>
      {letters.map((letter, index) => (
        <Letter
          key={index}
          spacing={spacingForLetterIndex(letters, index, spacing)}
          style={style}
        >
          {letter}
        </Letter>
      ))}
    </View>
  )
}
