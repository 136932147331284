import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const OrganizationFragments = {
  info: gql`
    fragment OrganizationInfo on Organization {
      id
      email
      name
      type

      members {
        id
        email
      }

      owner {
        id
        email
      }

      permissions {
        id
        permission
        targetVenue {
            id
            name
        }
      }
    }
  `,
  permissions: gql`
    fragment PermissionsInfo on AccessControlEntry {
      id
      permission
      targetVenue {
          id
          name
      }
    }
  `
}
