import React from 'react'
import { StyleSheet, View } from 'react-native'
import * as t from 'prop-types'
import { BaseText, Checkbox } from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import {
  colors,
  formatShortDate,
  getShortDateWithDayTime,
  hexToRgba,
} from '../../../functions'
import { EventPopover } from '../components'
import { Text } from 'react-native-web'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 90,
  },
  innerContentCollapsed: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  dotWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
  },
})

const statusColors = {
  published: '#69FF5E',
  unpublished: '#FF5E5E',
  expired: hexToRgba('#69FF5E', 0.5),
}

const StatusDot = ({ status }) => {
  const color = statusColors[status] ? statusColors[status] : colors.teal
  return (
    <View style={s.dotWrapper}>
      <View style={[s.dot, { backgroundColor: color }]} />
    </View>
  )
}

export const EventListItem = ({
  id,
  status,
  name,
  venue,
  date,
  endDate,
  venueId,
  queryDate,
  recurring,
  onChecked,
}) => {
  return (
    <DimensionsConsumer>
      {({ width }) => {
        const shouldCollapse = width < 590
        return (
          <View style={s.wrapper}>
            <StatusDot status={status} />
            <View
              style={[
                s.innerContent,
                shouldCollapse && s.innerContentCollapsed,
              ]}
            >
              <View style={{ width: 245, marginRight: 8 }}>
                <Text
                  numberOfLines={2}
                  style={{
                    fontSize: 14,
                    lineHeight: 19,
                    color: colors.secondary3,
                  }}
                >
                  {name}
                </Text>
              </View>
              <View style={{ width: 200, marginRight: 8 }}>
                <BaseText
                  color={'secondary3'}
                  fs={14}
                  lh={19}
                  children={venue.name}
                />
              </View>
              <View style={{ width: 100, marginRight: 3 }}>
                <BaseText
                  color={'secondary3'}
                  fs={14}
                  lh={19}
                  mr={20}
                  weight={shouldCollapse ? 'light' : 'regular'}
                  children={`${formatShortDate(date)}\n${formatShortDate(
                    endDate,
                  )}`}
                  //children={`${getShortDateWithDayTime(date)}\n${getShortDateWithDayTime(endDate)}`}
                />
              </View>
            </View>
            {onChecked ? (
              <Checkbox value={recurring} onChange={onChecked} />
            ) : (
              <EventPopover
                id={id}
                venueId={venueId}
                queryDate={queryDate}
                handleEdit={() => console.log('handle edit')}
              />
            )}
          </View>
        )
      }}
    </DimensionsConsumer>
  )
}

EventListItem.propTypes = {
  status: t.oneOf(['published', 'unpublished', 'expired', 'recurring'])
    .isRequired,
}
