import React from 'react'
import { StyleSheet, View } from 'react-native'
import { DimensionsConsumer } from '../../../lib'
import { colors, fonts, hexToRgba } from '../../../functions'
import { BaseText, Button, HoverableText } from '../../../components'

const s = StyleSheet.create({
  photoControlsSectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 17,
    marginRight: 20,
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginVertical: 7,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.secondary2,
    zIndex: 6,
  },
  dim: {
    opacity: 0.7,
  },
  textHoverStyle: {
    color: colors.white,
    opacity: 1,
  },
  sizeButtonStyle: {
    width: '100%',
    height: '100%',
    marginBottom: 0,
  },
  sizeIconStyle: {
    marginRight: 0,
    marginLeft: 10,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
})

const SectionTitle = props => (
  <BaseText
    fs={20}
    lh={27}
    mb={7}
    weight={'semibold'}
    color={'secondary3'}
    {...props}
  />
)

const SectionSubtitle = props => (
  <BaseText
    fs={14}
    lh={19}
    mb={9}
    weight={'light'}
    color={'secondary2'}
    {...props}
  />
)

const TitleSection = ({ title, subtext, isMedium }) => (
  <View style={s.column}>
    <SectionTitle children={title} />
    {subtext && <SectionSubtitle children={subtext} mb={isMedium ? 20 : 9} />}
  </View>
)

export const PhotoControls = ({
  title,
  subtext,
  showPublished,
  showLarge,
  handleSetState,
}) => (
  <DimensionsConsumer>
    {({ width }) => {
      const isSmall = width < 434 // used for mobile sizes
      const isMedium = subtext && width < 640 // used for menu
      const showCondensed = isSmall || isMedium
      return (
        <View style={s.photoControlsSectionHeader}>
          {showCondensed && (
            <TitleSection title={title} subtext={subtext} isMedium={isMedium} />
          )}
          <View style={s.row}>
            {!showCondensed && (
              <TitleSection
                title={title}
                subtext={subtext}
                isMedium={isMedium}
              />
            )}
            <View
              style={[
                s.rowCenter,
                showCondensed && {
                  width: '100%',
                  justifyContent: 'space-between',
                },
                { marginBottom: 7 },
              ]}
            >
              <View style={[s.rowCenter, { marginRight: 20 }]}>
                <HoverableText
                  disabled={!showPublished}
                  outerContainerStyle={s.touchWrapper}
                  textStyle={[s.text, !showPublished && s.dim]}
                  textHoverStyle={s.textHoverStyle}
                  children={'Published'}
                  onPress={() => handleSetState({ showPublished: true })}
                />
                <BaseText fs={13} lh={18} children={' | '} />
                <HoverableText
                  disabled={showPublished}
                  outerContainerStyle={s.touchWrapper}
                  textStyle={[s.text, showPublished && s.dim]}
                  textHoverStyle={s.textHoverStyle}
                  children={'Unpublished'}
                  onPress={() => handleSetState({ showPublished: false })}
                />
              </View>

              <View style={s.rowCenter}>
                <Button
                  icon={{
                    name: 'view-module',
                    size: 22,
                    color: !showLarge
                      ? colors.secondary2
                      : hexToRgba(colors.secondary2, 0.7),
                    style: s.sizeIconStyle,
                  }}
                  buttonStyle={s.sizeButtonStyle}
                  noShadow={true}
                  onPress={() => handleSetState({ showLarge: false })}
                />
                <Button
                  icon={{
                    name: 'view-grid',
                    size: 18,
                    color: showLarge
                      ? colors.secondary2
                      : hexToRgba(colors.secondary2, 0.7),
                    style: s.sizeIconStyle,
                  }}
                  buttonStyle={s.sizeButtonStyle}
                  noShadow={true}
                  onPress={() => handleSetState({ showLarge: true })}
                />
              </View>
            </View>
          </View>
          <View style={s.lineBreak} />
        </View>
      )
    }}
  </DimensionsConsumer>
)
