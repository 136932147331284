import React from 'react'
import { Link } from 'react-router-dom'
import { StyleSheet, View } from 'react-native'
import { withApollo } from 'react-apollo'
import {
  SectionHeader,
  ResponsiveContainer,
  BaseText,
  FormButton,
} from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingHorizontal: 20,
    paddingVertical: 17,
    zIndex: 1,
    width: '100%',
  },
  sectionContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1,
  },
})

class Section extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
    }
  }

  async download() {
    this.setState({
      isSubmitting: true,
    })

    const pageLimit = 1000
    const { client, title, reportQuery, metaQuery, formatData } = this.props
    const totalData = await client.query({ query: metaQuery })
    console.log('totalData', totalData.data.record)

    let recordData = null

    for (let page = 0; page < totalData.data.record.count / pageLimit; page++) {
      const dataPage = await client.query({
        query: reportQuery,
        variables: { limit: pageLimit, offset: page * pageLimit },
        fetchPolicy: 'no-cache',
      })
      console.log('dataPage', dataPage.data)

      if (!recordData) {
        recordData = dataPage
      } else {
        recordData.data.report = recordData.data.report.concat(
          dataPage.data.report,
        )
      }
    }

    let data = null
    if (typeof formatData === 'function') {
      data = {
        report: [],
      }
      data.report = formatData(recordData.data.report)
      console.log('data after format', data)
    } else {
      data = recordData.data
    }

    const keys = Object.keys(data.report[0]).filter(
      key => !key.startsWith('__'),
    )
    const headers = keys.map(key => `"${key}"`).join(',')

    let rows = data.report.map(row => {
      return keys
        .map(key => {
          return typeof row[key] === 'string'
            ? `"${row[key] ? row[key].replace(/"/g, "'") : ''}"`
            : `${row[key]}`
        })
        .join(',')
        .replace(/\n/g, '\\n')
    })

    rows = rows.join('\n')
    const table = `${headers}\n${rows}`.replace(/#/g, '%23')
    const filename = `${title}_Data.csv`

    var blob = new Blob([table], {
      type: 'application/csv;charset=utf-8;',
    })

    if (window.navigator.msSaveOrOpenBlob)
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, filename)
    else {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    this.setState({
      isSubmitting: false,
    })
  }

  render() {
    const { title, subtext, filename } = this.props

    return (
      <ResponsiveContainer
        style={s.sectionContainer}
        breakpoint={434} // 414 + 10 px padding on each side
        breakpointStyle={s.sectionContainerMobile}
      >
        <View style={s.titleWraper}>
          <BaseText
            fs={20}
            lh={27}
            mb={7}
            weight={'semibold'}
            color={'secondary3'}
            children={`${title} Data`}
          />
          {subtext && (
            <BaseText
              fs={14}
              lh={19}
              mb={9}
              weight={'light'}
              color={'secondary2'}
              children={subtext}
            />
          )}
        </View>
        <FormButton
          text={'Export Report'}
          textColor={colors.teal}
          handlePress={() => this.download()}
          isSubmitting={this.state.isSubmitting}
        />
      </ResponsiveContainer>
    )
  }
}

export default withApollo(Section)
