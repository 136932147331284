import React from 'react'
import * as t from 'prop-types'
import { View } from 'react-native'
import { DimensionsConsumer } from '../../lib'

export const ResponsiveContainer = ({
  style,
  breakpoint,
  breakpointStyle,
  children,
}) => (
  <DimensionsConsumer>
    {({ width }) => {
      const isBreakpoint = width < breakpoint
      return (
        <View style={[style, isBreakpoint && breakpointStyle]}>{children}</View>
      )
    }}
  </DimensionsConsumer>
)

ResponsiveContainer.propTypes = {
  style: t.oneOfType([t.object, t.array, t.number]).isRequired,
  breakpoint: t.number.isRequired,
  breakpointStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  children: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
}
