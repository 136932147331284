import React from 'react'
import { Details } from './Details'
import { OnSale } from './OnSale'
import { ShowQuantity } from './ShowQuantity'
import { TicketsAllowedPerOrder } from './TicketsAllowedPerOrder'
import { Fees } from './Fees'
import { Status } from './Status'
import { colors } from '../../../functions'

export const sections = [
  {
    id: '#details',
    title: 'Ticket Info',
    component: Details,
  },
  {
    id: '#tickets-allowed-per-order',
    title: 'Tickets Allowed Per Order',
    component: TicketsAllowedPerOrder,
  },
  {
    id: '#on-sale',
    title: 'On Sale Settings',
    subtext: 'Set when this Ticket Type goes on sale. All times are in Eastern Standard Time',
    component: OnSale,
  },
  {
    id: '#show-quantity',
    title: '',
    component: ShowQuantity,
  },
  {
    id: '#fees',
    title: 'Fee Structure',
    subtext: (
        <span>
            Fee's are managed by BarCard. For assistance, call us @ <a style={{ color: colors.white }} href="mailto:VenueSupport@getbarcard.com">
                VenueSupport@getbarcard.com
            </a>
        </span>
    ),
    component: Fees,
  },
  {
    id: '#status',
    title: 'Ticket Status',
    component: Status,
  },
]
