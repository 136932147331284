import React from 'react'
import * as t from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { BaseText } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 60, // same height as EventItem
  },
})

const statusMap = {
  published: 'No upcoming events',
  unpublished: 'No upcoming, unpublished events',
  expired: 'No past events',
}

export const NoEventsText = ({ status }) => (
  <View style={s.wrapper}>
    <BaseText
      color={'secondary2'}
      weight={'italic'}
      fs={14}
      lh={19}
      ta={'center'} // ensure the text wraps cleanly on smaller devices
      children={statusMap[status]}
    />
  </View>
)

NoEventsText.propTypes = {
  status: t.oneOf(['published', 'unpublished', 'expired']).isRequired,
}
