import React from 'react'
import { View, StyleSheet } from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { colors, hashMap } from '../../../functions'
import { DimensionsConsumer } from '../../../lib'
import {
  AutoCompleteWeb,
  BaseText,
  BaseTouchText,
  FormButton,
} from '../../../components'
import { timeOpts, dayOpts } from './timeOpts'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 38,
    marginBottom: 2,
  },
  placeholderTextWrapper: {
    zIndex: -5,
    width: 80,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 120,
    marginRight: 19,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
})

const FormTouchText = props => (
  <BaseTouchText fs={14} weight={'light'} {...props} />
)

const PlaceholderText = props => (
  <View style={s.placeholderTextWrapper}>
    <BaseText fs={14} weight={'light'} color={'secondary2'} {...props} />
  </View>
)

export class HoursInput extends React.Component {
  state = {
    editing: false,
    dayOpts: dayOpts,
    dayOptsHash: hashMap(dayOpts, 'value'),
    endTimeOptsHash: hashMap(timeOpts, 'endValue'),
    timeOpts: timeOpts,
    timeOptsHash: hashMap(timeOpts, 'value'),
    timeOptsNameHash: hashMap(timeOpts, 'name'),
    dayOptsNameHash: hashMap(dayOpts, 'name'),
    initialValues: null, // only necessary for clearing/resetting the form
    modalIsVisible: false,
  }

  componentWillMount() {
    // we need to do this so we can reset / clear the form (_handleCancel)
    const { startTime, endTime, day } = this.props
    const initialValues = { startTime, endTime, day }
    if (!this.props.startTime) {
      this.setState({ editing: true, initialValues })
    } else {
      this.setState({ initialValues })
    }
  }

  /*
   * commenting out for now.
   * - cancelling doesn't work properly when initialValues get overriden with nextProps
   * - however, editing, saving, then cancelling also produces this undesired behavior
   */
  // componentWillReceiveProps(nextProps) {
  // 	console.log('componentWillReceiveProps fired')
  // 	const { startTime, endTime, day } = nextProps
  // 	const initialValues = { startTime, endTime, day }
  // 	console.log('initialValues', initialValues)
  // 	this.setState({ initialValues })
  // }

  _renderModalContent = () => (
    <View style={s.modal}>
      <View style={s.modalMessageContainer}>
        <BaseText
          fs={16}
          lh={24}
          color={'secondary3'}
          children={'Are you sure you want to delete?'}
        />
      </View>
      <View style={s.modalControls}>
        <FormButton
          text={'Cancel'}
          outerStyle={{ marginRight: 0 }}
          textColor={colors.teal}
          handlePress={() => this.setState({ modalIsVisible: false })}
        />
        <FormButton
          text={'Delete'}
          outerStyle={{ marginRight: 0 }}
          textColor={colors.pink}
          handlePress={this._removePeriod}
        />
      </View>
    </View>
  )

  handleSelect = (field, item) => {
    /*
     * should check to see that endTime > startTime somewhere ...
     */
    const value =
      field === 'endTime' && item.endValue ? item.endValue : item.value
    this.props.onChange(field, value)
  }

  _handleEdit = () => {
    this.setState({ editing: true })
  }

  _handleCancel = () => {
    Object.keys(this.state.initialValues).forEach(x => {
      this.props.onChange(x, this.state.initialValues[x])
    })
    this.setState({ editing: false })
  }

  _handleRemove = () => {
    this.setState({ modalIsVisible: true })
  }

  _removePeriod = () => {
    this.props.handleRemove()
  }

  _handleSave = () => {
    this.setState({ editing: false })
    this.props.handleSubmit()
  }

  handleInput = (field, text) => {
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 6 && this.state.dayOptsNameHash[text]) {
      this.handleSelect(field, this.state.dayOptsNameHash[text])
    }
    if (text.length >= 7 && this.state.timeOptsNameHash[text]) {
      this.handleSelect(field, this.state.timeOptsNameHash[text])
    }
    if (text.length >= 7 && this.state.timeOptsNameHash[text]) {
      this.handleSelect(field, this.state.timeOptsNameHash[text])
    }
  }

  render() {
    const {
      editing,
      timeOpts,
      timeOptsHash,
      endTimeOptsHash,
      dayOpts,
      dayOptsHash,
      modalIsVisible,
    } = this.state
    const { startTime, endTime, day } = this.props

    const dayValue = dayOptsHash[day] ? dayOptsHash[day].name : null
    const startTimeValue = timeOptsHash[startTime]
      ? timeOptsHash[startTime].name
      : null
    const endTimeValue = timeOptsHash[endTime]
      ? timeOptsHash[endTime].name
      : endTimeOptsHash[endTime]
      ? endTimeOptsHash[endTime].name
      : null

    return (
      <DimensionsConsumer>
        {({ width }) => {
          const tooSmall = width < 608
          return (
            <React.Fragment>
              <View
                style={[
                  s.wrapper,
                  !editing && { zIndex: -5 },
                  tooSmall && { maxWidth: 400 },
                ]}
              >
                {!editing ? (
                  <React.Fragment>
                    <PlaceholderText weight={'regular'} children={dayValue} />
                    <PlaceholderText children={startTimeValue} />
                    <PlaceholderText children={endTimeValue} />
                    {!tooSmall && (
                      <View style={s.buttonsWrapper}>
                        <FormTouchText
                          color={'teal'}
                          children={'Edit'}
                          handlePress={this._handleEdit}
                        />
                        <FormTouchText
                          color={'pink'}
                          children={'Remove'}
                          handlePress={this._handleRemove}
                        />
                      </View>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AutoCompleteWeb
                      items={dayOpts}
                      handleSelect={text => this.handleSelect('day', text)}
                      handleInput={text => this.handleInput('day', text)}
                      value={dayValue ? dayValue : ''}
                      // wrapperStyle={{ marginRight: 15 }}
                      maxWidth={150}
                    />
                    <AutoCompleteWeb
                      items={timeOpts}
                      handleSelect={text =>
                        this.handleSelect('startTime', text)
                      }
                      handleInput={text => this.handleInput('startTime', text)}
                      value={startTimeValue ? startTimeValue : ''}
                      // wrapperStyle={{ marginRight: 15 }}
                      maxWidth={120}
                    />
                    <AutoCompleteWeb
                      items={timeOpts}
                      handleSelect={text => this.handleSelect('endTime', text)}
                      handleInput={text => this.handleInput('endTime', text)}
                      value={endTimeValue ? endTimeValue : ''}
                      // wrapperStyle={{ marginRight: 15 }}
                      maxWidth={120}
                    />
                    <View style={s.buttonsWrapper}>
                      <FormTouchText
                        color={'teal'}
                        children={'Save'}
                        handlePress={this._handleSave}
                      />
                      <FormTouchText
                        color={'pink'}
                        children={'Cancel'}
                        handlePress={this._handleCancel}
                      />
                    </View>
                  </React.Fragment>
                )}
              </View>
              <Modal
                isVisible={modalIsVisible}
                onBackdropPress={() => this.setState({ modalIsVisible: false })}
              >
                {this._renderModalContent()}
              </Modal>
            </React.Fragment>
          )
        }}
      </DimensionsConsumer>
    )
  }
}
