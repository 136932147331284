import React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { getNumberOfFormLines } from '../../../functions'
import {
  ResponsiveContainer,
  NumericInput,
  LabelInput,
  BaseText,
} from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import { colors } from '../../../functions'
import { WithSelectedVenuePermission } from '../../../layouts/components'

const s = StyleSheet.create({
  banner: {
    width: '100%',
    backgroundColor: '#2a2a34',
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 32,
  },
  bannerText: {
    color: colors.pink,
    textAlign: 'center',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  firstRowMobile: {
    flexDirection: 'column',
  },
  labelInputWrapperStyle: {
    marginBottom: 0,
    paddingBottom: 0,
    borderWidth: 0,
    marginRight: 8,
    flex: 1,
    maxWidth: 188,
  },
  textInputStyle: {
    padding: 10,
    paddingRight: 10,
    backgroundColor: '#2a2a34',
    width: '100%',
  },
  textInputCenterAlign: {
    textAlign: 'center',
  },
})

export const Fees = ({ additionalProps }) => (
  <WithSelectedVenuePermission permission={'MANAGE_VENUE_EVENTS_TICKETS_FEES'}>
    {({ permission }) => (
      <DimensionsConsumer>
        {({ width }) => {
          const canUserEdit = !!permission
          let descLength = getNumberOfFormLines(
            additionalProps.values.description,
          )
          descLength = descLength === 1 ? 2 : descLength

          return (
            <React.Fragment>
              {!canUserEdit && !additionalProps.values.fee && (
                <View style={[s.banner]}>
                  <Text style={[s.bannerText]}>
                    Fees must be set by a BarCard Administrator. Contact us @{' '}
                    <a
                      style={{ color: colors.pink }}
                      href="mailto:VenueSupport@getbarcard.com"
                    >
                      VenueSupport@getbarcard.com
                    </a>
                  </Text>
                </View>
              )}

              <ResponsiveContainer
                style={s.firstRow}
                breakpoint={769}
                breakpointStyle={s.firstRowMobile}
              >
                <NumericInput
                  collapseLabel={true}
                  label={'Fees'}
                  noFill={true}
                  labelWidth={75} // this is being weird, needed this or Price From is on 2 lines
                  name={'fee'}
                  onChangeText={additionalProps.setFieldValue}
                  onChangeTextOnlyOnBlur={true}
                  value={
                    additionalProps.values.fee
                      ? parseFloat(additionalProps.values.fee)
                      : ''
                  }
                  wrapperStyle={s.labelInputWrapperStyle}
                  textInputStyle={s.textInputStyle}
                  editable={canUserEdit}
                  placeholder={
                    permission ? 'Add Fee Amount' : ' No Set Fee Amount'
                  }
                  suffix={'per ticket'}
                  autoComplete={'off'}
                />
              </ResponsiveContainer>
            </React.Fragment>
          )
        }}
      </DimensionsConsumer>
    )}
  </WithSelectedVenuePermission>
)
