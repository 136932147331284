import { Recurring } from './Recurring'
import { Publishing } from './Publishing'

export const publishSections = [
  {
    id: '#recurring',
    title: 'Recurring',
    component: Recurring,
  },
  {
    id: '#publishing',
    title: 'Publishing',
    component: Publishing,
  },
]
