import React from 'react'
import {
  ActivityIndicator,
  Image,
  TouchableOpacity,
  Text,
  View,
} from 'react-native'
import { MCIcon } from '../index'
import { styles as s } from './shared'

class LoadingButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showSuccess: false,
    }
  }

  componentWillUnmount() {
    this.setState({ loading: false, showSuccess: false })
    // if (this.timer) clearTimeout(this.timer)
  }

  // componentWillReceiveProps(nextProps) {
  // 	if (nextProps.cancelLoadingButton) {
  // 		this.setState({ showSuccess: true })
  // 		this.props.dispatch({ type: 'CANCEL_LOADING_BUTTON', payload: false })
  // 		setTimeout(() => this.setState({ loading: false, showSuccess: false }), 650)
  // 	}
  // }

  _onPress = () => {
    this.setState({ loading: true })
    this.props.onPress()
  }

  render() {
    const {
      disabled,
      text,
      color,
      disabledColor,
      disabledTextColor,
      containerStyle,
      buttonStyle,
      textStyle,
      loadingColor,
      icon,
      image,
    } = this.props

    return (
      <View style={[s.wrapper, containerStyle]}>
        <TouchableOpacity
          style={[
            s.button,
            color && { backgroundColor: color },
            buttonStyle,
            disabled && { backgroundColor: disabledColor },
            s.shadow,
          ]}
          onPress={this._onPress}
          activeOpacity={0.8}
          disabled={disabled}
        >
          {!this.state.loading && (
            <View style={s.row}>
              {icon ? (
                <MCIcon
                  name={icon.name}
                  size={icon.size}
                  color={icon.color}
                  style={[s.iconStyle, icon.style && icon.style]}
                />
              ) : image ? (
                <Image
                  source={image.source}
                  style={[s.iconStyle, image.style]}
                />
              ) : null}
              <Text
                style={[
                  s.buttonText,
                  textStyle,
                  disabled && { color: disabledTextColor },
                ]}
              >
                {text}
              </Text>
            </View>
          )}
          {this.state.loading && !this.state.showSuccess && (
            <ActivityIndicator
              size="small"
              color={loadingColor ? loadingColor : '#fff'}
            />
          )}
          {this.state.showSuccess && (
            <MCIcon
              name={'checkbox-marked-circle-outline'}
              size={24}
              color={'green'}
              //style={}
            />
          )}
        </TouchableOpacity>
      </View>
    )
  }
}

export default LoadingButton
