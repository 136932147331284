import { EventTicketingToggle } from './EventTicketingToggle'
import { EventTicketingList } from './EventTicketingList'

export const ticketSections = [
  {
    id: '#ticketing',
    title: 'Ticketing',
    component: EventTicketingToggle,
  },
  {
    id: '#tickets',
    title: 'Tickets',
    component: EventTicketingList,
  },
]
