import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { BaseText, FormLabel } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    zIndex: -1,
  },
  timeOptsRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

function getDurationText(startDate, endDate) {
  try {
    const startTime = new Date(startDate).getTime()
    const endTime = new Date(endDate).getTime()

    const minutes = (endTime - startTime) / 1000 / 60
    if (minutes <= 0) {
      return '-'
    }
    const hrs = Math.floor(minutes / 60)
    const mins = minutes % 60

    const minutesString = mins === 1 ? `1 minutes` : `${mins} minutes`

    if (hrs > 24) {
      const days = Math.floor(hrs / 24)
      const dayString = days === 1 ? `1 day` : `${days} days`
      const newHrs = hrs % 24
      const newHoursString = newHrs === 1 ? `1 hour` : `${newHrs} hours`
      // was getting some weird decimal issue when times weren't set..
      if (mins === 0 || (mins > 0 && mins < 1)) {
        if (newHrs === 0) {
          return `${dayString}`
        } else {
          return `${dayString}, ${newHoursString}`
        }
      } else {
        return `${dayString}, ${newHoursString}, and ${minutesString}`
      }
    }
    const hoursString = hrs === 1 ? `1 hour` : `${hrs} hours`
    if (mins === 0) {
      return `${hoursString}`
    } else {
      return `${hoursString} and ${minutesString}`
    }
  } catch (e) {
    console.log('e', e)
    return `-`
  }
}

export const DurationRow = ({
  startDate,
  endDate,
  wrapperStyle,
  textWrapperStyle,
}) => {
  const durationText = getDurationText(startDate, endDate)
  return (
    <View style={[s.wrapper, wrapperStyle && wrapperStyle]}>
      <View style={s.timeOptsRow}>
        <Text style={textWrapperStyle && textWrapperStyle}>
          <FormLabel children={'Duration'} mr={15} />
          {durationText && (
            <BaseText fs={16} lh={24} color={'teal'} children={durationText} />
          )}
        </Text>
      </View>
    </View>
  )
}
