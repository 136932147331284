import gql from 'graphql-tag'

export const AUTHENTICATE_PARTNER_OR_EMPLOYEE = gql`
  mutation($email: String!, $password: String!) {
    authenticatePartnerOrEmployee(email: $email, password: $password) {
      id
      email
      firstName
      lastName
      profilePicture
      token
    }
  }
`

export const CONFIRM_INVITE = gql`
  mutation($inviteToken: String!, $password: String!) {
    confirmInvite(inviteToken: $inviteToken, password: $password) {
      id
      email
      firstName
      lastName
      profilePicture
      token
    }
  }
`

/* 
 * no longer using office365 login
 *

export const LOGIN_EMPLOYEE = gql`
	mutation($office365Token: String!) {
		loginEmployee: authenticateOffice365User(office365Token: $office365Token) {
			id
			email
			firstName
			lastName
			token
		}
	}
`
*/
