import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu'
import { HoverableTextTouchHighlight } from '../../components'
import { colors, fonts, hexToRgba } from '../../functions'

const { Popover } = renderers

const s = StyleSheet.create({
  // MENU PROVIDER STYLES
  menuProviderWrapper: {
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 0, // temp
  },
  menuProviderBackdrop: {
    // backgroundColor: colors.opaque,
  },

  // TRIGGER STYLES
  triggerOuterWrapper: {
    display: 'flex',
    flex: 0, // temp
  },
  triggerWrapper: {
    display: 'flex',
    flex: 0, // temp
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  // MENU OPTIONS STYLES
  optionsWrapper: {
    backgroundColor: colors.primary1,
    borderRadius: 10,
    maxWidth: 270,
  },
  optionsContainer: {
    backgroundColor: colors.primary1,
    borderRadius: 10,
    width: 270,
    paddingVertical: 11,
  },

  // MENU OPTION STYLES
  optionWrapper: {
    paddingLeft: 24,
    paddingVertical: 13,
  },
  optionText: {
    color: colors.secondary4,
    fontFamily: fonts.regular,
    fontSize: 16,
    lineHeight: 24,
  },

  // arrow indicator
  anchorStyle: {
    // marginTop: 12, // new
    left: 80, // move the arrow over to the right (instead of being in the middle)
    backgroundColor: colors.primary1,
  },
  centerAnchorStyle: {
    left: 0,
  },

  // styles for HoverableTextTouchHighlight
  menuContainer: {
    display: 'flex',
    backgroundColor: colors.primary1,
  },
  menuContainerHover: {
    backgroundColor: colors.primary1, // don't change the bg color on hover here
  },
  innerContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'flex-start',
  },
})

const TouchComponent = props => {
  return (
    <HoverableTextTouchHighlight
      activeOpacity={1}
      underlayColor={colors.primary1}
      innerContainerStyle={s.innerContainerStyle}
      style={s.menuContainer}
      // a bad hack:
      // need to eventually refactor menuOption to not use <Text/> as a child:
      text={props.children.props.children.props.children}
      hoverStyle={s.menuContainerHover}
      textStyle={{
        paddingLeft: 20,
        textAlign: 'left',
        color: colors.secondary3,
      }}
      textHoverStyle={{ textAlign: 'left', color: colors.teal }}
      onPress={props.onPress}
    />
  )
}

const menuOptionCustomStyles = {
  optionWrapper: s.optionWrapper, // Style of wrapping View component.
  optionText: s.optionText, // Style of Text component (when text shorthand option is defined)
  OptionTouchableComponent: TouchComponent,
  optionTouchable: {
    underlayColor: hexToRgba(colors.primary1, 0.5),
  },
}

export const PopoverMenu = ({
  renderTrigger,
  renderPreOptions,
  options,
  optionsWrapperStyle,
  optionsContainerStyle,
  menuProviderWrapperStyle,
  centerAnchor,
}) => {
  const optsContainerStyle = optionsContainerStyle
    ? { ...s.optionsContainer, ...optionsContainerStyle }
    : s.optionsContainer
  const optsWrapperStyle = optionsWrapperStyle
    ? { ...s.optionsWrapper, ...optionsWrapperStyle }
    : s.optionsWrapper
  return (
    <View
      style={[
        s.menuProviderWrapper,
        menuProviderWrapperStyle && menuProviderWrapperStyle,
      ]}
    >
      <Menu
        renderer={Popover}
        rendererProps={{
          placement: 'auto', // Position of popover to the menu trigger - top | right | bottom | left | auto
          preferredPlacement: 'bottom', // Preferred placement of popover - top | right | bottom | left. Applicable when placement is set to auto
          anchorStyle: [s.anchorStyle, centerAnchor && s.centerAnchorStyle], // Styles passed to popover anchor component
        }}
      >
        <MenuTrigger
          customStyles={{
            triggerOuterWrapper: s.triggerOuterWrapper, // Style of outer View component
            triggerWrapper: s.triggerWrapper, // Style of inner View component
            TriggerTouchableComponent: TouchableOpacity,
            triggerTouchable: {
              activeOpacity: 0.8,
            },
          }}
        >
          {renderTrigger()}
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionsWrapper: optsWrapperStyle, // Style of wrapping MenuOptions component
            optionsContainer: optsContainerStyle, // Style passed to the menu renderer (e.g. Animated.View)
          }}
        >
          {renderPreOptions && renderPreOptions()}
          {options &&
            options.map((x, i) => (
              <MenuOption
                key={i}
                text={x.label}
                customStyles={menuOptionCustomStyles}
                onSelect={x.onSelect}
              />
            ))}
        </MenuOptions>
      </Menu>
    </View>
  )
}
