import React from 'react'
import { Mutation } from 'react-apollo'
import { StyleSheet, View } from 'react-native'
import { Formik } from 'formik'
import { DimensionsConsumer } from '../../../lib'
import { getNumberOfFormLines } from '../../../functions'
import {
  UPDATE_SPECIAL_ITEM,
  CREATE_SPECIAL_ITEM,
  SPECIAL_QUERY,
} from '../../../gql'
import {
  ErrorsContainer,
  FormButtons,
  ResponsiveInput,
  NumericInput,
} from '../../../components'
import { SpecialTypeDropdown } from '../components'

const s = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  rowMobileOverride: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 15, // space btwn name & price
  },
  specialTypeWrapperMobile: {
    // default is paddingVertical 20
    paddingTop: 35,
    paddingBottom: 25,
  },
})

function sanitizeValues({ price, description, ...rest }) {
  return {
    ...rest,
    price: price ? parseFloat(price) : null,
    description:
      description && description.length > 0 && description !== ' '
        ? description
        : null,
  }
}

class SpecialItemForm extends React.Component {
  _setInitialValues = item => {
    this.setState({
      initialValues: {
        ...item,
        description: item.description === null ? '' : item.description,
      },
    })
  }

  componentWillMount() {
    // we need to do this so we can reset / clear the form (_handleCancel)
    if (this.props.item) {
      this._setInitialValues(this.props.item)
    }
  }

  componentWillReceiveProps(nextProps) {
    //prolly should do a comparison here
    if (nextProps.item) {
      this._setInitialValues(nextProps.item)
    }
  }
  render() {
    const { specialId } = this.props
    const { initialValues } = this.state

    return (
      <DimensionsConsumer>
        {({ width }) => {
          const isSmall = width < 439
          return (
            <Mutation mutation={UPDATE_SPECIAL_ITEM}>
              {(updateSpecialItem, { error, loading }) => (
                <Mutation
                  mutation={CREATE_SPECIAL_ITEM}
                  refetchQueries={() => [
                    { query: SPECIAL_QUERY, variables: { id: specialId } },
                  ]}
                >
                  {(createSpecialItem, { error, loading }) => (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        ...initialValues,
                        ...{ specialsIds: [specialId] },
                      }}
                      validateOnBlur={true}
                      validate={values => {
                        console.log('values', values)
                        let errors = {}
                        if (
                          values.name === null ||
                          values.name === undefined ||
                          values.name === ''
                        ) {
                          errors.name = 'Name required!'
                        }
                        if (typeof parseFloat(values.price) !== 'number') {
                          errors.price = 'Not a valid price'
                        }
                        if (values.description.length > 150) {
                          errors.description =
                            'Description is too many characters'
                        }
                        if (values.type === null || values.type === '') {
                          errors.type = 'Special item type is required!'
                        }
                        console.log('errors', errors)
                        return errors
                      }}
                      onSubmit={async (values, actions) => {
                        const sanitizedValues = sanitizeValues(values)
                        try {
                          const res = values.id
                            ? await updateSpecialItem({
                                variables: sanitizedValues,
                              })
                            : await createSpecialItem({
                                variables: sanitizedValues,
                              })
                          console.log('res.data', res.data)
                          // hide the modal after a special item is created
                          if (res && res.data.createSpecialItem) {
                            this.props.handleDismissModal()
                          }
                          actions.setSubmitting(false)
                        } catch (error) {
                          console.log('error', error)
                          actions.setSubmitting(false)
                        }
                      }}
                      render={props => {
                        const numLines = getNumberOfFormLines(
                          props.values.description,
                          80,
                        )
                        return (
                          <React.Fragment>
                            <ResponsiveInput
                              label={'Name'}
                              multiline={false}
                              numberOfLines={1}
                              name={'name'}
                              labelWidth={100}
                              onChangeText={props.setFieldValue}
                              value={props.values.name}
                              autoComplete={'off'}
                            />
                            <View
                              style={[s.row, isSmall && s.rowMobileOverride]}
                            >
                              <NumericInput
                                label={'Price'}
                                noFill={true}
                                name={'price'}
                                onChangeText={props.setFieldValue}
                                value={
                                  props.values.price
                                    ? parseFloat(props.values.price)
                                    : null
                                }
                              />
                              <SpecialTypeDropdown
                                showLabel={true}
                                maxWidth={200}
                                wrapperStyle={
                                  isSmall ? s.specialTypeWrapperMobile : null
                                }
                                onChange={props.setFieldValue}
                                value={props.values.type}
                              />
                            </View>
                            <ResponsiveInput
                              label={'Description'}
                              // multiline={true} // technically this should be multiline, but i think we want to figure out the data structure first
                              numberOfLines={numLines}
                              name={'description'}
                              labelWidth={100}
                              wrapperStyle={{ zIndex: -1 }}
                              onChangeText={props.setFieldValue}
                              value={
                                props.values.description
                                  ? props.values.description
                                  : ''
                              }
                              autoComplete={'off'}
                            />

                            <ErrorsContainer errors={props.errors} />
                            <FormButtons
                              disabled={!props.dirty || props.isSubmitting}
                              isSubmitting={props.isSubmitting}
                              handleSubmit={props.handleSubmit}
                              handleCancel={this.props.handleDismissModal}
                            />
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          )
        }}
      </DimensionsConsumer>
    )
  }
}

export default SpecialItemForm
