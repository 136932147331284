import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native'
import { Query } from 'react-apollo'
import { VENUE_INFO_QUERY } from '../../gql'
import {
  SectionHeader,
  Dropdown,
  ContentContainer,
  ResponsiveContainer,
} from '../../components'
import { colors, hashMap } from '../../functions'
import { WithSelectedVenue } from '../../layouts/components'
import {
  Categories,
  Description,
  Details,
  Info,
  HappyHours,
  Hours,
  Menus,
  Photos,
  Social,
} from './sections'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionContainer: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingHorizontal: 20,
    // shadow styles. not using these rn
    // shadowOpacity: 0.28,
    // shadowColor: colors.black0,
    // shadowRadius: 12, // shadowBlur
    // shadowOffset: { width: 0, height: 3 },
  },
  sectionContainerMobile: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
  },
})

// prettier-ignore
const Section = ({ title, subtext, component, noHeader, noRightPadding, initialValues, allVenueCategories }) => (
  <ResponsiveContainer 
    style={[s.sectionContainer, noRightPadding && { paddingRight: 0}]} 
    breakpoint={434} // 414 + 10 px padding on each side
    breakpointStyle={[s.sectionContainerMobile, noRightPadding && { paddingRight: 0}]}
    >
    {!noHeader && <SectionHeader title={title} subtext={subtext} /> }
    {component({initialValues, allVenueCategories, title, subtext})}
  </ResponsiveContainer>
)

const sections = [
  {
    id: '#contact',
    title: 'Contact',
    label: 'Contact',
    subtext: 'Add contact info so that customers can find and reach you.',
    component: Info,
  },
  {
    id: '#description',
    title: 'Description',
    label: 'Description',
    subtext: "Here you'll add a description of your venue and add photos.",
    component: Description,
  },
  {
    id: '#profile-photos',
    title: 'Profile Photos',
    label: 'Profile Photos',
    noHeader: true,
    noRightPadding: true,
    component: Photos, // i think this is stale
  },
  {
    id: '#hours',
    title: 'Hours',
    label: 'Hours',
    subtext:
      "Here you'll add your hours of operation as well as any regular happy hour times.",
    component: Hours,
  },
  {
    id: '#happy-hours',
    title: 'Happy Hours',
    label: 'Happy Hours',
    component: HappyHours,
  },
  {
    id: '#menus',
    title: 'Menus',
    label: 'Menus',
    noHeader: true,
    subtext: 'Upload hi-resolution photos of your menus here.',
    component: Menus,
  },
  {
    id: '#details',
    title: 'Details',
    label: 'Details',
    subtext: 'Here you can add info about happy hours and live music.',
    component: Details,
  },
  {
    id: '#categories',
    title: 'Categories',
    label: 'Categories',
    subtext: 'Add categories that describe your venue.',
    component: Categories,
  },
  {
    id: '#social',
    title: 'Social',
    label: 'Social',
    subtext:
      'Here you can add your social media profiles to display in the app.',
    component: Social,
  },
]

const dropdownItems = [{ id: '#all', label: 'All Pages' }, ...sections]

class ManageVenue extends React.Component {
  state = {
    sectionsHash: hashMap(dropdownItems, 'label'),
    sectionsHashHash: hashMap(dropdownItems, 'id'),
    dropdownItems: dropdownItems,
    currentSection: 'All Pages',
  }

  componentWillMount() {
    const { hash } = this.props.history.location
    if (hash && hash.length > 0 && this.state.sectionsHashHash[hash]) {
      this.setState({ currentSection: this.state.sectionsHashHash[hash].label })
    }
  }

  _handleSectionSelected = label => {
    const hash = this.state.sectionsHash[label].id
    this.setState({ currentSection: label })
    this.props.history.push(hash)
  }

  render() {
    const currentSectionProps =
      this.state.currentSection !== 'All Pages' &&
      this.state.sectionsHash[this.state.currentSection]

    return (
      <React.Fragment>
        <Dropdown
          items={this.state.dropdownItems}
          selected={this.state.currentSection}
          wrapperStyle={{ marginTop: -44 }}
          handleItemPress={this._handleSectionSelected}
        />
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.wrapper}
          scrollsToTop={true}
          // scrollEventThrottle={16}
        >
          <ContentContainer
            style={s.contentContainer}
            smallStyle={s.contentContainerMobile}
          >
            <WithSelectedVenue>
              {({ selectedVenue }) => (
                <Query
                  query={VENUE_INFO_QUERY}
                  variables={{ id: selectedVenue.id }}
                  fetchPolicy={'cache-and-network'}
                >
                  {({ data, loading, error }) => {
                    if (loading) {
                      return <ActivityIndicator />
                    }
                    if (error) {
                      console.log('error', error)
                      // TO DO: we should show an error screen here...
                      return <ActivityIndicator />
                    }
                    return (
                      <React.Fragment>
                        {this.state.currentSection === 'All Pages'
                          ? sections.map((x, i) => {
                              return (
                                <Section
                                  key={i}
                                  initialValues={data.Venue}
                                  allVenueCategories={data.allVenueCategories}
                                  {...x}
                                />
                              )
                            })
                          : currentSectionProps && (
                              <Section
                                initialValues={data.Venue}
                                allVenueCategories={data.allVenueCategories}
                                {...currentSectionProps}
                              />
                            )}
                      </React.Fragment>
                    )
                  }}
                </Query>
              )}
            </WithSelectedVenue>
          </ContentContainer>
        </ScrollView>
      </React.Fragment>
    )
  }
}

export default ManageVenue
