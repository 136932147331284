/*
 * Depreciate / no longer in use. Was only used in Demo. Use PhotoCard instead
 *
 */

import React from 'react'
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
} from 'react-native'
import { colors, fonts } from '../../functions'
import { getImgUrl } from '../../lib'

// const ratio = 354 / 230

const factor = 1.1
const image_width = Math.round(354 * factor)
const image_height = Math.round(230 * factor)

const s = StyleSheet.create({
  touchable: {
    display: 'flex',
    marginRight: '21px',
    marginBottom: '21px',
  },
  imageBackground: {
    width: image_width,
    height: image_height,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: image_width,
    height: image_height,
    borderRadius: 10,
    resizeMode: 'cover',
  },
  pressedImage: {
    // https://www.w3schools.com/cssref/css3_pr_filter.asp
    // brightness = 1 - (12 / 50)
    filter:
      'blur(7px) brightness(0.76) drop-shadow(0px 3px 3px rgba(0,0,0,0.16))',
  },
  backgroundFilter: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: '35px',
    alignItems: 'center',
    zIndex: 5,
  },
  touchWrapper: {
    display: 'flex',
    zIndex: 6,
    padding: 5,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: '13px',
    lineHeight: '18px',
    color: colors.white,
    zIndex: 6,
  },
})

const options = [
  {
    label: 'Move up',
    action: 'moveUp',
  },
  {
    label: 'Move back',
    action: 'moveBack',
  },
  {
    label: 'Make default',
    action: 'makeDefault',
  },
  {
    label: 'Remove',
    action: 'remove',
  },
]

export class PhotoCard extends React.Component {
  state = {
    pressed: false,
  }
  handlePress = () => {
    return this.setState({ pressed: !this.state.pressed })
  }
  handleMenuPress = (image, action) => {
    if (action === 'moveUp') {
      this.props.moveUp(image)
    }
    if (action === 'moveBack') {
      this.props.moveBack(image)
    }
    if (action === 'makeDefault') {
      this.props.makeDefault(image)
    }
    if (action === 'remove') {
      this.props.remove(image)
    }
    this.setState({ pressed: false })
  }
  render() {
    const { image } = this.props
    const { pressed } = this.state
    const imgUrl = typeof image === 'object' ? getImgUrl(image.handle) : image

    return (
      <TouchableOpacity
        activeOpacity={1}
        style={s.touchable}
        onPress={this.handlePress}
      >
        <ImageBackground
          source={{ uri: imgUrl }}
          style={s.imageBackground}
          imageStyle={[s.image, pressed && s.pressedImage]}
          // getting warnings adding filter to image instead of view, but the menu options are getting blurred w/ the following styles:
          // style={[s.imageBackground, pressed && s.pressedImage]}
          // imageStyle={s.image}
          resizeMode="cover"
        >
          {pressed && (
            <View style={s.backgroundFilter}>
              {options &&
                options.map(x => (
                  <TouchableOpacity
                    key={x.action}
                    activeOpacity={1}
                    style={s.touchWrapper}
                    onPress={() => this.handleMenuPress(image, x.action)}
                  >
                    <Text style={s.text}>{x.label}</Text>
                  </TouchableOpacity>
                ))}
            </View>
          )}
        </ImageBackground>
      </TouchableOpacity>
    )
  }
}

/*
 * can use filter css property in RNW
 *
 * BlurView component from RN seems like it might be compatible but it's not entirely clear
 * https://github.com/react-native-community/react-native-blur/pull/190
 *
 */
