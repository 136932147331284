import React from 'react'
import { StyleSheet, View } from 'react-native'
import { DimensionsConsumer } from '../../lib'
import { TextWithLetterSpacing } from '../../components'
import {
  colors,
  fonts,
  formatLongDateUpper,
  formatTime,
  hexToRgba,
} from '../../functions'

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  containerMobile: {
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  navTextStyle: {
    fontFamily: fonts.regular,
    fontSize: 18,
    lineHeight: 24,
    paddingVertical: 11,
    textAlign: 'center',
    color: hexToRgba(colors.white, 0.5),
  },
  navTextStyleMobile: {
    fontSize: 14,
    lineHeight: 24,
  },
})

class DateTimeHeader extends React.Component {
  state = {
    time: '',
    date: '',
  }

  componentWillMount() {
    this._setDate()
    // need to think about the interval more. currently doing 30 seconds
    this.currentTime = setInterval(this._setDate, 60000 / 2)
  }

  componentWillUnmount() {
    clearInterval(this.currentTime)
  }

  _setDate = () => {
    const date = new Date()
    this.setState({ time: formatTime(date), date: formatLongDateUpper(date) })
  }

  render() {
    return (
      <DimensionsConsumer>
        {({ width }) => {
          const isMobile = width < 414
          return (
            <View style={[s.container, isMobile && s.containerMobile]}>
              <TextWithLetterSpacing
                spacing={2}
                style={[s.navTextStyle, isMobile && s.navTextStyleMobile]}
                viewStyle={!isMobile && { marginRight: 37 }}
                children={this.state.time}
              />
              <TextWithLetterSpacing
                spacing={2}
                style={[s.navTextStyle, isMobile && s.navTextStyleMobile]}
                children={this.state.date}
              />
            </View>
          )
        }}
      </DimensionsConsumer>
    )
  }
}

export default DateTimeHeader
