import React from 'react'
import { StyleSheet, View } from 'react-native'
import { RadioButton, FormLabel } from '../../../components'
import { DimensionsConsumer } from '../../../lib'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})

const visibilityOptions = [
  {
    label: 'Show Quantity',
    value: true,
  },
  {
    label: "Don't Show",
    value: false,
  },
]

export const ShowQuantity = ({ additionalProps }) => (
  <View style={[s.wrapper]}>
    {visibilityOptions.map((item, index) => {
      const val = additionalProps.values.showQuantity === item.value
      return (
        <View key={index} style={s.radioContainer}>
          <RadioButton
            value={val}
            radioValue={item.value}
            onPress={e => {
              additionalProps.setFieldValue('showQuantity', item.value)
            }}
          />
          <FormLabel
            mr={20}
            ml={10}
            mt={4}
            children={item.label ? item.label : 'Option ' + index}
          />
        </View>
      )
    })}
  </View>
)
