import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { BaseText, MIcon } from '../../components'
import { colors } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    minWidth: 300,
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 25,
    backgroundColor: colors.primary2,
    shadowColor: colors.black0,
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.26,
    shadowRadius: 3,
    borderRadius: 10,
    borderLeftWidth: 10,
    marginTop: 20,
    justifyContent: 'space-between',
  },
  error: {
    borderLeftColor: colors.pink,
  },
  success: {
    borderLeftColor: colors.teal,
  },
})

export default class Toast extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
    }
  }

  _renderMessage = ({ type, text }, i) => (
    <View key={i} style={[s.contentWrapper, s[type]]}>
      <BaseText fs={14} color={'secondary2'}>
        {text}
      </BaseText>
      <TouchableOpacity
        onPress={() =>
          this.setState({
            messages: this.state.messages
              .slice(0, i)
              .concat(this.state.messages.slice(i + 1)),
          })
        }
        activeOpacity={0.8}
      >
        <MIcon name="close" size={24} color={colors.secondary3} />
      </TouchableOpacity>
    </View>
  )

  addMessage(message) {
    this.setState({ messages: this.state.messages.concat(message) })
  }

  render() {
    const { messages } = this.state
    const { wrapperStyles } = this.props

    return (
      <View style={[s.wrapper, wrapperStyles && wrapperStyles]}>
        {messages.map(this._renderMessage)}
      </View>
    )
  }
}
