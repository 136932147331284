import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { UPDATE_USER_INFO } from '../../../gql'
import { FormButtons, ResponsiveInput } from '../../../components'

export const Profile = initialValues => {
  return (
    <Mutation mutation={UPDATE_USER_INFO}>
      {(updateUser, { error, loading }) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            console.log('info form submit values', values)
            try {
              const res = await updateUser({ variables: values })
              console.log('res', res)
              console.log('res.data.updateUser', res.data.updateUser)
              actions.setSubmitting(false)
            } catch (error) {
              console.log('error', error)
              // actions.setErrors(parseAuthErrors(error.toString()))
              actions.setSubmitting(false)
            }
          }}
          render={props => (
            <React.Fragment>
              <ResponsiveInput
                label={'Email'}
                name={'email'}
                onChangeText={props.setFieldValue}
                value={props.values.email}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={'First Name'}
                name={'firstName'}
                onChangeText={props.setFieldValue}
                value={props.values.firstName}
                autoComplete={'off'}
              />
              <ResponsiveInput
                label={'Last Name'}
                name={'lastName'}
                onChangeText={props.setFieldValue}
                value={props.values.lastName}
                autoComplete={'off'}
              />
              <FormButtons
                disabled={!props.dirty || props.isSubmitting}
                isSubmitting={props.isSubmitting}
                handleSubmit={props.handleSubmit}
                handleCancel={() =>
                  console.log(
                    'cancel pressed. maybe reset/reinitalize the form ?',
                  )
                }
              />
            </React.Fragment>
          )}
        />
      )}
    </Mutation>
  )
}
