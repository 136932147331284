import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { View, StyleSheet, CheckBox } from 'react-native'
import { RadioButton, FormLabel, BaseText } from '../../../components'
import { UPDATE_EVENT_IS_TICKETED, EVENT_QUERY } from '../../../gql'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 20,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  withoutPermissionMessageWrapper: {
    width: '100%',
    marginBottom: 25,
  },
})

const ticketingOptions = [
  {
    label: 'Ticketed Event',
    value: true,
    radioValue: 'ticketed',
  },
  {
    label: 'Non-Ticketed Event',
    value: false,
    radioValue: 'nonTicketed',
  },
]

export const EventTicketingToggle = ({ initialTicketValues, permissions }) => {
  let currentValue = initialTicketValues.isTicketedEvent
    ? ticketingOptions[0]
    : ticketingOptions[1]

  const handleOnChange = ticketingValue => {
    if (currentValue.radioValue !== ticketingValue) {
      currentValue = ticketingOptions.find(option => {
        return option.radioValue === ticketingValue
      })
    }

    return currentValue.value
  }

  return (
    <Mutation
      mutation={UPDATE_EVENT_IS_TICKETED}
      refetchQueries={() => [
        { query: EVENT_QUERY, variables: { id: initialTicketValues.id } },
      ]}
    >
      {(updateEventIsTicketed, { error, loading }) => (
        <Formik
          initialValues={initialTicketValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            if (values.isTicketedEvent === currentValue.value) {
              return
            }

            values.isTicketedEvent = currentValue.value

            try {
              const res = await updateEventIsTicketed({
                variables: values,
              })
              console.log('res', res)
              console.log('res.data.updateEventIsTicketed', res.data)
              actions.setSubmitting(false)
            } catch (error) {
              console.log('error', error)
              actions.setSubmitting(false)
            }
          }}
          render={props => (
            <React.Fragment>
              <View style={[s.wrapper]}>
                {ticketingOptions.map((item, index) => {
                  const val = currentValue.radioValue == item.radioValue
                  return (
                    <View key={index} style={s.radioContainer}>
                      <CheckBox
                        name="isTicketedEvent"
                        checked={currentValue.value}
                        style={{ display: 'none' }}
                      />
                      <RadioButton
                        value={val}
                        isDisabled={
                          !permissions.find(
                            x => x.permission === 'MANAGE_VENUE_EVENTS_TICKETS',
                          )
                        }
                        radioValue={item.radioValue}
                        onPress={e => {
                          const isTicketedEvent = handleOnChange(
                            item.radioValue,
                          )
                          props.submitForm(e)
                        }}
                      />
                      <FormLabel
                        mr={20}
                        ml={10}
                        mt={4}
                        children={item.label ? item.label : 'Option ' + index}
                      />
                    </View>
                  )
                })}
              </View>
              {!permissions.find(
                x => x.permission === 'MANAGE_VENUE_EVENTS_TICKETS',
              ) && (
                <View style={s.withoutPermissionMessageWrapper}>
                  <BaseText
                    fs={18}
                    ta={'center'}
                    color={'pink'}
                    children={
                      'Ticketed Events is coming soon. Please contact Barcard to find out more info'
                    }
                  />
                </View>
              )}
            </React.Fragment>
          )}
        />
      )}
    </Mutation>
  )
}
