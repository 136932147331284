import React from 'react'
import { TouchableOpacity } from 'react-native'
import { BaseText } from './index'

// Touch Text
export const BaseTouchText = props => {
  const { handlePress, ...rest } = props
  return (
    <TouchableOpacity onPressIn={handlePress}>
      <BaseText {...rest} />
    </TouchableOpacity>
  )
}

//
// Venue + Event Pages
//

// venue name on venue page
export const Title = props => (
  <BaseText
    weight="bold"
    color="white"
    fs={26}
    lh={32}
    allowFontScaling={false}
    {...props}
  />
)

// address, neighborhood on venue page
export const Subtitle = props => (
  <BaseText weight="light" color="white" fs={15} lh={20} {...props} />
)

//
// Feed + Venue & Event Cards
//

// "editors choice evets", venue card title
export const FeedTitle = props => (
  <BaseText weight="light" color="white" fs={17} lh={23} {...props} />
)

export const CardTitle = props => (
  <BaseText
    weight="light"
    color="white"
    fs={20}
    lh={27}
    allowFontScaling={false}
    {...props}
  />
)

// price from text & distance, on cards
export const LabelText = props => (
  <BaseText weight="semibold" color="white" fs={11} lh={15} {...props} />
)

export const FifteenLight = props => (
  <BaseText weight="light" color="lightgray" fs={15} lh={20} {...props} />
)

//
// Profile & Edit Profile Pages
//

// edit profile page (i.e. Kevin Lanuk)
export const HeaderText = props => (
  <BaseText
    weight="bold"
    color="white"
    fs={24}
    lh={33}
    allowFontScaling={false}
    {...props}
  />
)

// edit profile page (i.e. This is a description...)
export const GrayInfoText = props => (
  <BaseText weight="light" color="lightgray" fs={15} lh={20} {...props} />
)

// edit profile page (i.e. Edit name)
export const PinkTouchText = props => {
  const { handlePress, ...rest } = props
  return (
    <TouchableOpacity onPressIn={handlePress}>
      <BaseText weight="light" color="pink" fs={15} lh={20} {...rest} />
    </TouchableOpacity>
  )
}

// form validation
export const PinkErrorText = props => (
  <BaseText
    weight="light"
    color="pink"
    ta="center"
    fs={15}
    lh={20}
    {...props}
  />
)

// My Favorites Swipe Row
export const WhiteTouchText = props => {
  const { handlePress, disabled, ...rest } = props
  return (
    <TouchableOpacity onPressIn={handlePress} disabled={disabled}>
      <BaseText weight="regular" color="white" fs={16} lh={18} {...rest} />
    </TouchableOpacity>
  )
}
