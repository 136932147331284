import React from 'react'
import { StyleSheet } from 'react-native'
import { SectionHeader, ResponsiveContainer } from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 20, // was paddingHorizontal: 20. changing for this one form
    paddingRight: 30, // see comment above
    zIndex: -1,
  },
  sectionContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: -1,
  },
})

export const Section = ({ title, children, handleDelete, outerStyle }) => (
  <ResponsiveContainer
    style={[s.sectionContainer, outerStyle && outerStyle]}
    breakpoint={434} // 414 + 10 px padding on each side
    breakpointStyle={[s.sectionContainerMobile, outerStyle && outerStyle]}
  >
    {title && <SectionHeader title={title} handleDelete={handleDelete} />}
    {children && children}
  </ResponsiveContainer>
)
