import React from 'react'
import PropTypes from 'prop-types'
import {
  Animated,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
} from 'react-native'
import { scale, colors, fonts } from '../../functions'

import BaseInput from './BaseInput'

const textOutputRange = [scale(15), scale(13)]
export default class Hoshi extends BaseInput {
  static propTypes = {
    borderColor: PropTypes.string,
    /*
     * this is used to set backgroundColor of label mask.
     * this should be replaced if we can find a better way to mask label animation.
     */
    maskColor: PropTypes.string,
    inputPadding: PropTypes.number,
    height: PropTypes.number,
    borderHeight: PropTypes.number,
  }

  static defaultProps = {
    borderColor: colors.teal,
    inputPadding: 10,
    height: 48,
    borderHeight: 3,
    labelColors: [colors.white, colors.teal],
  }

  render() {
    const {
      label,
      style: containerStyle,
      inputStyle,
      labelStyle,
      maskColor,
      borderColor,
      borderHeight,
      inputPadding,
      height: inputHeight,
      labelColors,
      renderChildren,
      ...rest
    } = this.props
    const { width, focusedAnim, value } = this.state
    const flatStyles = StyleSheet.flatten(containerStyle) || {}
    const containerWidth = flatStyles.width || width

    return (
      <View
        style={[
          styles.container,
          containerStyle,
          {
            height: inputHeight + inputPadding,
            width: containerWidth,
          },
        ]}
        onLayout={this._onLayout}
      >
        <TextInput
          ref={this.input}
          label={label}
          {...rest}
          style={[
            styles.textInput,
            inputStyle,
            {
              width,
              height: inputHeight,
              left: inputPadding,
            },
          ]}
          value={value ? value : ''}
          onBlur={this._onBlur}
          onChange={this._onChange}
          onFocus={this._onFocus}
          underlineColorAndroid={'transparent'}
        />
        <TouchableWithoutFeedback accessible={false} onPress={this.focus}>
          <Animated.View
            style={[
              styles.labelContainer,
              {
                opacity: focusedAnim.interpolate({
                  inputRange: [0, 0.5, 1],
                  outputRange: [1, 0, 1],
                }),
                top: focusedAnim.interpolate({
                  inputRange: [0, 0.5, 0.51, 1],
                  outputRange: [24, 24, 0, 0],
                }),
                left: focusedAnim.interpolate({
                  inputRange: [0, 0.5, 0.51, 1],
                  outputRange: [
                    inputPadding,
                    2 * inputPadding,
                    0,
                    inputPadding,
                  ],
                }),
              },
            ]}
          >
            <Animated.Text
              style={[
                styles.label,
                labelStyle,
                {
                  fontSize: focusedAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: textOutputRange,
                  }),
                  color: focusedAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [...labelColors],
                  }),
                  // ...focusedStyles,
                  // fontFamily: focusedAnim.interpolate({
                  //   inputRange: [0, 1],
                  //   outputRange: [fonts.light, fonts.semibold],
                  // }),
                },
              ]}
            >
              {label}
            </Animated.Text>
          </Animated.View>
        </TouchableWithoutFeedback>
        {renderChildren()}
        <View
          style={[
            styles.labelMask,
            {
              backgroundColor: maskColor,
              width: inputPadding,
            },
          ]}
        />
        <View
          style={[
            styles.border,
            { flex: 1, display: 'flex', flexDirection: 'row' },
          ]}
        >
          <Animated.View
            style={[
              styles.border,
              {
                width: focusedAnim.interpolate({
                  inputRange: [0, 0.25, 0.5, 0.75, 1],
                  outputRange: [
                    width,
                    width * 0.75,
                    width * 0.5,
                    width * 0.25,
                    0,
                  ],
                }),
                backgroundColor: colors.blackgray,
                height: borderHeight,
              },
            ]}
          />
          <Animated.View
            style={[
              styles.border,
              {
                width: focusedAnim.interpolate({
                  inputRange: [0, 0.25, 0.5, 0.75, 1],
                  outputRange: [
                    0,
                    width * 0.25,
                    width * 0.5,
                    width * 0.75,
                    width,
                  ],
                }),
                backgroundColor: borderColor,
                height: borderHeight,
              },
            ]}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // borderBottomWidth: 2,
    // borderBottomColor: '#b9c1ca',
  },
  labelContainer: {
    position: 'absolute',
  },
  label: {
    fontSize: 16,
    color: '#6a7989',
  },
  textInput: {
    position: 'absolute',
    bottom: 2,
    padding: 0,
    color: '#6a7989',
    fontSize: 18,
    // fontWeight: 'bold',
  },
  labelMask: {
    height: 24,
  },
  border: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
})
