import React from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { TrackError } from '../../../lib'
import { UPDATE_VENUE_CATEGORIES, VENUE_INFO_QUERY } from '../../../gql'
import { FormButtons, TagsInput } from '../../../components'

export const Categories = ({ initialValues, allVenueCategories }) => {
  return (
    <Mutation
      mutation={UPDATE_VENUE_CATEGORIES}
      refetchQueries={() => [
        { query: VENUE_INFO_QUERY, variables: { id: initialValues.id } },
      ]}
    >
      {(updateVenueCategories, { error, loading }) => (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: initialValues.id,
            categories: initialValues.categories,
            categoriesIds: initialValues.categories.map(x => x.id),
          }}
          validate={values => {
            let errors = {}
            // not sure if there's any errors we need to catch here ?
            return errors
          }}
          onSubmit={async (values, actions) => {
            console.log('info form submit values', values)
            try {
              const res = await updateVenueCategories({
                variables: {
                  id: values.id,
                  categoriesIds: values.categoriesIds,
                },
              })
              actions.setSubmitting(false)
              console.log(
                'res.data.updateVenueCategories',
                res.data.updateVenueCategories,
              )
            } catch (e) {
              TrackError(e)
              actions.setSubmitting(false)
              console.log('e', e)
            }
          }}
          render={props => (
            <React.Fragment>
              <TagsInput
                label={'Categories'}
                name={'categoriesIds'}
                options={allVenueCategories}
                values={props.values.categories}
                valueIds={props.values.categoriesIds}
                onChange={props.setFieldValue}
              />
              <FormButtons
                containerStyle={{ zIndex: -1 }}
                disabled={!props.dirty || props.isSubmitting}
                isSubmitting={props.isSubmitting}
                handleSubmit={props.handleSubmit}
                handleCancel={() => props.resetForm(initialValues)}
              />
            </React.Fragment>
          )}
        />
      )}
    </Mutation>
  )
}
