import { Platform } from 'react-native'
import { hexToRgba } from '../functions'

export const fonts = {
  regular: 'OpenSans',
  light: 'OpenSans-Light',
  italic: 'OpenSans-Italic',
  semibold: 'OpenSans-Semibold',
  bold: 'OpenSans-Bold',
  lightitalic:
    Platform.OS === 'ios' ? 'OpenSansLight-Italic' : 'OpenSans-LightItalic',
}

export const newColors = {
  primary1: '#17171D',
  primary2: '#21212A',
  primary3: '#25252E',
  secondary1: '#454556',
  secondary2: '#BEBED1',
  secondary3: '#DADADA',
  secondary4: '#DDDDDD',
  teal: '#16CCD9',
  brightTeal: '#00F6FF',
  pink: '#F462A8',
}

export const specialColors = {
  tealTwo: hexToRgba('#16A5D9', 0.46),
  tealThree: hexToRgba('#6288F4', 0.3),
}

export const colors = {
  white: '#FFFFFF',
  white80: 'rgba(255,255,255,0.8)',
  white75: 'rgba(255,255,255,0.7)',
  white25: 'rgba(255,255,255,0.25)',
  lightgray: newColors.secondary3,
  blackgray: newColors.secondary1,
  darkgray: newColors.secondary2,
  teal: '#16CCD9',
  brightTeal: '#00F6FF',
  pink: '#F462A8',
  blackBg: newColors.primary1,
  black: newColors.primary3,
  black10: '#101010',
  black23: newColors.primary2,
  black0: '#000000', // mostly shadows
  opaque: 'rgba(0,0,0,0)',
  /* new */
  primary1: '#17171D',
  primary2: '#21212A',
  primary3: '#25252E',
  secondary1: '#454556',
  secondary2: '#BEBED1',
  secondary3: '#DADADA',
  secondary4: '#DDDDDD',
  green: '#00FF00',
  red: '#FF0000',
  disabled: '#757575',
}

export const oldColors = {
  white: '#fff',
  white75: 'rgba(255,255,255,0.7)',
  white25: 'rgba(255,255,255,0.25)',
  lightgray: '#D4D4D4',
  blackgray: '#363636',
  darkgray: '#95989A',
  teal: '#16CCD9',
  pink: '#F462A8',
  blackBg: '#060606',
  black: '#2E2E2E',
  black23: '#232323',
}
