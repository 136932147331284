import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors, hexToRgba } from '../../functions'
import BulkCreateVenueForm from './BulkCreateVenueForm'
import { ContentContainer, MCIcon } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navbarContainer: {
    marginTop: -44,
    height: 44,
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.primary2, 0.1),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    marginHorizontal: 15,
    marginTop: -36,
    marginBottom: 10,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
    display: 'flex',
    flex: 1,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flex: 1,
  },
})

class BulkCreateVenue extends React.Component {
  state = {
    form: null,
  }

  _handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <React.Fragment>
        <View style={s.navbarContainer} />
        <TouchableOpacity
          style={s.icon}
          activeOpacity={0.7}
          onPress={this._handleBack}
        >
          <MCIcon name="arrow-left" size={24} color={colors.white} />
        </TouchableOpacity>
        <View style={s.wrapper}>
          <ContentContainer
            style={s.contentContainer}
            smallStyle={s.contentContainerMobile}
          >
            <BulkCreateVenueForm
              history={this.props.history}
              handleBack={this._handleBack}
            />
          </ContentContainer>
        </View>
      </React.Fragment>
    )
  }
}

export default BulkCreateVenue
