import React from 'react'
import * as t from 'prop-types'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { colors, fonts } from '../../functions'
import { MCIcon } from '../../components'
import { INPUT_TEXT_SIZE, INPUT_LINE_HEIGHT } from '../../config'

const s = StyleSheet.create({
  checkBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '4px',
  },
  columnWrapper: {
    flexDirection: 'column',
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
  },
  columnText: {
    paddingBottom: 15,
  },
  labelText: {
    fontFamily: fonts.regular,
    color: colors.secondary2,
    fontSize: `${INPUT_TEXT_SIZE}px`,
    lineHeight: `${INPUT_LINE_HEIGHT}px`,
  },
  checkBoxCont: {
    backgroundColor: colors.opaque,
    borderWidth: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3px',
    justifyContent: 'flex-end',
  },
})

export const Checkbox = ({
  label,
  value,
  column,
  labelStyle,
  wrapperStyle,
  onChange,
}) => (
  <View
    style={[
      s.checkBoxRow,
      column && s.columnWrapper,
      wrapperStyle && wrapperStyle,
    ]}
  >
    <View style={s.labelContainer}>
      <Text
        style={[s.labelText, column && s.columnText, labelStyle && labelStyle]}
      >
        {label}
      </Text>
    </View>
    <TouchableOpacity
      activeOpacity={1}
      style={s.checkBoxCont}
      onPress={() => onChange(!value)}
    >
      <MCIcon
        name={value ? 'checkbox-marked' : 'checkbox-blank-outline'}
        color={value ? colors.teal : colors.secondary2}
        size={24}
      />
    </TouchableOpacity>
  </View>
)

Checkbox.propTypes = {
  label: t.string,
  value: t.bool.isRequired,
  onChange: t.func.isRequired,
  /** if true, make the layout a column instead of a row */
  column: t.bool,
}

/*
 *  example with a link: 
 * 
  <View style={s.checkBoxRow}>
    <Text style={s.checkboxText}>{`I have read the `}</Text>
    <TouchableOpacity style={s.linkCont} activeOpacity={0.7} onPress={this._handleTerms}>
      <Text style={[s.checkboxText, s.linkText]}>terms</Text>
    </TouchableOpacity>
    <Text style={s.checkboxText}>{` and `}</Text>
    <TouchableOpacity style={s.linkCont} activeOpacity={0.7} onPress={this._handlePrivacy}>
      <Text style={[s.checkboxText, s.linkText]}>privacy policy</Text>
    </TouchableOpacity>
    <TealCheckBox onPress={() => this.setState({ termsChecked: !this.state.termsChecked })} checked={this.state.termsChecked} />
  </View>
 * 
 */
