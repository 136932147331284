import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'
import { Switch } from 'react-router-dom'
import Routing from '../../utils/routing'
import { childRoutes } from '../../config'

const Route = Routing.Route

// Radius of the background blur
const BLUR_RADIUS = 7

// Margin of the background to avoid gross blurry edges
const backgrounBlurPadding = BLUR_RADIUS * -3

const s = StyleSheet.create({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  f1: {
    flex: 1,
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    left: backgrounBlurPadding,
    right: backgrounBlurPadding,
    top: backgrounBlurPadding,
    bottom: backgrounBlurPadding,
  },
  blur: {
    // https://www.w3schools.com/cssref/css3_pr_filter.asp
    // brightness = 1 - (12 / 50)
    filter: `blur(${BLUR_RADIUS}px) brightness(1) drop-shadow(0px 3px 3px rgba(103,103,210,0.23))`,
  },
})

const BLURRED_VIEWS = [
  '/solutions',
  '/resources',
  '/login',
  '/employee-login',
  '/confirm-invite',
]

export const PublicLayout = ({ history }) => {
  const isBlurred =
    BLURRED_VIEWS.indexOf(history.location.pathname) < 0 ? false : true

  return (
    <ImageBackground
      source={require('../../assets/bartop-fade.jpg')}
      style={[s.column, s.f1]}
      imageStyle={[s.image, isBlurred && s.blur]}
      resizeMode="cover"
      pointerEvents={'box-none'}
    >
      <View style={s.f1} pointerEvents={'box-none'}>
        <Switch>
          {childRoutes.public.map(
            ({ routeKey, exact, path, component, stackProps, ...rest }, i) => {
              return (
                <Route
                  key={i}
                  exact={exact}
                  path={path}
                  component={component}
                  {...stackProps}
                  {...rest}
                />
              )
            },
          )}
        </Switch>
      </View>
    </ImageBackground>
  )
}
