import React from 'react'
import { View, StyleSheet } from 'react-native'
import { hashMap } from '../../../functions'
import { AutoCompleteWeb, BaseText, FormLabel } from '../../../components'
import { timeOpts } from './timeOpts'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export class TimeInput extends React.Component {
  state = {
    timeOpts: timeOpts,
    timeOptsHash: hashMap(timeOpts, 'value'),
    timeOptsNameHash: hashMap(timeOpts, 'name'),
  }
  handleSelect = (field, item) => {
    /*
     * should check to see that endTime > startTime
     * also need to add logic to pull endValue instead of value
     */
    this.props.onChange(field, item.value)
  }

  handleInput = (field, text) => {
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 7 && this.state.timeOptsNameHash[text]) {
      this.handleSelect(field, this.state.timeOptsNameHash[text])
    }
  }

  handleSearch = (query, item) => {
    if (!query || !item) {
      return false
    }

    const { timeOpts } = this.state
    const regex = /^0?([12]?\d)\s*:?\s*((?![\s:])[0-5]?\d)?\s*([ap]m?)?$/i
    const match = query.match(regex)
    const itemMatch = item.name.match(regex)

    if (!match || !itemMatch) {
      return false
    }

    let [hour, minute, standard] = query.match(regex).slice(1)
    let [itemHour, itemMinute, itemStandard] = item.name.match(regex).slice(1)

    if (parseInt(hour) === 0) {
      hour = '12'
      minute = minute === undefined ? '' : minute
      standard = 'am'
    } else if (parseInt(hour) > 12) {
      hour = `${parseInt(hour) - 12}`
      minute = minute === undefined ? '' : minute
      standard = 'pm'
    } else {
      hour = `${parseInt(hour)}`
      minute = minute === undefined ? '' : minute
      standard = (standard || '').toLowerCase()
    }

    if (parseInt(itemHour) === 0) {
      itemHour = '12'
      itemMinute = itemMinute === undefined ? '' : itemMinute
      itemStandard = 'am'
    } else if (parseInt(itemHour) > 12) {
      itemHour = `${parseInt(itemHour) - 12}`
      itemMinute = itemMinute === undefined ? '' : itemMinute
      itemStandard = 'pm'
    } else {
      hour = `${parseInt(hour)}`
      itemMinute = itemMinute === undefined ? '' : itemMinute
      itemStandard = (itemStandard || '').toLowerCase()
    }

    return (
      itemHour === hour &&
      itemMinute.startsWith(minute) &&
      itemStandard.startsWith(standard)
    )
  }

  handleSort = (a, b, value) => {
    return a.label > b.label ? -1 : 1
  }

  render() {
    const { timeOpts, timeOptsHash } = this.state
    const { name, value, textAlign, disabled } = this.props
    const innerMaxWidth = this.props.innerMaxWidth || 120

    const sValue = timeOptsHash[value] ? timeOptsHash[value].name : null

    return (
      <View style={s.wrapper}>
        <AutoCompleteWeb
          items={timeOpts}
          handleSelect={text => this.handleSelect(name, text)}
          handleInput={text => this.handleInput(name, text)}
          value={sValue ? sValue : ''}
          wrapperStyle={{ marginRight: 15 }}
          maxWidth={innerMaxWidth}
          width={'100%'}
          textAlign={textAlign}
          disabled={disabled}
          onSearch={this.handleSearch}
          onSort={this.handleSort}
        />
      </View>
    )
  }
}
