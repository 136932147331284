import React from 'react'
import { BreadCrumb } from '../../../components'
import { ViewPropTypes } from 'react-native-web'

export class EventBreadcrumb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentState:
        props.isEdit && props.currentTab ? props.currentTab : 'info',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTab && nextProps.isEdit) {
      this.setState({ currentState: nextProps.currentTab })
    }
  }

  handleClick = prop => {
    this.setState({ currentState: prop.state })
    this.props.onPress(prop)
  }

  render() {
    if (!this.props.isEdit) {
      return <React.Fragment />
    }

    return (
      <BreadCrumb
        items={[
          {
            path: 'https://google.com',
            label: '1. ',
            underlinedLabel: 'Basic Info',
            state: 'info',
          },
          {
            path: 'https://google.com',
            label: '2. ',
            underlinedLabel: 'Ticketing',
            state: 'ticket',
          },
          {
            path: 'https://google.com',
            label: '3. ',
            underlinedLabel: 'Publishing',
            state: 'publish',
          },
        ]}
        onPress={this.handleClick}
        currentState={this.state.currentState}
      />
    )
  }
}
