import React from 'react'
import { Query, Mutation } from 'react-apollo'
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { colors } from '../../functions'
import { SPECIAL_QUERY, DELETE_SPECIAL_ITEM } from '../../gql'
import {
  BaseText,
  FormButton,
  MCIcon,
  ContentContainer,
  FixedWidthContainer,
} from '../../components'
import { Section, SpecialItemForm, SpecialForm } from './components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: colors.primary2,
    height: 68,
    paddingBottom: 12,
    paddingHorizontal: 20,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  addSpecialItemButton: {
    // layout
    marginTop: 10,
    marginBottom: 30,
    alignSelf: 'center',
    // style overrides
    height: 40,
    borderRadius: 10,
  },
  addModal: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  addModalMobile: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignSelf: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
})

class Special extends React.Component {
  state = {
    specialId: null,
    action: null,
    label: null,
    addModalIsVisible: false,
    deleteModalIsVisible: false,
    specialItemToDeleteId: null, // holds the id of the specialItem we want to delete
  }

  _setInitialState = pathname => {
    // split the the pathname
    const paths = pathname.split('/')
    // parse a path that looks like this: /special/cjqpjpv8l002j0zz4cl3tdmxs/edit
    if (paths[2].length > 20 && paths[2].charAt(0) === 'c') {
      this.setState({
        specialId: paths[2],
        action: paths[3],
        label: paths[3]
          ? paths[3].charAt(0).toUpperCase() + paths[3].slice(1)
          : '',
      })
      // parse a path that looks like this: /special/new
    } else if (paths[2] === 'new' || paths[2] === 'add') {
      this.setState({
        specialId: null,
        action: 'new',
        label: 'New',
      })
    } else {
      // to do:
      // show an error message to the user or redirect them
      console.log('ERROR: something is wrong with the URL')
    }
  }

  componentWillMount() {
    this._setInitialState(this.props.location.pathname)
  }

  componentWillReceiveProps(nextProps) {
    // we need this for when a new special was created, and we redirect to the edit page
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this._setInitialState(nextProps.location.pathname)
    }
  }

  // redirect to the Edit Special view after a new special was created
  _handleNewSpecial = id => {
    this.props.history.replace(`${id}/edit`)
  }

  // show the form for adding a new special item
  _handleAddSpecialItem = () => {
    this.setState({ addModalIsVisible: true })
  }

  // allow us to dismiss the add special item modal from the SpecialItemForm component
  _handleDismissModal = () => {
    this.setState({ addModalIsVisible: false })
  }

  // show the confirm delete modal & temporarily store the id of the item to be deleted in state
  _handleDeleteSpecialItem = id => {
    this.setState({ specialItemToDeleteId: id, deleteModalIsVisible: true })
  }

  _handleBack = () => {
    // To do:
    // if we are referred from the specials back, we want to use go back so we have the cool animation
    // if a user is referred to a naked page (or opens in new tab), the back functionality won't work
    // this is the same for all stack changes

    // we cannot read a 'previous' on history, and history.length doesn't help much.
    // we either need to do something w/ our stack implmenetation, or perhaps we should just keep
    // track of something like 'showBackButton' in react context. similar to: https://bit.ly/2CqF4eE

    this.props.history.goBack()
    // this.props.history.push('/specials')
  }

  _renderAddModalContent = () => (
    <FixedWidthContainer
      style={s.addModal}
      width={600}
      padding={22}
      breakpointStyle={s.addModalMobile}
    >
      <Section title={'New Special Item'} outerStyle={{ marginBottom: 0 }}>
        <SpecialItemForm
          item={{
            id: null,
            name: '',
            description: null,
            price: null,
          }}
          specialId={this.state.specialId}
          handleDismissModal={this._handleDismissModal}
        />
      </Section>
    </FixedWidthContainer>
  )

  _renderDeleteConfirmModal = () => (
    <Mutation
      mutation={DELETE_SPECIAL_ITEM}
      refetchQueries={() => [
        { query: SPECIAL_QUERY, variables: { id: this.state.specialId } },
      ]}
    >
      {(deleteSpecialItem, { error, loading }) => (
        <FixedWidthContainer style={s.modal} width={400} padding={22}>
          <View style={s.modalMessageContainer}>
            <BaseText
              fs={16}
              lh={24}
              color={'secondary3'}
              children={'Are you sure you want to delete?'}
            />
          </View>
          <View style={s.modalControls}>
            <FormButton
              text={'Cancel'}
              outerStyle={{ marginRight: 0 }}
              textColor={colors.teal}
              handlePress={() => this.setState({ deleteModalIsVisible: false })}
            />
            <FormButton
              text={'Delete'}
              outerStyle={{ marginRight: 0 }}
              textColor={colors.pink}
              handlePress={async () => {
                // to do:
                // need to make this cascade & delete all children !
                try {
                  console.log(
                    'specialItem.id',
                    this.state.specialItemToDeleteId,
                  )
                  const res = await deleteSpecialItem({
                    variables: { id: this.state.specialItemToDeleteId },
                  })
                  console.log('successfully removed Special Item', res)
                  if (res) {
                    this.setState({
                      deleteModalIsVisible: false,
                      specialItemToDelete: null,
                    })
                  }
                } catch (e) {
                  console.log('error removing Special', e)
                }
              }}
            />
          </View>
        </FixedWidthContainer>
      )}
    </Mutation>
  )

  render() {
    const {
      specialId,
      label,
      addModalIsVisible,
      deleteModalIsVisible,
    } = this.state

    return (
      <View style={s.wrapper}>
        <View style={s.header}>
          <TouchableOpacity activeOpacity={0.7} onPress={this._handleBack}>
            <MCIcon name="arrow-left" size={24} color={colors.white} />
          </TouchableOpacity>
          <BaseText
            fs={20}
            lh={27}
            weight={'light'}
            children={`${label} Special`}
          />
        </View>
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.scrollContainer}
          scrollsToTop={true}
        >
          <ContentContainer
            style={s.contentContainer}
            smallStyle={s.contentContainerMobile}
          >
            {specialId ? (
              <Query
                query={SPECIAL_QUERY}
                variables={{ id: specialId }}
                fetchPolicy={'cache-and-network'}
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return <ActivityIndicator />
                  }
                  if (error) {
                    console.log('error', error)
                    // TO DO: we should show an error screen here...
                    return <ActivityIndicator />
                  }
                  return (
                    <React.Fragment>
                      <Section title={`${label} Special`}>
                        <SpecialForm special={data.Special} />
                      </Section>
                      <FormButton
                        outerStyle={s.addSpecialItemButton}
                        text={'Add special item'}
                        textColor={colors.teal}
                        handlePress={this._handleAddSpecialItem}
                      />

                      {data.Special.items &&
                        data.Special.items.map((x, i) => (
                          <Section
                            title={`Special Item #${i + 1}`}
                            key={`${x.id}-${i}`}
                            handleDelete={() =>
                              this._handleDeleteSpecialItem(x.id)
                            }
                          >
                            <SpecialItemForm
                              item={x}
                              index={i}
                              specialId={data.Special.id}
                            />
                          </Section>
                        ))}
                    </React.Fragment>
                  )
                }}
              </Query>
            ) : (
              <Section title={`${label} Special`}>
                <SpecialForm
                  special={{
                    name: '',
                    description: null,
                    days: [],
                    startTime: null,
                    endTime: null,
                    published: false,
                  }}
                  handleNewSpecial={this._handleNewSpecial}
                />
              </Section>
            )}
          </ContentContainer>
        </ScrollView>
        <Modal
          isVisible={addModalIsVisible}
          onBackdropPress={() => this.setState({ addModalIsVisible: false })}
        >
          {this._renderAddModalContent()}
        </Modal>
        <Modal
          isVisible={deleteModalIsVisible}
          onBackdropPress={() => this.setState({ deleteModalIsVisible: false })}
        >
          {this._renderDeleteConfirmModal()}
        </Modal>
      </View>
    )
  }
}

export default Special
