import React from 'react'
import { StyleSheet } from 'react-native'
import { SectionHeader, ResponsiveContainer } from '../../../components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  sectionContainer: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingHorizontal: 20,
    paddingBottom: 17,
    zIndex: 1,
  },
  sectionContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1,
  },
})

export const Section = ({
  title,
  subtext,
  // this is the component that was passed in (...info-sections, organization-sections)
  component,
  additionalProps,
  organizations,
  venues,
  handleDelete,
}) => (
  <ResponsiveContainer
    style={s.sectionContainer}
    breakpoint={434} // 414 + 10 px padding on each side
    breakpointStyle={s.sectionContainerMobile}
  >
    {title === '' ? (
      <React.Fragment />
    ) : (
      <SectionHeader
        title={title}
        subtext={subtext}
        handleDelete={handleDelete}
      />
    )}
    {component({
      additionalProps,
      organizations,
      venues
    })}
  </ResponsiveContainer>
)
