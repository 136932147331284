import gql from 'graphql-tag'
import { VenueFragments } from './fragments'

/*
 * Update the venue's social details
 *
 */

export const UPDATE_VENUE_SOCIAL = gql`
  mutation(
    $id: ID!
    $instagramHandle: String
    $facebookUrl: String
    $yelpId: String
  ) {
    updateVenueSocial(
      id: $id
      instagramHandle: $instagramHandle
      facebookUrl: $facebookUrl
      yelpId: $yelpId
    ) {
      ...VenueSocialDetails
      venue {
        id
        name
      }
    }
  }
  ${VenueFragments.social}
`

/*
 * Create social details for a venue
 *
 */

export const CREATE_VENUE_SOCIAL = gql`
  mutation(
    $venueId: ID!
    $instagramHandle: String
    $facebookUrl: String
    $yelpId: String
  ) {
    createVenueSocial(
      venueId: $venueId
      instagramHandle: $instagramHandle
      facebookUrl: $facebookUrl
      yelpId: $yelpId
    ) {
      ...VenueSocialDetails
      venue {
        id
        name
      }
    }
  }
  ${VenueFragments.social}
`
