import React from 'react'
import { Circle, G, Path, Rect, Svg } from 'react-native-svg'
import { scale } from '../../functions'

const height = 100.301
const width = 100.301
const viewBox = `0 0 ${width} ${height}`

export const Person = () => (
  <Svg height={scale(height)} width={scale(width)} viewBox={viewBox}>
    <G transform="translate(-3404 3663.999)">
      <Rect
        width="100.301"
        height="100.301"
        transform="translate(3404 -3664)"
        fill="#fff"
        opacity="0"
      />
      <G transform="translate(3426 -3643.248)">
        <Path
          d="M59.338,35.752V28.835A13.835,13.835,0,0,0,45.5,15H17.835A13.835,13.835,0,0,0,4,28.835v6.917"
          transform="translate(-4 26.504)"
          fill="none"
          stroke="#bebed1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <Circle
          cx="13.835"
          cy="13.835"
          r="13.835"
          transform="translate(13.835 0)"
          strokeWidth="2"
          stroke="#bebed1"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
    </G>
  </Svg>
)
