import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const TicketFragments = {
  details: gql`
    fragment TicketOptionDetails on TicketOption {
      id
      name
      description
      maxQuantity
      price
      isDeleted
    }
  `,
  ticketsAllowedPerOrder: gql`
    fragment TicketsAllowedPerOrder on TicketOption {
      minimumAllowed
      maximumAllowed
    }
  `,
  showQuantity: gql`
    fragment TicketOptionShowQuantity on TicketOption {
      showQuantity
    }
  `,
  fee: gql`
    fragment TicketOptionFee on TicketOption {
      fee
    }
  `,
  status: gql`
    fragment TicketOptionStatusVars on TicketOptionStatus {
      id
      value
      displayName
    }
  `,
  event: gql`
    fragment TicketOptionEvent on Event {
      id
    }
  `,
  onSale: gql`
    fragment TicketOptionOnSale on TicketOption {
      salesStart
      salesEnd
      sellAfterTicket

      soldAfterTicket {
        ...TicketOptionDetails
        ...TicketsAllowedPerOrder
        ...TicketOptionShowQuantity
        ...TicketOptionFee
        salesStart
        salesEnd
        sellAfterTicket

        status {
          ...TicketOptionStatusVars
        }

        event {
          ...TicketOptionEvent
        }
      }
    }
  `,
}
