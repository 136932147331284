import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors, hexToRgba } from '../../functions'
import { BaseText, MCIcon } from '../../components'

const s = StyleSheet.create({
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 17,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  lineBreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginVertical: 7,
  },
})

export const SectionTitle = props => (
  <BaseText
    fs={20}
    lh={27}
    mb={7}
    weight={'semibold'}
    color={'secondary3'}
    {...props}
  />
)

export const SectionSubtitle = props => (
  <BaseText
    fs={14}
    lh={19}
    mb={9}
    weight={'light'}
    color={'secondary2'}
    {...props}
  />
)

export const SectionSubheader = props => (
  <BaseText
    fs={14}
    lh={19}
    mt={19}
    mb={20}
    weight={'regular'}
    color={'secondary3'}
    {...props}
  />
)

export const SectionHeader = ({ title, subtext, handleDelete, customLink }) => (
  <View style={s.sectionHeader}>
    <View style={s.row}>
      <SectionTitle children={title} />
      {customLink && (
        <TouchableOpacity onPressIn={customLink.handleClick}>
          <BaseText fs={14} color={'teal'} children={customLink.text} />
        </TouchableOpacity>
      )}
      {handleDelete && (
        <TouchableOpacity activeOpacity={0.7} onPress={handleDelete}>
          <MCIcon
            name={'trash-can-outline'}
            color={colors.secondary3}
            size={20}
          />
        </TouchableOpacity>
      )}
    </View>
    {subtext && <SectionSubtitle children={subtext} />}
    <View style={s.lineBreak} />
  </View>
)
