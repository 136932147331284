import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { scale, fonts, colors } from '../../functions'

const iconSize = scale(20)
const height = scale(44)

const s = StyleSheet.create({
  outer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  label: {
    fontFamily: fonts.regular,
    fontSize: scale(16),
    lineHeight: scale(22),
    color: colors.white,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: hexToRgba(colors.black23, 0.5),
    marginBottom: scale(2),
    borderBottomWidth: scale(1),
    borderBottomColor: colors.lightgray,
  },
  wrapperFocused: {
    borderBottomColor: colors.teal,
  },
  input: {
    display: 'flex',
    flex: 1,
    height: height,
    fontFamily: fonts.light,
    fontSize: scale(15),
    lineHeight: scale(20),
    color: '#fff',
    // paddingLeft: scale(13),
    marginVertical: 0,
  },
  icon: {
    marginTop: scale(4),
    paddingRight: scale(17),
  },
})

export default class UnderlineInput extends React.Component {
  state = {
    focused: false,
    options: {},
  }
  componentWillMount() {
    let options = {}
    if (this.props.maskType && this.props.maskType === 'datetime') {
      options.format = 'MM/YY'
      return this.setState({ options })
    }
    if (
      this.props.maskType &&
      this.props.maskType === 'credit-card' &&
      this.props.obfuscated
    ) {
      options.obfuscated = true
      return this.setState({ options })
    }
    if (
      this.props.maskType &&
      this.props.maskType === 'custom' &&
      this.props.cvcHide === true
    ) {
      options.mask = '*****'
      return this.setState({ options })
    }
    if (
      this.props.maskType &&
      this.props.maskType === 'custom' &&
      this.props.ccHide === true
    ) {
      options.mask = '**** **** **** ****'
      return this.setState({ options })
    }
  }
  handleChange = value => {
    // remember that onChangeText will be Formik's setFieldValue
    //let rawValue = this.refs.maskedInput.getRawValue()
    //console.log('rawValue', rawValue)
    this.props.onChangeText(this.props.name, value)
  }
  handleBlur = () => {
    // remember that onBlur will be Formik's setFieldTouched
    this.props.onBlur(this.props.name, true)
  }
  handleFocus = () => {
    this.setState({ focused: true })
  }
  handleOnEndEditing = () => {
    this.setState({ focused: false })
  }

  render() {
    // we want to pass through all the props except for onChangeText & onBlur
    const {
      outerStyle,
      maskType,
      label,
      onChangeText,
      onBlur,
      error,
      ...otherProps
    } = this.props
    const { focused } = this.state
    return (
      <View style={[s.outer, outerStyle && outerStyle]}>
        {label && <Text style={s.label} children={label} />}
        <View style={[s.wrapper, focused && s.wrapperFocused]}>
          <TextInputMask
            // TextInputMask props
            ref={ref => (this.maskedInput = ref)}
            type={maskType ? maskType : 'only-numbers'}
            options={this.state.options}
            // regular TextInput props
            keyboardAppearance={'dark'}
            style={s.input}
            onChangeText={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onEndEditing={this.handleOnEndEditing}
            underlineColorAndroid="transparent"
            placeholderTextColor={colors.darkgray}
            selectionColor={colors.teal}
            {...otherProps}
          />
          {error && (
            <Icon
              name={'alert-circle'}
              size={iconSize}
              color={colors.pink}
              style={[s.icon, this.state.password && s.iconPassword]}
            />
          )}
        </View>
      </View>
    )
  }
}
