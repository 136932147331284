import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const VenueFragments = {
  basic: gql`
    fragment VenueBasics on Venue {
      id
      name
    }
  `,
  details: gql`
    fragment VenueDetails on Venue {
      id
      name
      address
      phoneNumber
      intlPhoneNumber
      website
      description
      happyHourDetails
      liveMusicDetails
      hours(orderBy: day_ASC) {
        id
        day
        startTime
        endTime
      }
      happyHours(orderBy: day_ASC) {
        id
        day
        startTime
        endTime
      }
      categories(orderBy: name_ASC) {
        id
        name
      }
    }
  `,
  social: gql`
    fragment VenueSocialDetails on VenueSocial {
      id
      instagramHandle
      facebookUrl
      yelpId
    }
  `,
  photoDetails: gql`
    fragment VenuePhotoDetails on VenuePhoto {
      id
      feature
      profile
      menu
      foodDrink
      experience
      published
      filename
      handle
      mimetype
      size
      url
    }
  `,
  hoursDetails: gql`
    fragment HoursDetails on HoursPeriod {
      id
      day
      startTime
      endTime
      venue {
        # this should be in separate fragments as there could be a circular reference to the venue
        id
        hours(orderBy: day_ASC) {
          id
          day
          startTime
          endTime
        }
      }
    }
  `,
  happyHoursDetails: gql`
    fragment HappyHoursDetails on HappyHoursPeriod {
      id
      day
      startTime
      endTime
      venue {
        # this should be in separate fragments as there could be a circular reference to the venue
        id
        happyHours(orderBy: day_ASC) {
          id
          day
          startTime
          endTime
        }
      }
    }
  `,
}
