import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { MIcon } from '../index'
import { scale } from '../../functions'

const iconColor = '#232323'
const iconSize = scale(20)

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: scale(2.5),
    backgroundColor: '#363636',
  },
  icon: {},
  text: {
    fontFamily: 'OpenSans-Regular',
    fontSize: scale(13),
    lineHeight: scale(18),
    color: '#16CCD9',
    paddingVertical: scale(6),
    paddingLeft: scale(6),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: scale(6),
    paddingLeft: scale(3),
    alignItems: 'baseline',
  },
})

export const Pill = ({ item, handleRemove }) => (
  <View style={s.container}>
    <Text style={s.text}>{item && item.name}</Text>
    <TouchableOpacity
      style={s.iconContainer}
      onPress={() => handleRemove(item)}
    >
      <MIcon name="close" size={iconSize} color={iconColor} style={s.icon} />
    </TouchableOpacity>
  </View>
)
