import { Platform, StyleSheet } from 'react-native'
import { INPUT_TEXT_SIZE, INPUT_LINE_HEIGHT } from '../../config'

export const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
  },
  button: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '40px',
    marginBottom: '5px',
    width: '169px',
    height: '44px',
  },
  buttonText: {
    fontFamily: 'OpenSans',
    // fontSize: '15px',
    // lineHeight: '18px',
    fontSize: `${INPUT_TEXT_SIZE}px`,
    lineHeight: `${INPUT_LINE_HEIGHT}px`,
    fontWeight: '400',
    color: '#fff',
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconStyle: {
    marginRight: '15px',
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 6 },
        shadowOpacity: 0.26,
        shadowRadius: 6,
      },
      android: {
        elevation: 3,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 6 },
        shadowOpacity: 0.26,
        shadowRadius: 6,
      },
    }),
  },
  iconButton: {
    height: '44px',
    width: '44px',
    borderRadius: '44px',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
