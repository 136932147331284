import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from '../../functions'
import { BaseText, MCIcon } from '../../components'
import { Mutation } from 'react-apollo'
import AssignVenueForm from './AssignVenueForm'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  icon: {
    marginHorizontal: 15,
    marginTop: -36,
    marginBottom: 10,
  },
})

class OrganizationAssignVenue extends React.Component {
  state = {
    form: null,
  }

  _handleBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <View style={s.wrapper}>
        <TouchableOpacity
          style={s.icon}
          activeOpacity={0.7}
          onPress={this._handleBack}
        >
          <MCIcon name="arrow-left" size={24} color={colors.white} />
        </TouchableOpacity>
        <AssignVenueForm
          formRef={form => this.state.form || this.setState({ form })}
          history={this.props.history}
          handleBack={this._handleBack}
        />
      </View>
    )
  }
}

export default OrganizationAssignVenue
