/*
 * somehow make this a field set for Aria purposes? So tab / down skips mon-sat?
 * rnw checkbox? https://necolas.github.io/react-native-web/storybook/?selectedKind=Components&selectedStory=CheckBox&full=0&addons=0&stories=1&panelRight=0
 *
 */

import React from 'react'
import * as t from 'prop-types'
import { StyleSheet } from 'react-native'
import { Checkbox, Row } from '../../components'

const s = StyleSheet.create({
  rowStyle: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginVertical: 15,
  },
  checkboxWrapper: {
    marginTop: 5,
    marginRight: 30,
  },
})

const intialState = {
  SUNDAY: false,
  MONDAY: false,
  TUESDAY: false,
  WEDNESDAY: false,
  THURSDAY: false,
  FRIDAY: false,
  SATURDAY: false,
}

// const daysAbbr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const daysAbbr = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export class DaysInput extends React.Component {
  state = { ...intialState }

  _setInitialState = value => {
    const newState = value.reduce((tot, curr) => {
      const newTot = Object.assign({}, tot, { [curr]: true })
      return newTot
    }, this.state)
    this.setState(newState)
  }

  componentWillMount() {
    if (this.props.value) {
      this._setInitialState(this.props.value)
    } else {
      return
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      if (nextProps.value.toString() !== this.props.value.toString())
        this._setInitialState(nextProps.value)
    } else {
      return
    }
  }

  dispatchChange = () => {
    let days = Object.entries(this.state)
      .filter(x => x[1] === true)
      .map(x => x[0])
    this.props.onChange(days)
  }

  _handleChange = (day, value) => {
    this.setState({ ...this.state, [day]: value }, () => this.dispatchChange())
  }

  render() {
    return (
      <Row style={s.rowStyle}>
        {Object.keys(this.state).map((x, i) => (
          <Checkbox
            key={x}
            column={true}
            wrapperStyle={s.checkboxWrapper}
            label={daysAbbr[i]}
            onChange={val => this._handleChange(x, val)}
            value={this.state[x]}
          />
        ))}
      </Row>
    )
  }
}

DaysInput.propTypes = {
  days: t.array.isRequired,
  onChange: t.func.isRequired,
}

DaysInput.defaultProps = {
  days: [],
}
