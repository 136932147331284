import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors, hashMap } from '../../functions'
import { TagsPillNew, DropdownModal } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 10,
    marginBottom: 20,
    backgroundColor: colors.primary3,
  },
  tagsRow: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 15,
  },
})

function mapOptions(options) {
  return options.map(x => {
    return {
      ...x,
      label: x.name,
    }
  })
}

export class TagsInputNew extends React.Component {
  state = {
    opts: mapOptions(this.props.options),
    optsIdMap: hashMap(this.props.options, 'id'),
    optsNameMap: hashMap(this.props.options, 'name'),
    selectedItems: this.props.values || [],
    selectedIds: this.props.valueIds || [],
    value: '',
  }
  handleRemove = item => {
    this.setState(
      {
        selectedItems: this.state.selectedItems.filter(x => x.id !== item.id),
        selectedIds: this.state.selectedIds.filter(x => x !== item.id),
      },
      () => this.props.onChange('categoriesIds', this.state.selectedIds),
    )
  }
  handleSelect = (field, items) => {
    /*
     * should check to see that endTime > startTime
     * also need to add logic to pull endValue instead of value
     */
    this.setState(
      {
        value: '',
        selectedItems: items,
        selectedIds: items.map(({ id }) => id),
      },
      () => this.props.onChange('categoriesIds', this.state.selectedIds),
    )
    // this.props.onChange(field, item.value)
  }

  handleInput = (field, text) => {
    this.setState({
      value: text,
    })
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 7 && this.state.optsNameMap[text]) {
      this.handleSelect(field, this.state.optsNameMap[text])
    }
  }

  handleAddTag = () => {
    this.props.onAddButtonClick()
  }

  render() {
    const { opts, selectedItems, selectedIds, value } = this.state
    const { name } = this.props

    // lets only show options that aren't yet selected
    const options = opts.map(item => ({
      item: item,
      selected: selectedIds.indexOf(item.id) !== -1,
    }))

    return (
      <DropdownModal
        title={'Event Category'}
        items={options}
        handleSubmit={items => this.handleSelect(name, items)}
        render={openDropdownModal => (
          <View style={s.wrapper}>
            <View style={s.tagsRow}>
              {selectedItems &&
                selectedItems.length > 0 &&
                selectedItems.map((x, i) => (
                  <TagsPillNew
                    key={i}
                    item={x}
                    handleClick={this.handleRemove}
                  />
                ))}
              <TagsPillNew
                item={{ name: 'ADD TYPE' }}
                handleClick={() => {
                  openDropdownModal()
                }}
                isButton={true}
              />
            </View>
          </View>
        )}
      />
    )
  }
}
