export const dayEnumMap = []
dayEnumMap[0] = 'SUNDAY'
dayEnumMap[1] = 'MONDAY'
dayEnumMap[2] = 'TUESDAY'
dayEnumMap[3] = 'WEDNESDAY'
dayEnumMap[4] = 'THURSDAY'
dayEnumMap[5] = 'FRIDAY'
dayEnumMap[6] = 'SATURDAY'

export const dayMap = []
dayMap[0] = 'Sunday'
dayMap[1] = 'Monday'
dayMap[2] = 'Tuesday'
dayMap[3] = 'Wednesday'
dayMap[4] = 'Thursday'
dayMap[5] = 'Friday'
dayMap[6] = 'Saturday'

export function minutesToTime(mins) {
  // modulus operater to get remainder (i.e. 65 % 60 = 5)
  const minutes = mins % 60
  // number of hours. can be > 24 in instances where the venue closes after midnight
  const hours = (mins - minutes) / 60
  // add preceding 0 for single digit minute numbers
  const fMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes

  // 12 AM, same day & next day
  if (hours === 0 || hours === 24) {
    return `12:${fMinutes} AM`
    // 12 PM noon
  } else if (hours === 12) {
    return `12:${fMinutes} PM`
    // times after midnight (i.e. 4AM)
  } else if (hours > 24) {
    return `${hours - 24}:${fMinutes} AM`
    // times beteween 1PM & 11PM
  } else if (hours > 12) {
    return `${hours - 12}:${fMinutes} PM`
    // times before 12PM noon
  } else {
    return `${hours}:${fMinutes} AM`
  }
}

// used for form <input type='time' />
export function minutesToMilitaryTime(mins) {
  // modulus operater to get remainder (i.e. 65 % 60 = 5)
  const minutes = mins % 60
  // number of hours. can be > 24 in instances where the venue closes after midnight
  const hours = (mins - minutes) / 60
  // add preceding 0 for single digit minute numbers
  const fMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes

  // convert our pseudo military time to military time
  if (hours >= 24) {
    return `0${hours - 24}:${fMinutes}`
  } else if (hours < 10) {
    return `0${hours}:${fMinutes}`
  } else {
    return `${hours}:${fMinutes}`
  }
}

// needs to be upgraded for when end time == 1am --> need to do psuedo military time
export function militaryTimeToMinutes(militaryTime) {
  const hours = militaryTime.split(':')[0]
  const minutes = militaryTime.split(':')[1]
  return hours * 60 + minutes
}

// needs to be tested to to ensure something like 1:30 works (not just 01:30)
export function timeStringToMinutes(time) {
  var hours = parseInt(time.slice(0, 2)) * 60
  var minutes = parseInt(time.slice(2, 4))
  return hours + minutes
}

/*
 * examples of using the minutesToTime and minutesToMilitaryTime
 * functions (all returning true):
 *

  const a = 600
  const b = 900
  const c = 990
  const d = 1440
  const dd = 1439
  const ddd = 1441
  const e = 1530
  const ee = 90
  const f = 1680
  const g = 12 * 60
  const h = 12 * 60 + 15

  console.log('a:', minutesToTime(a), minutesToTime(a) === '10:00 AM')
  console.log('b:', minutesToTime(b), minutesToTime(b) === '3:00 PM')
  console.log('c:', minutesToTime(c), minutesToTime(c) === '4:30 PM')
  console.log('d:', minutesToTime(d), minutesToTime(d) === '12:00 AM')
  console.log('dd:', minutesToTime(dd), minutesToTime(dd) === '11:59 PM')
  console.log('ddd:', minutesToTime(ddd), minutesToTime(ddd) === '12:01 AM')
  console.log('e:', minutesToTime(e), minutesToTime(e) === '1:30 AM')
  console.log('ee:', minutesToTime(ee), minutesToTime(ee) === '1:30 AM')
  console.log('f:', minutesToTime(f), minutesToTime(f) === '4:00 AM')
  console.log('g:', minutesToTime(g), minutesToTime(g) === '12:00 PM')
  console.log('h:', minutesToTime(h), minutesToTime(h) === '12:15 PM')

  console.log('a:', minutesToMilitaryTime(a), minutesToMilitaryTime(a) === '10:00')
  console.log('b:', minutesToMilitaryTime(b), minutesToMilitaryTime(b) === '15:00')
  console.log('c:', minutesToMilitaryTime(c), minutesToMilitaryTime(c) === '16:30')
  console.log('d:', minutesToMilitaryTime(d), minutesToMilitaryTime(d) === '00:00')
  console.log('dd:', minutesToMilitaryTime(dd), minutesToMilitaryTime(dd) === '23:59')
  console.log('ddd:', minutesToMilitaryTime(ddd), minutesToMilitaryTime(ddd) === '00:01')
  console.log('e:', minutesToMilitaryTime(e), minutesToMilitaryTime(e) === '01:30')
  console.log('ee:', minutesToMilitaryTime(ee), minutesToMilitaryTime(ee) === '01:30')
  console.log('f:', minutesToMilitaryTime(f), minutesToMilitaryTime(f) === '04:00')
  console.log('g:', minutesToMilitaryTime(g), minutesToMilitaryTime(g) === '12:00')
  console.log('h:', minutesToMilitaryTime(h), minutesToMilitaryTime(h) === '12:15')

*/
