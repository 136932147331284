import React from 'react'
import { View, StyleSheet } from 'react-native'
import { DateInput, FormLabel, TimeInput } from '../../../components'
import { adjustForTimezone } from '../../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
  },
  dateWrapperStyle: {
    marginRight: 20,
  },
  timeOptsRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelWrapper: {
    minWidth: 60,
  },
})

export class DateTimeInput extends React.Component {
  state = {
    test: false,
  }

  handleChange = (field, value) => {
    // const baseDate = adjustForTimezone(this.props.value, true)
    const baseDate = new Date(this.props.value)
    if (field === 'dateDate') {
      const newDate = new Date(value)
      const currHours = baseDate.getHours()
      const currMin = baseDate.getMinutes()
      newDate.setHours(currHours)
      newDate.setMinutes(currMin)
      this.props.onChange(
        this.props.name,
        // adjustForTimezone(newDate, false).toISOString(),
        new Date(newDate).toISOString(),
      )
    }
    if (field === 'dateTime') {
      const newHours = Math.floor(value / 60)
      const newMins = value % 60
      baseDate.setHours(newHours)
      baseDate.setMinutes(newMins)
      this.props.onChange(
        this.props.name,
        // adjustForTimezone(baseDate, false).toISOString(),
        new Date(baseDate).toISOString(),
      )
    }
  }

  render() {
    const {
      value,
      label,
      wrapperStyle,
      labelWrapperStyle,
      labelStyle,
      dateWrapperStyle,
      timeInnerMaxWidth,
      timeTextAlign,
      disabled
    } = this.props

    // const newDate = adjustForTimezone(value, true)
    const newDate = new Date(value)
    const timeVal = value
      ? newDate.getHours() * 60 + newDate.getMinutes()
      : null

    return (
      <View style={[s.wrapper, wrapperStyle && wrapperStyle]}>
        <View style={s.timeOptsRow}>
          <View
            style={[s.labelWrapper, labelWrapperStyle && labelWrapperStyle]}
          >
            <FormLabel
              children={label || 'Start'}
              mr={15}
              style={labelStyle && labelStyle}
            />
          </View>
          <DateInput
            wrapperStyle={[
              s.dateWrapperStyle,
              dateWrapperStyle && dateWrapperStyle,
            ]}
            name={'dateDate'}
            value={newDate}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <TimeInput
            name={'dateTime'}
            value={timeVal}
            onChange={this.handleChange}
            innerMaxWidth={timeInnerMaxWidth}
            textAlign={timeTextAlign}
            disabled={disabled}
          />
        </View>
      </View>
    )
  }
}
