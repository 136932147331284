import React from 'react'
import { View, ScrollView, StyleSheet } from 'react-native-web'
import { BaseText, Checkbox } from '../../../components'
import { SuggestionCard } from './SuggestionCard'
import { scale } from '../../../functions'

const s = StyleSheet.create({
  topNewVenues: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
  topAddedVenues: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 15,
  },
  checkAllWrapper: {
    marginRight: 13,
    width: '15%',
  },
  newVenuesWrapper: {
    display: 'flex',
    flex: 1,
    maxHeight: 250,
  },
  addedVenuesWrapper: {
    display: 'flex',
    flex: 1,
    maxHeight: 200,
  },
})

export class SearchResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      newVenues: [],
      addedVenues: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.filter !== nextProps.filter ||
      this.props.disabled !== nextProps.disabled ||
      this.state.suggestions !== nextState.suggestions ||
      this.state.newVenues !== nextState.newVenues ||
      this.state.addedVenues !== nextState.addedVenues
    )
  }

  _handleFilter = suggestion => {
    const name = suggestion.formattedSuggestion.mainText.toLowerCase()
    const filter = (this.props.filter || '').toLowerCase()

    return name.indexOf(filter) !== -1
  }

  render() {
    const { disabled } = this.props

    return (
      <React.Fragment>
        {this.state.newVenues.length > 0 && (
          <React.Fragment>
            <View style={[s.topNewVenues]}>
              <BaseText
                fs={scale(20)}
                lh={scale(26)}
                weight={'semibold'}
                children={'New Venues'}
                mb={4}
              />
              <View style={s.checkAllWrapper}>
                <Checkbox
                  label={'Check All'}
                  value={this.state.newVenues
                    .filter(this._handleFilter)
                    .every(({ checked }) => checked)}
                  onChange={checked => {
                    if (!disabled) {
                      this.state.newVenues.forEach(suggestion => {
                        suggestion.checked = checked
                      })
                      this.setState({ newVenues: this.state.newVenues }, () => {
                        this.forceUpdate()
                        this.props.onCheckedItemChanged()
                      })
                    }
                  }}
                />
              </View>
            </View>
            <View style={s.newVenuesWrapper}>
              <ScrollView>
                <View>
                  {this.state.newVenues
                    .filter(this._handleFilter)
                    .map((suggestion, i) => (
                      <SuggestionCard
                        key={i}
                        suggestion={suggestion}
                        toast={this.toast}
                        history={this.props.history}
                        onChecked={checked => {
                          if (!disabled) {
                            suggestion.checked = checked
                            this.setState(
                              { newVenues: this.state.newVenues },
                              () => {
                                this.props.onCheckedItemChanged()
                              },
                            )
                          }
                        }}
                      />
                    ))}
                </View>
              </ScrollView>
            </View>
          </React.Fragment>
        )}
        {this.state.addedVenues.length > 0 && (
          <React.Fragment>
            <View style={[s.topAddedVenues]}>
              <BaseText
                fs={scale(20)}
                lh={scale(26)}
                weight={'semibold'}
                children={'Added Venues'}
                mb={4}
              />
            </View>
            <View style={s.addedVenuesWrapper}>
              <ScrollView>
                <View>
                  {this.state.addedVenues
                    .filter(this._handleFilter)
                    .map((suggestion, i) => (
                      <SuggestionCard
                        key={i}
                        suggestion={suggestion}
                        toast={this.toast}
                        history={this.props.history}
                      />
                    ))}
                </View>
              </ScrollView>
            </View>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
