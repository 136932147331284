import React from 'react'
import { View } from 'react-native'
import { withRouter } from 'react-router-native'
import Modal from 'modal-enhanced-react-native-web'
import { colors } from '../../../functions'
import { MIcon, PopoverMenu } from '../../../components'

class TicketPopover extends React.Component {
  state = {
    modalIsVisible: false,
  }

  _handleRemove = id => {
    this.props.deleteTicketOption(id)
  }

  _handleEdit = id => {
    this.props.history.push(`/ticket/${id}/edit`)
  }

  _handleDuplicate = id => {
    this.props.history.push(`/ticket/duplicate?${id}`)
  }

  _renderModalContent = () => (
    // TODO Modal with mutation component to delete the ticket
    <React.Fragment />
  )

  render() {
    const { id } = this.props
    const { modalIsVisible } = this.state

    return (
      <React.Fragment>
        <View>
          <PopoverMenu
            centerAnchor={true}
            menuProviderWrapperStyle={{ paddingTop: 0 }}
            renderTrigger={() => (
              <MIcon name={'settings'} color={colors.white} size={24} />
            )}
            options={[
              {
                label: 'Edit',
                onSelect: () => this._handleEdit(id),
              },
              {
                label: 'Duplicate',
                onSelect: () => this._handleDuplicate(id),
              },
              {
                label: 'Delete',
                onSelect: () => this._handleRemove(id),
              },
            ]}
          />
        </View>

        <Modal
          isVisible={modalIsVisible}
          onBackdropPress={() => this.setState({ modalIsVisible: false })}
        >
          {this._renderModalContent()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(TicketPopover)
