import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Route, Link } from 'react-router-native'
import LinearGradient from 'react-native-web-linear-gradient'
import { SubText } from '../../components'

const bgColor = 'rgba(35,35,35,0.65)'
const borderColor = 'rgba(54,53,53,0.65)'
const borderActiveColor = 'rgba(54,53,53,1)'
const width = 200
const borderWidth = 2

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: bgColor,
    // backgroundColor: 'rgba(0,0,0,0)',
    borderBottomColor: borderColor,
    borderTopColor: borderColor,
    borderBottomWidth: `${borderWidth}px`,
    borderTopWidth: `${borderWidth}px`,
    justifyContent: 'flex-end',
  },
  navItem: {
    width: `${width}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '21px',
    paddingBottom: '20px',
    borderLeftColor: borderColor,
    borderRightColor: borderColor,
    borderLeftWidth: `${borderWidth}px`,
    borderRightWidth: `${borderWidth}px`,
  },
  first: {
    width: `${width - borderWidth}px`,
    borderLeftWidth: `${borderWidth * 2}px`,
  },
  last: {
    width: `${width - borderWidth}px`,
    borderRightWidth: `${borderWidth * 2}px`,
  },
  gradientBg: {
    width: `${width}px`,
    display: 'flex',
    borderRightColor: borderActiveColor,
    borderLeftColor: borderActiveColor,
    borderRightWidth: `${borderWidth}px`,
    borderLeftWidth: `${borderWidth}px`,
  },
  innerTouch: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '21px',
    paddingBottom: '20px',
  },
})

const routes = [
  { path: '/solutions', label: 'Solutions' },
  { path: '/resources', label: 'Resources' },
  // { path: '/contact', label: 'Contact Us' },
  { path: '/login', label: 'Log In' },
]

const NavItem = ({ path, label, first, last }) => (
  <Route
    path={path}
    children={({ match }) => {
      return (
        <React.Fragment>
          {match ? (
            <LinearGradient
              start={{ x: 0.5, y: 1 }}
              end={{ x: 0.5, y: 0 }}
              colors={['#FF5E62', '#FF9966']}
              style={[s.gradientBg, first && s.first, last && s.last]}
            >
              <Link
                activeOpacity={0.8}
                style={s.innerTouch}
                to={path}
                // underlayColor={'rgba(51,50,50, 0.5)'}
              >
                <SubText fw={400} color={'#fff'} children={label} />
              </Link>
            </LinearGradient>
          ) : (
            <Link
              activeOpacity={0.8}
              style={[s.navItem, first && s.first, last && s.last]}
              to={path}
              // underlayColor={'rgba(51,50,50, 0.5)'}
            >
              <SubText fw={400} color={'#fff'} children={label} />
            </Link>
          )}
        </React.Fragment>
      )
    }}
  />
)

export const SplashNav = () => (
  <View style={s.wrapper}>
    {routes.map((x, i) => (
      <NavItem
        key={i}
        path={x.path}
        label={x.label}
        first={i === 0 && true}
        last={i === routes.length - 1 && true}
      />
    ))}
  </View>
)
