import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import {
  BaseText,
  Input,
  PinkErrorText,
  BaseTouchText,
  FormButton,
} from '../../components'
import { colors, scale } from '../../functions'
import { parseAuthErrors } from '../../lib'
import { AUTHENTICATE_PARTNER_OR_EMPLOYEE } from '../../gql'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary2,
    marginHorizontal: 'auto',
    width: '90%',
    paddingTop: 40,
    paddingBottom: 40,
    borderRadius: 20,
    maxWidth: 600,
    minHeight: 300,
  },
  formView: {
    display: 'flex',
    alignSelf: 'stretch',
    marginHorizontal: scale(50),
    marginBottom: scale(17),
    alignItems: 'center',
  },
  errorsContainer: {
    // minHeight: 60,
  },
  button: {
    width: '100%',
    maxWidth: '100%',
    marginRight: 0,
    backgroundColor: colors.white,
    alignSelf: 'center',
    shadowColor: colors.black0,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.26,
    shadowRadius: 6,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: 300,
  },
})

class LoginForm extends React.Component {
  state = {
    serverError: null,
  }

  render() {
    return (
      <Mutation
        mutation={AUTHENTICATE_PARTNER_OR_EMPLOYEE}
        // variables={this.state}
      >
        {(authenticatePartnerOrEmployee, { error, loading }) => (
          <Formik
            ref={ref => (this.loginForm = ref)}
            initialValues={{
              email: '',
              password: '',
            }}
            validate={values => {
              let errors = {}
              if (!values.email) {
                errors.email = 'Email required!'
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Not a valid email address!'
              }
              if (!values.password) {
                errors.password = 'Password required!'
              }
              return errors
            }}
            onSubmit={async (values, actions) => {
              try {
                const res = await authenticatePartnerOrEmployee({
                  variables: values,
                })
                console.log('res', res)
                localStorage.setItem(
                  'token',
                  res.data.authenticatePartnerOrEmployee.token,
                )
                setTimeout(() => this.props.history.push('/dashboard'), 300)
                actions.setSubmitting(false)
              } catch (error) {
                actions.setErrors(parseAuthErrors(error.toString()))
                actions.setSubmitting(false)
              }
            }}
            render={({
              values,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              touched,
              errors,
              dirty,
              isSubmitting,
            }) => (
              <View style={s.wrapper}>
                <BaseText weight={'bold'} fs={22} lh={28} mb={20} ta={'center'}>
                  Sign into your account
                </BaseText>
                <View style={s.formView}>
                  <View style={s.inputContainer}>
                    <Input
                      name="email"
                      placeholder={'Email Address'}
                      autoCapitalize={'none'}
                      keyboardType={'email-address'}
                      onChangeText={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.email}
                      error={touched.email && errors.email}
                      editable={!isSubmitting}
                    />
                    <Input
                      name="password"
                      placeholder={'Password'}
                      autoCapitalize={'none'}
                      secureTextEntry={true}
                      onChangeText={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.password}
                      onSubmitEditing={handleSubmit} // not working for some reason :/
                      error={touched.password && errors.password}
                      editable={!isSubmitting}
                    />
                  </View>
                  <View style={s.errorsContainer}>
                    {touched.email && errors.email ? (
                      <PinkErrorText children={errors.email} mb={10} />
                    ) : null}
                    {touched.password && errors.password ? (
                      <PinkErrorText children={errors.password} mb={10} />
                    ) : null}
                    {this.state.serverError ? (
                      <PinkErrorText
                        children={this.state.serverError}
                        mb={10}
                      />
                    ) : null}
                  </View>
                  <View style={s.inputContainer}>
                    <FormButton
                      text={'Sign In'}
                      textColor={colors.primary1}
                      isSubmitting={isSubmitting}
                      handlePress={handleSubmit}
                      outerStyle={s.button}
                    />
                  </View>
                </View>
              </View>
            )}
          />
        )}
      </Mutation>
    )
  }
}

export default LoginForm
