import React from 'react'
import { StyleSheet } from 'react-native'
import { getNumberOfFormLines } from '../../../functions'
import { ResponsiveInput, BaseText } from '../../../components'

export const DetailsEdit = ({ props }) => {
  const s = StyleSheet.create({
    labelInputWrapperStyle: {
      marginBottom: 17,
      paddingBottom: 0,
      borderWidth: 0,
      marginRight: 8,
      flex: 1,
    },
    textInputStyle: {
      padding: 10,
      paddingRight: 10,
      backgroundColor: '#2a2a34',
      width: '100%',
    },
  })

  var descLength = getNumberOfFormLines(props.values.description)
  descLength = descLength === 1 ? 2 : descLength

  return (
    <React.Fragment>
      <ResponsiveInput
        label={'Event name'}
        name={'name'}
        onChangeText={props.setFieldValue}
        onChangeTextOnlyOnBlur={true}
        value={props.values.name}
        placeholder={'add name here'}
        autoComplete={'off'}
      />
      {props.errors.name && (
        <BaseText fs={12} color={'pink'} children={props.errors.name} />
      )}
      <ResponsiveInput
        label={'Description'}
        name={'description'}
        multiline={true}
        numberOfLines={descLength}
        onChangeText={props.setFieldValue}
        onChangeTextOnlyOnBlur={true}
        value={props.values.description || ''}
        placeholder={'Type your description here.'}
        wrapperStyle={s.labelInputWrapperStyle}
        textInputStyle={s.textInputStyle}
        autoComplete={'off'}
      />
      {props.errors.description && (
        <BaseText fs={12} color={'pink'} children={props.errors.description} />
      )}
    </React.Fragment>
  )
}
