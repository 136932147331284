import React from 'react'
import { View, StyleSheet, Text } from 'react-native-web'
import { BaseText, LabelInput } from '../../../components'
import PlacesAutocomplete from 'react-places-autocomplete'
import { colors, fonts } from '../../../functions'

const s = StyleSheet.create({
  suggestionsWrapper: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 1000,
    backgroundColor: colors.primary2,
  },
  suggestion: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    zIndex: 1000,
    height: 44,
    paddingLeft: 14,
    backgroundColor: colors.primary2,
    cursor: 'pointer',
  },
  suggestionLabel: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.white,
  },
})

const SEARCH_DEBOUNCE_TIME = 1000 // in millis

class LocationAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
    }
  }

  _handleChange = address => {
    this.setState({ address }, () => {
      if (this.props.onChange) {
        this.props.onChange(address)
      }
    })
  }

  _handleSelect = address => {
    this.setState({ address }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(address)
      }
    })
  }

  clear = () => {
    this._handleChange('')
    this._handleSelect('')
  }

  render() {
    return (
      <React.Fragment>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this._handleChange}
          onSelect={this._handleSelect}
          debounce={SEARCH_DEBOUNCE_TIME}
          shouldFetchSuggestions={true}
          searchOptions={{
            type: ['address'],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            const inputProps = getInputProps()

            return (
              <View>
                <LabelInput
                  collapseLabel={true}
                  labelWidth={'100%'}
                  label={'Neighborhood, City, or Zip'}
                  onChangeText={(name, value) =>
                    inputProps.onChange({ target: { value } })
                  }
                  autoComplete={'off'} // turn off autocomplete as it's suggesting firstName / lastName
                  placeholder={'Neighborhood, City, or Zip'}
                  value={this.state.address}
                  disabled={this.props.disabled}
                />
                <View style={s.suggestionsWrapper}>
                  {loading && (
                    <div>
                      <BaseText fs={14} children={'Loading...'} />
                    </div>
                  )}
                  {suggestions.map(suggestion => {
                    let suggestionItemProps = getSuggestionItemProps(suggestion)

                    return (
                      <View
                        key={suggestion.id}
                        style={s.suggestion}
                        {...suggestionItemProps}
                      >
                        <Text style={s.suggestionLabel}>
                          {suggestion.description}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </View>
            )
          }}
        </PlacesAutocomplete>
      </React.Fragment>
    )
  }
}

export default LocationAutoComplete
