import React from 'react'
import * as t from 'prop-types'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { colors, fonts } from '../../functions'
import { MCIcon } from '../../components'
import { INPUT_TEXT_SIZE, INPUT_LINE_HEIGHT } from '../../config'

const s = StyleSheet.create({
  checkBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '4px',
  },
  columnWrapper: {
    flexDirection: 'column',
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
  },
  columnText: {
    paddingBottom: 15,
  },
  labelText: {
    fontFamily: fonts.regular,
    color: colors.secondary2,
    fontSize: `${INPUT_TEXT_SIZE}px`,
    lineHeight: `${INPUT_LINE_HEIGHT}px`,
  },
  checkBoxCont: {
    backgroundColor: colors.opaque,
    borderWidth: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3px',
    justifyContent: 'flex-end',
  },
})

export const RadioButton = ({
  label,
  value,
  radioValue,
  column,
  labelStyle,
  wrapperStyle,
  isDisabled,
  disabledLabelStyle,
  onPress,
}) => (
  <View
    style={[
      s.checkBoxRow,
      column && s.columnWrapper,
      wrapperStyle && wrapperStyle,
    ]}
  >
    <View style={s.labelContainer}>
      <Text
        style={[s.labelText, column && s.columnText, labelStyle && labelStyle]}
      >
        {label}
      </Text>
    </View>
    <TouchableOpacity
      activeOpacity={1}
      disabled={isDisabled}
      style={s.checkBoxCont}
      onPress={() => {
        if (!isDisabled) {
          return onPress(radioValue)
        }
      }}
    >
      <MCIcon
        name={value ? 'radiobox-marked' : 'radiobox-blank'}
        color={
          isDisabled
            ? colors.blackgray
            : value
            ? colors.teal
            : colors.secondary2
        }
        size={24}
      />
    </TouchableOpacity>
  </View>
)

RadioButton.propTypes = {
  label: t.string,
  value: t.bool.isRequired,
  onPress: t.func.isRequired,
  column: t.bool,
}
