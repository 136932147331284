import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { BaseText, LineBreak, MCIcon, SectionTitle } from '../../components'
import { colors, fonts, hexToRgba } from '../../functions'

const steps = [
  {
    id: 'email',
    label: 'Verify your email address.',
    color: '#1684D9',
    completed: true,
  },
  {
    id: 'organization',
    label: 'Add details about your organization.',
    color: '#16CCD9',
    completed: true,
  },
  {
    id: 'venue',
    label: 'Add your first venue.',
    color: '#16D9AC',
    completed: true,
  },
  {
    id: 'event',
    label: 'Add your first event.',
    color: '#16D957',
    completed: false,
  },
  {
    id: 'profile',
    label: 'Add user profile details.',
    color: '#9FD916',
    completed: false,
  },
]

const s = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  centeredRow: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // verticalAlign: 'middle',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  completionLineBase: {
    display: 'flex',
    backgroundColor: colors.secondary1,
    height: 4,
    minHeight: 4,
    maxHeight: 4,
    width: 100,
    marginTop: 5,
  },
  stepContainer: {
    paddingVertical: 14,
  },
  stepText: {
    fontFamily: fonts.regular,
    fontSize: 16,
    lineHeight: 22,
    color: hexToRgba(colors.white, 0.5),
  },
})

class Onboarding extends React.Component {
  state = {
    progress: 3 / 5,
  }

  renderCompletion = () => {
    const { progress } = this.state
    const completionPercent = (progress * 100).toFixed(0) + '%'
    return (
      <View style={[s.row, s.centeredRow]}>
        <View
          style={[
            s.completionLineBase,
            { backgroundColor: colors.teal, width: progress * 100 },
          ]}
        />
        <View style={[s.completionLineBase, { width: (1 - progress) * 100 }]} />
        <BaseText
          ml={10}
          fs={14}
          lh={19}
          color={'teal'}
          children={completionPercent}
        />
      </View>
    )
  }

  renderStep = ({ id, label, color, completed }) => (
    <View style={s.stepContainer} key={id}>
      <View style={s.row}>
        <Text
          style={[s.stepText, !completed && { color: colors.white }]}
          children={label}
        />
        {completed && <MCIcon name={'check'} size={24} color={colors.teal} />}
      </View>
      <LineBreak
        style={{
          marginTop: 7,
          backgroundColor: hexToRgba(color, completed ? 0.5 : 1),
        }}
      />
    </View>
  )

  render() {
    return (
      <React.Fragment>
        <View style={[s.row, { marginBottom: 2 }]}>
          <SectionTitle children={'Get Started'} />
          <View style={[s.column, { alignItems: 'flex-end' }]}>
            {this.renderCompletion()}
            <BaseText
              fs={14}
              lh={19}
              color={'secondary2'}
              children={'3/5 items completed'}
            />
          </View>
        </View>
        <LineBreak style={{ marginTop: 10, marginBottom: 14 }} />
        {steps && steps.map(x => this.renderStep(x))}
      </React.Fragment>
    )
  }
}

export default Onboarding
