import React from 'react'
import { TouchableOpacity } from 'react-native'
import { BaseText } from '../../../components/Text/BaseText'

const ACTIVE_OPACITY = 0.8

// NOTE: BaseText with Touchable capabilities
export const TouchableBaseText = props => (
  <TouchableOpacity {...props} activeOpacity={ACTIVE_OPACITY}>
    <BaseText
      color={props.color}
      fs={props.fs}
      lh={props.lh}
      weight={props.weight}
      children={props.children}
    />
  </TouchableOpacity>
)
