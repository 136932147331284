import React from 'react'
import {
  Image,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { BaseText, WhiteTouchText } from '../../components'

const RESOURCES = [
  {
    title: 'Mobile Advertising',
    date: 'April 1, 2017',
    preview:
      '/reports/Mobile Advertising - An Untapped Industry_100818_cover.jpg',
    pdf: 'reports/Mobile Advertising - An Untapped Industry_100818.pdf',
  },
  {
    title: 'Digital Marketplaces',
    date: 'January 6, 2018',
    preview: '/reports/Demystifying Digital Marketplaces_100818_cover-1.jpg',
    pdf: 'reports/Demystifying Digital Marketplaces_100818.pdf',
  },
  {
    title: 'Restaurant Tech',
    date: 'September 30, 2018',
    preview: '/reports/Time to Add Technology to the Menu_100818_cover.jpg',
    pdf: 'reports/Time to Add Technology to the Menu_100818.pdf',
    disabled: true,
  },
  {
    title: 'Embracing Technology',
    date: 'October 8, 2018',
    preview: '/reports/staff_technology_cover.jpg',
    pdf: '',
    disabled: true,
  },
  // {
  //   title: 'Grubhub',
  //   date: 'June 1, 2017',
  //   preview: '/reports/GRUB_Report_2017_cover.jpg',
  //   pdf: 'reports/GRUB_Report_2017.pdf'
  // },
]

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  reportWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginHorizontal: 20,
  },
  pdfPreview: {
    width: 8.5 * 30,
    height: 11 * 30,
  },
})

const Report = ({ title, date, preview, pdf, disabled }) => {
  return (
    <View style={s.reportWrapper}>
      <BaseText children={title} fs={24} />
      <BaseText children={date} weight={'light'} mb={10} />
      <TouchableOpacity
        disabled={disabled}
        onPress={() => Linking.openURL(pdf)}
        activeOpacity={0.7}
      >
        <Image source={{ uri: preview }} style={s.pdfPreview} />
      </TouchableOpacity>
      <WhiteTouchText
        color={disabled ? 'lightgray' : 'white'}
        weight={disabled ? 'light' : 'regular'}
        disabled={disabled}
        handlePress={() => Linking.openURL(pdf)}
        children={'Download PDF'}
        mt={4}
      />
    </View>
  )
}

export const Resources = () => (
  <View style={s.wrapper}>
    <View style={s.innerContent}>
      {RESOURCES && RESOURCES.map((x, i) => <Report key={i} {...x} />)}
    </View>
  </View>
)
