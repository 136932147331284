import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { MIcon } from '..'
import { scale, fonts, colors } from '../../functions'

const iconColor = colors.blackBg
const iconSize = 20

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.teal,
    borderRadius: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 5,
    marginVertical: 3,
  },
  text: {
    fontFamily: fonts.regular,
    fontSize: 13,
    lineHeight: 18,
    color: colors.white,
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  tagIsButton: {
    backgroundColor: colors.pink,
  },
  textIsButton: {
    paddingRight: 10,
  },
  crossIcon: {
    paddingRight: 2,
    paddingLeft: 2,
  },
})

export const TagsPillNew = ({ item, handleClick, isButton }) => {
  if (isButton) {
    return (
      <View style={[s.container, s.tagIsButton]}>
        <TouchableOpacity
          style={s.iconContainer}
          onPressIn={() => handleClick()}
        >
          <Text style={[s.text, s.textIsButton]}>{item.name}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View style={s.container}>
      <Text style={s.text}>{item && item.name}</Text>
      <TouchableOpacity
        style={s.iconContainer}
        onPressIn={() => handleClick(item)}
      >
        <MIcon
          name="close"
          size={iconSize}
          color={colors.white}
          style={s.crossIcon}
        />
      </TouchableOpacity>
    </View>
  )
}
