import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { PopoverMenuNew } from '../../components'
import { colors, fonts, hexToRgba } from '../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    zIndex: -1, // need this so it doesn't bleed through the UserProfilePopover when expanded
    maxHeight: 70,
    // width: '100%',
    // backgroundColor: colors.primary2,
  },
})

export const VenuePopover = ({
  renderTrigger,
  allVenues,
  handleSelect,
  scrollable,
}) => (
  <View style={s.wrapper}>
    <PopoverMenuNew
      menuProviderWrapperStyle={{ padding: 0 }}
      renderTrigger={renderTrigger}
      options={allVenues}
      handleSelect={handleSelect}
      scrollable={scrollable}
    />
  </View>
)
