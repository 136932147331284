import React from 'react'
import * as t from 'prop-types'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { colors, fonts } from '../../functions'

const s = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    fontFamily: fonts.regular,
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    color: colors.secondary2,
  },
})

class HoverableText extends React.Component {
  setStyles = textStyle => {
    this.text.setNativeProps({
      style: textStyle,
    })
  }

  render() {
    const {
      outerContainerStyle,
      innerContainerStyle,
      textStyle,
      textHoverStyle,
      children,
      onPress,
    } = this.props
    return (
      <TouchableWithoutFeedback
        accessible={true}
        onMouseEnter={() => this.setStyles(textHoverStyle)}
        onMouseLeave={() => this.setStyles(textStyle)}
        style={[s.outerContainer, outerContainerStyle && outerContainerStyle]}
        onPress={onPress}
      >
        <View
          style={[s.innerContainer, innerContainerStyle && innerContainerStyle]}
        >
          <Text
            ref={ref => (this.text = ref)}
            style={[s.textStyle, textStyle]}
            children={children}
          />
        </View>
      </TouchableWithoutFeedback>
    )
  }
}

HoverableText.propTypes = {
  /** the string of text to display */
  children: t.node.isRequired,
  /** the event handler */
  onPress: t.func.isRequired,
  /** text color and style overrides for Text */
  textStyle: t.oneOfType([t.object, t.array, t.number]).isRequired, // needs number for RN stylesheet reference
  /** style for hover state for Text */
  textHoverStyle: t.oneOfType([t.object, t.array, t.number]).isRequired,
  /** style overrides for outermost Touchable */
  outerContainerStyle: t.oneOfType([t.object, t.array, t.number]),
  /** style overrides for View wrapping the Text */
  innerContainerStyle: t.oneOfType([t.object, t.array, t.number]),
}

export default HoverableText
