import React from 'react'
import { View, StyleSheet } from 'react-native'
import { hashMap } from '../../../functions'
import { AutoCompleteWeb, FormLabel } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    zIndex: 5,
    //marginBottom: 50,
  },
  timeOptsRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const typeOpts = [
  { name: 'Beer', label: 'Beer', value: 'Beer' },
  { name: 'Brunch', label: 'Brunch', value: 'Brunch' },
  { name: 'Cocktail', label: 'Cocktail', value: 'Cocktail' },
  { name: 'Food', label: 'Food', value: 'Food' },
  { name: 'Other', label: 'Other', value: 'Other' },
  { name: 'Wine', label: 'Wine', value: 'Wine' },
]

export class SpecialTypeDropdown extends React.Component {
  state = {
    typeOpts: typeOpts,
    typeOptsHash: hashMap(typeOpts, 'value'),
  }

  handleSelect = (field, item) => {
    this.props.onChange(field, item.value)
  }

  handleInput = (field, text) => {
    if (text.length === 0) {
      this.handleSelect(field, { value: '' })
    }
    if (text.length >= 4 && this.state.typeOptsHash[text]) {
      this.handleSelect(field, this.state.typeOptsHash[text])
    }
  }

  render() {
    const { typeOpts } = this.state
    const { value, showLabel, wrapperStyle, maxWidth } = this.props
    return (
      <View
        style={[
          s.wrapper,
          wrapperStyle && wrapperStyle,
          maxWidth && { maxWidth },
        ]}
      >
        <View style={s.timeOptsRow}>
          {showLabel && <FormLabel children={'Type:'} mr={15} />}
          <AutoCompleteWeb
            maxNumberOfItems={6} // typeOpts.length = 6
            items={typeOpts}
            handleSelect={text => this.handleSelect('type', text)}
            handleInput={text => this.handleInput('type', text)}
            value={value ? value : ''}
            wrapperStyle={{ marginRight: 15 }}
            maxWidth={120}
          />
        </View>
      </View>
    )
  }
}
