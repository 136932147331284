import React from 'react'
import { Svg, Path } from 'react-native-svg'
import { scale } from '../../functions'

const height = 166.457
const width = 166.456
const viewBox = `0 0 ${width} ${height}`

export const EmptyBoxOpen = () => (
  <Svg height={scale(height)} width={scale(width)} viewBox={viewBox}>
    <Path
      d="M166.456,101.108V41.081a3.76,3.76,0,0,0-.038-.534c0-.034-.009-.069-.015-.1a3.813,3.813,0,0,0-.111-.489l0-.01c-.036-.117-.078-.233-.126-.348-.014-.033-.031-.063-.046-.1s-.038-.081-.058-.121a3.738,3.738,0,0,0-.211-.372c-.011-.017-.02-.034-.031-.05a3.747,3.747,0,0,0-.3-.392c-.02-.023-.041-.044-.062-.067a3.692,3.692,0,0,0-.318-.306l-.052-.046a3.777,3.777,0,0,0-.4-.283c-.032-.02-.065-.038-.1-.057a3.808,3.808,0,0,0-.394-.2c-.016-.007-.03-.017-.046-.023L75.279.294,75.254.285C75.22.27,75.184.259,75.148.246a3.747,3.747,0,0,0-1.656-.23l-.031,0a3.782,3.782,0,0,0-.731.145l-.043.015c-.1.033-.207.069-.308.111l-.014.005L46.3,11.231a3.766,3.766,0,0,0,0,6.946l23.759,9.969v33.2L2.308,89.77c-.047.02-.091.044-.137.066s-.09.041-.134.064q-.16.082-.309.179c-.016.01-.032.017-.048.028l-.011.008a3.713,3.713,0,0,0-.356.27c-.012.01-.022.021-.033.031a3.812,3.812,0,0,0-.273.267c-.026.027-.05.056-.075.084-.07.081-.137.164-.2.25-.024.032-.047.064-.07.1q-.1.149-.188.305c-.011.019-.023.037-.033.056a3.838,3.838,0,0,0-.175.38c-.014.035-.025.071-.038.107-.035.1-.067.2-.095.3-.012.044-.023.087-.033.131q-.036.155-.059.313c-.005.035-.012.07-.016.105A3.758,3.758,0,0,0,0,93.254v32.151a3.767,3.767,0,0,0,2.309,3.473l88.846,37.277a3.724,3.724,0,0,0,.737.229c.024,0,.048.007.072.011.1.018.2.034.3.044.125.012.25.019.375.019s.25-.006.375-.019c.1-.01.2-.026.3-.044.024,0,.048-.006.072-.011a3.716,3.716,0,0,0,.737-.229l70.034-29.384a3.766,3.766,0,0,0,2.309-3.473v-32.19Zm-42.093,12.024-23.783-9.978a3.766,3.766,0,0,0-2.914,6.946l16.963,7.117-22,9.229L51.827,109.324,73.822,100.1,87.2,105.709a3.766,3.766,0,0,0,2.914-6.946L77.588,93.507V69.514l75.368,31.622ZM70.056,93.507,42.093,105.24l-28.593-12L70.056,69.514V93.507ZM73.822,7.851l79.134,33.2-16.334,6.854L75.3,22.179l-.035-.014L57.488,14.7Zm61.316,47.6a3.767,3.767,0,0,0,2.942.012l20.845-8.747V95.471L77.588,61.345V31.306ZM7.531,98.907l81.336,34.127v23.994L7.531,122.9Zm151.393,31.887L96.4,157.027V133.034L158.924,106.8Z"
      fill="#21212a"
      transform="translate(0.001 -0.002)"
    />
  </Svg>
)
