import React from 'react'
import { View, StyleSheet } from 'react-native'
import { PinkErrorText } from '../../components'

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    alignSelf: 'stretch',
    minHeight: 40,
    zIndex: -1,
  },
})

export const ErrorsContainer = ({ errors }) => {
  const entries = Object.values(errors)
  const lastItem = entries.length - 1
  return (
    <View style={s.container}>
      {entries &&
        entries.map((x, i) => (
          <PinkErrorText
            key={i}
            children={`${x}${i !== lastItem ? '\n' : ''}`}
          />
        ))}
    </View>
  )
}
