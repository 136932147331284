/* The following removes the gray background from Chrome autofill (i.e. in inputs). */
const autofillStyles = `
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
    }
`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = autofillStyles
} else {
  style.appendChild(document.createTextNode(autofillStyles))
}

// Inject stylesheet
document.head.appendChild(style)
