import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { colors, hexToRgba } from '../../functions'
import { ContentContainer } from '../../components'
import DateTimeHeader from './DateTimeHeader'
import Onboarding from './Onboarding'
import QuickActions from './QuickActions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  navbarContainer: {
    marginTop: -44,
    backgroundColor: hexToRgba(colors.primary2, 0.1),
  },
  sectionContainer: {
    borderRadius: 20,
    backgroundColor: colors.primary2,
    padding: 19,
  },
  onboarding: {
    marginTop: 48,
  },
  quickActions: {
    marginTop: 24,
    marginBottom: 24, // add extra space for mobile/tablet screens, so the gray container isn't touching the bottom
  },
})

class Dashboard extends React.Component {
  handleNavigation = path => {
    return this.props.history.push(path)
  }
  render() {
    return (
      <React.Fragment>
        <View style={s.navbarContainer}>
          <DateTimeHeader />
        </View>
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.wrapper}
          scrollsToTop={true}
          scrollEventThrottle={16}
        >
          <ContentContainer style={[s.sectionContainer, s.quickActions]}>
            <QuickActions handleNavigation={this.handleNavigation} />
          </ContentContainer>
        </ScrollView>
      </React.Fragment>
    )
  }
}

export default Dashboard
