import React from 'react'
import { Mutation } from 'react-apollo'
import { Formik, FieldArray } from 'formik'
import { TrackError } from '../../../lib'
import { AddButton, HoursInput } from '../../../components'
import {
  VENUE_INFO_QUERY,
  UPDATE_HAPPY_HOURS_PERIOD,
  CREATE_HAPPY_HOURS_PERIOD,
  DELETE_HAPPY_HOURS_PERIOD,
} from '../../../gql'

export const HappyHours = ({ initialValues: { id, happyHours } }) => {
  return (
    <Mutation mutation={UPDATE_HAPPY_HOURS_PERIOD}>
      {(updateHappyHoursPeriod, { error, loading }) => (
        <Mutation mutation={CREATE_HAPPY_HOURS_PERIOD}>
          {(createHappyHoursPeriod, { error, loading }) => (
            <Mutation
              mutation={DELETE_HAPPY_HOURS_PERIOD}
              refetchQueries={() => [
                { query: VENUE_INFO_QUERY, variables: { id: id } },
              ]}
            >
              {(deleteHappyHoursPeriod, { error, loading }) => (
                <React.Fragment>
                  {happyHours &&
                    happyHours.length > 0 &&
                    happyHours.map((x, i) => (
                      <Formik
                        key={`${i}-${x.id}`}
                        enableReinitialize={true}
                        validateOnBlur={true}
                        initialValues={{
                          id: x.id,
                          day: x.day,
                          startTime: x.startTime,
                          endTime: x.endTime,
                        }}
                        validate={values => {
                          console.log('values', values)
                          let errors = {}
                          if (values.day === null || values.day === undefined) {
                            errors.day = 'Day required!'
                          }
                          if (
                            values.startTime === null ||
                            values.startTime === undefined
                          ) {
                            errors.startTime = 'Start Time required!'
                          }
                          if (
                            values.endTime === null ||
                            values.endTime === undefined
                          ) {
                            errors.endTime = 'End Time required!'
                          }
                          if (values.startTime > values.endTime) {
                            errors.endTime =
                              'End Time must be after Start Time!'
                          }
                          console.log('errors', errors)
                          return errors
                        }}
                        onSubmit={async (values, actions) => {
                          if (
                            values.day === x.day &&
                            values.startTime === x.startTime &&
                            values.endTime === x.endTime
                          ) {
                            console.log('nothing changed')
                            return actions.setSubmitting(false)
                          } else {
                            try {
                              const res = await updateHappyHoursPeriod({
                                variables: values,
                              })
                              console.log(
                                'res.data.updateHappyHoursPeriod',
                                res.data.updateHappyHoursPeriod,
                              )
                              actions.setSubmitting(false)
                            } catch (error) {
                              TrackError(error)
                              console.log('error', error)
                              actions.setSubmitting(false)
                            }
                          }
                        }}
                        render={props => (
                          <HoursInput
                            day={props.values.day}
                            startTime={props.values.startTime}
                            endTime={props.values.endTime}
                            onChange={(field, value) =>
                              props.setFieldValue(field, value)
                            }
                            handleSubmit={props.handleSubmit}
                            handleRemove={async () => {
                              try {
                                console.log('props.values.id', props.values.id)
                                const res = await deleteHappyHoursPeriod({
                                  variables: { id: props.values.id },
                                })
                                console.log('res', res)
                              } catch (e) {
                                TrackError(e)
                                console.log(
                                  'error removing HappyHoursPeriod',
                                  e,
                                )
                              }
                            }}
                          />
                        )}
                      />
                    ))}
                  {/*
                   * not sure the best way to mimic arrayHelpers.push() logic, so adding an extra form to handle new hours periods for now...
                   * this cannot be a class component (with state), or it will break the component rendering logic on /ManageVenue/index.js :/
                   */}
                  <Formik
                    enableReinitialize={true}
                    initialValues={{ happyHours: [] }}
                    onSubmit={(values, actions) => {
                      const newPeriods = values.happyHours.filter(
                        x =>
                          x.startTime !== undefined &&
                          x.endTime !== undefined &&
                          x.day !== undefined,
                      )
                      return newPeriods.forEach(p => {
                        return createHappyHoursPeriod({
                          variables: { ...p, venueId: id },
                        })
                          .then(
                            res =>
                              actions.setValues({ happyHours: [] }) &&
                              console.log('res', res),
                          )
                          .catch(e => console.log('err', e))
                      })
                    }}
                    render={props => {
                      return (
                        <React.Fragment>
                          <FieldArray
                            name={'happyHours'}
                            render={arrayHelpers => (
                              <React.Fragment>
                                {props.values.happyHours &&
                                  props.values.happyHours.length > 0 &&
                                  props.values.happyHours.map((x, index) => (
                                    <HoursInput
                                      key={index}
                                      day={props.values.happyHours[index].day}
                                      startTime={
                                        props.values.happyHours[index].startTime
                                      }
                                      endTime={
                                        props.values.happyHours[index].endTime
                                      }
                                      onChange={(field, value) =>
                                        props.setFieldValue(
                                          `happyHours[${index}][${field}]`,
                                          value,
                                        )
                                      }
                                      handleSubmit={props.handleSubmit}
                                      handleRemove={() =>
                                        arrayHelpers.remove(index)
                                      }
                                    />
                                  ))}
                                <AddButton
                                  containerStyle={{ marginBottom: 22 }}
                                  text={'Add Happy Hours'}
                                  disabledText={
                                    'If you would like to edit happy hours please use a tablet or desktop.'
                                  }
                                  onPress={() => arrayHelpers.push('')}
                                />
                              </React.Fragment>
                            )}
                          />
                        </React.Fragment>
                      )
                    }}
                  />
                </React.Fragment>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  )
}
