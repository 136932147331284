import React from 'react'
import { StyleSheet, View, CheckBox } from 'react-native'
import { BaseText, RadioButton, FormLabel } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioWrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: -1,
    marginTop: 10,
    marginBottom: 15,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})

export const AgeGroup = ({ props, allAgeGroups }) => {
  return (
    <React.Fragment>
      <View style={s.wrapper}>
        <View style={[s.radioWrapper]}>
          {allAgeGroups.map((item, index) => {
            const val = props.values.ageGroupId == item.id
            return (
              <View key={index} style={s.radioContainer}>
                <CheckBox
                  name="ageGroup"
                  checked={props.values.ageGroupId}
                  style={{ display: 'none' }}
                />
                <RadioButton
                  value={val}
                  radioValue={item.id}
                  onPress={() => {
                    props.setFieldValue('ageGroupId', item.id)
                  }}
                />
                <FormLabel
                  mr={20}
                  ml={10}
                  mt={4}
                  children={item.ageText ? item.ageText : 'Option ' + index}
                />
              </View>
            )
          })}
        </View>
        {props.errors.ageGroupId && (
          <BaseText fs={12} color={'pink'} children={props.errors.ageGroupId} />
        )}
      </View>
    </React.Fragment>
  )
}
