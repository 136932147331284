// in the future, we should have a better implementation
// but for now, this essentially returns normal Label Input on tablet/desktop
// devices, and a collapsed (psuedo-multi-line) version on mobile

import React from 'react'
import { DimensionsConsumer } from '../../lib'
import { LabelInput, Input } from '../../components'

export const ResponsiveInput = props => (
  <DimensionsConsumer>
    {({ width }) => {
      const isSmall = width < 580
      const showSmall = isSmall && props.multiline !== true
      if (showSmall) {
        return <LabelInput collapseLabel={true} {...props} />
      } else {
        return <LabelInput {...props} />
      }
    }}
  </DimensionsConsumer>
)

// TO DO:
// 1. need to adjust the numberOfLines functions for mobile. These assume widths < 728
// 2. move this into LabelInput...
