import React from 'react'
import { Organization } from './Organization'
import { Venues } from './Venues'
import { colors } from '../../../functions'

export const sections = [
  {
    id: '#organization',
    title: 'Choose an Organization',
    subtext: 'Search for an organization in the system',
    component: Organization,
  },
  {
    id: '#venues',
    title: 'Add Venue\'s to an Organization',
    subtext: 'Search for venue\'s already added in the system',
    component: Venues,
  },
]
