import gql from 'graphql-tag'

/*
 * fragments so we can stay DRY
 * https://www.apollographql.com/docs/react/advanced/fragments.html
 *
 */

export const EventFragments = {
  short: gql`
    fragment EventDetails on Event {
      id
      name
      date
      endDate
      published
      hidden
    }
  `,
  shortAdmin: gql`
    fragment EventDetailsAdmin on Event {
      id
      name
      date
      endDate
      published
      hidden
      venue {
        id
        name
      }
    }
  `,
  category: gql`
    fragment EventCategoryDetails on EventCategory {
      id
      name
      displayName
    }
  `,
  photo: gql`
    fragment EventPhotoDetails on EventPhoto {
      id
      filename
      size
      mimetype
      handle
      url
      cover
      card
      cardWide
      createdAt
    }
  `,
  full: gql`
    fragment EventFullDetails on Event {
      id
      name
      description
      date
      endDate
      ticketUrl
      priceFrom
      free
      recurring # internal only
      photos {
        id
        filename
        handle
        url
        cover
        card
        cardWide
        createdAt
      }
      categories(orderBy: name_ASC) {
        id
        name
        displayName
      }
      ageGroup {
        id
        age
        ageText
      }
      venue {
        id
        name
      }
      hidden # to do: switch this out for published
      published
      createdAt
      updatedAt
      isTicketedEvent
      ticketOptions(filter: { isDeleted: false }) {
        id
        price
        description
        name
        isDeleted
        status {
          id
          displayName
          value
        }
      }
    }
  `,
}
