import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { AutoCompleteWeb, FormButton, ScrollColumn } from '../../components'
import { colors, fonts, hexToRgba } from '../../functions'
import { VenuePopover } from '../components'

const WIDTH = 400
const PHOTOS_HEIGHT = 300

const s = StyleSheet.create({
  touchContainer: {
    display: 'flex',
    alignSelf: 'stretch',
  },
  triggerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: hexToRgba(colors.white, 0.09),
    alignSelf: 'stretch', // fixes overlap issue chris was having. need to do this w profile pic popover
    // to do: fix popover menu render
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
    zIndex: -1, // need this so it doesn't bleed through the UserProfilePopover when expanded
    // width: '100%',
  },
  venueName: {
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 24,
    color: hexToRgba(colors.white, 0.87),
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: WIDTH,
    alignSelf: 'center',
    backgroundColor: colors.primary2,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  modalMessageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 23,
    zIndex: 1,
  },
  modalControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  photosContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    // width: WIDTH,
    height: PHOTOS_HEIGHT,
    flex: 1,
  },
})

const TriggerContainer = ({ selectedVenue }) => {
  const text =
    selectedVenue && selectedVenue.name ? selectedVenue.name : 'Select a Venue'
  return (
    <View style={s.triggerContainer}>
      <Text style={s.venueName} children={text} />
    </View>
  )
}

export class VenueSelector extends React.Component {
  state = {
    modalIsVisible: false,
  }

  componentDidMount() {
    this.listener = document.addEventListener('keydown', this._handleKeyDown)
  }

  componentWillUnmount() {
    if (this._handleKeyDown) {
      document.removeEventListener('keydown', this._handleKeyDown)
    }
  }

  _handleKeyDown = e => {
    if (
      (e.ctrlKey === true && e.key === 'b') || // mac
      (e.altKey === true && e.key === 'b') // windows
    ) {
      this.setState(prevState => ({
        modalIsVisible: !prevState.modalIsVisible,
      }))
    }
    if (e.key === 'Escape') {
      this.setState({ modalIsVisible: false })
    }
  }

  _handleSetVenue = () => {
    this.props.closeDrawer && this.props.closeDrawer()
    this.setState({ modalIsVisible: true })
  }

  _handleVenueSelection = venue => {
    this.setState({ modalIsVisible: false }, () =>
      this.props.handleSelect(venue),
    )
  }

  render() {
    const { selectedVenue, allVenues, handleSelect } = this.props
    const { modalIsVisible } = this.state
    return (
      <React.Fragment>
        {allVenues.length > 300 ? (
          <TouchableOpacity
            style={s.touchContainer}
            activeOpacity={0.8}
            onPress={this._handleSetVenue}
          >
            <TriggerContainer selectedVenue={selectedVenue} />
          </TouchableOpacity>
        ) : (
          <VenuePopover
            renderTrigger={() => (
              <TriggerContainer selectedVenue={selectedVenue} />
            )}
            scrollable={allVenues.length > 10}
            allVenues={allVenues}
            handleSelect={handleSelect}
          />
        )}
        {/* this is the modal that pops up when a user has access to more than 6 venues */}
        <Modal
          isVisible={modalIsVisible}
          onBackdropPress={() => this.setState({ modalIsVisible: false })}
        >
          <View style={s.modal}>
            <View style={s.modalMessageContainer}>
              <View style={s.photosContainer}>
                <AutoCompleteWeb
                  items={allVenues}
                  handleSelect={this._handleVenueSelection}
                  handleInput={null}
                  value={selectedVenue.name || ''}
                  width={250}
                  maxWidth={250}
                  maxNumberOfItems={6}
                />
              </View>
            </View>
            <View style={s.modalControls}>
              <FormButton
                text={'Confirm'}
                outerStyle={{ marginRight: 0 }}
                textColor={colors.teal}
                handlePress={() => this.setState({ modalIsVisible: false })}
              />
              <FormButton
                text={'Dismiss'}
                outerStyle={{ marginRight: 0 }}
                textColor={colors.pink}
                handlePress={() => this.setState({ modalIsVisible: false })}
              />
            </View>
          </View>
        </Modal>
      </React.Fragment>
    )
  }
}
