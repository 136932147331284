import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { colors } from '../../functions'
import { BaseText, MCIcon, SectionHeader } from '../../components'
import { WithUser } from '../../layouts/components'
import { Profile } from './sections'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.primary3,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: colors.primary2,
    height: 68,
    paddingBottom: 12,
    paddingHorizontal: 20,
  },
  sectionContainer: {
    backgroundColor: colors.primary2,
    borderRadius: 20,
    marginTop: 24,
    paddingHorizontal: 20,
    marginHorizontal: 10,
  },
})

const Section = ({ title, subtext, component, initialValues, onLayout }) => (
  <View style={s.sectionContainer} onLayout={onLayout}>
    <SectionHeader title={title} subtext={subtext} />
    {component(initialValues)}
  </View>
)

const sections = [
  {
    id: '#profile',
    title: 'Profile',
    label: 'Profile',
    component: Profile,
  },
]

class EditProfile extends React.Component {
  _handleBack = () => {
    this.props.history.goBack()
  }
  render() {
    return (
      <View style={s.wrapper}>
        <View style={s.header}>
          <TouchableOpacity activeOpacity={0.7} onPress={this._handleBack}>
            <MCIcon name="arrow-left" size={24} color={colors.white} />
          </TouchableOpacity>
          <BaseText
            fs={20}
            lh={27}
            weight={'light'}
            children={'Edit Profile'}
          />
        </View>
        <WithUser>
          {({ user }) => (
            <React.Fragment>
              {sections.map((x, i) => (
                <Section key={i} initialValues={user} {...x} />
              ))}
            </React.Fragment>
          )}
        </WithUser>
      </View>
    )
  }
}

export default EditProfile
