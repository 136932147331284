/*
 * Depreciated (in favor of EventPopover / PopoverMenu / react-native-popup-menu)...
 *
 */
import React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Modal from 'modal-enhanced-react-native-web'
import { withRouter } from 'react-router-native'
import { colors } from '../../../functions'
import { HoverableTextTouchHighlight, MIcon } from '../../../components'

const s = StyleSheet.create({
  modalWrapper: {
    display: 'flex',
    // flex: 1,
    // backgroundColor: colors.teal,
  },
  modalContent: {
    // layout
    display: 'flex',
    flexDirection: 'column',
    // style
    backgroundColor: colors.primary1,
    borderRadius: 10,
    maxWidth: 270,
    paddingHorizontal: 25,
    paddingVertical: 15,
    // positioning
    // marginTop: -280,
    marginLeft: 530, // 330 = centered
  },
  menuContainer: {
    display: 'flex',
    backgroundColor: colors.primary1,
  },
  menuContainerHover: {
    backgroundColor: colors.primary1, // don't change the bg color on hover here
  },
  innerContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'flex-start',
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: colors.black0,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.8,
        shadowRadius: 6,
      },
      android: {
        elevation: 3,
      },
      web: {
        shadowColor: colors.black0,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.8,
        shadowRadius: 6,
      },
    }),
  },
})

const tempEventPositionHash = {
  cjcmjcohe4rk80127qshyzilo: 66,
  cjd236bcl0b7w01043qtvuxj6: 90,
  cjd236bcp0aqf01707h7c6tg0: 114,
  cjdi1qunu1vyc0117moqw7faq: 139,
  cjdi1qunyopry01888kp0kbc2: 163,
}

const eventActions = [
  { label: 'Publish', action: 'publish' },
  { label: 'Unpublish', action: 'unpublish' },
  { label: 'Edit', action: 'edit' },
  { label: 'Clone', action: 'clone' },
  { label: 'Delete', action: 'delete' },
]

class EventMenuModal extends React.Component {
  state = {
    modalVisible: false,
  }
  setModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible })
  }
  _handleEventAction = (eventId, action) => {
    console.log('event action:', eventId, action)
    this.props.history.push(`event/${eventId}/${action}`)
    this.setModalVisible()
  }
  renderModalContent = () => {
    const { eventId } = this.props
    // something like this wont work, as the model is centered in the page. need to have it start from the top or something
    // const marginTopCalc = (tempEventPositionHash[eventId] + 340) * -1
    // console.log('event y:', tempEventPositionHash[eventId], 'marginTopCalc', marginTopCalc)
    return (
      // <View style={[s.modalContent, s.shadow, { marginTop: marginTopCalc }]}>
      <View
        style={[
          s.modalContent,
          s.shadow,
          { top: tempEventPositionHash[eventId] },
        ]}
      >
        {eventActions.map(({ label, action }) => (
          <HoverableTextTouchHighlight
            key={action}
            activeOpacity={1}
            underlayColor={colors.primary1}
            innerContainerStyle={s.innerContainerStyle}
            style={s.menuContainer}
            text={label}
            hoverStyle={s.menuContainerHover}
            textStyle={{ textAlign: 'left', color: colors.white }}
            textHoverStyle={{ textAlign: 'left', color: colors.teal }}
            onPress={() => this._handleEventAction(eventId, action)}
          />
        ))}
      </View>
    )
  }

  render() {
    return (
      <View style={s.modalWrapper}>
        <Modal
          animationType={'none'} // enum('slide', 'fade', 'none')
          transparent={true}
          // Default => 'overFullScreen' or 'fullScreen' depending on 'transparent' property.
          presentationStyle={'overFullScreen'} // enum('fullScreen', 'pageSheet', 'formSheet', 'overFullScreen')
          visible={this.state.modalVisible}
          onBackdropPress={this.setModalVisible}
        >
          {this.renderModalContent()}
        </Modal>

        <TouchableOpacity onPress={this.setModalVisible}>
          <MIcon name={'more-horiz'} color={colors.teal} size={24} />
        </TouchableOpacity>
      </View>
    )
  }
}

export default withRouter(EventMenuModal)
