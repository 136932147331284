import React from 'react'
import { StyleSheet, View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { Hoshi } from '../../components'
import { scale, fonts, colors } from '../../functions'

const iconSize = scale(20)
const height = scale(58) + 10
const passwordOffset = (height - iconSize) / 2

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: scale(12),
  },
  input: {
    display: 'flex',
    flex: 1,
    height: height,
    // paddingLeft: scale(13),
    // marginVertical: 0,
  },
  inputStyle: {
    fontFamily: fonts.light,
    fontSize: scale(15),
    lineHeight: scale(24),
    color: colors.white,
  },
  labelStyle: {
    fontFamily: fonts.light,
  },
  pinkIcon: {
    position: 'absolute',
    right: scale(17),
    top: passwordOffset,
  },
  pinkIconPassword: {
    right: scale(49.5),
  },
  passwordVisibility: {
    position: 'absolute',
    right: scale(17),
    top: passwordOffset,
  },
})

export default class Input extends React.Component {
  state = {
    icon: 'eye-off',
    secureTextEntry: false,
    password: false,
  }
  componentWillMount() {
    if (this.props.secureTextEntry) {
      return this.setState({ password: true, secureTextEntry: true })
    } else {
      return this.setState({ password: false, secureTextEntry: false })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.secureTextEntry) {
      return this.setState({ password: true, secureTextEntry: true })
    } else {
      return this.setState({ password: false, secureTextEntry: false })
    }
  }
  handleToggle = () => {
    if (this.state.secureTextEntry) {
      return this.setState({ icon: 'eye', secureTextEntry: false })
    } else {
      return this.setState({ icon: 'eye-off', secureTextEntry: true })
    }
  }
  handleChange = value => {
    // remember that onChangeText will be Formik's setFieldValue
    this.props.onChangeText(this.props.name, value)
  }
  handleBlur = () => {
    // remember that onBlur will be Formik's setFieldTouched
    this.props.onBlur(this.props.name, true)
  }

  render() {
    // we want to pass through all the props except for onChangeText & onBlur
    const {
      onChangeText,
      onBlur,
      error,
      multiline,
      numberOfLines,
      secureTextEntry,
      placeholder,
      borderColor,
      borderHeight,
      ...otherProps
    } = this.props
    return (
      <View style={s.wrapper}>
        <Hoshi
          // react-native-textinput-effects props
          label={placeholder}
          // borderColor={colors.teal}
          style={[
            s.input,
            multiline && {
              paddingTop: scale(15),
              height: numberOfLines * scale(44),
            },
          ]}
          borderColor={borderColor ? borderColor : colors.teal}
          borderHeight={borderHeight ? borderHeight : undefined}
          inputStyle={s.inputStyle}
          labelStyle={s.labelStyle}
          // TextInput props
          selectionColor={colors.teal}
          onChangeText={this.handleChange}
          onBlur={this.handleBlur}
          multiline={multiline}
          secureTextEntry={this.state.secureTextEntry}
          numberOfLines={numberOfLines}
          underlineColorAndroid="transparent"
          keyboardAppearance={'dark'}
          placeholderTextColor={colors.teal}
          {...otherProps}
          renderChildren={() => {
            return (
              <React.Fragment>
                {error && (
                  <Icon
                    name={'alert-circle'}
                    size={iconSize}
                    color={colors.pink}
                    style={[
                      s.pinkIcon,
                      this.state.password && s.pinkIconPassword,
                    ]}
                  />
                )}
                {this.state.password && (
                  <Icon
                    name={this.state.icon}
                    size={iconSize}
                    color={colors.blackgray}
                    style={s.passwordVisibility}
                    onPress={this.handleToggle}
                  />
                )}
              </React.Fragment>
            )
          }}
        />
      </View>
    )
  }
}
