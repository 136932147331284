import React from 'react'
import { View, StyleSheet } from 'react-native'
import { BaseText, EmptyBoxOpen } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 120, // to account for the gradient header / navigation
  },
})

export const NoAccount = () => (
  <View style={s.wrapper}>
    <EmptyBoxOpen />
    <BaseText
      mt={44}
      mh={20} // make sure the text doesnt touch the edges of the screen on smaller devices
      color={'secondary2'}
      fs={16}
      lh={22}
      ta={'center'} // ensure the text wraps cleanly on smaller devices
      children={"There doesn't seem to be anything here yet."}
    />
    {/* <FormButton
			text={'+ New Event'}
			textColor={colors.teal}
			handlePress={() => console.log('add event button pressed ')}
		/> */}
  </View>
)
