import React from 'react'
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native'
import { Query } from 'react-apollo'
import { TICKET_OPTION_STATUS_QUERY } from '../../../gql'
import { Dropdown, BaseText } from '../../../components'
import { DimensionsConsumer } from '../../../lib'
import { WithSelectedVenuePermission } from '../../../layouts/components'
import { colors } from '../../../functions'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  banner: {
    width: '100%',
    backgroundColor: '#2a2a34',
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 50,
  },
  bannerText: {
    color: colors.pink,
    textAlign: 'center',
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  labelWrapper: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
  },
  labelWrapperActive: {
    backgroundColor: colors.primary3,
  },
})

export const Status = ({ additionalProps, onFormReset }) => (
  <WithSelectedVenuePermission permission={'MANAGE_VENUE_EVENTS_TICKETS_FEES'}>
    {({ permission }) => (
      <Query query={TICKET_OPTION_STATUS_QUERY}>
        {({ loading, error, data, refetch }) => {
          if (loading || !data) return <ActivityIndicator />

          const canUserEdit = !!permission || additionalProps.values.fee
          const statusItems = (data.allTicketOptionStatuses || []).map(
            item => ({
              label: item.displayName,
            }),
          )

          const selectedStatus = additionalProps.values.status
            ? additionalProps.values.status.displayName
            : data.allTicketOptionStatuses.length
            ? statusItems[0].label
            : ''

          return (
            <DimensionsConsumer>
              {({ width }) => {
                return (
                  <React.Fragment>
                    {!canUserEdit && (
                      <View style={[s.banner]}>
                        <Text style={[s.bannerText]}>
                          This ticket can be published after fees are activated
                          for your ticket type. Contact us @{' '}
                          <a
                            style={{ color: colors.pink }}
                            href="mailto:VenueSupport@getbarcard.com"
                          >
                            VenueSupport@getbarcard.com
                          </a>
                        </Text>
                      </View>
                    )}

                    <View style={[s.wrapper]}>
                      <Dropdown
                        containerStyle={[s.labelWrapper]}
                        containerActiveStyle={[
                          s.labelWrapper,
                          s.labelWrapperActive,
                        ]}
                        selected={selectedStatus}
                        items={statusItems}
                        handleItemPress={label => {
                          const item = data.allTicketOptionStatuses.find(
                            item => item.displayName === label,
                          )
                          additionalProps.setFieldValue('status', item)
                          additionalProps.setFieldValue('statusId', item.id)
                        }}
                        disabled={!canUserEdit}
                      />
                      <View style={s.filler} />
                    </View>
                    {additionalProps.errors.status && (
                      <BaseText
                        fs={12}
                        color={'pink'}
                        children={additionalProps.errors.status}
                      />
                    )}
                  </React.Fragment>
                )
              }}
            </DimensionsConsumer>
          )
        }}
      </Query>
    )}
  </WithSelectedVenuePermission>
)
