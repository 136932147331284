import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import { compose, graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { colors, hashMap } from '../../functions'
import { VENUE_SPECIALS_QUERY_NEW } from '../../gql'
import { Dropdown, FormButton } from '../../components'
import { WithSelectedVenue } from '../../layouts/components'
import { NoSpecials, SpecialCard } from './components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: 1200,
    paddingHorizontal: 20,
    borderRadius: 20,
    marginTop: 25,
  },
})

const dropdownBase = [{ id: 'all', label: 'All Specials' }]

class Specials extends React.Component {
  state = {
    sections: [],
    dropdownItems: dropdownBase,
    specialsHash: null,
    currentSection: 'All Specials',
  }

  // on more thought, we should probably have the days of the week in the header
  // will do this soon
  _setInitialState = specials => {
    // get the list of specials from the apollo, so we can populate the dropdown menu
    const dropdownItems = [
      ...dropdownBase,
      ...specials.map(x => {
        return { id: x.id, label: x.name }
      }),
    ]
    // create the hash map as well
    const specialsHash = hashMap(specials, 'name')
    // To Do: should check to see if theyre the same, and only update if theyre different
    this.setState({ dropdownItems, specialsHash })
  }

  componentWillMount() {
    if (
      this.props.data &&
      this.props.data.Venue &&
      this.props.data.Venue.specials
    ) {
      this._setInitialState(this.props.data.Venue.specials)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data &&
      nextProps.data.Venue &&
      nextProps.data.Venue.specials
    ) {
      this._setInitialState(nextProps.data.Venue.specials)
    }
  }

  _handleSectionSelected = label => {
    this.setState({ currentSection: label })
  }

  _handleEdit = id => {
    this.props.history.push(`special/${id}/edit`)
  }

  _handleAdd = () => {
    this.props.history.push(`special/new`)
  }

  render() {
    const { loading, Venue } = this.props.data
    const { dropdownItems, currentSection, specialsHash } = this.state

    return (
      <React.Fragment>
        <Dropdown
          items={dropdownItems}
          selected={currentSection}
          wrapperStyle={{ marginTop: -44 }}
          handleItemPress={this._handleSectionSelected}
        />
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.wrapper}
          scrollsToTop={true}
          scrollEventThrottle={16}
        >
          {loading ? (
            <ActivityIndicator />
          ) : (
            <React.Fragment>
              {Venue && Venue.specials && Venue.specials.length > 0 ? (
                <View style={s.contentContainer}>
                  {currentSection === 'All Specials' ? (
                    Venue.specials.map(x => (
                      <SpecialCard
                        key={x.id}
                        special={x}
                        handleEdit={this._handleEdit}
                      />
                    ))
                  ) : (
                    <SpecialCard
                      special={specialsHash[currentSection]}
                      handleEdit={this._handleEdit}
                    />
                  )}
                  <FormButton
                    outerStyle={{ marginTop: 20 }}
                    text={'+ New Special'}
                    textColor={colors.teal}
                    handlePress={this._handleAdd}
                  />
                </View>
              ) : (
                <NoSpecials handlePress={this._handleAdd} />
              )}
            </React.Fragment>
          )}
        </ScrollView>
      </React.Fragment>
    )
  }
}

const SpecialsWithData = compose(
  withRouter,
  graphql(VENUE_SPECIALS_QUERY_NEW, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: { id: props.selectedVenue.id },
    }),
  }),
)(Specials)

export default () => (
  <WithSelectedVenue>
    {({ selectedVenue }) => <SpecialsWithData selectedVenue={selectedVenue} />}
  </WithSelectedVenue>
)
