import gql from 'graphql-tag'
import { VenueFragments } from './fragments'

/*
 * Get Venue Photos
 *
 */

export const VENUE_PHOTOS_QUERY = gql`
  query($id: ID!) {
    Venue(id: $id) {
      id
      photos {
        ...VenuePhotoDetails
      }
    }
  }
  ${VenueFragments.photoDetails}
`

/*
 * sets a feature photo when one has not been set yet
 *
 */

export const SET_VENUE_FEATURE_PHOTO = gql`
  mutation($featurePhotoId: ID!) {
    setFeature: updateVenuePhoto(
      id: $featurePhotoId
      feature: true
      profile: true
    ) {
      ...VenuePhotoDetails
    }
  }
  ${VenueFragments.photoDetails}
`

/*
 * removes the previous feature photo, and adds the new one.
 *
 */

export const UPDATE_VENUE_FEATURE_PHOTO = gql`
  mutation($featurePhotoId: ID!, $prevFeaturePhotoId: ID!) {
    remove: updateVenuePhoto(id: $prevFeaturePhotoId, feature: false) {
      ...VenuePhotoDetails
    }
    add: updateVenuePhoto(id: $featurePhotoId, feature: true, profile: true) {
      ...VenuePhotoDetails
    }
  }
  ${VenueFragments.photoDetails}
`

/*
 * updates a VenuePhoto record. Sets profile, & menu (and foodDrink & experience). feature is managed separately
 * all props are optional, so this can be used for multiple types of updates
 */

export const UPDATE_VENUE_PHOTO = gql`
  mutation(
    $id: ID!
    $profile: Boolean
    $menu: Boolean
    $foodDrink: Boolean
    $experience: Boolean
    $published: Boolean
    $filename: String
  ) {
    updatePhoto: updateVenuePhoto(
      id: $id
      profile: $profile
      menu: $menu
      foodDrink: $foodDrink
      experience: $experience
      published: $published
      filename: $filename
    ) {
      ...VenuePhotoDetails
    }
  }
  ${VenueFragments.photoDetails}
`

export const CREATE_VENUE_PHOTO = gql`
  mutation(
    $filename: String!
    $handle: String!
    $mimetype: String!
    $size: Int!
    $url: String!
    $profile: Boolean
    $feature: Boolean
    $menu: Boolean
    $foodDrink: Boolean
    $experience: Boolean
    $published: Boolean
    $venueId: ID!
  ) {
    createVenuePhoto(
      filename: $filename
      handle: $handle
      mimetype: $mimetype
      size: $size
      url: $url
      profile: $profile
      feature: $feature
      menu: $menu
      foodDrink: $foodDrink
      experience: $experience
      published: $published
      venueId: $venueId
    ) {
      ...VenuePhotoDetails
    }
  }
  ${VenueFragments.photoDetails}
`

/*
 * how we were managing VenuePhotos in bc admin:
 *
handleProfilePhotoChange = o => {
  const newPhotos = this.props.data.Venue.photos.map(x => {
    let n = {}
    n.id = x.id
    n.profile = o.profilePhotoIds.indexOf(x.id) < 0 ? false : true
    n.gallery = o.galleryPhotoIds.indexOf(x.id) < 0 ? false : true
    n.menu = o.menuPhotoIds.indexOf(x.id) < 0 ? false : true
    return n
  })
  return newPhotos.map(x => {
    return this.props
      .updateProfilePhoto(x)
      .then(response => {
        console.log('SUCCESS', response)
      })
      .catch(err => {
        console.error('ERROR', err)
      })
  })
}
*/

/*
 * how we were managing the feature photo in bc admin
 * 
  handleFeaturePhotoChange = o => {
  const prevFeaturePhotoId = this.props.data.Venue.photos
    ? this.props.data.Venue.photos.filter(t => t.feature).map(a => a.id)[0]
    : null
  if (prevFeaturePhotoId) {
    o.prevFeaturePhotoId = prevFeaturePhotoId
    return this.props
      .updateFeature(o)
      .then(response => {
        console.log('SUCCESS', response)
      })
      .catch(err => {
        console.error('ERROR', err)
      })
  } else {
    return this.props
      .addFeature(o)
      .then(response => {
        console.log('SUCCESS', response)
      })
      .catch(err => {
        console.error('ERROR', err)
      })
  }
}
 */
