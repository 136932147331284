export const GRAPHCOOL_SIMPLE =
  'https://api.graph.cool/simple/v1/cj8or7gcl00tu01625r7ikfj2'

// styles related to the Inner Content
export const INNER_ML = 44 // inner margin left
export const ROW_MT = 33 // header row margin top
export const SCROLL_PT = 16 // scroll view padding top
export const HEADER_WIDTH = '273px' // header text width (proxy for margin right)
export const SUBHEADER_MR = 92 // subheader text margin right

// styles related to Text Inputs
export const INPUT_MARGIN_BOTTOM = 2
export const INPUT_BORDER = 2
export const INPUT_TEXT_SIZE = 15 // previously 13
export const INPUT_LINE_HEIGHT = 20 // previously 18

// styles related to Side Nav / Logos need to be ported here
// can be found @ /screens/Main/components/SideNav.js
