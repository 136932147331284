import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { MIcon } from '../../components'
import { scale, fonts, colors } from '../../functions'

const iconColor = colors.blackBg
const iconSize = 20

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: scale(2.5),
    backgroundColor: colors.black23,
    marginBottom: 6,
  },
  icon: {},
  text: {
    fontFamily: fonts.regular,
    fontSize: 13,
    lineHeight: 18,
    color: colors.teal,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 6,
    paddingLeft: 3,
    alignItems: 'baseline',
  },
})

export const TagPill = ({ item, handleRemove }) => (
  <View style={s.container}>
    <Text style={s.text}>{item && item.name}</Text>
    <TouchableOpacity
      style={s.iconContainer}
      onPressIn={() => handleRemove(item)}
    >
      <MIcon name="close" size={iconSize} color={iconColor} style={s.icon} />
    </TouchableOpacity>
  </View>
)
