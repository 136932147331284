import React from 'react'
import { Linking, Image, StyleSheet, View, ScrollView } from 'react-native'
import { WhiteTouchText, MIcon, AutoComplete } from '../../components'
import { scale, colors } from '../../functions'
import { LoginForm } from './index'
import { TouchableOpacity } from 'react-native-web'

const logoHeight = 70
const logoTextDimensions = 650 / 200
const flexRatio = 0.85

const s = StyleSheet.create({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    marginTop: 20,
    paddingTop: scale(10),
    paddingHorizontal: scale(30),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: scale(logoHeight * logoTextDimensions),
    height: scale(logoHeight),
    // marginBottom: scale(logoHeight / 10),
    zIndex: 4,
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentScroll: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class Login extends React.Component {
  state = {
    loginError: null,
  }

  // render() {
  //   return (
  //   <View style={s.container}>
  //     <View style={s.top}>
  //       <Image
  //         source={require('../../assets/bc-virtual-manager-large-min.png')}
  //         style={s.logo}
  //       />
  //       <MIcon name={'menu'} color={colors.white} size={32} />
  //     </View>
  //     <View style={s.wrapper}>
  //       <View style={s.content}>
  //         <LoginForm
  //           history={this.props.history}
  //           handleCancel={this._handleClose}
  //         />
  //       </View>
  //     </View>
  //   </View>
  //   )
  // }

  render() {
    return (
      <View style={s.wrapper}>
        <View style={s.top}>
          <Image
            source={require('../../assets/barcard-text-logo.svg')}
            style={s.logo}
            resizeMode={'contain'}
          />
          <MIcon name={'sort'} color={colors.white} size={scale(32)} />
        </View>
        <View style={s.contentWrapper}>
          <ScrollView
            style={s.contentScroll}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <View style={s.content}>
              <LoginForm
                style={s.loginForm}
                history={this.props.history}
                handleCancel={this._handleClose}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    )
  }
}

export default Login
