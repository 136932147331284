import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Switch } from 'react-router-dom'
import Routing from '../../utils/routing'
import { colors } from '../../functions'
import { childRoutes } from '../../config'
import { WithUser } from '../components'

const Route = Routing.Route

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.primary3,
  },
})

export const AuthLayoutStack = ({ children }) => {
  return (
    <View style={s.wrapper}>
      <WithUser>
        {({ user }) => {
          return (
            <Switch>
              {childRoutes.authStack.map(
                (
                  { routeKey, exact, path, component, stackProps, ...rest },
                  i,
                ) => (
                  <Route
                    key={i}
                    exact={exact}
                    path={path}
                    component={component}
                    {...stackProps}
                    {...rest}
                  />
                ),
              )}
            </Switch>
          )
        }}
      </WithUser>
    </View>
  )
}
