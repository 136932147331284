import styled from 'styled-components/primitives'

export const Header = styled.Text`
  font-family: 'OpenSans';
  font-weight: ${props => (props.fw ? props.fw : 300)};
  font-size: 30px;
  line-height: 40px;
  color: ${props => (props.color ? props.color : '#707070')};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
`
export const SubHeader = styled.Text`
  font-family: 'OpenSans';
  /*font-weight: ${props => (props.active ? 600 : 200)};*/
  font-weight: ${props => (props.fw ? props.fw : 300)};
  font-size: 20px;
  line-height: 27px;
  color: ${props =>
    props.active ? '#fff' : props.color ? props.color : '#95989A'};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
`
export const SubText = styled.Text`
  font-family: 'OpenSans';
  font-weight: ${props => (props.fw ? props.fw : 300)};
  font-size: 16px;
  line-height: 22px;
  color: ${props => (props.color ? props.color : '#C4C4C4')};
  ${props => props.as && `align-self: ${props.as}`};
  ${props => props.jss && `justify-self: ${props.js}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
`
export const InfoText = styled.Text`
  font-family: 'OpenSans';
  font-weight: ${props => (props.fw ? props.fw : 300)};
  font-size: 13px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : '#707070')};
  ${props => props.as && `align-self: ${props.as}`};
  ${props => props.jss && `justify-self: ${props.js}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
`
export const DropdownText = styled.Text`
  font-family: 'OpenSans';
  font-weight: ${props => (props.fw ? props.fw : 300)};
  font-size: 11px;
  line-height: 15px;
  color: ${props => (props.color ? props.color : '#707070')};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.mv && `margin-top: ${props.mv}px`};
  ${props => props.mv && `margin-bottom: ${props.mv}px`};
  ${props => props.mh && `margin-left: ${props.mh}px`};
  ${props => props.mh && `margin-right: ${props.mh}px`};
  ${props => props.pt && `padding-top: ${props.pt}px`};
  ${props => props.pb && `padding-bottom: ${props.pb}px`};
  ${props => props.pl && `padding-left: ${props.pl}px`};
  ${props => props.pr && `padding-right: ${props.pr}px`};
  ${props => props.pv && `padding-top: ${props.pv}px`};
  ${props => props.pv && `padding-bottom: ${props.pv}px`};
  ${props => props.ph && `padding-left: ${props.ph}px`};
  ${props => props.ph && `padding-right: ${props.ph}px`};
`
