export const dayOpts = [
  { name: 'Sunday', label: 'Sunday', value: 0 },
  { name: 'Monday', label: 'Monday', value: 1 },
  { name: 'Tuesday', label: 'Tuesday', value: 2 },
  { name: 'Wednesday', label: 'Wednesday', value: 3 },
  { name: 'Thursday', label: 'Thursday', value: 4 },
  { name: 'Friday', label: 'Friday', value: 5 },
  { name: 'Saturday', label: 'Saturday', value: 6 },
]

export const timeOpts = [
  // we may want to put PM first since these will be more common for us
  { name: '12:00 PM', label: '12:00 PM', value: 720 },
  { name: '12:30 PM', label: '12:30 PM', value: 750 },
  { name: '1:00 PM', label: '1:00 PM', value: 780 },
  { name: '1:30 PM', label: '1:30 PM', value: 810 },
  { name: '2:00 PM', label: '2:00 PM', value: 840 },
  { name: '2:30 PM', label: '2:30 PM', value: 870 },
  { name: '3:00 PM', label: '3:00 PM', value: 900 },
  { name: '3:30 PM', label: '3:30 PM', value: 930 },
  { name: '4:00 PM', label: '4:00 PM', value: 960 },
  { name: '4:30 PM', label: '4:30 PM', value: 990 },
  { name: '5:00 PM', label: '5:00 PM', value: 1020 },
  { name: '5:30 PM', label: '5:30 PM', value: 1050 },
  { name: '6:00 PM', label: '6:00 PM', value: 1080 },
  { name: '6:30 PM', label: '6:30 PM', value: 1110 },
  { name: '7:00 PM', label: '7:00 PM', value: 1140 },
  { name: '7:30 PM', label: '7:30 PM', value: 1170 },
  { name: '8:00 PM', label: '8:00 PM', value: 1200 },
  { name: '8:30 PM', label: '8:30 PM', value: 1230 },
  { name: '9:00 PM', label: '9:00 PM', value: 1260 },
  { name: '9:30 PM', label: '9:30 PM', value: 1290 },
  { name: '10:00 PM', label: '10:00 PM', value: 1320 },
  { name: '10:30 PM', label: '10:30 PM', value: 1350 },
  { name: '11:00 PM', label: '11:00 PM', value: 1380 },
  { name: '11:30 PM', label: '11:30 PM', value: 1410 },
  { name: '5:30 AM', label: '5:30 AM', value: 330 },
  { name: '6:00 AM', label: '6:00 AM', value: 360 },
  { name: '6:30 AM', label: '6:30 AM', value: 390 },
  { name: '7:00 AM', label: '7:00 AM', value: 420 },
  { name: '7:30 AM', label: '7:30 AM', value: 450 },
  { name: '8:00 AM', label: '8:00 AM', value: 480 },
  { name: '8:30 AM', label: '8:30 AM', value: 510 },
  { name: '9:00 AM', label: '9:00 AM', value: 540 },
  { name: '9:30 AM', label: '9:30 AM', value: 570 },
  { name: '10:00 AM', label: '10:00 AM', value: 600 },
  { name: '10:30 AM', label: '10:30 AM', value: 630 },
  { name: '11:00 AM', label: '11:00 AM', value: 660 },
  { name: '11:30 AM', label: '11:30 AM', value: 690 },
  { name: '12:00 AM', label: '12:00 AM', value: 0, endValue: 1440 },
  { name: '12:30 AM', label: '12:30 AM', value: 30, endValue: 1470 },
  { name: '1:00 AM', label: '1:00 AM', value: 60, endValue: 1500 },
  { name: '1:30 AM', label: '1:30 AM', value: 90, endValue: 1530 },
  { name: '2:00 AM', label: '2:00 AM', value: 120, endValue: 1560 },
  { name: '2:30 AM', label: '2:30 AM', value: 150, endValue: 1590 },
  { name: '3:00 AM', label: '3:00 AM', value: 180, endValue: 1620 },
  { name: '3:30 AM', label: '3:30 AM', value: 210, endValue: 1650 },
  { name: '4:00 AM', label: '4:00 AM', value: 240, endValue: 1680 },
  { name: '4:30 AM', label: '4:30 AM', value: 270, endValue: 1710 }, // maybe should only go to 4AM
  { name: '5:00 AM', label: '5:00 AM', value: 300, endValue: 1740 },

  // this can work if we keep name & label the same:
  // { name: '12:00 AM', label: '12:00 AM (midnight)', value: 1440 },
  // { name: '12:30 AM', label: '12:30 AM (next day)', label: 'day', value: 1470 },
  // { name: '1:00 AM', label: '1:00 AM (next day)', label: 'day', value: 1500 },
  // { name: '1:30 AM', label: '1:30 AM (next day)', label: 'day', value: 1530 },
  // { name: '2:00 AM', label: '2:00 AM (next day)', label: 'day', value: 1560 },
  // { name: '2:30 AM', label: '2:30 AM (next day)', label: 'day', value: 1590 },
  // { name: '3:00 AM', label: '3:00 AM (next day)', label: 'day', value: 1620 },
  // { name: '3:30 AM', label: '3:30 AM (next day)', label: 'day', value: 1650 },
  // { name: '4:00 AM', label: '4:00 AM (next day)', label: 'day', value: 1680 },
]

export const timeOptsOld = [
  { name: '12:00 AM' },
  { name: '12:30 AM' },
  { name: '1:00 AM' },
  { name: '1:30 AM' },
  { name: '2:00 AM' },
  { name: '2:30 AM' },
  { name: '3:00 AM' },
  { name: '3:30 AM' },
  { name: '4:00 AM' },
  { name: '4:30 AM' },
  { name: '5:00 AM' },
  { name: '5:30 AM' },
  { name: '6:00 AM' },
  { name: '6:30 AM' },
  { name: '7:00 AM' },
  { name: '7:30 AM' },
  { name: '8:00 AM' },
  { name: '8:30 AM' },
  { name: '9:00 AM' },
  { name: '9:30 AM' },
  { name: '10:00 AM' },
  { name: '10:30 AM' },
  { name: '11:00 AM' },
  { name: '11:30 AM' },
  { name: '12:00 PM' },
  { name: '12:30 PM' },
  { name: '1:00 PM' },
  { name: '1:30 PM' },
  { name: '2:00 PM' },
  { name: '2:30 PM' },
  { name: '3:00 PM' },
  { name: '3:30 PM' },
  { name: '4:00 PM' },
  { name: '4:30 PM' },
  { name: '5:00 PM' },
  { name: '5:30 PM' },
  { name: '6:00 PM' },
  { name: '6:30 PM' },
  { name: '7:00 PM' },
  { name: '7:30 PM' },
  { name: '8:00 PM' },
  { name: '8:30 PM' },
  { name: '9:00 PM' },
  { name: '9:30 PM' },
  { name: '10:00 PM' },
  { name: '10:30 PM' },
  { name: '11:00 PM' },
  { name: '11:30 PM' },
]
