import React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { colors, fonts, hexToRgba } from '../../functions'
import { INPUT_MARGIN_BOTTOM, INPUT_BORDER } from '../../config'
import { MIcon } from '../../components'

const INPUT_LABEL_WIDTH = 175

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    // backgroundColor: 'rgba(35,35,35,0.5)',
    paddingTop: '15px',
    paddingBottom: '11px',
    marginBottom: `${INPUT_MARGIN_BOTTOM}px`,
    borderBottomWidth: `${INPUT_BORDER}px`,
    borderBottomColor: hexToRgba(colors.secondary1, 0.2),
  },
  inputActive: {
    borderBottomColor: colors.teal,
  },
  inputError: {
    borderBottomColor: colors.pink,
  },
  // multiline input styles
  multiWrapper: {
    flexDirection: 'column',
  },
  multiInput: {
    marginTop: 7,
    paddingRight: 13,
  },
  labelWrapper: {
    //paddingLeft: 13,
    width: INPUT_LABEL_WIDTH,
  },
  labelWrapperWide: {
    width: INPUT_LABEL_WIDTH * 1.3,
  },
  input: {
    fontFamily: fonts.light,
    fontSize: 14,
    lineHeight: 24,
    color: colors.secondary2,
    flex: 1,
    outline: 'none',
    paddingRight: 13,
  },
  disabledInput: {
    color: hexToRgba(colors.secondary2, 0.7),
  },
  label: {
    fontFamily: fonts.semibold,
    fontSize: 14,
    lineHeight: 24,
    color: colors.secondary3,
  },
  labelActive: {
    color: colors.teal,
  },
  labelError: {
    color: colors.pink,
  },
  disabledIcon: {
    paddingLeft: -20,
  },
  labelOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  textInputWrapper: {
    alignSelf: 'stretch',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
})

class LabelInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      textInputValue: props.value,
    }
  }

  handleChange = value => {
    if (!this.props.onChangeTextOnlyOnBlur) {
      this.props.onChangeText(this.props.name, value)
    } else {
      this.setState({ textInputValue: value })
    }
  }
  handleOnBlur = () => {
    // eventually should make onBlur required, but for now, let's do a conditional
    // check so we don't break the app in places where an onBlur is not defined
    // after further investigation, it seems like we may not need this.
    if (this.props.onBlur) {
      return (
        this.props.onBlur(this.props.name, true) &&
        this.setState({ focused: false })
      )
    } else {
      this.setState({ focused: false })
    }

    if (this.props.onChangeTextOnlyOnBlur && this.props.onChangeText) {
      this.props.onChangeText(this.props.name, this.state.textInputValue)
    }
  }
  handleOnFocus = () => {
    return this.setState({ focused: true })
  }
  // we may not need this if we have onBlur, but leaving for now
  handleOnEndEditing = () => {
    this.setState({ focused: false })
  }

  render() {
    const {
      placeholder,
      wide,
      onChangeText,
      onChangeTextOnlyOnBlur,
      multiline,
      collapseLabel,
      label,
      backgroundColor,
      labelColor,
      labelWidth,
      disabled,
      wrapperStyle,
      textInputWrapperStyle,
      textInputStyle,
      textInputKeyboardType,
      ...inputProps
    } = this.props
    const { focused } = this.state
    const showCollapsedLabel = multiline || collapseLabel

    if (onChangeTextOnlyOnBlur) {
      inputProps.value = this.state.textInputValue
    }

    return (
      <View
        style={[
          s.wrapper,
          backgroundColor && { backgroundColor: backgroundColor },
          showCollapsedLabel && s.multiWrapper,
          focused && s.inputActive,
          wrapperStyle && wrapperStyle,
        ]}
      >
        <View style={s.labelOuterWrapper}>
          <View
            style={[
              s.labelWrapper,
              wide && s.labelWrapperWide,
              labelWidth && { width: labelWidth },
            ]}
          >
            <Text
              style={[
                s.label,
                labelColor && { color: labelColor },
                focused && s.labelActive,
              ]}
            >
              {label && label}
            </Text>
          </View>
          {multiline && disabled && (
            <MIcon
              name={'lock-outline'}
              size={18}
              color={colors.secondary1}
              style={s.disabledIcon}
            />
          )}
        </View>
        <View
          style={[
            s.textInputWrapper,
            textInputWrapperStyle && textInputWrapperStyle,
          ]}
        >
          <TextInput
            ref={x => (this.textInput = x)}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            onEndEditing={this.handleOnEndEditing}
            placeholder={placeholder}
            style={[
              s.text,
              s.input,
              showCollapsedLabel && s.multiInput,
              disabled && s.disabledInput,
              textInputStyle && textInputStyle,
            ]}
            onChangeText={this.handleChange}
            multiline={multiline ? true : false}
            disabled={disabled}
            data-hj-whitelist // whitelist on hotjar
            keyboardType={
              textInputKeyboardType ? textInputKeyboardType : 'default'
            }
            {...inputProps}
          />
          {!multiline && disabled && (
            <MIcon
              name={'lock-outline'}
              size={18}
              color={colors.secondary1}
              style={s.disabledIcon}
            />
          )}
        </View>
      </View>
    )
  }
}

export default LabelInput

/*
 *
 * need to make sure we're using keyboard type + max length:
 *   keyboardType={props.keyboardType ? props.keyboardType : 'default'}
 *   maxLength={props.maxLength ? props.maxLength : null}
 *
 * need to implement placeholders
 *   placeholder
 *   placeholderTextColor
 *
 */
