import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native'
import { Query } from 'react-apollo'
import { EVENTS_QUERY } from '../../gql'
import { Dropdown, FormButton, ContentContainer } from '../../components'
import { colors, hashMap, nearestInterval } from '../../functions'
import { EventsList, NoEvents, Section } from './components'
import { WithSelectedVenue } from '../../layouts/components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const sections = [
  {
    id: '#published',
    title: 'Published Events',
    label: 'Published Events',
    status: 'published',
    component: EventsList,
  },
  {
    id: '#unpublished',
    title: 'Unpublished Events',
    label: 'Unpublished Events',
    status: 'unpublished',
    component: EventsList,
  },
  {
    id: '#expired',
    title: 'Expired Events',
    label: 'Expired Events',
    status: 'expired',
    component: EventsList,
  },
]

const dropdownItems = [{ id: '#all', label: 'All Events' }, ...sections]

class Events extends React.Component {
  state = {
    sectionsHash: hashMap(dropdownItems, 'label'),
    sectionsHashHash: hashMap(dropdownItems, 'id'),
    dropdownItems: dropdownItems,
    currentSection: 'All Events',
    date: null,
  }

  componentWillMount() {
    const { hash } = this.props.history.location
    if (hash && hash.length > 0 && this.state.sectionsHashHash[hash]) {
      this.setState({
        currentSection: this.state.sectionsHashHash[hash].label,
        date: nearestInterval(15),
      })
    } else {
      this.setState({ date: nearestInterval(15) })
    }
  }

  _handleNewEvent = venueId => {
    this.props.history.push(`/event/new?${venueId}`)
  }

  _handleSectionSelected = label => {
    const hash = this.state.sectionsHash[label].id
    this.setState({ currentSection: label })
    this.props.history.push(hash)
  }

  render() {
    const { currentSection, sectionsHash, date } = this.state

    return (
      <React.Fragment>
        <Dropdown
          items={this.state.dropdownItems}
          selected={currentSection}
          wrapperStyle={{ marginTop: -44 }}
          handleItemPress={this._handleSectionSelected}
        />
        <ScrollView
          ref={c => (this.scrollView = c)}
          contentContainerStyle={s.wrapper}
          scrollsToTop={true}
          // scrollEventThrottle={16}
        >
          <WithSelectedVenue>
            {({ selectedVenue }) => (
              <Query
                query={EVENTS_QUERY}
                fetchPolicy={'cache-and-network'}
                variables={{ id: selectedVenue.id, date: date }}
              >
                {({ data: { Venue }, networkStatus, error }) => {
                  if (networkStatus === 1) return <ActivityIndicator />

                  // to do: add an error screen !
                  if (error) console.log('apollo error', error)

                  if (
                    Venue &&
                    Venue._eventsMeta &&
                    Venue._eventsMeta.count === 0
                  )
                    return (
                      <NoEvents
                        handleNewEvent={() =>
                          this._handleNewEvent(selectedVenue.id)
                        }
                      />
                    )

                  const currSectionProps =
                    currentSection !== 'All Events' &&
                    sectionsHash[currentSection]

                  return (
                    <ContentContainer
                      style={s.contentContainer}
                      smallStyle={s.contentContainerMobile}
                    >
                      {currentSection === 'All Events'
                        ? sections.map((x, i) => (
                            <Section
                              key={i}
                              venueId={selectedVenue.id}
                              queryDate={date}
                              events={
                                Venue && Venue[x.status] && Venue[x.status]
                              }
                              scrollable={true}
                              status={x.status}
                              // handleMeasureLayout={this.handleMeasureLayout}
                              {...x}
                            />
                          ))
                        : currSectionProps && (
                            <Section
                              initialValues={Venue} // do we need this ?
                              events={
                                Venue &&
                                Venue[currSectionProps.status] &&
                                Venue[currSectionProps.status]
                              }
                              scrollable={false}
                              // handleMeasureLayout={this.handleMeasureLayout}
                              {...currSectionProps}
                            />
                          )}
                      <FormButton
                        outerStyle={{ marginTop: 15, alignSelf: 'center' }}
                        text={'+ New Event'}
                        textColor={colors.teal}
                        handlePress={() =>
                          this._handleNewEvent(selectedVenue.id)
                        }
                      />
                    </ContentContainer>
                  )
                }}
              </Query>
            )}
          </WithSelectedVenue>
        </ScrollView>
      </React.Fragment>
    )
  }
}

export default Events
