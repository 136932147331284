import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-native'
import { colors } from '../../../functions'
import {
  BaseText,
  FormButton,
  MIcon,
  PopoverMenu,
  FixedWidthContainer,
} from '../../../components'
import { EVENTS_QUERY_ADMIN, UPDATE_EVENT_PUBLISHED } from '../../../gql'

const s = StyleSheet.create({
  wrapper: {
    // padding: 10,
  },
})

class EventPopover extends React.Component {
  _handleEdit = id => {
    this.props.history.push(`event/${id}/edit`)
  }

  _handleDuplicate = id => {
    this.props.history.push(`event/duplicate?${id}`)
  }

  _handleAdd = () => {
    this.props.history.push(`event/new`)
  }

  render() {
    const { id, handleEdit } = this.props

    return (
      <React.Fragment>
        <Mutation
          mutation={UPDATE_EVENT_PUBLISHED}
          refetchQueries={() => [
            {
              query: EVENTS_QUERY_ADMIN,
              variables: { date: this.props.queryDate, limit: 100 },
            },
          ]}
        >
          {(updateEventPublished, { error, loading }) => (
            <View style={s.wrapper}>
              <PopoverMenu
                menuProviderWrapperStyle={{ padding: 0 }}
                renderTrigger={() => (
                  <MIcon name={'more-horiz'} color={colors.teal} size={24} />
                )}
                options={[
                  {
                    label: 'Publish',
                    onSelect: async () => {
                      try {
                        console.log('event.id', id)
                        const res = await updateEventPublished({
                          variables: { id: id, hidden: false, published: true },
                        })
                        console.log(
                          'successfully published Event',
                          res.data.updateEventPublished,
                        )
                      } catch (e) {
                        console.log('error publishing Event', e)
                      }
                    },
                  },
                  {
                    label: 'Unpublish',
                    onSelect: async () => {
                      try {
                        console.log('event.id', id)
                        const res = await updateEventPublished({
                          // to do: change to published
                          variables: { id: id, hidden: true, published: false },
                        })
                        console.log(
                          'successfully unpublished Event',
                          res.data.updateEventPublished,
                        )
                      } catch (e) {
                        console.log('error unpublishing Event', e)
                      }
                    },
                  },
                  {
                    label: 'Edit',
                    onSelect: () => this._handleEdit(id),
                  },
                  {
                    label: 'Duplicate',
                    onSelect: () => this._handleDuplicate(id),
                  },
                ]}
              />
            </View>
          )}
        </Mutation>
      </React.Fragment>
    )
  }
}

export default withRouter(EventPopover)
