import React from 'react'
import { ActivityIndicator, ScrollView, View, StyleSheet } from 'react-native'
import { graphql, Mutation } from 'react-apollo'
import {
  EVENT_QUERY,
  UPDATE_EVENT_INFO,
  UPDATE_EVENT_IS_PUBLISHED,
} from '../../gql'
import { adjustForTimezone, nearestInterval } from '../../functions'
import { ContentContainer, FormButton } from '../../components'
import { Section } from './components'
import { infoSections } from './info-sections'
import { ticketSections } from './ticket-sections'
import { publishSections } from './publish-sections'
import { EventBreadcrumb } from './components/EventBreadcrumb'
import { colors, sanitizeValues } from '../../functions'
import { Formik } from 'formik'
import { WithSelectedVenuePermission } from '../../layouts/components'

const INFO_TAB = 'info'
const TICKET_TAB = 'ticket'
const PUBLISH_TAB = 'publish'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: 30,
    //marginTop: -100,
    flex: 1,
  },
  contentContainer: {
    paddingHorizontal: 20,
    paddingTop: 48,
    display: 'flex',
    flex: 1,
  },
  contentContainerMobile: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  navButtonsWrapper: {
    marginVertical: 20,
  },
  subArrow: {
    display: 'block',
  },
  start: {
    marginLeft: 'auto',
  },
  formWrapper: {
    display: 'flex',
    flex: 1,
  },
})

function getNewEventTemplate(venueId) {
  // doing nearest interval since times are in increments of 30
  // also need to adjust for timezone :/
  const start = adjustForTimezone(nearestInterval(30, false), false)
  const e = new Date(start)
  const THREE_HOURS = 3 * 60 * 60 * 1000
  const end = new Date(e.setTime(e.getTime() + THREE_HOURS)) // make the endDate 3 hours from the start

  return {
    id: 'null', // id can't be null or queries will break
    name: '', // name is required
    date: start.toISOString(), // date is required
    endDate: end.toISOString(),
    description: '',
    categories: [], // need an empty array
    photos: [], // need an empty array
    hidden: true, // to do: change to published false (or won't need to require in future)
    free: false, // to do: update free input to handle null as false
    venue: {
      id: venueId, // need to the venueId to grab photos
    },
    isTicketedEvent: false,
    recurring: false,
  }
}
//Duplicate event now auto-pushes event to one full week ahead
//with the exact same timeslot and other parameters
function formatDuplicateEvent(Event) {
  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000
  const origStartDate = Date.parse(Event.date)
  const origEndDate = Date.parse(Event.endDate)
  const dupStartDate = new Date(origStartDate + ONE_WEEK)
  const dupEndDate = new Date(origEndDate + ONE_WEEK)

  return {
    ...Event,
    date: dupStartDate,
    endDate: dupEndDate,
    photosIds: Event.photos.map(x => x.id),
    categoriesIds: Event.categories.map(x => x.id),
    ageGroupId: Event.ageGroup && Event.ageGroup.id,
    venueId: Event.venue && Event.venue.id,
  }
}

class EventForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sentDuplicate: false,
      currentTab:
        props.eventId && props.currentTab ? props.currentTab : INFO_TAB,
    }
  }

  componentDidMount() {
    if (
      this.props.duplicate &&
      this.props.data.Event &&
      this.state.sentDuplicate === false
    ) {
      const formattedEvent = formatDuplicateEvent(this.props.data.Event)
      this.setState({ sentDuplicate: true }, () =>
        this.props.handleDuplicate(formattedEvent),
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTab && nextProps.eventId) {
      this.setState({ currentTab: nextProps.currentTab })
    } else if (!nextProps.currentTab) {
      this.setState({ currentTab: INFO_TAB })
    }

    if (
      nextProps.duplicate &&
      nextProps.data.Event &&
      this.state.sentDuplicate === false
    ) {
      const formattedEvent = formatDuplicateEvent(nextProps.data.Event)
      this.setState({ sentDuplicate: true }, () =>
        this.props.handleDuplicate(formattedEvent),
      )
    }
  }

  onEventBreadcrumbPress = event => {
    this.setState({ currentTab: event.state })
    this.props.handleCurrentTabChanged(event.state)
    this.goToTop()
  }

  showTicketingComponent = (sectionId, initialTicketValues) => {
    if (sectionId !== '#tickets') {
      return true
    }

    if (initialTicketValues.isTicketedEvent) {
      return true
    }

    return false
  }

  addTicketButton = (initialTicketValues, isPermitted, eventId) => {
    if (initialTicketValues.isTicketedEvent && isPermitted) {
      return (
        <FormButton
          text={'+ Add Ticket'}
          textColor={colors.teal}
          outerStyle={{ marginLeft: 'auto', marginRight: 'auto' }}
          handlePress={() => this.props.handleNewTicketOption(eventId)}
        />
      )
    }

    return <React.Fragment />
  }

  validateEventForm = values => {
    const { eventId } = this.props

    let errors = {}
    if (eventId) {
      if (!values.name || values.name === '') {
        errors.name = 'A name for your event is required!'
      }
      if (values.date === values.endDate) {
        errors.endDate = 'Start date cannot equal end date!'
      }
      if (!values.date) {
        errors.date = 'A start date is required!'
      }
      if (!values.endDate) {
        errors.endDate = 'End Date required!'
      } else if (values.endDate < values.date) {
        errors.endDate = "The end date can't before before the start date!"
      }
      if (!values.ageGroupId) {
        errors.ageGroupId = 'Age group is required!'
      }

      if (!values.categoriesIds || values.categoriesIds.length === 0) {
        errors.categoriesIds = 'Category Type is required!'
      }
    }

    return errors
  }

  goToTop = () => {
    this.scrollView.scrollTo({ x: 0, y: 0, animated: true })
  }

  render() {
    switch (this.state.currentTab) {
      case INFO_TAB:
        return this.getInfoTabView()
        break

      case TICKET_TAB:
        return this.getTicketTabView()
        break

      case PUBLISH_TAB:
        return this.getPublishTabView()
        break
    }
  }

  getInfoTabView() {
    const { eventId, venueId, duplicate } = this.props
    const {
      Event,
      allEventCategories,
      allAgeGroups,
      loading,
      error,
    } = this.props.data

    // if we're createing an event, we need to create an empty object with
    // certain to keys to pre-populate the form
    const newEventObj = getNewEventTemplate(venueId)
    const initialValues = Event ? Event : newEventObj
    initialValues.venueId = initialValues.venue.id

    // for now, let's only show the first section when someone is createing a new event
    const sectionsToDisplay = duplicate
      ? infoSections.slice(0, 1)
      : eventId
      ? infoSections.slice(1)
      : infoSections.slice(0, 1)

    return (
      <Mutation
        mutation={UPDATE_EVENT_INFO}
        refetchQueries={mutationResult => [
          {
            query: EVENT_QUERY,
            variables: { id: mutationResult.data.updateEvent.id },
          },
        ]}
      >
        {(updateEvent, { data }) => (
          <ScrollView
            ref={c => (this.scrollView = c)}
            contentContainerStyle={s.wrapper}
            scrollsToTop={true}
          >
            <ContentContainer
              style={s.contentContainer}
              smallStyle={s.contentContainerMobile}
            >
              {loading || error ? (
                <ActivityIndicator />
              ) : (
                <React.Fragment>
                  <EventBreadcrumb
                    isEdit={eventId}
                    onPress={this.onEventBreadcrumbPress}
                    currentTab={this.state.currentTab}
                  />
                  <Formik
                    ref={this.props.formRef}
                    enableReinitialize={true}
                    initialValues={{
                      ageGroupId:
                        initialValues.ageGroup && initialValues.ageGroup.id,
                      categoriesIds: initialValues.categories.map(x => x.id),
                      ...initialValues,
                    }}
                    isInitialValid={({ initialValues, validate }) =>
                      validate(initialValues)
                    }
                    validate={this.validateEventForm}
                    onSubmit={async (values, actions) => {
                      console.log('info form submit values', values)
                      try {
                        // lets not insert names or descriptions that look like ' ' or ''
                        const variables = sanitizeValues(values, [
                          'name',
                          'description',
                          'ticketUrl',
                        ])

                        const res = await updateEvent({ variables })

                        console.log(
                          `res.data.updateEvent`,
                          res.data,
                          res.data['updateEvent'],
                        )

                        actions.setSubmitting(false)

                        this.onEventBreadcrumbPress({ state: 'ticket' })
                      } catch (error) {
                        console.log('error', error)
                        // actions.setErrors(parseAuthErrors(error.toString()))
                        actions.setSubmitting(false)
                      }
                    }}
                    render={props => {
                      return (
                        <React.Fragment>
                          <View style={s.contentContainer}>
                            <ScrollView>
                              {sectionsToDisplay.map((x, i) => (
                                <Section
                                  key={i}
                                  props={props}
                                  allAgeGroups={allAgeGroups}
                                  allEventCategories={allEventCategories}
                                  initialValues={initialValues}
                                  handleEventAdded={this.props.handleEventAdded}
                                  {...x}
                                />
                              ))}
                            </ScrollView>
                            {(() => {
                              if (duplicate || eventId) {
                                return (
                                  <View style={s.navButtonsWrapper}>
                                    <View style={[s.navButtons]}>
                                      <FormButton
                                        text={'Next'}
                                        textColor={colors.teal}
                                        disabled={!props.isValid}
                                        handlePress={props.handleSubmit}
                                      />
                                    </View>
                                  </View>
                                )
                              }
                            })()}
                          </View>
                        </React.Fragment>
                      )
                    }}
                  />
                </React.Fragment>
              )}
            </ContentContainer>
          </ScrollView>
        )}
      </Mutation>
    )
  }

  getTicketTabView() {
    const { eventId, venueId, duplicate } = this.props
    const {
      Event,
      allEventCategories,
      allAgeGroups,
      loading,
      error,
    } = this.props.data
    const newEventObj = getNewEventTemplate(venueId)
    const permissions = []

    return (
      <ScrollView
        ref={c => (this.scrollView = c)}
        contentContainerStyle={s.wrapper}
        scrollsToTop={true}
      >
        <ContentContainer
          style={s.contentContainer}
          smallStyle={s.contentContainerMobile}
        >
          {loading || error ? (
            <ActivityIndicator />
          ) : (
            <WithSelectedVenuePermission
              permission={'MANAGE_VENUE_EVENTS_TICKETS'}
            >
              {({ permission }) => {
                if (permission) {
                  permissions.push(permission)
                }

                return (
                  <React.Fragment>
                    <EventBreadcrumb
                      isEdit={eventId}
                      onPress={this.onEventBreadcrumbPress}
                      currentTab={this.state.currentTab}
                    />
                    <View style={s.formWrapper}>
                      <ScrollView>
                        {ticketSections.map((x, i) => {
                          const showComponent = this.showTicketingComponent(
                            x.id,
                            Event ? Event : newEventObj,
                          )
                          if (!showComponent) {
                            return
                          }

                          return (
                            <Section
                              key={i}
                              initialTicketValues={Event ? Event : newEventObj}
                              permissions={permissions}
                              {...x}
                            />
                          )
                        })}
                        {this.addTicketButton(
                          Event ? Event : newEventObj,
                          permission ? true : false,
                          eventId,
                        )}
                      </ScrollView>
                      <View style={s.navButtonsWrapper}>
                        <View style={[s.navButtons]}>
                          <FormButton
                            text={'Back'}
                            textColor={colors.teal}
                            handlePress={() =>
                              this.onEventBreadcrumbPress({ state: 'info' })
                            }
                          />
                          <FormButton
                            text={'Next'}
                            textColor={colors.teal}
                            handlePress={() =>
                              this.onEventBreadcrumbPress({ state: 'publish' })
                            }
                          />
                        </View>
                      </View>
                    </View>
                  </React.Fragment>
                )
              }}
            </WithSelectedVenuePermission>
          )}
        </ContentContainer>
      </ScrollView>
    )
  }

  getPublishTabView() {
    const { eventId, venueId, duplicate, handleBack } = this.props
    const { Event, loading, error } = this.props.data

    // if we're createing an event, we need to create an empty object with
    // certain to keys to pre-populate the form
    const newEventObj = getNewEventTemplate(venueId)
    const initialValues = Event ? Event : newEventObj

    return (
      <Mutation
        mutation={UPDATE_EVENT_IS_PUBLISHED}
        refetchQueries={mutationResult => [
          {
            query: EVENT_QUERY,
            variables: { id: mutationResult.data.updateEvent.id },
          },
        ]}
      >
        {(updateEventIsPublished, { data }) => (
          <ScrollView
            ref={c => (this.scrollView = c)}
            contentContainerStyle={s.wrapper}
            scrollsToTop={true}
          >
            <ContentContainer
              style={s.contentContainer}
              smallStyle={s.contentContainerMobile}
            >
              {loading || error ? (
                <ActivityIndicator />
              ) : (
                <React.Fragment>
                  <EventBreadcrumb
                    isEdit={eventId}
                    onPress={this.onEventBreadcrumbPress}
                    currentTab={this.state.currentTab}
                  />
                  <Formik
                    ref={this.props.formRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    isInitialValid={({ initialValues, validate }) =>
                      validate(initialValues)
                    }
                    validate={values => {
                      const isNew = values.id === 'null'
                      let errors = {}

                      // console.log('errors', errors)
                      return errors
                    }}
                    onSubmit={async (values, actions) => {
                      console.log('publishing form submit values', values)
                      try {
                        const variables = values

                        const res = await updateEventIsPublished({ variables })

                        console.log(
                          'updateEvent',
                          res.data,
                          res.data['updateEvent'],
                        )
                        actions.setSubmitting(false)
                      } catch (error) {
                        console.log('error', error)
                        // actions.setErrors(parseAuthErrors(error.toString()))
                        actions.setSubmitting(false)
                      }
                    }}
                    render={props => {
                      const sectionsToDisplay = publishSections
                      return (
                        <React.Fragment>
                          <View style={s.contentContainer}>
                            <ScrollView>
                              {sectionsToDisplay.map((x, i) => (
                                <Section key={i} props={props} {...x} />
                              ))}
                            </ScrollView>
                            <View style={s.navButtonsWrapper}>
                              <View style={[s.navButtons]}>
                                <FormButton
                                  text={'Back'}
                                  textColor={colors.teal}
                                  handlePress={async () => {
                                    await props.submitForm()
                                    this.onEventBreadcrumbPress({
                                      state: 'ticket',
                                    })
                                  }}
                                />
                                <FormButton
                                  text={'Complete'}
                                  textColor={colors.teal}
                                  disabled={!props.isValid}
                                  handlePress={async () => {
                                    await props.submitForm()
                                    handleBack()
                                  }}
                                />
                              </View>
                            </View>
                          </View>
                        </React.Fragment>
                      )
                    }}
                  />
                </React.Fragment>
              )}
            </ContentContainer>
          </ScrollView>
        )}
      </Mutation>
    )
  }
}

export default graphql(EVENT_QUERY, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: { id: props.eventId ? props.eventId : 'null' },
  }),
})(EventForm)
