import React from 'react'
import { StyleSheet, View } from 'react-native'
import { LabelInput, BaseText } from '../../../components'
import { DimensionsConsumer } from '../../../lib'

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  wrapper: {
    flexDirection: 'row',
    flex: 4,
  },
  filler: {
    flex: 1,
  },
  labelInputWrapperStyle: {
    marginBottom: 0,
    paddingBottom: 0,
    borderWidth: 0,
    marginRight: 8,
    flex: 1,
  },
  textInputCenterAlign: {
    textAlign: 'center',
  },
  textInputStyle: {
    padding: 10,
    paddingRight: 10,
    backgroundColor: '#2a2a34',
    width: '100%',
  },
})

export const TicketsAllowedPerOrder = ({ additionalProps }) => {
  return (
    <DimensionsConsumer>
      {({ width }) => {
        return (
          <React.Fragment>
            <View style={[s.container]}>
              <View style={[s.wrapper]}>
                <LabelInput
                  collapseLabel={true}
                  label={'Minimum'}
                  name={'minimumAllowed'}
                  onChangeText={additionalProps.setFieldValue}
                  onChangeTextOnlyOnBlur={true}
                  value={
                    additionalProps.values.minimumAllowed
                      ? additionalProps.values.minimumAllowed.toString()
                      : ''
                  }
                  wrapperStyle={s.labelInputWrapperStyle}
                  textInputStyle={[s.textInputStyle, s.textInputCenterAlign]}
                  textInputKeyboardType="numeric"
                  autoComplete={'off'}
                />
                <LabelInput
                  collapseLabel={true}
                  label={'Maximum'}
                  name={'maximumAllowed'}
                  onChangeText={additionalProps.setFieldValue}
                  onChangeTextOnlyOnBlur={true}
                  value={
                    additionalProps.values.maximumAllowed
                      ? additionalProps.values.maximumAllowed.toString()
                      : ''
                  }
                  wrapperStyle={s.labelInputWrapperStyle}
                  textInputStyle={[s.textInputStyle, s.textInputCenterAlign]}
                  textInputKeyboardType="numeric"
                  autoComplete={'off'}
                />
              </View>
              <View style={[s.filler]} />
            </View>
            {additionalProps.errors.minimumAllowed && (
              <BaseText
                fs={12}
                color={'pink'}
                children={additionalProps.errors.minimumAllowed}
              />
            )}
          </React.Fragment>
        )
      }}
    </DimensionsConsumer>
  )
}
