import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import CalendarPicker from 'react-native-calendar-picker'
import Modal from 'modal-enhanced-react-native-web'
import { colors, fonts, formatShortDate } from '../../functions'
import { FormButton, FixedWidthContainer } from '../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
  },
  inputStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 120,
    backgroundColor: colors.primary3,
    paddingHorizontal: 13, // matches styles from TimeInput (AutoCompletWeb)
    paddingVertical: 7, // matches styles from TimeInput (AutoCompletWeb)
  },
  datePlaceholder: {
    fontFamily: fonts.light,
    color: colors.white,
    fontSize: 14,
    lineHeight: 24,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: 420,
    height: 420,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary3,
    paddingHorizontal: 11,
    paddingVertical: 13,
    borderRadius: 20,
  },
  calendarCurrentDayStyle: {
    color: colors.white,
  },
  // sets style for the View, not Text. need to create
  // a PR in `react-native-calendar-picker` to fix
  // calendarSelectedDayStyle: {
  //   fontFamily: fonts.bold,
  //   color: colors.white,
  //   backgroundColor: colors.teal,
  // },
  calendarTextStyle: {
    fontFamily: fonts.regular,
    fontSize: 12,
    lineHeight: 17,
    color: colors.white,
  },
  disabled: {
    color: colors.secondary2
  }
})

class DateInput extends React.Component {
  state = {
    modalIsVisible: false,
  }

  toggleModal = () => {
    return this.setState({ modalIsVisible: !this.state.modalIsVisible })
  }

  setDate = newDate => {
    this.props.onChange(this.props.name, newDate)
  }

  render() {
    const { wrapperStyle, disabled } = this.props
    const { modalIsVisible } = this.state
    const baseDate = this.props.value || new Date()
    return (
      <View style={[s.wrapper, wrapperStyle && wrapperStyle]} pointerEvents={(disabled) ? 'none' : 'auto'}>
        <TouchableOpacity
          style={s.inputStyle}
          activeOpacity={0.8}
          onPress={this.toggleModal}
        >
          <Modal
            isVisible={modalIsVisible}
            onBackdropPress={() => this.setState({ modalIsVisible: false })}
          >
            <FixedWidthContainer
              style={s.modal}
              width={420}
              padding={44} // normally 22, but adding some extra here
            >
              <CalendarPicker
                initialDate={baseDate} // sets the initial day to start on (really just sets the month)
                selectedStartDate={baseDate} // sets the selected date
                allowRangeSelection={false} // default is false, but notating just to be certain about our usage
                weekdays={['S', 'M', 'T', 'W', 'T', 'F', 'S']} // override weekday labels
                previousTitle={'<'} // override 'Previous' title
                nextTitle={'>'} // override 'Next' title
                textStyle={s.calendarTextStyle} // sets the style of all text
                selectedDayColor={colors.teal} // sets the style of 'today' (default is green background)
                selectedDayTextColor={colors.white} // sets the style of 'today' (default is green background)
                todayTextStyle={s.calendarCurrentDayStyle} // sets the style of 'today' (default to gray background)
                todayBackgroundColor={colors.opaque} // sets the style of 'today' (default to gray background)
                height={320}
                onDateChange={this.setDate}
              />
              <FormButton
                text={'Accept'}
                textColor={colors.teal}
                outerStyle={{ marginTop: 25 }}
                // disabled={disabled}
                // isSubmitting={isSubmitting}
                handlePress={this.toggleModal}
              />
            </FixedWidthContainer>
          </Modal>
          <Text
            style={[s.datePlaceholder, disabled && s.disabled ]}
            children={formatShortDate(baseDate)}
          />
        </TouchableOpacity>
      </View>
    )
  }
}

export default DateInput
