import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors, hexToRgba } from '../../functions'

const s = StyleSheet.create({
  lineBreakBase: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.5),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    padding: 1,
  },
})

export const LineBreak = ({ style }) => (
  <View style={[s.lineBreakBase, style && style]} />
)
