import gql from 'graphql-tag'

export const VENUE_META_QUERY = gql`
  query {
    record: _allVenuesMeta {
      count
    }
  }
`

export const USER_META_QUERY = gql`
  query {
    record: _allUsersMeta {
      count
    }
  }
`

export const VENUE_REPORT_QUERY = gql`
  query($limit: Int, $offset: Int) {
    report: allVenues(first: $limit, skip: $offset) {
      name
      address
      phoneNumber
      intlPhoneNumber
      website
      description
      photos {
        profile
        published
        feature
      }
      hours {
        day
        startTime
        endTime
      }
      hours {
        day
        startTime
        endTime
      }
      happyHours {
        day
        startTime
        endTime
      }
      menus {
        id
      }
      happyHourDetails
      specials {
        name
      }
      categories {
        name
      }
      social {
        instagramHandle
        facebookUrl
        yelpId
      }
      events {
        id
      }
      createdAt
      updatedAt
      published
    }
  }
`

export const USER_REPORT_QUERY = gql`
  query($limit: Int, $offset: Int) {
    report: allUsers(first: $limit, skip: $offset) {
      id
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      organizations {
        id
        name
        owner {
          id
        }
        permissions {
          id
          permission
          targetVenue {
            id
            name
          }
        }
      }
    }
  }
`
