import React from 'react'
import * as t from 'prop-types'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { colors, hexToRgba, toCurrency } from '../../../functions'
import { BaseText, MIcon } from '../../../components'

const s = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  priceWrapper: {
    minWidth: 67,
  },
  linebreak: {
    display: 'flex',
    alignSelf: 'stretch',
    backgroundColor: hexToRgba(colors.secondary1, 0.2),
    height: 2,
    minHeight: 2,
    maxHeight: 2,
    marginBottom: 20,
    marginTop: 14,
  },
  lastLinebreak: {
    marginBottom: 2, // may not be exact to design spec
  },
})

const SpecialName = props => (
  <BaseText
    fs={14}
    lh={24}
    color={'secondary2'}
    numberOfLines={1}
    ellipsizeMode={'tail'}
    {...props}
  />
)

const SpecialPrice = ({ price, ...rest }) => {
  const text = price ? `$${toCurrency(price)}` : '$ -'
  return (
    <View style={s.priceWrapper}>
      <BaseText
        fs={14}
        lh={24}
        color={'secondary3'}
        numberOfLines={1}
        ellipsizeMode={'tail'}
        children={text}
        {...rest}
      />
    </View>
  )
}

const SpecialDescription = props => (
  // marginTop={2} is not in the design, but i think it's needed here
  <BaseText
    fs={14}
    lh={19}
    mt={2}
    color={'secondary2'}
    weight={'italic'}
    {...props}
  />
)

export class SpecialItem extends React.Component {
  state = {
    opened: false,
  }

  _handleOpen = () => {
    this.setState({ opened: !this.state.opened })
  }

  render() {
    const { opened } = this.state
    const { name, description, price, first, last } = this.props

    return (
      <View style={s.wrapper}>
        {first && <View style={s.linebreak} />}
        <View style={s.mainRow}>
          <View style={s.row}>
            <SpecialPrice price={price} mr={26} />
            <SpecialName children={name} />
          </View>
          <View style={s.row}>
            {description && (
              <TouchableOpacity onPress={this._handleOpen}>
                <MIcon
                  name={`arrow-drop-${opened ? 'up' : 'down'}`}
                  color={colors.teal}
                  size={24}
                  style={{ marginLeft: 10 }}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
        {opened && <SpecialDescription children={description} />}
        <View style={[s.linebreak, last && s.lastLinebreak]} />
      </View>
    )
  }
}

SpecialItem.propTypes = {
  name: t.string.isRequired,
  first: t.bool.isRequired,
  price: t.number,
  description: t.string,
}

SpecialItem.defaultProps = {
  name: 'Bud light draft',
  price: 5,
}
